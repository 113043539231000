import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineClear } from "react-icons/md";
import { toast } from "react-hot-toast";
import { instance } from "common/Instance";
import { UPDATE_ICON_LINK } from "../../../../../redux/reducerSlice/arSlice";
import imageCompression from 'browser-image-compression';
export default function UploadIcon({ open, handleClose, id }) {
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.ar.data);
  const dispatch = useDispatch();
  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;
      console.log(files, "imageFiles");

      if (files) {
        const imageFile = files[0];
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "image/jpg, image/svg",
        ];
        if (!allowedTypes.includes(imageFile.type)) {
          setLoading(false);
          toast.error("Please select a .jpg, .jpeg, .svg or .png  file");
          return;
        }

        const fileSizeInMB = imageFile.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
          setLoading(false);
          toast.error("File size should be less than 2 MB");
          return;
        }

        const imageFiles = Array.from(files).filter((file) =>
          file.type.startsWith("image/")
        );
        const imageElements = imageFiles.map((file) => {
          const image = new Image();
          image.src = URL.createObjectURL(file);
          return image;
        });


        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }

        const compressedBlob = await imageCompression(imageFile, options);
        console.log('Compressed file:', compressedBlob);
        console.log(`Compressed file size: ${compressedBlob.size / 1024 / 1024} MB`);

        // Convert Blob to File
        const compressedFile = new File([compressedBlob], imageFile.name, {
          type: imageFile.type,
          lastModified: Date.now(),
        });


        const formData = new FormData();
        console.log(files, "files");
        formData.append("file", compressedFile);
        formData.append("assetType", "image");

        const response = await instance.post(
          `/api/ar/upload/${user.username}/${arData.name}`,
          formData,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          setLoading(false);
          const action = {
            urlLink: response?.data?.url,
            id: id,
          };

          dispatch(UPDATE_ICON_LINK(action));

          toast.success("Icon is Uploaded Successfully");
          handleClose();
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message ?? error.message);
      handleClose();
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: "fit",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
  };

  const iconLink = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find((data) => data.id === id);
    return icon?.src;
  });
  return (
    <Modal open={open} onClose={handleClose}>
      <div>
        <Box sx={style}>
          <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
            <p className="text-lg font-bold ">Immarsify</p>
            <span onClick={handleClose} className="cursor-pointer">
              <MdOutlineClear size={20} />
            </span>
          </div>

          <div className="mt-2 flex h-full w-full flex-col items-center justify-center gap-2 ">
            <label
              htmlFor="fileInput"
              className="flex  items-center justify-center rounded-md    p-2 text-white  dark:!border-navy-700 lg:pb-0"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/png, image/jpeg, image/jpg, image/svg"
                className="hidden"
                onChange={(e) => {
                  handleFileUpload(e);
                }}
              />

              <div className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white text-sm">
                Select Icon
              </div>
            </label>

            <p className="text-md mt-2 font-bold">
              Supported Format :- JPEG, JPG, PNG, SVG
            </p>
            <span className=" mb-2 text-xs">
              Icon Should be less than or equal to 2 mb
            </span>
          </div>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <h1 className="font-bold">Uploading Icon</h1>
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </div>
    </Modal>
  );
}
