import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import { instance } from "common/Instance";
import { useFormik } from "formik";
import Skeleton from "@mui/material/Skeleton";
import * as Yup from "yup";
import {
  SET_UPDATE_STATE_AR,
  SET_INITIAL_STATE_AR,
  SET_TEMPLATE_DATA,
  SET_TEMPLATE_TOTAL_PAGE,
  SET_TEMPLATE_CURRENT_PAGE,
} from "../../../redux/reducerSlice/arSlice";
import InputField from "components/fields/InputField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { setViewCreated } from "../../../redux/reducerSlice/valueSlice";
import Pagination from "@mui/material/Pagination";
import { Tooltip } from "@mui/material";

const style = {
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  transform: "translate(-50%, -50%)",
  borderRadius: "0.5rem",
};

const TemplateList = () => {
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);

  const [itemsPerPage, setSelectedValue] = useState(6);
  const templateData = useSelector((state) => state.ar.templateData);
  const currentPage = useSelector((state) => state.ar.currentPage);
  const totalPages = useSelector((state) => state.ar.totalPages);
  console.log(totalPages);
  const dispatch = useDispatch();

  const handleChangeRows = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setSelectedValue(newItemsPerPage);

    const newPage = Math.min(
      currentPage,
      Math.ceil(templateData.length / newItemsPerPage)
    );
    dispatch(SET_TEMPLATE_CURRENT_PAGE(newPage));
  };

  const getTemplateData = async () => {
    if (!templateData) {
      setLoading(true);
    }

    try {
      const response = await instance.get(
        `/api/template/all_templates/BusinessCard/?page=${currentPage}&limit=${itemsPerPage}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_TEMPLATE_DATA(response?.data?.data));
        dispatch(SET_TEMPLATE_TOTAL_PAGE(response?.data?.totalPages));

        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTemplateData();
  }, [currentPage, itemsPerPage]);

  const handleChange = (event, value) => {
    dispatch(SET_TEMPLATE_CURRENT_PAGE(value));
    console.log(currentPage);
  };

  return (
    <div className="relative flex h-[100vh-20px] flex-col items-center ">
      
      <div className="sticky z-10 top-20  -mt-2 flex w-full flex-wrap items-center bg-gray-100  rounded-md   justify-between  p-2  ">
       
          <p className="text-sm font-bold">BusinessCard</p>
    
          <Tooltip title="Template per page:">
          <select
            className="ml-2 block  w-12  border-2 border-gray-600 rounded-md bg-gray-200  text-sm "
            placeholder="List"
            value={itemsPerPage}
            onChange={handleChangeRows}
          >
            {[...Array(11).keys()].map((_, index) => (
              <>
                {index > 0 && (
                  <option key={index} value={index * 5} className='p-4 rounded-none'>
                    {index * 5}
                  </option>
                )}
              </>
            ))}
          </select>
          </Tooltip>
          
      </div>

      <div className="mt-7 min-h-[65vh] w-full px-5">
        {loading ? (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4   ">
            {[...Array(6)].map((element) => (
              <Card sx={{ maxWidth: 450 }}>
                <div className="mt-2 flex flex-col gap-2 px-6 pb-2 pt-1 ">
                  <Skeleton
                    variant="rectangular"
                    height={150}
                    className="rounded-md"
                    animation="wave"
                  />

                  <Skeleton animation="wave" height={15} width="50%" />
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4  ">
            {templateData?.map((item, index) => (
              <CardTemplate item={item} key={index} />
            ))}
          </div>
        )}
      </div>

      {templateData?.length > 0 && (
        <div className="absolute -bottom-10 left-1/2 mt-10 -translate-x-1/2 transform">
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default TemplateList;

const CardTemplate = ({ item }) => {
  const historyData = useSelector((state) => state.ar.historyData);
  const allowedExperiencesCount = useSelector(
    (state) => state.auth.auth.user?.allowedExperiencesCount
  );
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  const handleNewAr = (item) => {
    dispatch(SET_INITIAL_STATE_AR());

    dispatch(SET_UPDATE_STATE_AR(item));
    handleOpen();
  };
  return (
    <div className="group relative flex max-w-[400px] cursor-pointer flex-col rounded-lg bg-white pb-2  shadow-lg ">
      <div className="flex items-center justify-center overflow-hidden rounded-md px-6   ">
        <img
          src={
            item.targetImage && item.targetImage.src
              ? item.targetImage.src
              : "https://liftlearning.com/wp-content/uploads/2020/09/default-image.png"
          }
          alt="hello"
          loading="lazy"
          className="mt-2 h-[150px]  rounded-md object-cover   "
        />
      </div>

      <div className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 duration-200 group-hover:opacity-100 ">
        <button
          className={`rounded-lg border border-gray-200 ${
            historyData?.length >
            (allowedExperiencesCount ? allowedExperiencesCount : 1)
              ? "bg-gray-600"
              : "bg-[#3d2fa9]"
          } p-2 text-sm font-normal text-white`}
          disabled={historyData?.length > 20}
          onClick={() => {
            if (
              historyData.length <
              (allowedExperiencesCount ? allowedExperiencesCount : 1)
            ) {
              handleNewAr(item);
            } else {
              toast.error(
                `The user has exceeded the limit for creating experiences`
              );
            }
          }}
        >
          Create experience
        </button>
      </div>

      <div className="mt-2 pl-6">
        <p className="text-font font-semibold text-blue-800">
          {item?.templateName ?? item.name}
        </p>
      </div>

      <CreateExperience open={open} handleClose={handleClose} />
    </div>
  );
};

const CreateExperience = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [experienceName, setExperienceName] = useState("");
  const [experienceAvailable, setExperienceAvailable] = useState(true);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.auth.auth.token);
  const arData = useSelector((state) => state.ar.data);
  const userName = useSelector((state) => state.auth.auth.user.username);
  const resellerId = useSelector(
    (state) => state?.auth?.auth?.user?.resellerId
  );

  const userid = useSelector((state) => state.auth.auth.user._id);

  const dispatch = useDispatch();

  const createARExperience = async () => {
    setLoading(true);
    try {
      const response = await instance.post(
        `/api/ar/create_experience`,
        {
          ...arData,
          userId: userid,
          userName: userName,
          name: name,
          arexperienceName: experienceName,
          ...(resellerId && { resellerId: resellerId }),
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        const _id = response.data.data.insertedId;

        dispatch(
          SET_UPDATE_STATE_AR({
            ...arData,
            userId: userid,
            userName: userName,
            name: name,
            arexperienceName: experienceName,
            _id,
            ...(resellerId && { resellerId: resellerId }),
          })
        );

        dispatch(setViewCreated(""));
        navigate(`/admin/ar_experience/${name}`);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const checkExperienceExist = async (nameparam) => {
    try {
      const response = await instance.post(
        "/api/ar/check_experience_name",
        {
          name: nameparam,
          userName: userName,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      setExperienceAvailable(response.data.data);
    } catch (error) {
      return false;
    }
  };
  const validationSchema = Yup.object({
    experienceName: Yup.string()
      .min(4, "Experience must be at least 4 characters")
      .required("Experience Name is required")
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        "Valid characters:letters,numbers,and underscores. "
      )
      .test("Ok", "Experience already exists", async () => {
        return experienceAvailable;
      }),
  });
  const formik = useFormik({
    initialValues: {
      experienceName: experienceName,
    },
    validationSchema: validationSchema,

    onSubmit: async () => {
      await createARExperience();
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div className="mt-2  flex  w-full flex-col justify-center p-4 ">
          <div className="flex w-full items-center justify-center gap-2">
            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Experience Name*"
                placeholder="Enter Experience Name"
                id="experienceName"
                type="text"
                onChange={(event) => {
                  formik.setFieldValue(
                    "experienceName",
                    event.target.value.toLowerCase()
                  );

                  setExperienceName(event.target.value.toLowerCase());
                  let inputValue = event.target.value.toLowerCase();
                  let result = inputValue.replace(/\s+/g, "-");
                  setName(result);
                  checkExperienceExist(result);
                }}
                value={formik.values.experienceName}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.experienceName && formik.errors.experienceName
                }
              />
            </div>
          </div>
          <div className="mt-2 text-center ">
            <button
              type="submit"
              onClick={formik.handleSubmit}
              className={`rounded-lg border  border-gray-200  ${
                !experienceAvailable ? "bg-gray-600" : "bg-brand-main"
              } p-2 text-sm font-normal text-white`}
              disabled={!experienceAvailable}
            >
              Create AR
            </button>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h3 className=" text-lg font-bold">Creating AR experience</h3>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
};
