import { useState, useEffect } from "react";
// import Chart from "react-apexcharts";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { instance } from "common/Instance";
// import { SET_INITIAL_STATE_ANALYTICS_WEEKLY } from "../../../../../redux/reducerSlice/analytics";
// import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import Card from "components/card";
// import BarChart from "components/charts/BarChart";
// import { barChartOptionsDailyTraffic } from "variables/charts";
// import { TECollapse } from "tw-elements-react";
import VideoThumbnail from "react-video-thumbnail";
import { Tooltip } from "@mui/material";

const Collapse = ({ children, isOpen, onToggle }) => {
  return (
    <div onClick={onToggle} className="mt-0 rounded-b-none shadow-none cursor-pointer">
      {/* <button onClick={onToggle} className="flex items-center p-2">
        {isOpen ? 'Hide' : 'Show'} Details
      </button> */}
      {isOpen && <div className="flex flex-wrap gap-2 p-1">{children}</div>}
    </div>
  );
};

const ExperienceCount = () => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const [videoCount, setVideoCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeElements, setActiveElements] = useState([]);
  const [totalCountSum, setTotalCountSum] = useState(0)

  const handleClick = (index) => {
    if (activeElements.includes(index)) {
      setActiveElements(activeElements.filter((i) => i !== index));
    } else {
      setActiveElements([...activeElements, index]);
    }
  };

  const fetchVideoCount = async () => {
    setLoading(true);
    try {
      const response = await instance.get(
        `api/visitors/get_visitors_videos_count`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response?.status === 200) {
        const videoData = response?.data?.data;
        const totalSum =
          videoData &&
          videoData.length > 0 &&
          videoData.reduce((sum, video) => {
            return sum + (video.totalVideoCount || 0);
          }, 0);
        setVideoCount(videoData);
        setTotalCountSum(totalSum); 
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.message || "Error fetching user data");
    }
    setLoading(false);
  };

  const isValidImageUrl = (url) => {
    const regex = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;
    return url && regex.test(url);
  };

  useEffect(() => {
    fetchVideoCount();
  }, []);

  return (
    <Card extra="pb-7 max-h-[447px] p-[20px]">
      {/* <div className="flex flex-row justify-between"> */}
      <div className="mb-2 ml-1 flex justify-between pt-2">
        <div>
          <p className="text-sm font-medium leading-4 text-gray-600">
            Total Videos
          </p>
          {loading ? (
            <div className="rounded-lg">
              <div className="flex animate-pulse py-3">
                <div className="h-6 w-12 rounded-xl bg-gray-500"></div>
              </div>
            </div>
          ) : (
            <>
              <p className="text-[34px] font-bold text-navy-700 dark:text-white">
                {videoCount && videoCount.length}
              </p>
              <p className="text-sm font-medium leading-6 text-gray-600">Count</p>
            </>
          )}
        </div>
        <div className="flex flex-col items-center">
          <p className="text-sm font-medium leading-4 text-gray-600">
            Total Clicks
          </p>
          {loading ? (
            <div className="rounded-lg">
              <div className="flex animate-pulse py-3">
                <div className="h-6 w-12 rounded-xl bg-gray-500"></div>
              </div>
            </div>
          ) : (
            <>
              <p className="text-[34px] font-bold text-navy-700 dark:text-white">
                {totalCountSum}
              </p>
            </>
          )}
        </div>

      </div>

      {loading ? (
        <div className="flex justify-center py-4">
          <span className="font-semibold text-gray-500">Loading...</span>
        </div>
      ) : videoCount && videoCount.length > 0 ? (
        videoCount.map((item, index) => (
          <div
            key={index}
            className="border-neutral-200 dark:border-neutral-600 dark:bg-neutral-800 mb-2 rounded-t-lg border bg-white"
          >
            <div className="item-center  flex justify-between px-2 pb-[8px] pt-1">
              <div className="h-10 w-24 rounded-lg object-cover">
                <VideoThumbnail
                  videoUrl={
                    item?.src && isValidImageUrl(item?.src)
                      ? item.src
                      : "https://dl.dropboxusercontent.com/s/7b21gtvsvicavoh/statue-of-admiral-yi-no-audio.mp4?dl=1"
                  }
                  renderThumbnail={true}
                  height={400}
                  width={400}
                />
              </div>
              <div className="g flex items-center">
                <p className="min-w-[80px] text-sm text-gray-400">
                  {item?.totalVideoCount} Click
                </p>
                <button
                  className={`${activeElements.includes(index)
                    ? "text-primary dark:!text-primary-400 max-w-[65px]"
                    : ""
                    } text-neutral-800 dark:bg-neutral-800 group relative flex w-full max-w-[65px] items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:text-white`}
                  type="button"
                  onClick={() => handleClick(index)}
                  aria-expanded={activeElements.includes(index)}
                  aria-controls={`collapse${index}`}
                >
                  <span
                    className={`${activeElements.includes(index)
                      ? "-mr-1 rotate-[-180deg]"
                      : "rotate-0 fill-gray-800 dark:fill-white"
                      } h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="rgb(156 163 175)"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>

            {/* <TECollapse
              show={activeElements.includes(index)}
              className="!mt-0 !rounded-b-none !shadow-none"
            >
              <div className="flex flex-wrap gap-2 p-1">
                {item?.socialCounts?.map((icon, iconIndex) => (
                  <div key={iconIndex}>
                    {isValidImageUrl(icon?.src) ? (
                      <div className="relative flex cursor-pointer gap-2 rounded-lg px-2 py-2 ">
                        <Tooltip
                          className="cursor-pointer"
                          title={icon?.name}
                          arrow
                          placement="top"
                        >
                          <img
                            src={icon?.src}
                            alt={icon?.name}
                            className="h-12 w-12 transform rounded-full object-cover transition-transform hover:scale-105"
                          />
                        </Tooltip>
                        <div className="absolute right-[2px] top-[2px] flex flex-col items-end">
                          <span className=" rounded-[50%] bg-red-500 px-[6px] py-[] text-sm text-white">
                            {icon?.totalCount}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </div>
            </TECollapse> */}
            <Collapse isOpen={activeElements.includes(index)} onToggle={() => handleClick(index)}>
              {item?.socialCounts?.map((icon, iconIndex) => (
                <div key={iconIndex}>
                  {isValidImageUrl(icon?.src) ? (
                    <div className="relative flex cursor-pointer gap-2 rounded-lg px-2 py-2">
                      <Tooltip title={icon?.name} arrow placement="top">
                        <img
                          src={icon?.src}
                          alt={icon?.name}
                          className="h-12 w-12 transform rounded-full object-cover transition-transform hover:scale-105"
                        />
                      </Tooltip>
                      <div className="absolute right-[2px] top-[2px] flex flex-col items-end">
                        <span className="rounded-[50%] bg-red-500 px-[6px] py-[2px] text-sm text-white">
                          {icon?.totalCount}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
            </Collapse>
          </div>
        ))
      ) : (
        <div className="flex w-full items-center justify-center rounded-lg bg-gray-200 py-4 shadow-md">
          <span className="font-semibold text-gray-500">
            No Videos
          </span>
        </div>
      )}
    </Card>
  );
};

export default ExperienceCount;
