import { createSlice } from "@reduxjs/toolkit";


const INITIAL_STATE = {
  userdata: {},
};

const signupexpSlice = createSlice({
  name: "signupexp",
  initialState: INITIAL_STATE,
  reducers: {
    SET_USERDATA_ON_SIGNUP: (state, action) => {
      state.userdata = action.payload;
    },
  },
});

export const { SET_USERDATA_ON_SIGNUP } = signupexpSlice.actions;
export default signupexpSlice.reducer;
