import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaMobileAlt, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardSixteen = React.forwardRef(
  ({ username, experiencename, name, user }, ref) => {
    return (
      <div
        ref={ref}
        className="relative h-[250px] w-[400px] overflow-hidden  bg-gradient-to-br from-gray-900 via-purple-900 to-indigo-900 shadow-xl"
      >
        <div className="absolute right-4 top-2  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#ffff" className="    top-3 " />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          {/* <img src={Logo("#ffff")} className=" right-1 top-4   h-4 w-7" /> */}

          <Logo color={"#ffff"} height={35} width={35} />
        </div>
        {/* 3D Artist-themed SVG Background */}
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* 3D Grid */}
          <defs>
            <pattern
              id="grid3d"
              width="30"
              height="30"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 30 0 L 0 0 0 30"
                fill="none"
                stroke="rgba(255,255,255,0.1)"
                strokeWidth="0.5"
              />
              <path
                d="M 0 30 L 30 0"
                fill="none"
                stroke="rgba(255,255,255,0.05)"
                strokeWidth="0.5"
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid3d)" />

          {/* Low-poly style elements */}
          <polygon points="350,0 400,50 400,0" fill="rgba(147,51,234,0.3)" />
          <polygon points="0,200 50,225 0,225" fill="rgba(79,70,229,0.3)" />

          {/* Wireframe cube */}
          <g transform="translate(50,50) scale(0.8) rotate(45)">
            <path
              d="M0,0 L40,0 L40,40 L0,40 Z"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="2"
            />
            <path
              d="M0,0 L20,-20 L60,-20 L40,0 Z"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="2"
            />
            <path
              d="M40,40 L60,20 L60,-20 M0,40 L20,20 L60,20"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="2"
            />
          </g>

          {/* 3D text */}
          <text
            x="280"
            y="180"
            fontSize="60"
            fill="none"
            stroke="rgba(255,255,255,0.1)"
            strokeWidth="1"
          >
            3D
          </text>

          {/* Rendering progress bar */}
          <rect
            x="300"
            y="50"
            width="80"
            height="10"
            rx="5"
            fill="none"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth="2"
          />
          <rect
            x="300"
            y="50"
            width="60"
            height="10"
            rx="5"
            fill="rgba(147,51,234,0.5)"
          />
        </svg>

        {/* Card Content */}
        <div className="relative z-10 flex h-full w-full flex-col items-center p-6 pt-12 text-white">
          <h2
            className={` mb-2 flex items-center justify-center break-words capitalize break-all ${
              name.length > 20 ? "text-2xl" : "text-3xl"
            }  font-bold `}
          >
              {name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
          </h2>

          <QRCodeSVG
            value={`https://sandboxar.immarsify.com/${username}/${experiencename}`}
            fgColor="#ffff"
            bgColor="transparent"
            className=" h-[115px] w-[115px] text-[#fdd446]"
          />
        </div>
      </div>
    );
  }
);

export default CardSixteen;


export const CardSixteenBackSide = React.forwardRef(
  ({ username, experiencename, name, user , cardData }, ref) => {
    return (
      <div
        ref={ref}
        className="relative h-[250px] w-[400px] overflow-hidden  bg-gradient-to-br from-gray-900 via-purple-900 to-indigo-900 shadow-xl"
      >
        <div className="absolute right-4 top-2  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#ffff" className="    top-3 " />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          {/* <img src={Logo("#ffff")} className=" right-1 top-4   h-4 w-7" /> */}

          <Logo color={"#ffff"} height={35} width={35} />
        </div>
        {/* 3D Artist-themed SVG Background */}
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* 3D Grid */}
          <defs>
            <pattern
              id="grid3d"
              width="30"
              height="30"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 30 0 L 0 0 0 30"
                fill="none"
                stroke="rgba(255,255,255,0.1)"
                strokeWidth="0.5"
              />
              <path
                d="M 0 30 L 30 0"
                fill="none"
                stroke="rgba(255,255,255,0.05)"
                strokeWidth="0.5"
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid3d)" />

          {/* Low-poly style elements */}
          <polygon points="350,0 400,50 400,0" fill="rgba(147,51,234,0.3)" />
          <polygon points="0,200 50,225 0,225" fill="rgba(79,70,229,0.3)" />

          {/* Wireframe cube */}
          <g transform="translate(50,50) scale(0.8) rotate(45)">
            <path
              d="M0,0 L40,0 L40,40 L0,40 Z"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="2"
            />
            <path
              d="M0,0 L20,-20 L60,-20 L40,0 Z"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="2"
            />
            <path
              d="M40,40 L60,20 L60,-20 M0,40 L20,20 L60,20"
              fill="none"
              stroke="rgba(255,255,255,0.2)"
              strokeWidth="2"
            />
          </g>

          {/* 3D text */}
          <text
            x="280"
            y="180"
            fontSize="60"
            fill="none"
            stroke="rgba(255,255,255,0.1)"
            strokeWidth="1"
          >
            3D
          </text>

          {/* Rendering progress bar */}
          <rect
            x="300"
            y="50"
            width="80"
            height="10"
            rx="5"
            fill="none"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth="2"
          />
          <rect
            x="300"
            y="50"
            width="60"
            height="10"
            rx="5"
            fill="rgba(147,51,234,0.5)"
          />
        </svg>

        {/* Card Content */}
        <div className="relative  p-4  text-sm break-all text-white">
        <div className="mt-8">

    
<h2 className={` font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`} }`}>
    {cardData?.companyName?.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')}
  </h2>

<div className="flex justify-center ">

<div className="flex flex-col gap-2 mt-4">


{cardData?.address && <p className="text-sm  flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" />
{cardData?.address}</p>}

{cardData?.email && <p className="text-sm  flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" />
{cardData?.email}</p>}

{cardData?.Phone && <p className="text-sm  flex gap-2"><FaMobileAlt className="min-w-5 min-h-5"/>
{cardData?.Phone}</p>}

</div>


</div>







</div>
        </div>
      </div>
    );
  }
);
