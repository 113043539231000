import React, { useState } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineClear } from "react-icons/md";
import Modal from "@mui/material/Modal";
import InputField from "components/fields/InputField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { instance } from "common/Instance";
import { useDispatch, useSelector } from "react-redux";
import { SET_UPDATE_STATE_AR } from "../../../../../redux/reducerSlice/worldTrackSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 180,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

function TemplateModal(open, arData) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state?.auth?.auth?.token ?? "");
  const userDetails = useSelector((state) => state?.auth?.auth?.user ?? {});
  const validationSchema = Yup.object({
    experienceName: Yup.string()
      .min(4, "Experience must be at least 4 characters")
      .required("Experience Name is required")
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Valid characters:letters,numbers,and underscores. No spaces allowed"
      )
      .test("Ok", "Experience already exists", async (value) => {
        try {
          const response = await instance.post(
            "/api/ar/check_experience_name",
            {
              name: value,
              username: userDetails?.userName,
            },
            {
              headers: {
                authorization: `Bearer ${userToken}`,
              },
            }
          );
          return response.data.data;
        } catch (error) {
          return false;
        }
      }),
  });



  const publishARExperience = async (values) => {

    console.log(userDetails?.resellerId)

    const data = {
      ...arData,

    };
    delete data._id;

    setLoading(true);
    try {
      const response = await instance.post(
        `/api/ar/create_experience`,
        {
          ...data,
          userName: userDetails?.userName,
          userId: userDetails?._id,
          name: values?.experienceName,
          ...(userDetails?.resellerId && { resellerId: userDetails.resellerId })
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        const _id = response.data.data.insertedId;
        dispatch(
          SET_UPDATE_STATE_AR({
            ...arData,
            userId: userDetails?._id,
            ...(userDetails?.resellerId && { resellerId: userDetails.resellerId }),
            _id,
          })
        );
        navigate(`/admin/World_Tracking_history/${arData.name}`);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      experienceName: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      await publishARExperience(values);
    },
  });

  return (
    <Modal open={open}>
      <Box sx={style}>
        <div className="my-2  flex  w-full flex-col justify-center p-4  ">
          <div className="flex w-full items-center justify-center gap-2">
            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Create Experience"
                placeholder=""
                id="experienceName"
                type="text"
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                value={formik.values.experienceName}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.experienceName && formik.errors.experienceName
                }
              />
            </div>
          </div>
          <div className="mt-2 text-center text-sm">
            <button
              onClick={formik.handleSubmit}
              type="submit"
              className="rounded-lg border border-gray-200  bg-[#3d2fa9]  p-2 text-sm font-normal text-white"
            >
              Create
            </button>
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h3 className=" text-lg font-bold ">Creating AR experience</h3>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
}

export default TemplateModal;
