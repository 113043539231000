import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import imageCompression from "browser-image-compression";

import { FaLink, FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { AiFillFacebook } from "react-icons/ai";

import { MdCloudUpload } from "react-icons/md";
import { SET_ICONS_PROPERTIES } from "../../../../redux/reducerSlice/arSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { SET_USERDATA } from "../../../../redux/reducerSlice/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  organization: Yup.string(),
  email: Yup.string().email("Invalid email").required("Email is required"),
  workPhone: Yup.string()
    .matches(/^[+\d]{10,13}$/, "Invalid phone number")
    .required("Work phone is required"),
  cellPhone: Yup.string()
    .matches(/^[+\d]{10,13}$/, "Invalid phone number")
    .required("Personal phone is required"),
  // birthday: Yup.date()
  //     .max(new Date(), 'Birthday cannot be in the future')
  //     .required('Birthday is required'),
  title: Yup.string(),
  url: Yup.string().url("Invalid URL"),
  socialUrls: Yup.object().shape({
    custom: Yup.string().url("Invalid Instagram Url"),
    facebook: Yup.string().url("Invalid Facebook Url"),
    twitter: Yup.string().url("Invalid Twitter Url"),
    linkedin: Yup.string().url("Invalid Linkedin Url"),
  }),

  note: Yup.string(),
});

function VCard({ open, handleClose, id }) {
  const dispatch = useDispatch();
  const VcardDetails = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find((data) => data.id === id);
    return icon?.vCardJson;
  });

  const VcardPhoto = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find((data) => data.id === id);
    return icon?.vCardJson;
  });

  const userToken = useSelector((state) => state.auth.auth.token);
  const arData = useSelector((state) => state.ar.data);

  const userName = useSelector((state) => state.auth.auth.user.username);
  const user = useSelector((state) => state.auth.auth.user);

  const [imageSrc, setImageSrc] = useState(null);
  const [photoSrc, setPhotoSrc] = useState("");

  const StoreImage = useSelector((state) => {
    const icon = state?.ar?.data?.icons?.find((data) => data.id === id);
    return icon?.vCardJson?.photo;
  });

  const uploadImage = async (imageFile) => {
    try {
      console.log("working");
      const fileSizeInMB = imageFile.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        // setLoading(false);
        toast.error("File size should be less than 2 MB");
        return;
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedBlob = await imageCompression(imageFile, options);
      console.log("Compressed file:", compressedBlob);
      console.log(
        `Compressed file size: ${compressedBlob.size / 1024 / 1024} MB`
      );

      // Convert Blob to File
      const compressedFile = new File([compressedBlob], imageFile.name, {
        type: imageFile.type,
        lastModified: Date.now(),
      });

      const formData = new FormData();
      formData.append("file", compressedFile);
      formData.append("assetType", "image");

      const response = await instance.post(
        `/api/ar/upload/${userName}/${arData.name}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
      if (response.status === 200) {
        return response?.data?.url;
        // setLoading(false);
      }
    } catch (err) {
      console.log("error uploading image", err);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      setImageSrc(reader.result);
      const photo = await uploadImage(file);
      setImageSrc(photo);
      setPhotoSrc(photo);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const updateUserData = async (values) => {
   console.log(values,'rohit11')
    try {
      const response = await instance.post(
        `/api/user/update_user_details/${user._id}`,
        { city: values?.homeAddress?.city, state: values?.homeAddress?.state },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_USERDATA(response?.data?.data));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const handleSubmit = async (action) => {
    try {
      const updatedValue = {
        ...action.value,
        workUrl: `https://sandboxar.immarsify.com/${userName}/${arData.name}`,
        photo: photoSrc,
      };

      console.log(action,'rohit1 onedrive')

      updateUserData(action?.value);
      // Create a new action object with the updated value
      const updatedAction = {
        ...action,
        value: updatedValue,
      };

      dispatch(SET_ICONS_PROPERTIES(updatedAction));

      const actionJson = {
        key: "vCard",
        id: id,
        value: updatedAction.value,
      };

      dispatch(SET_ICONS_PROPERTIES(actionJson));
      toast.success("File Added successfully");
    } catch (error) {
      console.log("Error", error);
      toast.error("V card error");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="overflow-y-auto"
    >
      <Box
        sx={{
          ...style,
          width: "90%",
          maxWidth: "100%",
          maxHeight: "90vh",
          overflowY: "auto",
          margin: "auto",
          "@media (min-width: 768px)": {
            width: "auto",
          },
        }}
        className="rounded-lg"
      >
        <Formik
          initialValues={{ ...VcardDetails }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const action = {
              key: "vCardJson",
              id: id,
              value: values,
            };
            handleSubmit(action);
            handleClose();
          }}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col items-center text-sm">
              <h1 className=" flex w-full justify-center rounded-t-lg py-2 font-semibold text-blue-500">
                V-Card
              </h1>

              <div className="flex w-full flex-col items-center px-3 py-2">
                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex w-full flex-col items-center py-1">
                    {/* Display uploaded image or dummy link */}
                    {StoreImage ? (
                      <img
                        src={StoreImage}
                        alt="Uploaded"
                        className="h-40 w-40 rounded-full"
                      />
                    ) : user.image ? (
                      <img
                        src={user.image}
                        alt="Uploaded"
                        className="h-40 w-40 rounded-full"
                      />
                    ) : imageSrc ? (
                      <img
                        src={imageSrc}
                        alt="Uploaded"
                        className="h-40 w-40 rounded-full"
                      />
                    ) : (
                      <img
                        src="https://t4.ftcdn.net/jpg/02/44/43/69/360_F_244436923_vkMe10KKKiw5bjhZeRDT05moxWcPpdmb.jpg"
                        alt="Dummy"
                        className="h-40 w-40 rounded-full border border-blue-400"
                      />
                    )}
                    {/* File input to upload image */}
                    <div className="w-full px-2">
                      <label
                        htmlFor="fileInput"
                        className="my-2 flex w-full  cursor-pointer justify-center rounded-md border border-blue-500 hover:bg-gray-50"
                      >
                        {/* Add your custom icon here */}
                        <MdCloudUpload className="text-2xl text-blue-400" />
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        onChange={handleFileChange}
                        className="hidden"
                      />
                    </div>
                  </div>
                  <div className="flex w-full flex-col">
                    <div className="flex w-full px-2 ">
                      <h1 className="border-b border-black font-semibold">
                        Personal Details
                      </h1>
                    </div>

                    <div className="flex w-full flex-col ">
                      <div className="flex w-full flex-col p-2 text-sm">
                        <label
                          htmlFor="firstName"
                          className="flex items-center gap-1 text-xs"
                        >
                          First Name
                        </label>
                        <Field
                          type="text"
                          name="firstName"
                          className="rounded-md border border-blue-400 p-1 outline-none"
                        />
                        <ErrorMessage name="firstName">
                          {(msg) => (
                            <div className="text-xs text-red-500">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="flex w-full flex-col p-2 text-sm">
                        <label
                          htmlFor="lastName"
                          className="flex items-center gap-1 text-xs "
                        >
                          Last Name
                        </label>
                        <Field
                          type="text"
                          name="lastName"
                          className="rounded-md border border-blue-400 p-1 outline-none"
                        />
                        <ErrorMessage name="lastName">
                          {(msg) => (
                            <div className="text-xs text-red-500">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="flex w-full flex-col p-2 text-sm">
                        <label
                          htmlFor="cellPhone"
                          className="flex items-center gap-1 text-xs"
                        >
                          Personal Phone
                        </label>
                        <Field
                          type="tel"
                          name="cellPhone"
                          className="rounded-md border border-blue-400 p-1 outline-none"
                        />
                        <ErrorMessage name="cellPhone">
                          {(msg) => (
                            <div className="text-xs text-red-500">{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      {/* 
                                    <div className='flex flex-col p-2 text-sm w-full'>
                                        <label htmlFor="birthday" className='text-xs'>Birthday</label>
                                        <Field type="date" name="birthday" className="border outline-none p-1 border-blue-400 rounded-md" />
                                        <ErrorMessage name="birthday">
                                            {msg => <div className="text-red-500 text-xs">{msg}</div>}
                                        </ErrorMessage>
                                    </div> 
                                    */}
                    </div>
                  </div>

                  <div className="w-full">
                    <h1 className="px-2 font-semibold">Home Address</h1>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="homeAddress.street"
                        className="flex items-center gap-1 text-xs"
                      >
                        Street
                      </label>
                      <Field
                        type="text"
                        name="homeAddress.street"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                    </div>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="homeAddress.city"
                        className="flex items-center gap-1 text-xs"
                      >
                        City
                      </label>
                      <Field
                        type="text"
                        name="homeAddress.city"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                    </div>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="homeAddress.state"
                        className="flex items-center gap-1 text-xs"
                      >
                        State
                      </label>
                      <Field
                        type="text"
                        name="homeAddress.state"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex w-full flex-col px-2 md:flex-row">
                  <div className="flex w-full flex-col">
                    <h1 className="border-b border-black px-2 font-semibold">
                      Work Details
                    </h1>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="organization"
                        className="flex items-center gap-1 text-xs"
                      >
                        Organization
                      </label>
                      <Field
                        type="text"
                        name="organization"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                      <ErrorMessage name="organization">
                        {(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="email"
                        className="flex items-center gap-1 text-xs"
                      >
                        Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                      <ErrorMessage name="email">
                        {(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="workPhone"
                        className="flex items-center gap-1 text-xs"
                      >
                        Work Phone
                      </label>
                      <Field
                        type="tel"
                        name="workPhone"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                      <ErrorMessage name="workPhone">
                        {(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      </ErrorMessage>
                    </div>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="title"
                        className="flex items-center gap-1 text-xs"
                      >
                        Designation
                      </label>
                      <Field
                        type="text"
                        name="title"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                      <ErrorMessage name="title">
                        {(msg) => (
                          <div className="text-xs text-red-500">{msg}</div>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="w-full">
                    <h1 className="px-2 font-semibold">Work Address</h1>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="workAddress.street"
                        className="flex items-center gap-1 text-xs"
                      >
                        Street
                      </label>
                      <Field
                        type="text"
                        name="workAddress.street"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                    </div>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="workAddress.city"
                        className="flex items-center gap-1 text-xs"
                      >
                        City
                      </label>
                      <Field
                        type="text"
                        name="workAddress.city"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                    </div>
                    <div className="flex w-full flex-col p-2 text-sm">
                      <label
                        htmlFor="workAddress.stateProvince"
                        className="flex items-center gap-1 text-xs"
                      >
                        State
                      </label>
                      <Field
                        type="text"
                        name="workAddress.stateProvince"
                        className="rounded-md border border-blue-400 p-1 outline-none"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex w-full flex-col px-2 md:flex-row">
                  <h1 className="border-b border-black font-semibold">
                    Social Details
                  </h1>
                </div>
                <div className="flex w-full flex-col md:flex-row">
                  <div className="flex w-full flex-col p-2 text-sm">
                    <label
                      htmlFor="socialUrls.facebook"
                      className="flex items-center gap-1 text-xs"
                    >
                      <AiFillFacebook className="text-blue-500 " /> Facebook
                    </label>
                    <Field
                      type="url"
                      name="socialUrls.facebook"
                      className="rounded-md border border-blue-400 p-1 outline-none"
                    />
                    <ErrorMessage name="socialUrls.facebook">
                      {(msg) => (
                        <div className="text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="flex w-full flex-col p-2 text-sm">
                    <label
                      htmlFor="socialUrls.twitter"
                      className="flex items-center gap-1 text-xs"
                    >
                      <FaSquareXTwitter /> Twitter
                    </label>
                    <Field
                      type="url"
                      name="socialUrls.twitter"
                      className="rounded-md border border-blue-400 p-1 outline-none"
                    />
                    <ErrorMessage name="socialUrls.twitter">
                      {(msg) => (
                        <div className="text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="flex w-full  flex-col items-start md:flex-row">
                  <div className="flex w-full flex-col p-2 text-sm">
                    <label
                      htmlFor="socialUrls.custom"
                      className="flex items-center gap-1 text-xs"
                    >
                      <FaSquareXTwitter /> Instagram
                    </label>
                    <Field
                      type="url"
                      name="socialUrls.custom"
                      className="rounded-md border border-blue-400 p-1 outline-none"
                    />
                    <ErrorMessage name="socialUrls.custom">
                      {(msg) => (
                        <div className="text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>

                  <div className="flex w-full flex-col p-2 text-sm">
                    <label
                      htmlFor="socialUrls.linkedin"
                      className="flex items-center gap-1 text-xs"
                    >
                      <FaLinkedin className="text-blue-400" /> Linkedin
                    </label>
                    <Field
                      type="url"
                      name="socialUrls.linkedin"
                      className="rounded-md border border-blue-400 p-1 outline-none"
                    />
                    <ErrorMessage name="socialUrls.linkedin">
                      {(msg) => (
                        <div className="text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="flex w-full flex-col p-2 text-sm">
                    <label
                      htmlFor="url"
                      className="flex items-center gap-1 text-xs"
                    >
                      <FaLink /> Website URL
                    </label>
                    <Field
                      type="url"
                      name="url"
                      className="rounded-md border border-blue-400 p-1 outline-none"
                    />
                    <ErrorMessage name="url">
                      {(msg) => (
                        <div className="text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="flex w-full items-start">
                  <div className="flex w-full flex-col p-2 text-sm">
                    <label
                      htmlFor="note"
                      className="flex items-center gap-1 text-xs"
                    >
                      Business Keyword
                    </label>
                    <Field
                      type="text"
                      name="note"
                      className="rounded-md border border-blue-400 p-1 outline-none"
                    />
                    <ErrorMessage name="note">
                      {(msg) => (
                        <div className="text-xs text-red-500">{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="flex w-full items-center justify-end gap-5 px-2 py-4 ">
                  <button
                    type="submit"
                    className="rounded-lg bg-red-500 px-8 py-1 text-white"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="rounded-lg bg-[#3d2fa9] px-8 py-1 text-white"
                  >
                    Add
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

export default VCard;
