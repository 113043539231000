import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import { instance } from "common/Instance";
import Drawer from "@mui/material/Drawer";
import toast from "react-hot-toast";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { loadTexture } from "./EditorUtils";
import upload from "../../../../assets/img/upload.png";
import { MdOutlineClear } from "react-icons/md";
import ModelThumbnail from "./Modalviewer/ModelThumbnail"
import ModelViewer from "3dmodelviewinreact";

import { GLTFModel, DirectionLight } from "react-3d-viewer";
import { OBJModel } from "react-3d-viewer";

import { SET_3DMODEL } from "../../../../redux/reducerSlice/worldTrackSlice";
import {AWS_STATIC_PREFIX} from "common/Instance"

const Add3dModel = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState("upload");
  const [active, setActive] = useState("Chef");
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const arData = useSelector((state) => state.WorldTrack.data);
  const dispatch = useDispatch();

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;
      console.log(files, "modelFiles");

      if (files) {
        const modelFile = files[0];

        const fileSizeInMB = modelFile.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
          setLoading(false);
          toast.error("File size should be less than 2 MB");
          return;
        }

        if (
          modelFile.type === "model/gltf-binary" ||
          modelFile.name.endsWith(".glb")
        ) {
          const formData = new FormData();
          console.log(files, "files");
          formData.append("file", modelFile);
          formData.append("assetType", "model");
          console.log(modelFile, "modelfile");

          const response = await instance.post(
            `/api/WTracking/upload/${user.username}/${arData.name}`,
            formData,
            {
              headers: {
                authorization: `Bearer ${userToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          setLoading(false);

          dispatch(
            SET_3DMODEL({
              id: uuidv4(),
              type: "model",
              src: response?.data?.url,
              name: modelFile.name.replace(".glb", ""),
              position: { x: 0, y: 0, z: 0 },
              rotation: { x: -1.5, y: 0, z: 0 },
              scale: { x: 0.1, y: 0.1, z: 0.1 },
              isAnimation: false,
              isAutorotate: false,

              animation: {
                duration: 0,
                delay: 0,
                direction: "topToBottom",
              },
            })
          );

          toast.success("Model is Uploaded Successfully");

          handleClose();
        } else {
          setLoading(false);
          toast.error("Please upload a GLB file");
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
      handleClose();
    }
  };

  const listOfThreeD = [
    {
      category: "Chef",
      innerData: [
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/chef/chp+board+.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/board.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/chef/pot.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/pot.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/chef/spoon+woood.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/spoon_wood.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/chef/spoon.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/spoon.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/chef/toster.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/toster.jpg`
        }
      ]
    },
    {
      category: "Doctor",
      innerData: [
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/doctor/bp.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/bp.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/doctor/first+aid+box.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/first_aid_box.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/doctor/injection.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/injection.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/doctor/mask+box.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/mask_box.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/doctor/maxsk.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/mask.jpg`
        }
      ]
    },
    {
      category: "Real-Estate",
      innerData: [
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/real+state/building.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/building.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/real+state/measure+tape.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/measure_tape.png`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/real+state/plier.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/plier.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/real+state/screw.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/screw.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/real+state/wrench.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/wrench.jpg1`
        }
      ]
    },
    {
      category: "Teacher",
      innerData: [
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/teacher/board.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/board.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/teacher/book.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/book.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/teacher/chair.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/chair.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/teacher/clock.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/clock.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/teacher/self.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dModel-images/Self.jpg`
        }
      ]
    },
    {
      category: "Engineer",
      innerData: [
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/SHOES.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/shoes.jpg`
        },

        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/JACKET.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/jacket.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/PANTS.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/pants.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/vest.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/vest.jpg`
        }
        ,
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/cap.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/cap.jpg`
        }
        ,
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/kinife.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/engineer/knife.jpg`
        }

      ]
    }
,
    {
      category: "Food",
      innerData: [
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/food/pizza_2.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/food/pizza%202.jpg`
        },

        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/food/pizzaa.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/food/pizza.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/food/donut.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/food/donut.jpg`
        },
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/food/FRIES.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/food/fries.jpg`
        }
        ,
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/food/CAKE.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/food/cake.jpg`
        }
        ,
        {
          modelsrc: `${AWS_STATIC_PREFIX}3dmodels/food/BURGER.glb`,
          imageSrc: `${AWS_STATIC_PREFIX}3dmodels/food/burger.jpg`
        }

      ]
    }
  ];
  
  

  const handleUploadModel=(url)=>{
    dispatch(
      SET_3DMODEL({
        id: uuidv4(),
        type: "model",
        src: url,
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: -1.5, y: 0, z: 0 },
        scale: { x: 0.1, y: 0.1, z: 0.1 },
        isAnimation: false,
        isAutorotate: false,

        animation: {
          duration: 0,
          delay: 0,
          direction: "topToBottom",
        },
      })
    );

    toast.success("Model is Uploaded Successfully");

    handleClose()
  }

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      className="relative"
    >
      <Box
        sx={{ width: 500 }}
        role="presentation"
        className="relative h-screen overflow-hidden"
      >
        <div>
          <div className="relative mb-9 mt-0 flex flex-col ">
            <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className=" text-lg font-semibold ">3D Model</h4>
              <span
                className="cursor-pointer"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <MdOutlineClear size={20} />
              </span>
            </div>

            <div className="mb-2 flex w-full items-center justify-between gap-1 px-2 ">
              <button
                className={`w-full rounded-lg  ${
                  tab === "upload"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }  px-2 py-3  `}
                onClick={() => setTab("upload")}
              >
                Upload Model
              </button>
              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "browse"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("browse")}
              >
                Browse Model
              </button>
              <button
                className={`w-full rounded-lg px-2 py-3  ${
                  tab === "library"
                    ? "bg-[#3d2fa9] text-white"
                    : "border-2  text-[#3d2fa9] "
                }`}
                onClick={() => setTab("library")}
              >
                Model Library
              </button>
            </div>

            {tab === "upload" ? (
              <div className="mt-5">
                <div className=" mb-4 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
                  <img
                    src={upload}
                    alt="select "
                    className="h-[40%] w-[40%] rounded-lg"
                  />

                  <h1 className="pb-2 text-center text-2xl font-semibold text-gray-800">
                    Upload Modal
                  </h1>
                </div>

                <div className="px-4">
                  <label
                    htmlFor="fileInput"
                    className="flex h-full w-full items-center  justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4  dark:!border-navy-700 lg:pb-0"
                  >
                    <input
                      type="file"
                      id="fileInput"
                      accept=".glb,model/gltf-binary"
                      className="hidden"
                      onChange={(e) => {
                        handleFileUpload(e);
                      }}
                    />

                    <div className="flex flex-col items-center justify-center">
                      <button
                        type="button"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                        className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white "
                      >
                        Browse
                      </button>
                      <p className="mt-4 text-lg font-semibold text-gray-900">
                        Supported Format :- GLB
                      </p>

                      <p className="text-md my-2 text-gray-900">
                        file limit size upto 2mb
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            ) : tab === "browse" ? (
              <div className="py-1">
                <div className="flex flex-col gap-2 ">
                  <div className="bg-bg-brandLinear flex max-w-full items-center justify-center gap-1 px-2 ">
                    {listOfThreeD.map((item) => (
                      <button
                        className={`w-full text-xs rounded-sm px-2 py-1 text-white ${
                          active === item.category
                            ? `bg-brand-main`
                            : `bg-brandLinear`
                        } `}
                        onClick={() => {
                          setActive(item.category);
                        }}
                      >
                        {item.category}
                      </button>
                    ))}
                  </div>


                <div className="mt-2  grid max-h-full   grid-cols-1 justify-center gap-4 overflow-y-auto px-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
                    {listOfThreeD
                      .find((item) => item?.category === active)
                      ?.innerData?.map((item, index) => (
                        <div
                          key={index}
                          className="rounded-md border border-gray-900 p-2"

                          onClick={()=>{
                            handleUploadModel(item?.modelsrc)
                          }}
                        >
                          
                          <img src={item?.imageSrc} className='w-[40] h-[40] cursor-pointer'/>
                        </div>
                      ))}
                  </div>
                
                </div>

                
              </div>
            ) : (
              <div> Model Library</div>
            )}
          </div>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <h1 className="font-bold">Uploading Icon</h1>
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </Box>
    </Drawer>
  );
};

export default Add3dModel;
