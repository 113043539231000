import React, { useState, useEffect } from "react";
import { Box, Divider, IconButton } from "@mui/material";
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
} from "react-icons/md";
import { BiFont } from "react-icons/bi";

const TextEditorToolbar = ({
  selectedElementIndex,
  elements,
  setElements,
  elementsForBack,
  setElementsForBack,
  tabValue,
  handleTextColorChange,
}) => {
  const [fontSize, setFontSize] = useState(16);
  const [activeIcons, setActiveIcons] = useState({
    bold: false,
    italic: false,
    underline: false,
    textAlign: "center",
  });

  useEffect(() => {
    if (selectedElementIndex !== null) {
      const currentElement =
        tabValue === "front"
          ? elements[selectedElementIndex]
          : elementsForBack[selectedElementIndex];

      if (currentElement) {
        setActiveIcons({
          bold: currentElement.fontWeight === "bold",
          italic: currentElement.italic,
          underline: currentElement.underline,
          textAlign: currentElement.textAlign,
        });
        setFontSize(currentElement.fontSize);
      }
    }
  }, [selectedElementIndex, elements, elementsForBack, tabValue]);

  const updateElement = (updates) => {
    const updateFunction = (elements) =>
      elements.map((el, index) =>
        index === selectedElementIndex ? { ...el, ...updates } : el
      );

    if (tabValue === "front") {
      setElements(updateFunction(elements));
    } else {
      setElementsForBack(updateFunction(elementsForBack));
    }
  };

  const handleFontWeightChange = () => {
    const newBoldState = !activeIcons.bold;
    setActiveIcons((prev) => ({ ...prev, bold: newBoldState }));
    updateElement({ fontWeight: newBoldState ? "bold" : "normal" });
  };

  const handleItalicChange = () => {
    const newItalicState = !activeIcons.italic;
    setActiveIcons((prev) => ({ ...prev, italic: newItalicState }));
    updateElement({ italic: newItalicState });
  };

  const handleUnderlineChange = () => {
    const newUnderlineState = !activeIcons.underline;
    setActiveIcons((prev) => ({ ...prev, underline: newUnderlineState }));
    updateElement({ underline: newUnderlineState });
  };

  const handleTextAlignChange = (alignment) => {
    setActiveIcons((prev) => ({ ...prev, textAlign: alignment }));
    updateElement({ textAlign: alignment });
  };

  const handleFontSizeChange = (newSize) => {
    setFontSize(newSize);
    updateElement({ fontSize: newSize });
  };

  return (
    <div className="flex flex-wrap items-center justify-start bg-white px-2 py-1 w-full gap-1 sm:gap-2 md:gap-4 overflow-x-auto">
    <div className="flex items-center space-x-1">
      <IconButton
        className="text-black p-1"
        size="small"
        onClick={() => handleFontSizeChange(fontSize - 1)}
      >
        <span className="text-xs sm:text-sm">-</span>
      </IconButton>
      <span className="text-xs sm:text-sm text-black mx-1">{fontSize}</span>
      <IconButton
        className="text-black p-1"
        size="small"
        onClick={() => handleFontSizeChange(fontSize + 1)}
      >
        <span className="text-xs sm:text-sm">+</span>
      </IconButton>
    </div>

    <IconButton
      className="relative h-8 w-8 sm:h-10 sm:w-10 rounded-full p-0 overflow-hidden"
    >
      <input
        type="color"
        value={elements[selectedElementIndex]?.color}
        onChange={handleTextColorChange}
        className="absolute inset-0 cursor-pointer opacity-0"
      />
      <div
        className="h-full w-full rounded-full shadow-sm border-2"
        style={{
          background: elements[selectedElementIndex]?.color,
        }}
      />
    </IconButton>

    <IconButton
      className="text-black p-1"
      size="small"
      onClick={handleFontWeightChange}
    >
      <MdFormatBold className={`${activeIcons.bold ? 'bg-black text-white' : ''} rounded-full text-lg sm:text-xl p-0.5`} />
    </IconButton>

    <IconButton
      className="text-black p-1"
      size="small"
      onClick={handleItalicChange}
    >
      <MdFormatItalic className={`${activeIcons.italic ? 'bg-black text-white' : ''} rounded-full text-lg sm:text-xl p-0.5`} />
    </IconButton>

    <IconButton
      className="text-black p-1"
      size="small"
      onClick={handleUnderlineChange}
    >
      <MdFormatUnderlined className={`${activeIcons.underline ? 'bg-black text-white' : ''} rounded-full text-lg sm:text-xl p-0.5`} />
    </IconButton>

    <div className="flex bg-gray-100 rounded-md">
      <IconButton
        className="text-black p-1"
        size="small"
        onClick={() => handleTextAlignChange("left")}
      >
        <MdFormatAlignLeft className={`${activeIcons.textAlign === 'left' ? 'bg-black text-white' : ''} rounded-full text-lg sm:text-xl p-0.5`} />
      </IconButton>
      <IconButton
        className="text-black p-1"
        size="small"
        onClick={() => handleTextAlignChange("center")}
      >
        <MdFormatAlignCenter className={`${activeIcons.textAlign === 'center' ? 'bg-black text-white' : ''} rounded-full text-lg sm:text-xl p-0.5`} />
      </IconButton>
      <IconButton
        className="text-black p-1"
        size="small"
        onClick={() => handleTextAlignChange("right")}
      >
        <MdFormatAlignRight className={`${activeIcons.textAlign === 'right' ? 'bg-black text-white' : ''} rounded-full text-lg sm:text-xl p-0.5`} />
      </IconButton>
    </div>
  </div>
  );
};

export default TextEditorToolbar;
