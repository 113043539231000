import { useState, useEffect } from "react";
import Chart from 'react-apexcharts';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { instance } from "common/Instance";
import { SET_INITIAL_STATE_ANALYTICS_WEEKLY } from "../../../../../redux/reducerSlice/analytics";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import Card from "components/card";
import BarChart from "components/charts/BarChart";
import { barChartOptionsDailyTraffic } from "variables/charts";

const DailyTraffic = ({ activeSwitchId, isMultiscene, experience }) => {
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.auth.auth.token);

  const [weeklyAnalytics,setWeeklyAnalytics]=useState({

  })


  const [dailyVisitor, setDailyVisitor] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [percentageChange, setPercentageChange] = useState(0);
  const [barChartDataDailyTraffic, setBarChartDataDailyTraffic] = useState([{ name: "Daily Traffic", data: [] }]);
  const [chartOptions, setChartOptions] = useState({
    ...barChartOptionsDailyTraffic,
    xaxis: { ...barChartOptionsDailyTraffic.xaxis, categories: [] },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await instance.get(`api/visitors/get_weekly_dashboard_data_user_reseller?userID=${activeSwitchId}&experienceName=${experience}${
          isMultiscene ? `&multiScene=true` : "" }`, {
          headers: { authorization: `Bearer ${userToken}` },
        });

        console.log(response,'rohit112e')

        if (response.status === 200 && response.data?.data?.visitor_current_weekly_list) {
         setWeeklyAnalytics(response.data.data);
        } else {
          console.error("Dashboard Error: Data not available or invalid response");
        }
      } catch (error) {
        console.error("Dashboard Error: ", error);
      }
    };


    if(experience && activeSwitchId){

    fetchData();
    }
  }, [dispatch, userToken,activeSwitchId, isMultiscene,experience ]);

  useEffect(() => {
    if (weeklyAnalytics) {
      const visitorData = weeklyAnalytics?.visitor_current_weekly_list || [];
      const catData = visitorData.map((item) => item.date);
      const valData = visitorData.map((item) => item.count);

      if (valData.length >= 2) {
        const lastTwo = valData.slice(-2);
        const percentageChanges = ((lastTwo[1] - lastTwo[0]) / lastTwo[0]) * 100;
        setPercentageChange(percentageChanges);
      }

      setCategories(catData);
      setBarChartDataDailyTraffic([{ name: "Daily Traffic", data: valData }]);
      setChartOptions(prevOptions => ({ ...prevOptions, xaxis: { ...prevOptions.xaxis, categories: catData } }));
      setDailyVisitor(weeklyAnalytics.visitor_daily_count);
      setLoading(false);
    }
  }, [weeklyAnalytics]);

  return (
    <Card extra="pb-7 p-[20px]">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <p className="text-sm font-medium leading-4 text-gray-600">Daily Traffic</p>
          {loading ? (
            <div className="rounded-lg">
              <div className="animate-pulse flex py-3">
                <div className="rounded-xl bg-gray-500 h-6 w-12"></div>
              </div>
            </div>
          ) : (
            <>
              <p className="text-[34px] font-bold text-navy-700 dark:text-white">{dailyVisitor}&nbsp;</p>
              <p className="text-sm font-medium leading-6 text-gray-600">Visitors</p>
            </>
          )}
        </div>
        <div className="mt-2 flex items-start">
          {loading ? (
            <div className="rounded-lg">
              <div className="animate-pulse flex">
                <div className="rounded-xl bg-gray-500 h-6 w-20"></div>
              </div>
            </div>
          ) : (
            <>
            {/* <div className={`flex items-center text-sm ${percentageChange >= 0 ? " font-bold text-green-500" : "font-bold text-red-500"}`}>
              {percentageChange >= 0 ? <MdArrowDropUp className="h-5 w-5" /> : <MdArrowDropDown className="h-5 w-5" />}
              <p>{percentageChange >= 0 ? "+" : "-"}{Math.abs(percentageChange).toFixed(2)}%</p>
            </div> */}
            </>
            
          )}
        </div>
      </div>

      <div className="h-[300px] w-full pt-1 pb-0 ">
        {loading ? (
          <div className="rounded-lg w-full h-full">
            <div className="animate-pulse flex w-full h-full">
              <div className="rounded-xl bg-gray-500 w-full h-full"></div>
            </div>
          </div>
        ) : (
          <Chart options={chartOptions} series={barChartDataDailyTraffic} type="bar" width="100%" height="100%" />
        )}
      </div>
    </Card>
  );
};

export default DailyTraffic;
