import { useState } from "react";
import { FaUser, FaBuilding, FaHashtag } from "react-icons/fa";
import { BiErrorCircle } from "react-icons/bi";
import { MdAccountBalance } from "react-icons/md";
import { BsBank } from "react-icons/bs";
import {
  SET_AUTH,
  SET_USERDATA,
} from "../../../../redux/reducerSlice/authSlice";
import { instance } from "common/Instance";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
export default function BankInformationForm() {
  const user = useSelector((state) => state.auth.auth.user);
  const userToken = useSelector((state) => state.auth.auth.token);
  const [formData, setFormData] = useState({
    registeredName: user?.bankDetails?.registeredName || "",
    bankName: user?.bankDetails?.bankName || "",
    bankType: user?.bankDetails?.bankType || "",
    branchName: user?.bankDetails?.branchName || "",
    accountNumber: user?.bankDetails?.accountNumber || "",
    ifscCode: user?.bankDetails?.ifscCode || "",
  });

  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await instance.post(
        `/api/user/update_user_details/${user._id}`,
        { ...user, bankDetails: formData },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Profile updated successfully");
        dispatch(SET_USERDATA(response?.data?.data));
      }
    } catch (error) {
      setError(error.response?.data?.message || "Failed to update");
      toast.error("Failed to update profile");
    } finally {
      setLoading(false); // Set submitting state to false after API call completes
    }
  };

  return (
    <div className="overflow-hidden rounded-lg bg-white shadow-lg">
      <div className="p-6 ">
        <h2 className="text-start text-2xl font-bold ">Bank Information</h2>
      </div>

      <div className="p-6">
        {error && (
          <div className="mb-6 flex items-center border-l-4 border-red-500 bg-red-50 p-4">
            <BiErrorCircle className="mr-2 text-xl text-red-500" />
            <p className="text-red-700">{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className=" flex flex-col gap-2 space-y-6">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
            {/* Registered Name */}
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Account holder name
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaUser className="text-gray-400" />
                </div>
                <input
                  type="text"
                  name="registeredName"
                  value={formData.registeredName}
                  onChange={handleChange}
                  className="block w-full rounded-lg border border-gray-300 py-2.5 pl-10 pr-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter registered name"
                  required
                />
              </div>
            </div>

            {/* Bank Name */}
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Bank Name
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <BsBank className="text-gray-400" />
                </div>
                <input
                  type="text"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange}
                  className="block w-full rounded-lg border border-gray-300 py-2.5 pl-10 pr-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter bank name"
                  required
                />
              </div>
            </div>

            {/* Bank Type */}
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Bank Type
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MdAccountBalance className="text-gray-400" />
                </div>
                <select
                  name="bankType"
                  value={formData.bankType}
                  onChange={handleChange}
                  className="block w-full rounded-lg border border-gray-300 bg-white py-2.5 pl-10 pr-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Select bank type</option>
                  <option value="savings">Savings</option>
                  <option value="current">Current</option>
                  <option value="salary">Salary</option>
                </select>
              </div>
            </div>

            {/* Branch Name */}
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Branch Name
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaBuilding className="text-gray-400" />
                </div>
                <input
                  type="text"
                  name="branchName"
                  value={formData.branchName}
                  onChange={handleChange}
                  className="block w-full rounded-lg border border-gray-300 py-2.5 pl-10 pr-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter branch name"
                  required
                />
              </div>
            </div>

            {/* Account Number */}
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                Account Number
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaHashtag className="text-gray-400" />
                </div>
                <input
                  type="number"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  className="block w-full rounded-lg border border-gray-300 py-2.5 pl-10 pr-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter account number"
                  required
                />
              </div>
            </div>

            {/* IFSC Code */}
            <div>
              <label className="mb-2 block text-sm font-medium text-gray-700">
                IFSC Code
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <BsBank className="text-gray-400" />
                </div>
                <input
                  type="text"
                  name="ifscCode"
                  value={formData.ifscCode}
                  onChange={handleChange}
                  className="block w-full rounded-lg border border-gray-300 py-2.5 pl-10 pr-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter IFSC code"
                  pattern="^[A-Z]{4}0[A-Z0-9]{6}$"
                  title="Please enter a valid IFSC code"
                  required
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end" >
            <button
              type="submit"
              disabled={loading}
              className=" rounded-lg bg-brand-main px-4 py-2.5 font-medium text-white transition-colors hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-300"
            >
              {loading ? "Submitting..." : "Submit Bank Information"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
