import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import Switch from "components/switch";
import Pagination from "@mui/material/Pagination";
import { useDispatch } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DashboardUser from "./Dashboarduser";

const UserList = () => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state?.auth?.auth?.token);

  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setSelectedValue] = useState(10);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [tableData, setTableData] = useState([]);
  const [activeSwitchId, setActiveSwitchId] = useState(null); // Track only one active switch ID
  const [multisceneData, setMultisceneData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [selectOCRandAR, setSelectOCRandAR] = useState("AR");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const yearList = [];
    for (let i = 0; i <= 5; i++) {
      yearList.push(currentYear - i);
    }
    setYears(yearList);
    setSelectedYear(currentYear);
    setSelectedMonth(months[currentMonth]);
  }, []);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    const currentDate = new Date().toISOString().split("T")[0];
    console.log("currentDate ==>", event.target.value, currentDate);
    setEndDate(currentDate);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleOCRandARChange = (e) => {
    setSelectOCRandAR(e.target.value);
  };

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const userDataList = async () => {
    if (tableData?.length === 0) {
      setLoading(true);
    }

    try {
      const response = await instance.get(
        `api/visitors/get_users_list_reseller_dashboard?startDate=${startDate}&endDate=${endDate}&searchText=${searchParam}&page=${searchParam !== "" ? 1 : currentPage
        }&limit=${itemsPerPage}${selectOCRandAR === "AR" ? "" : "&ocr=true"
        }`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response?.status === 200) {
        setTableData(response?.data?.data);
        setTotalPages(response?.data?.totalPages);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Error fetching user data");
      setLoading(false);
    }
  };

  useEffect(() => {
    userDataList();
  }, [
    currentPage,
    itemsPerPage,
    startDate,
    endDate,
    searchParam,
    selectOCRandAR,
  ]);

  useEffect(() => {
    userDataList(1);
  }, [searchParam]);

  const handleSwitchChange = (itemId) => {
    if (activeSwitchId === itemId) {
      // Turn off the currently active switch
      setActiveSwitchId(null);
      setMultisceneData([]);
      setExperienceData([]);
    } else {
      // Activate the new switch
      setActiveSwitchId(itemId);
      const selectedItem = tableData.find(item => item._id === itemId);
      setMultisceneData(selectedItem?.multipleScene || []);
      setExperienceData(selectedItem?.experiences || []);
    }
  };

  // console.log(experienceData,'rohit112')
  // console.log(multisceneData,'rohit112')
  // console.log(activeSwitchId,'rohit112')
  return (
    <div>
      <Card extra={"w-full sm:overflow-auto px-6 py-4"}>
        {/* <header className="flex w-full flex-col justify-center">
          <div className="relative flex items-center justify-between">
            <div className="text-md font-bold text-navy-700 dark:text-white">User List</div>
          </div>
          <div className="relative flex w-full flex-col items-center gap-2 py-1 md:flex-row">
            <div className="flex w-full items-center">
              <input
                className="w-[100%] rounded-lg border-2 p-2.5 text-sm outline-none"
                type="text"
                value={search}
                onChange={handleSearch}
                placeholder="Search"
              />
            </div>
            <div className="flex w-full items-center justify-end gap-4 ">
              <div className="flex items-center gap-2">
                <div className="w-full ">
                  <select
                    id="year"
                    value={selectedYear}
                    onChange={handleYearChange}
                    className="block h-10 w-full rounded-md border border-gray-600 shadow-sm sm:text-sm"
                  >
                    <option value={""}>Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="w-full max-w-sm">
                  <select
                    id="month"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    className="block h-10 w-full rounded-md border border-gray-600 shadow-sm sm:text-sm"
                  >
                    <option value={""}>Select Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month}>{month}</option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={() => {
                    setSelectedMonth("");
                    setSelectedYear("");
                  }}
                  className="text-md w-[300px] rounded-md bg-brand-main px-2 py-1 text-center font-semibold text-white"
                >
                  Clear filter
                </button>
              </div>
              <Tooltip title="Rows per page">
                <select
                  className="ml-2 block rounded-md border border-brand-main bg-gray-100 p-1 shadow-sm outline-none"
                  value={itemsPerPage}
                  onChange={handleChange}
                >
                  {[...Array(11).keys()].map((_, index) => (
                    index > 0 && (
                      <option key={index} value={index * 5}>{index * 5}</option>
                    )
                  ))}
                </select>
              </Tooltip>
            </div>
          </div>
        </header> */}
        <header className="flex w-full flex-col justify-center">
          <div className="relative flex items-center justify-between">
            <div className="text-md font-bold text-navy-700 dark:text-white">
              User List
            </div>
          </div>
          <div className="flex w-full flex-col gap-2 sm:flex-row md:w-40">
            {/* <select
              id="year"
              value={selectOCRandAR}
              onChange={handleOCRandARChange}
              className="block h-11 rounded-md border border-gray-600 px-1 shadow-sm sm:w-4/12 sm:text-sm md:w-full"
            >
              <option value={"AR"}>AR</option>
              <option value={"OCR"}>OCR</option>
            </select> */}
            <div className="ml-[-8px]">
              <FormControl
                sx={{ m: 1, minWidth: 150, maxWidth: "100%", bgColor: "#fff" }}
                className="bg-white"
              >
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ fontSize: "0.9rem", marginTop: "3px" }}
                >
                  Products
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={selectOCRandAR}
                  label="Products"
                  onChange={handleOCRandARChange}
                  sx={{
                    fontSize: "0.75rem",
                    minWidth: "150px",
                    bgColor: "#fff",
                    height: "40px", // Set height
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  className="bg-white"
                >
                  <MenuItem value={"AR"} sx={{ fontSize: "0.75rem" }}>
                    AR
                  </MenuItem>
                  <MenuItem value={"OCR"} sx={{ fontSize: "0.75rem" }}>
                    OCR
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="flex sm:w-8/12 md:hidden items-center justify-stretch">
              <input
                className="w-[100%] rounded-lg border-2 p-2 text-sm outline-none h-[40px]" // Set height to match
                type="text"
                value={search}
                onChange={handleSearch}
                placeholder="Search"
              />
            </div>
          </div>
          <div className=" mt-2 flex flex-col items-start gap-2 sm:flex-row sm:items-center md:mt-[-15px]">
            <div className="hidden w-full md:flex ">
              <input
                className="w-[100%] rounded-lg border-2 p-2.5 text-sm outline-none"
                type="text"
                value={search}
                onChange={handleSearch}
                placeholder="Search"
              />
            </div>
            <div
              id="date-range-picker"
              date-rangepicker
              class="flex w-full items-center justify-stretch gap-1"
            >
              <div class="w-6/12 sm:pb-6">
                <label
                  for="datepicker-range-start"
                  class=" text-sm text-gray-400"
                >
                  Start Date
                </label>
                <input
                  id="datepicker-range-start"
                  name="start"
                  type="date"
                  class="block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div class="w-6/12 sm:pb-6">
                <label for="datepicker-range-end" class="text-sm text-gray-400">
                  End Date
                </label>
                <input
                  id="datepicker-range-end"
                  name="end"
                  type="date"
                  class="block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 p-2.5  text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
            <div className="flex w-full gap-2">
              <div className="h-[42px] w-8/12 sm:w-full">
                <button
                  onClick={() => {
                    setStartDate("");
                    setEndDate("");
                  }}
                  // className="text-md h-full w-[300px] rounded-md bg-brand-main px-2 py-1 text-center font-semibold text-white"
                  className="text-md h-full w-full min-w-[150px] rounded-md bg-brand-main px-2 py-1 text-center font-semibold text-white"
                >
                  Clear filter
                </button>
              </div>
              <div className="h-[41px] w-4/12 sm:w-full">
                <Tooltip title="Rows per page">
                  {/* <select
                    className=" block h-full w-full rounded-md border border-brand-main bg-gray-100 p-1 shadow-sm outline-none"
                    value={itemsPerPage}
                    onChange={handleChange}
                  >
                    {[...Array(11).keys()].map(
                      (_, index) =>
                        index > 0 && (
                          <option key={index} value={index * 5}>
                            {index * 5}
                          </option>
                        )
                    )}
                  </select> */}

                  <div className="mt-[-10px]">
                    <div className="md:hidden">
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 80,
                          maxWidth: "100%",
                          bgColor: "#fff",
                          marginBottom: "30px",
                        }}
                        size="small"
                        className="bg-white "
                      >
                        <InputLabel
                          id="demo-select-small-label"
                          sx={{ fontSize: "0.85rem", marginTop: "3px" }}
                        >
                          Items Per Page
                        </InputLabel>
                        <Select
                          id="demo-select-small"
                          value={itemsPerPage}
                          onChange={handleChange}
                          label="Items Per Page"
                          sx={{
                            fontSize: "0.75rem",
                            height: "44px",
                            minWidth: "80px",
                            bgColor: "#fff",
                          }}
                          className="bg-white "
                        >
                          {[...Array(11).keys()].map(
                            (_, index) =>
                              index > 0 && (
                                <MenuItem
                                  key={index}
                                  value={index * 5}
                                  sx={{ fontSize: "0.75rem" }}
                                >
                                  {index * 5}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="hidden md:block">
                      <FormControl
                        sx={{
                          m: 1,
                          minWidth: 150,
                          maxWidth: "100%",
                          bgColor: "#fff",
                          marginBottom: "30px",
                        }}
                        size="small"
                        className="bg-white "
                      >
                        <InputLabel
                          id="demo-select-small-label"
                          sx={{ fontSize: "0.85rem", marginTop: "3px" }}
                        >
                          Items Per Page
                        </InputLabel>
                        <Select
                          id="demo-select-small"
                          value={itemsPerPage}
                          onChange={handleChange}
                          label="Items Per Page"
                          sx={{
                            fontSize: "0.75rem",
                            height: "44px",
                            minWidth: "150px",
                            bgColor: "#fff",
                          }}
                          className="bg-white "
                        >
                          {[...Array(11).keys()].map(
                            (_, index) =>
                              index > 0 && (
                                <MenuItem
                                  key={index}
                                  value={index * 5}
                                  sx={{ fontSize: "0.75rem" }}
                                >
                                  {index * 5}
                                </MenuItem>
                              )
                          )}
                        </Select>
                      </FormControl>
                    </div>

                  </div>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className="relative flex w-full flex-col items-center gap-2 py-1 md:flex-row">
            <div className="flex w-full items-center justify-end gap-4 ">
              <div className="flex items-center gap-2">
                {/* <div className="w-full ">
                  <select
                    id="year"
                    value={selectedYear}
                    onChange={handleYearChange}
                    className="block h-10 w-full rounded-md border border-gray-600 shadow-sm sm:text-sm"
                  >
                    <option value={""}>Select Year</option>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full max-w-sm">
                  <select
                    id="month"
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    className="block h-10 w-full rounded-md border border-gray-600 shadow-sm sm:text-sm"
                  >
                    <option value={""}>Select Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={month}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div> */}
              </div>
            </div>
          </div>
        </header>

        <div className="mt-4 overflow-x-scroll xl:overflow-x-auto">
          <table className="w-full min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Username
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Email
                </th>
                {selectOCRandAR === "AR" ? (
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Exp. Count
                  </th>
                ) : (
                  <></>
                )}
                {selectOCRandAR === "AR" ? (
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Multiscene Count
                  </th>
                ) : (
                  <></>
                )}
                {selectOCRandAR !== "AR" ? (
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Inquires Count
                  </th>
                ) : (
                  <></>
                )}
                {selectOCRandAR === "AR" ? (
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Analytics Dashboard
                  </th>
                ) : (
                  <></>
                )}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {loading ? (
                [...Array(itemsPerPage)].map((_, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div role="status" className="animate-pulse">
                        <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : tableData.length > 0 ? (
                tableData.map((item) => (
                  <tr key={item._id}>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">
                        {item?.fullName}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">
                        {item?.username}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">{item?.email}</div>
                    </td>
                    {selectOCRandAR === "AR" ? (
                      <td className="whitespace-nowrap px-6 py-4">
                        <div className="text-sm text-gray-900">
                          {item?.experienceCount}
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}
                    {selectOCRandAR === "AR" ? (
                      <td className="whitespace-nowrap px-6 py-4">
                        <div className="text-sm text-gray-900">
                          {item?.multipleSceneCount}
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}
                    {selectOCRandAR !== "AR" ? (
                      <td className="whitespace-nowrap px-6 py-4">
                        <div className="text-sm text-gray-900">
                          {item?.inquiriesCount}
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}
                    {selectOCRandAR === "AR" ? (
                      <td className="whitespace-nowrap px-6 py-4">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={activeSwitchId === item._id}
                                onChange={() => handleSwitchChange(item._id)}
                              />
                            }
                          />
                        </FormGroup>
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))
              ) : (
                <tr className="w-full">
                  <td colSpan="12" className="max-w-full py-4 text-center">
                    <p className="w-full max-w-full bg-blue-100 px-10 py-5 text-sm text-blue-600">
                      Data Not Found!
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="my-5 flex w-full items-center justify-end text-sm">
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handleChanges}
            />
          </div>
        </div>
      </Card>

      <DashboardUser experienceData={experienceData} activeSwitchId={activeSwitchId} multisceneData={multisceneData} />
    </div>
  );
};

export default UserList;
