import React, { useEffect, useState } from "react";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import toast from "react-hot-toast";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import { useDispatch } from "react-redux";

import {
  SET_AUTH,
  SET_USERDATA,
} from "../../../../redux/reducerSlice/authSlice";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import logo from "../../../../assets/img/logo_1.png";

import "./Pricing.css";
import { useNavigate } from "react-router-dom";
import PlanCard from "./PlanCard";
import { RZOR_KEY } from "common/Instance";
import CommonBackdrop from "common/CommonBackdrop";

export function PricingOne() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.auth.user);

  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userid = useSelector((state) => state.auth.auth.user._id);
  const userToken = useSelector((state) => state.auth.auth.token);
  const [plans, setPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [isActive, setActive] = useState(false);
  const [loadingSuccess, setLoadingSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

 

  const handleCheckOut = async (planId, planIndex, addOn) => {
    try {
      setLoading(true);
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        toast.error("Razorpay SDK failed to load. Are you online?");
        return;
      }

      razorPayPaymentSystem(planId, addOn);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const razorPayPaymentSystem = async (planId, addOn) => {
    try {
      const session = await PostApiRazorPay(planId, addOn);

      handleRazorPayResponse(session);
    } catch (err) {
      console.error(err);
    }
  };

  const handleRazorPayResponse = (data) => {
    try {
      const options = {
        key: RZOR_KEY,
        currency: "INR",
        name: "Immarsify",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        subscription_id: data.data.data.id,

        handler: async (response) => {
          if (response.razorpay_signature) {
            setOpen(true);
            setActive(true);
          }
        },
        prefill: {
          name: user.fullName,
          email: user.email,
          contact: user?.phoneNumber || "",
        },

        theme: {
          color: "#005aeb",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.on("payment.failure", (response) => {
        toast.error("Error: ");
      });
      setLoading(false);
      rzp.open();
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  async function PostApiRazorPay(planId ,addOn) {
    try {
      const response = await instance.post(
        "/api/rezorpay/subscription_create_checkout_session",
        { planId: planId,
          addOn
         },
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 201) {
        const session = response;
        return session;
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error(err);
    }
  }
  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  async function getSubscriptionPlans() {
    try {
      const response = await instance.get(
        "/api/subscription/get_subscriptions",
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        setPlans(response?.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const Loginuser = async () => {
    try {
      const response = await instance.get(`/api/auth/getUser/${userid}`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      console.log(response.data.data);

      const data = {
        user: response.data.data,
        token: userToken,
      };

      console.log(data, "data");

      dispatch(SET_AUTH(data));
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        if (user?.isSubscriptionActive === "active") {
          setLoadingSuccess(true);

          setTimeout(() => {
            setOpen(false);
            setActive(false);
            clearInterval(intervalId);
            navigate("/admin/template");
          }, 3000);
        } else {
          Loginuser();
        }
      }, 5000);
    }

    return () => {
      clearInterval(intervalId); // Clear the interval on cleanup
    };
  }, [isActive, user]);


 
  console.log("plans", plans);

  return (
    <>
      <section className="relative overflow-hidden pt-10">
        <div className="relative mx-auto max-w-7xl px-4 ">
          <div className="mx-auto max-w-7xl">
            <div className="flex flex-wrap items-center  gap-2">
              {plans.length > 0 &&
                plans.map((plan, index) => (
                  <PlanCard
                    planId={plan?.PlanID}
                    planIndex={index}
                    planType={plan?.PlanName}
                    price={plan?.PlanAmount}
                    features={plan?.features}
                    handleCheckOut={handleCheckOut}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
      <Processing open={open} loadingSuccess={loadingSuccess} />
      <CommonBackdrop
        loading={loading}
        title={"Opening payment page ..."}
        description={"Please wait"}
        loadingColor={"white"}
      />
    </>
  );
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 500,
  height: 290,
  p: 1,
  backgroundColor: "background.paper",
  boxShadow: "0 0 24px rgba(0, 0, 0, 0.25)",
  transform: "translate(-50%, -50%)",
  borderRadius: "0.5rem",
  border: "none",
  outline: "none",
};

const Processing = ({ open, loadingSuccess }) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        {loadingSuccess ? (
          <div className="  flex flex-col  items-center justify-center">
            <div className=" p-6 text-center md:mx-auto">
              <svg
                viewBox="0 0 24 24"
                className="mx-auto my-6 h-16 w-16 animate-bounce text-green-600"
              >
                <path
                  fill="currentColor"
                  d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                />
              </svg>
              <h3 className="text-center text-base font-semibold text-gray-900 md:text-2xl">
                Payment Done!
              </h3>
            </div>
            <p classname="text-gray-600 pt-10 mb-2">
              Thank you for completing your secure online payment.
            </p>
          </div>
        ) : (
          <div className="payment-loader ">
            <div className="pad">
              <div className="chip"></div>
              <div className="linen1 line11"></div>
              <div className="linen1 line21"></div>
            </div>
            <div className="mt-5 text-center text-lg  font-semibold text-blue-900 ">
              Payment Processing...
            </div>
            <p className="mt-10 text-center text-sm text-gray-900">
              Please do not refresh the page, we are processing your payment
            </p>
          </div>
        )}
      </Box>
    </Modal>
  );
};
