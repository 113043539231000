import React, { useState } from "react";
import {} from "unsplash-js";

import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";

import Drawer from "@mui/material/Drawer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { SET_ADD_RESUME } from "../../../../redux/reducerSlice/worldTrackSlice";
import { useDispatch } from "react-redux";
import { MdOutlineClear } from "react-icons/md";
import upload from "../../../../assets/img/upload.png";
import { AWS_STATIC_PREFIX } from "common/Instance";


const AddResume = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <Box
        sx={{ width: 500 }}
        role="presentation"
        className="relative h-screen overflow-hidden"
      >
        <div className="relative ">
          <div className="relative mb-9 mt-0   ">
            <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className=" text-lg font-semibold">Resume</h4>
              <span className='cursor-pointer' onClick={()=>{
                handleClose()
              }}> <MdOutlineClear size={20} /></span>
            </div>


            <div className="-mb-10 mt-10 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
              <img
                src={upload}
                alt="select "
                className="h-[20%] w-[40%] rounded-lg"
              />
            </div>

            <div className="flex h-[40vh] w-full items-center justify-center">
              <button
                className="cursor-pointer rounded-lg bg-brand-main p-2 text-md text-white "
                onClick={() => {
                  dispatch(
                    SET_ADD_RESUME({
                      id: uuidv4(),
                      type: "resume",
                      src: AWS_STATIC_PREFIX +`images/Resume-preview.png`,
                      position: { x: 0, y: 0.0005, z: 0 },
                      rotation: { x: -Math.PI / 2, y: 0, z: 0 },
                      scale: { x: 1, y:1, z:1},
                      isAnimation: false,
                      icon: {},
                      texts: {},
                      animation: {
                        duration: 0,
                        delay: 0,
                        direction: "topToBottom",
                      },
                    })
                  );
                  handleClose()
                }}
              >
                Click Here To Add Resume
              </button>
            </div>
          </div>
        </div>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="font-bold">Uploading Image</h1>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
    </Drawer>
  );
};

export default AddResume;
