import axios from "axios";
import { store } from "../redux/store";
import { SET_TOKENEXPIRED_LOADER } from "../redux/reducerSlice/authSlice";

const url = "https://sandboxapi.immarsify.com/";
// const url = "http://localhost:8001/";
// export const RZOR_KEY = "rzp_test_4lmeofj69swege";

export const RZOR_KEY ="rzp_test_Mn0t8Nywu2LLf0"
export const AWS_DATA_PREFIX =
  "https://sandbox-immarsify-data-bucket.s3.ap-south-1.amazonaws.com/";
export const AWS_STATIC_PREFIX =
  "https://sandbox-immarsify-staticdata.s3.ap-south-1.amazonaws.com/";

const instance = axios.create({
  baseURL: url,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(SET_TOKENEXPIRED_LOADER(true));
    }
    return Promise.reject(error);
  }
);

export { instance };
