import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import Card from "components/card";

const DeviceTraffic = ({ data, loading }) => {
  const pieChartData = [
    parseFloat(data?.total_visitor_desktop_percentage),
    parseFloat(data?.total_visitor_mobile_percentage),
  ];

  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Device
          </h4>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        {!loading ? (
          <PieChart options={pieChartOptions} series={pieChartData} />
        ) : (
          <div className=" h-full w-full rounded-lg p-4">
            <div className="flex h-full w-full animate-pulse items-center justify-center space-x-4">
              <div className="h-36 w-36 rounded-full bg-gray-500"></div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">
              Laptop/Desktop
            </p>
          </div>
          {loading ? (
            <div className=" h-full w-full rounded-lg">
              <div className="flex h-full  w-full animate-pulse items-center justify-center">
                <div className="h-6 w-24 rounded-xl bg-gray-500"></div>
              </div>
            </div>
          ) : (
            <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
              {parseFloat(data?.total_visitor_desktop_percentage)}%
            </p>
          )}
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Phone</p>
          </div>
          {loading ? (
            <div className=" h-full w-full rounded-lg">
              <div className="flex h-full  w-full animate-pulse items-center justify-center">
                <div className="h-6 w-24 rounded-xl bg-gray-500"></div>
              </div>
            </div>
          ) : (
            <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
              {parseFloat(data?.total_visitor_mobile_percentage)}%
            </p>
          )}
        </div>
      </div>
    </Card>
  );
};

export default DeviceTraffic;
