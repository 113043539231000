import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import ContainerPreviewExport from "./preview/exportPreview";
import "./export.css";
import ExportMenuButton from "./ExportMenu";
import html2canvas from "html2canvas";
import { toJpeg, toPng } from "html-to-image";
import jsPDF from "jspdf";
const PrintWrapper = ({
  frontRef,
  backRef,
  tabValue,
  setTabValue,
  elements,
  elementsForBack,
  setSelectedElementIndex,
  selectedElementIndex,
  setIsEditable,
  isEditable,
  setBgImageSelected,
  previewFrontRef,
  previewBackRef
}) => {
  const componentRef = useRef();
  const tempRef = useRef();
  const printComponent = useReactToPrint({
    content: () => componentRef.current, // Use ref to identify what to print
  });

  const exportToImage = async () => {
    const node = document.getElementById("print-content"); // The container element for export

    if (node) {
      // Temporarily remove the "no-display" class for export
      node.classList.remove("no-display");

      // Store the original styles
      const originalStyle = {
        position: node.style.position,
        left: node.style.left,
        visibility: node.style.visibility,
        display: node.style.display,
      };

      // Temporarily style the node
      node.style.position = "absolute";
      node.style.left = "-9999px"; // Keep it off-screen
      node.style.visibility = "visible"; // Make it visible
      node.style.display = "block"; // Ensure it's displayed

      // Allow the DOM to update
      await new Promise((resolve) => setTimeout(resolve, 100)); // Small delay

      try {
        // Use html2canvas to capture the content
        const canvas = await html2canvas(node);
        const image = canvas.toDataURL("image/png"); // Get image data

        // Create a download link for the image
        const link = document.createElement("a");
        link.download = "card-preview.png"; // Name of the downloaded file
        link.href = image;
        link.click(); // Trigger the download
      } catch (error) {
        console.error("Failed to export image", error);
      } finally {
        // Restore the element's original styles
        node.classList.add("no-display");
        node.style.position = originalStyle.position; // Reset position
        node.style.left = originalStyle.left; // Reset left
        node.style.visibility = originalStyle.visibility; // Restore visibility
        node.style.display = originalStyle.display; // Restore display
      }
    }
  };

  const exportToPDF = async () => {
    const node = document.getElementById("print-content");
  
    if (node) {
      // Temporarily remove the "no-display" class for export
      node.classList.remove("no-display");
  
      // Store the original styles
      const originalStyle = {
        position: node.style.position,
        left: node.style.left,
        visibility: node.style.visibility,
        display: node.style.display,
      };
  
      // Temporarily style the node
      node.style.position = "absolute";
      node.style.left = "-9999px";
      node.style.visibility = "visible";
      node.style.display = "block";
  
      await new Promise((resolve) => setTimeout(resolve, 100));
  
      try {
        const canvas = await html2canvas(node);
        const imgData = canvas.toDataURL('image/png');
  
        // Get original dimensions
        const originalWidth = canvas.width;
        const originalHeight = canvas.height;
  
        // Calculate half dimensions (maintaining aspect ratio)
        const scaleFactor = 0.5;
        const pdfWidth = originalWidth * scaleFactor;
        const pdfHeight = originalHeight * scaleFactor;
  
        // Create PDF with A4 size
        const pdf = new jsPDF({
          orientation: pdfWidth > pdfHeight ? 'landscape' : 'portrait',
          unit: 'px',
          format: 'a4'
        });
  
        // Get A4 dimensions in pixels (assuming 96 DPI)
        const a4Width = pdf.internal.pageSize.getWidth() * 96 / 72;  // Convert pt to px
        
        // Calculate centered position horizontally, but fixed position from top
        const xOffset = (a4Width - pdfWidth) / 2;
        const topMargin = 40; // Add a small margin from top (adjust as needed)
  
        // Add the image to PDF with scaled dimensions and top positioning
        pdf.addImage(imgData, 'PNG', xOffset, topMargin, pdfWidth, pdfHeight);
  
        // Save the PDF
        pdf.save('card-preview.pdf');
  
      } catch (error) {
        console.error("Failed to export PDF", error);
      } finally {
        // Restore the element's original styles
        node.classList.add("no-display");
        node.style.position = originalStyle.position;
        node.style.left = originalStyle.left;
        node.style.visibility = originalStyle.visibility;
        node.style.display = originalStyle.display;
      }
    }
  };
  return (
    <div>
      <div id="print-content" className="no-display" ref={componentRef}>
        <ContainerPreviewExport
          frontRef={frontRef}
          backRef={backRef}
          tabValue={tabValue}
          setTabValue={setTabValue}
          elements={elements}
          elementsForBack={elementsForBack}
          setSelectedElementIndex={setSelectedElementIndex}
          selectedElementIndex={selectedElementIndex}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          previewFrontRef={previewFrontRef}
          previewBackRef={previewBackRef}
        />
      </div>

      {/* Button to trigger the print */}
      {/* <button
        onMouseEnter={(event) => {
          setSelectedElementIndex(null);
          setIsEditable(false);
          setBgImageSelected(false);
        }}
        className="rounded-md bg-brand-main px-4 py-2 font-semibold shadow-lg"
        onClick={printComponent}
      >
        Export
      </button> */}

      <ExportMenuButton
        setSelectedElementIndex={setSelectedElementIndex}
        setIsEditable={setIsEditable}
        setBgImageSelected={setBgImageSelected}
        printComponent={printComponent}
        exportToImage={exportToImage}
        exportToPDF={exportToPDF}
      />
    </div>
  );
};

export default PrintWrapper;
