import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaMobileAlt,
  FaYoutube,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardFifteen = React.forwardRef(
  ({ username, experiencename, name, user, cardData }, ref) => {
    return (
      <div
        ref={ref}
        className="relative h-[250px] w-[400px]    overflow-hidden  bg-gradient-to-br from-gray-900 to-gray-800 shadow-xl"
      >
        <div className="absolute right-3  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#ffff" className="top-4" />
          <Logo color={"#ffff"} height={35} width={35} />
        </div>
        {/* Motor Vehicle-themed SVG Background */}
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Subtle grid for texture */}
          <pattern
            id="grid"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M 20 0 L 0 0 0 20"
              fill="none"
              stroke="rgba(255,255,255,0.05)"
              strokeWidth="0.5"
            />
          </pattern>
          <rect width="100%" height="100%" fill="url(#grid)" />

          {/* Stylized car silhouette */}
          <path
            d="M50,140 Q100,100 200,100 T350,140 L350,180 H50 Z"
            fill="none"
            stroke="rgba(255,255,255,0.1)"
            strokeWidth="2"
          />

          {/* Wheel */}
          <circle
            cx="100"
            cy="180"
            r="25"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />
          <circle
            cx="300"
            cy="180"
            r="25"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />

          {/* Speedometer-like element */}
          <path
            d="M320,50 A40,40 0 0,1 380,90"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />
          <line
            x1="350"
            y1="70"
            x2="370"
            y2="80"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth="2"
          />

          {/* Abstract lines suggesting speed or aerodynamics */}
          <path
            d="M20,30 Q100,10 200,30 T380,30"
            fill="none"
            stroke="rgba(255,255,255,0.1)"
            strokeWidth="2"
          />
          <path
            d="M20,60 Q100,40 200,60 T380,60"
            fill="none"
            stroke="rgba(255,255,255,0.05)"
            strokeWidth="2"
          />
        </svg>

        {/* Card Content */}
        <div className="relative z-10 flex h-full flex-col justify-center p-6  pt-12 text-white">
          <div>
            <h2
              className={` mb-2 flex items-center justify-center break-words break-all capitalize  ${
                name.length > 20 ? "text-2xl" : "text-3xl"
              }  font-bold `}
            >
              {name
                .split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}
            </h2>
          </div>
          <div className="flex items-center justify-center">
            {/* Placeholder for QR Code */}
            <div className="flex items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-gray-300 shadow-inner">
              <QRCodeSVG
                value={`https://sandboxar.immarsify.com/${username}/${experiencename}`}
                fgColor="#ffff"
                size={110}
                bgColor="transparent"
                className=" text-[#ffff]"
              />
            </div>
            <div className="text-right">
              {/* <p className="shadow-text text-sm text-gray-300">
                www.autoinnovate.com
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default CardFifteen;

export const CardFifteenBackSide = React.forwardRef(
  ({ username, experiencename, name, user, cardData }, ref) => {
    return (
      <div
        ref={ref}
        className="relative h-[250px] w-[400px]    overflow-hidden  bg-gradient-to-br from-gray-900 to-gray-800 shadow-xl"
      >
        <div className="absolute right-3  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#ffff" className="top-4" />
          <Logo color={"#ffff"} height={35} width={35} />
        </div>
        {/* Motor Vehicle-themed SVG Background */}
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Subtle grid for texture */}
          <pattern
            id="grid"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M 20 0 L 0 0 0 20"
              fill="none"
              stroke="rgba(255,255,255,0.05)"
              strokeWidth="0.5"
            />
          </pattern>
          <rect width="100%" height="100%" fill="url(#grid)" />

          {/* Stylized car silhouette */}
          <path
            d="M50,140 Q100,100 200,100 T350,140 L350,180 H50 Z"
            fill="none"
            stroke="rgba(255,255,255,0.1)"
            strokeWidth="2"
          />

          {/* Wheel */}
          <circle
            cx="100"
            cy="180"
            r="25"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />
          <circle
            cx="300"
            cy="180"
            r="25"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />

          {/* Speedometer-like element */}
          <path
            d="M320,50 A40,40 0 0,1 380,90"
            fill="none"
            stroke="rgba(255,255,255,0.2)"
            strokeWidth="3"
          />
          <line
            x1="350"
            y1="70"
            x2="370"
            y2="80"
            stroke="rgba(255,255,255,0.3)"
            strokeWidth="2"
          />

          {/* Abstract lines suggesting speed or aerodynamics */}
          <path
            d="M20,30 Q100,10 200,30 T380,30"
            fill="none"
            stroke="rgba(255,255,255,0.1)"
            strokeWidth="2"
          />
          <path
            d="M20,60 Q100,40 200,60 T380,60"
            fill="none"
            stroke="rgba(255,255,255,0.05)"
            strokeWidth="2"
          />
        </svg>

        <div className="relative  break-all  p-4 text-sm text-white">
          <div className="mt-8">
            <h2
              className={` mb-2 text-center font-bold ${
                cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`
              } }`}
            >
              {cardData?.companyName
                ?.split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}
            </h2>

            <div className="flex justify-center ">
              <div className="mt-4 flex flex-col gap-2">
                {cardData?.address && (
                  <p className="flex  gap-2 text-sm">
                    <IoLocationSharp className="min-h-5 min-w-5" />
                    {cardData?.address}
                  </p>
                )}

                {cardData?.email && (
                  <p className="flex  gap-2 text-sm">
                    <MdOutlineMail className="min-h-5 min-w-5" />
                    {cardData?.email}
                  </p>
                )}

                {cardData?.Phone && (
                  <p className="flex  gap-2 text-sm">
                    <FaMobileAlt className="min-h-5 min-w-5" />
                    {cardData?.Phone}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
