import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import Publish from "./tabcomponent/Publish";
import { useSelector, useDispatch } from "react-redux";
import { SET_INITIAL_STATE_AR } from "../../../redux/reducerSlice/worldTrackSlice";
import { useParams } from "react-router-dom";
import Editor from "./tabcomponent/Editor";
import { setRun } from "../../../redux/reducerSlice/joyrideSlice";
import { setViewCreated } from "../../../redux/reducerSlice/valueSlice";
import { MdOutlineClear } from "react-icons/md";
import { instance } from "common/Instance";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 180,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const WorldExperience = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [form, setForm] = useState(false);

  const run = useSelector((state) => state.joyride.run);

  useEffect(() => {
    dispatch(setRun(true));
  }, [run]);

  const [opensave, setOpenSave] = React.useState(false);
  const handleSaveOpen = () => setOpenSave(true);
  const handleCloseSave = () => setOpenSave(false);
  const dispatch = useDispatch();

  const { taskId } = useParams();
 
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        hidden={value !== index}
        id={`simple-CustomTabPanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      sx: {
        fontWeight: "bold",
        textTransform: "capitalize",
        fontSize: 16,
        marginTop: 2,
        alignItems: "start",
      },
    };
  }

  return (
    <div className="-p-[22px]">
      <Box
        className="rounded-xl border-2 bg-white p-2 shadow-md "
        sx={{ width: "100%", height: "100%" }}
      >
        {/* <CustomTabPanel value={value} index={0}> */}
        <Editor
          form={form}
          setForm={setForm}
          taskId={taskId}
          handleOpen={handleOpen}
          handleSaveOpen={handleSaveOpen}
        />
        {/* </CustomTabPanel> */}
      </Box>
      <Publish open={open} handleClose={handleClose} />
      <Save open={opensave} handleClose={handleCloseSave} />
    </div>
  );
};

const Save = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const arData = useSelector((state) => state?.WorldTrack?.data);
  const userToken = useSelector((state) => state.auth.auth.token);
  const userName = useSelector(
    (state) => state?.auth?.auth?.user?.username ?? ""
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSave = async () => {
    setLoading(true);

    try {
      const data = {
        ...arData,
        userName,
      };
      delete data._id;

      const response = await instance.put(
        `api/WTracking/WorldTracking_update/${arData._id}`,
        data,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        navigate("/admin/World_Tracking_history");
        dispatch(SET_INITIAL_STATE_AR());
        dispatch(setViewCreated(""));
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="rounded-lg">
        <div className="flex items-center justify-between rounded-t-lg bg-gray-200 p-2">
          <p className="text-lg font-bold ">Immarsify</p>
          <span onClick={handleClose} className="cursor-pointer">
            <MdOutlineClear size={20} />
          </span>
        </div>
        <p className="text-md p-4">Are You Sure, You Want To Save?</p>

        <div className="absolute bottom-2 right-2 mt-2 flex items-center justify-center gap-2 p-2 ">
          <button
            className="rounded-lg border  bg-[#3d2fa9] px-4 py-1 text-white "
            onClick={handleSave}
          >
            Save
          </button>
          <button
            className="rounded-lg border border-gray-500 px-4 py-1 text-gray-900 "
            onClick={() => {
              navigate("/admin/World_Tracking_history");
              dispatch(SET_INITIAL_STATE_AR());
              dispatch(setViewCreated(""));
            }}
          >
            {" "}
            Don't save{" "}
          </button>
          <button
            className="rounded-lg border border-gray-500 px-4 py-1 text-gray-900 "
            onClick={handleClose}
          >
            {" "}
            Cancel{" "}
          </button>
        </div>
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h1 className="font-bold">Saving AR Experience</h1>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
};

export default WorldExperience;
