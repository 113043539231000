import { createSlice } from "@reduxjs/toolkit";
import { LOGOUT } from "./rootSlice";


const INITIAL_STATE = {
  data: {
    mind: { src: "" },
    targetImage: { src: "" },
    model: [],

    photos: [],
    icons: [],
    buttons: [],
    videos: [],
    text: [],
    documents: [],
    centerPosition: {},
    name: "",
    isVisibleOnLoad: false,
    stickTOCam:false,
    setEnquiry: false,
    hideCamera:false,

    carousel: [],
    resume: [],
    multiTarget:{
      mindSrc:'',
      Targetimages:[]
    }
  },
  isLoaded: false,
  multisceneData: [],
  historyData: [],
  currentPageHistory: 1,
  totalPagesHistory: 0,
  templateData: [],
  currentPage: 1,
  totalPages: 0,
  brandingData: [],
  historyDataForSidebar: [],
};

const arSlice = createSlice({
  name: "ar",
  initialState: INITIAL_STATE,
  reducers: {
    SET_INITIAL_STATE_AR: (state, action) => {
      state.data = INITIAL_STATE.data;
    },
    SET_UPDATE_STATE_AR: (state, action) => {
      state.data = action.payload;
    },
    SET_TARGET_IMG: (state, action) => {
      state.data.targetImage = action.payload;
    },
    SET_TARGET_HIDDEN_VALUE: (state, action) => {
      state.data.targetImage.isHidden = action.payload;
    },
    SET_MIND_FILE: (state, action) => {
      state.data.mind.src = action.payload;
    },
    SET_LOADED_AR: (state, action) => {
      state.isLoaded = action.payload;
    },

    SET_HISTORY_DATA: (state, action) => {
      state.historyData = action.payload;
    },
    SET_HISTORY_DATA_FOR_SIDEBAR: (state, action) => {
      state.historyDataForSidebar = action.payload;
    },
    SET_IS_VISIBLE_ON_LOAD: (state, action) => {
      state.data.isVisibleOnLoad = action.payload;
    },
    SET_STICK_TO_CAMERA: (state, action) => {
      state.data.stickTOCam = action.payload;
    },
    SET_IS_ENQUIRY: (state, action) => {
      state.data.setEnquiry = action.payload;
    },

    SET_HIDE_CAMERA: (state, action) => {
      state.data.hideCamera = action.payload;
    },

    UPDATE_TARGET_FOR_EDITOR: (state, action) => {
      state.data.targetImage.scale = action.payload.scale;
    },

    SET_TEMPLATE_DATA: (state, action) => {
      state.templateData = action.payload;
    },

    SET_MIND_FORMULTIPLE_TARGET_IMG: (state, action) => {
      if (!state.data.multiTarget) {
        state.data.multiTarget = {
          mindSrc: "",
          Targetimages: [...state?.data?.multiTarget?.Targetimages],
        };
      }

      state.data.multiTarget.mindSrc = action.payload;
    },

    SET_ALTERNATE_TARGET_IMG: (state, action) => {
      if (!state.data.multiTarget) {
        state.data.multiTarget = {
          mindSrc: "",
          Targetimages: [],
        };
      }

      state.data.multiTarget.Targetimages = [
        ...state.data.multiTarget.Targetimages,
        action.payload,
      ];
    },

    SET_INITIAL_ALTERNATE_TARGET_IMG: (state, action) => {
      if (!state.data.multiTarget) {
        state.data.multiTarget = {
          mindSrc: "",
          Targetimages: [],
        };
      }

      if (!Array.isArray(state.data.multiTarget.Targetimages)) {
        state.data.multiTarget.Targetimages = [];
      }

      const newMultiTarget = {
        ...state.data.multiTarget,
        Targetimages: [...state.data.multiTarget.Targetimages],
      };

      newMultiTarget.Targetimages[0] = action.payload;

      state.data.multiTarget = newMultiTarget;
    },
    DELETE_ALTERNATE_TARGET_IMG: (state, action) => {
      if (
        state.data.multiTarget &&
        Array.isArray(state.data.multiTarget.Targetimages)
      ) {
        state.data.multiTarget.Targetimages =
          state.data.multiTarget.Targetimages.filter(
            (element) => element.src !== action.payload
          );
      }
    },

    SET_MULTISCENE_DATA: (state, action) => {
      let isPresent = state.multisceneData.some((item) => {
        return JSON.stringify(item) === JSON.stringify(action.payload);
      });

      if (!isPresent) {
        state.multisceneData = [...state.multisceneData, action.payload];
      } else {
        state.multisceneData = state.multisceneData.filter((item) => {
          return JSON.stringify(item) !== JSON.stringify(action.payload);
        });
      }
    },

    CLEAR_MULTISCENE_DATA: (state, action) => {
      state.multisceneData = INITIAL_STATE.multisceneData;
    },
    // _________TEXT START___________

    UPDATE_TEXT_PROPERTIES: (state, action) => {
      const { value, id, key, parentKey } = action.payload;
      const { text } = state.data;

      const textIndex = text.findIndex((text) => text.id === id);

      if (textIndex !== -1) {
        const updatedText = [...text];

        if (parentKey === "Animation") {
          updatedText[textIndex] = {
            ...updatedText[textIndex],
            animation: {
              ...updatedText[textIndex].animation,
              [key]: value,
            },
          };
        } else {
          updatedText[textIndex] = {
            ...updatedText[textIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            text: updatedText,
          },
        };
      }
    },
    UPDATE_TEXT_FOR_EDITOR: (state, action) => {
      let textData = [...state.data.text];
      const singleData = textData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        textData[singleData] = { ...textData[singleData], ...action.payload };
      }
      state.data = {
        ...state.data,
        text: textData,
      };
    },
    SET_ADD_TEXT: (state, action) => {
      state.data = {
        ...state.data,
        text: [...state.data.text, action.payload],
      };
    },
    REMOVE_TEXT: (state, action) => {
      const { body } = action.payload;
      state.data = {
        ...state.data,
        text: state.data.text.filter((text) => text.body !== body),
      };
    },

    // _________TEXT END_______________

    // _________PHOTOS START___________
    SET_ADD_PHOTOS: (state, action) => {
      state.data = {
        ...state.data,
        photos: [...state.data.photos, action.payload],
      };
    },
    REMOVE_PHOTO: (state, action) => {
      state.data = {
        ...state.data,
        photos: state.data.photos.filter(
          (photo) => photo.id !== action.payload.id
        ),
      };
    },
    CLEAR_ALL_PHOTO: (state, action) => {
      state.data = {
        ...state.data,
        photos: [],
      };
    },
    UPDATE_PHOTO_FOR_EDITOR: (state, action) => {
      let photoData = [...state.data.photos];
      const singleData = photoData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        photoData[singleData] = { ...photoData[singleData], ...action.payload };
      }
      state.data = {
        ...state.data,
        photos: photoData,
      };
    },
    SET_PHOTO_PROPERTIES: (state, action) => {
      const { id, value, key, parentKey } = action.payload;
      const { photos } = state.data;
      const photoIndex = photos.findIndex((photo) => photo.id === id);

      if (photoIndex !== -1) {
        const updatedPhotos = [...photos];
        if (parentKey === "Animation") {
          updatedPhotos[photoIndex] = {
            ...updatedPhotos[photoIndex],
            animation: {
              ...updatedPhotos[photoIndex].animation,
              [key]: value,
            },
          };
        } else {
          updatedPhotos[photoIndex] = {
            ...updatedPhotos[photoIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            photos: updatedPhotos,
          },
        };
      }

      return state;
    },

    SET_PHOTO_LINK: (state, action) => {
      const { id, link } = action.payload;
      const { photos } = state.data;
      const photoIndex = photos.findIndex((photo) => photo.id === id);

      if (photoIndex !== -1) {
        const updatedPhotos = [...photos];

        updatedPhotos[photoIndex] = {
          ...updatedPhotos[photoIndex],
          urlLink: link,
        };

        return {
          ...state,
          data: {
            ...state.data,
            photos: updatedPhotos,
          },
        };
      }
    },
    // _______PHOTOS END__________________

    // Resume start

    SET_ADD_RESUME: (state, action) => {
      if (!state.data.resume) {
        state.data.resume = [];
      }

      state.data = {
        ...state.data,
        resume: [...state.data.resume, action.payload],
      };
    },
    REMOVE_RESUME: (state, action) => {
      state.data = {
        ...state.data,
        resume: state.data.resume.filter(
          (resume) => resume.id !== action.payload.id
        ),
      };
    },
    CLEAR_ALL_RESUME: (state, action) => {
      state.data = {
        ...state.data,
        resume: [],
      };
    },
    UPDATE_RESUME_FOR_EDITOR: (state, action) => {
      let resumeData = [...state.data.resume];

      console.log(action.payload, "this is acction payload");
      const singleData = resumeData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        resumeData[singleData] = {
          ...resumeData[singleData],
          ...action.payload,
        };
      }
      state.data = {
        ...state.data,
        resume: resumeData,
      };
    },

    SET_RESUME_PROPERTIES: (state, action) => {
      const { id, value, key, parentKey } = action.payload;
      const { resume } = state.data;
      console.log("resume: ", resume);
      const resumeIndex = resume.findIndex((photo) => photo.id === id);

      if (resumeIndex !== -1) {
        const updatedResume = [...resume];
        if (parentKey === "Animation") {
          updatedResume[resumeIndex] = {
            ...updatedResume[resumeIndex],
            animation: {
              ...updatedResume[resumeIndex].animation,
              [key]: value,
            },
          };
        } else {
          updatedResume[resumeIndex] = {
            ...updatedResume[resumeIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            resume: updatedResume,
          },
        };
      }

      return state;
    },

    SET_RESUME_FIELD: (state, action) => {
      const { id, fieldId, fieldValue, fieldKey } = action.payload;
      const { resume } = state.data;
      const resumeIndex = resume.findIndex((photo) => photo.id === id);

      if (resumeIndex !== -1) {
        const updatedResume = [...resume];
        const resumeData = resume[resumeIndex]?.resumeData || [];
        const resumeFieldDataIndex = resumeData.findIndex(
          (data) => data.id === fieldId
        );

        if (resumeFieldDataIndex !== -1) {
          resumeData[resumeFieldDataIndex] = {
            ...resumeData[resumeFieldDataIndex],
            [fieldKey]: fieldValue,
          };
        }

        updatedResume[resumeIndex] = {
          ...updatedResume[resumeIndex],
          resumeData,
        };

        return {
          ...state,
          data: {
            ...state.data,
            resume: updatedResume,
          },
        };
      }

      return state;
    },
    UPDATE_RESUME_FIELD_ITEM: (state, action) => {
      const { id, itemData, resumeId } = action.payload;

      const resumeIndex = state.data.resume.findIndex(
        (data) => data.id === resumeId
      );

      if (resumeIndex !== -1) {
        const updatedResumeData = state.data.resume[resumeIndex].resumeData.map(
          (data) => (data.id === id ? { ...data, ...itemData } : data)
        );

        const updatedResume = state.data.resume.map((resume, index) =>
          index === resumeIndex
            ? { ...resume, resumeData: updatedResumeData }
            : resume
        );

        state.data = {
          ...state.data,
          resume: updatedResume,
        };
      }
    },

    ADD_NEW_RESUME_FIELD: (state, action) => {
      const { id, fieldData, resumeId } = action.payload;

      const resumeIndex = state.data.resume.findIndex(
        (data) => data.id === resumeId
      );

      if (resumeIndex !== -1) {
        const existingResumeData =
          state.data.resume[resumeIndex].resumeData || [];

        // Create the updated resumeData array
        const updatedResumeData = [...existingResumeData, { ...fieldData, id }];
        const updatedResume = state.data.resume.map((resume, index) =>
          index === resumeIndex
            ? { ...resume, resumeData: updatedResumeData }
            : resume
        );

        state.data = {
          ...state.data,
          resume: updatedResume,
        };
      }
    },

    REMOVE_RESUME_FIELD: (state, action) => {
      const { id, fieldId } = action.payload;
      console.log(id, fieldId, "this is field id and id");

      const updatedResume = state.data.resume.map((resumeItem) => {
        if (resumeItem.id === id) {
          return {
            ...resumeItem,
            resumeData: resumeItem.resumeData.filter(
              (data) => data.id !== fieldId
            ),
          };
        }
        return resumeItem;
      });

      state.data = {
        ...state.data,
        resume: updatedResume,
      };
      console.log(state.data.resume, "this is updated resume data");
    },

    // Resume End

    //CAROUSEL START
    SET_ADD_CAROUSEL: (state, action) => {
      if (!state.data.carousel) {
        state.data.carousel = [];
      }
      state.data = {
        ...state.data,
        carousel: [...state.data.carousel, action.payload],
      };
    },

    ADD_ITEM_TO_CAROUSEL: (state, action) => {
      console.log(action.payload, "actionrohit");

      state.data = {
        ...state.data,
        carousel: state.data.carousel.map((carouselData) =>
          carouselData.id === action.payload.id
            ? {
                ...carouselData,
                children: [...(carouselData.children || []), action.payload],
              }
            : carouselData
        ),
      };
    },

    REMOVE_CAROUSEL: (state, action) => {
      state.data = {
        ...state.data,
        carousel: state.data.carousel.filter(
          (carousel) => carousel.id !== action.payload.id
        ),
      };
    },

    REMOVE_CAROUSEL_CHILDREN: (state, action) => {
      const { id, childId } = action.payload;

      // Create a copy of the current carousel array
      let carouselData = state?.data?.carousel.map((carousel) => {
        if (carousel.id === id) {
          const updatedChildren = carousel.children.filter(
            (element) => element.childId !== childId
          );

          return {
            ...carousel,
            children: updatedChildren,
          };
        }
        return carousel;
      });

      state.data = {
        ...state.data,
        carousel: carouselData,
      };
    },

    CLEAR_ALL_CAROUSEL: (state, action) => {
      state.data = {
        ...state.data,
        carousel: [],
      };
    },

    UPDATE_CAROUSEL_FOR_EDITOR: (state, action) => {
      let carouselData = [...state?.data?.carousel];
      const singleData = carouselData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        carouselData[singleData] = {
          ...carouselData[singleData],
          ...action.payload,
        };
      }
      state.data = {
        ...state.data,
        carousel: carouselData,
      };
    },

    SET_CAROUSEL_PROPERTIES: (state, action) => {
      const { id, value, key, parentKey } = action.payload;
      const { carousel } = state.data;

      const carouselIndex = carousel.findIndex((icon) => icon.id === id);
      const updatedCarousel = [...carousel];

      if (carouselIndex !== -1) {
        if (parentKey === "Animation") {
          updatedCarousel[carouselIndex] = {
            ...updatedCarousel[carouselIndex],
            animation: {
              ...updatedCarousel[carouselIndex].animation,
              [key]: value,
            },
          };
        } else {
          updatedCarousel[carouselIndex] = {
            ...updatedCarousel[carouselIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            carousel: updatedCarousel,
          },
        };
      }
    },

    SET_CAROUSEL_CHILDREN_POSITION: (state, action) => {
      console.log(action.payload, "actionrohit");

      state.data = {
        ...state.data,
        carousel: state.data.carousel.map((carouselData) =>
          carouselData.id === action.payload.id
            ? {
                ...carouselData,
                children: [...carouselData.children, action.payload],
              }
            : carouselData
        ),
      };
    },

    // _______ICONS ___________

    UPDATE_ICONS: (state, action) => {
      console.log("UPDATE_ICONS ==", state, action);
      state.data = {
        ...state.data,
        icons: [...state.data.icons, action.payload],
      };
    },
    CLEAR_ICONS: (state) => {
      state.data = {
        ...state.data,
        icons: [],
      };
    },
    REMOVE_ICONS: (state, action) => {
      state.data = {
        ...state.data,
        icons: state.data.icons.filter((icon) => icon.id !== action.payload.id),
      };
    },

    UPDATE_ICON_FOR_EDITOR: (state, action) => {
      let iconsData = [...state.data.icons];
      const singleData = iconsData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        iconsData[singleData] = { ...iconsData[singleData], ...action.payload };
      }
      state.data = {
        ...state.data,
        icons: iconsData,
      };
    },

    SET_CENTER_FOR_EDITOR: (state, action) => {
      state.data.centerPosition = action.payload;
    },
    UPDATE_CENTER_FOR_EDITOR: (state, action) => {
      let centerPositions = { ...state.data.centerPosition };
      centerPositions = { ...centerPositions, ...action.payload };

      state.data = {
        ...state.data,
        centerPosition: centerPositions,
      };
    },

    SET_ICONS_PROPERTIES: (state, action) => {
      const { id, value, key, parentKey } = action.payload;
      const { icons } = state.data;

      const iconIndex = icons.findIndex((icon) => icon.id === id);
      const updatedIcons = [...icons];

      if (iconIndex !== -1) {
        if (parentKey === "Animation") {
          updatedIcons[iconIndex] = {
            ...updatedIcons[iconIndex],
            animation: {
              ...updatedIcons[iconIndex].animation,
              [key]: value,
            },
          };
        } else {
          updatedIcons[iconIndex] = {
            ...updatedIcons[iconIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            icons: updatedIcons,
          },
        };
      }
    },
    // for to change the icon from right panel

    UPDATE_ICON_LINK: (state, action) => {
      const { id, urlLink } = action.payload;

      const { icons } = state.data;
      const iconIndex = icons.findIndex((photo) => photo.id === id);

      if (iconIndex !== -1) {
        const updatedIcons = [...icons];

        updatedIcons[iconIndex] = {
          ...updatedIcons[iconIndex],
          src: urlLink,
        };

        return {
          ...state,
          data: {
            ...state.data,
            icons: updatedIcons,
          },
        };
      }
    },

    //For to set the URL to Icon
    SET_ICONS_LINK: (state, action) => {
      const { id, iconLink } = action.payload;

      const { icons } = state.data;
      const iconIndex = icons.findIndex((photo) => photo.id === id);

      console.log("iconIndex", iconIndex);

      if (iconIndex !== -1) {
        const updatedIcons = [...icons];

        updatedIcons[iconIndex] = {
          ...updatedIcons[iconIndex],
          iconLink: iconLink,
        };

        return {
          ...state,
          data: {
            ...state.data,
            icons: updatedIcons,
          },
        };
      }
    },

    // To set the icons name
    SET_ICONS_NAME: (state, action) => {
      const { id, iconName } = action.payload;

      const { icons } = state.data;
      const iconIndex = icons.findIndex((photo) => photo.id === id);

      console.log("iconIndex", iconIndex);

      if (iconIndex !== -1) {
        const updatedIcons = [...icons];

        updatedIcons[iconIndex] = {
          ...updatedIcons[iconIndex],
          iconName: iconName || "",
        };

        return {
          ...state,
          data: {
            ...state.data,
            icons: updatedIcons,
          },
        };
      }
    },

    UPDATE_POSITION_FOR_FULLSCREEN: (state, action) => {
      const { id, position } = action.payload;

      const { icons } = state.data;
      const iconIndex = icons.findIndex((icon) => icon.id === id);

      if (iconIndex !== -1) {
        const updatedIcons = [...icons];

        updatedIcons[iconIndex] = {
          ...updatedIcons[iconIndex],
          positionFullscreen: position,
        };

        return {
          ...state,
          data: {
            ...state.data,
            icons: updatedIcons,
          },
        };
      }
    },

    // _______ICON  END________

    //DOCUMENT_START

    ADD_DOCUMENT: (state, action) => {
      if (!state.data.documents) {
        state.data.documents = [];
      }
      state.data = {
        ...state.data,
        documents: [...state.data.documents, action.payload],
      };
    },
    CLEAR_DOCUMENT: (state) => {
      state.data = {
        ...state.data,
        documents: [],
      };
    },
    REMOVE_DOCUMENT: (state, action) => {
      state.data = {
        ...state.data,
        documents: state.data.documents.filter(
          (document) => document.id !== action.payload.id
        ),
      };
    },

    UPDATE_DOCUMENT_FOR_EDITOR: (state, action) => {
      let documentsData = [...state.data.documents];
      const singleData = documentsData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        documentsData[singleData] = {
          ...documentsData[singleData],
          ...action.payload,
        };
      }
      state.data = {
        ...state.data,
        documents: documentsData,
      };
    },

    SET_DOCUMENT_PROPERTIES: (state, action) => {
      const { id, value, key, parentKey } = action.payload;
      const { documents } = state.data;

      const documentIndex = documents.findIndex((icon) => icon.id === id);
      const updatedDocuments = [...documents];

      if (documentIndex !== -1) {
        if (parentKey === "Animation") {
          updatedDocuments[documentIndex] = {
            ...updatedDocuments[documentIndex],
            animation: {
              ...updatedDocuments[documentIndex].animation,
              [key]: value,
            },
          };
        } else {
          updatedDocuments[documentIndex] = {
            ...updatedDocuments[documentIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            documents: updatedDocuments,
          },
        };
      }
    },

    SET_DOCUMENT_LINK: (state, action) => {
      const { id, iconLink } = action.payload;

      const { documents } = state.data;
      const iconIndex = documents.findIndex((document) => document.id === id);

      if (iconIndex !== -1) {
        const updatedDocument = [...documents];

        updatedDocument[iconIndex] = {
          ...updatedDocument[iconIndex],
          iconLink: iconLink,
        };

        return {
          ...state,
          data: {
            ...state.data,
            documents: updatedDocument,
          },
        };
      }
    },

    //DOCUMENT_END

    UPDATE_TAP_TO_VIEW: (state, action) => {
      if (!state.data.multiTarget) {
        state.data.multiTarget = {
          mindSrc: "",
          Targetimages: [],
        };
      }

      state.data.multiTarget.Targetimages =
        state.data.multiTarget.Targetimages.map((element) =>
          element.id === action.payload.id
            ? { ...element, isTaptoView: true }
            : { ...element, isTaptoView: false }
        );
    },

    // _________VIDEO START_____

    SET_ADD_VIDEO: (state, action) => {
      state.data = {
        ...state.data,
        videos: [...state.data.videos, action.payload],
      };
    },
    REMOVE_VIDEO: (state, action) => {
      state.data = {
        ...state.data,
        videos: state.data.videos.filter(
          (video) => video.id !== action.payload.id
        ),
      };
    },

    SET_VIDEO_PROPERTIES: (state, action) => {
      const { id, key, value, parentKey } = action.payload;
      const { videos } = state.data;

      const videoIndex = videos.findIndex((video) => video.id === id);

      if (videoIndex !== -1) {
        const videoData = [...videos];
        if (parentKey === "Animation") {
          videoData[videoIndex] = {
            ...videoData[videoIndex],
            animation: {
              ...videoData[videoIndex].animation,
              [key]: value,
            },
          };
        } else if (parentKey === "Vsettings") {
          videoData[videoIndex] = {
            ...videoData[videoIndex],
            vsettings: {
              ...videoData[videoIndex].vsettings,
              [key]: value,
            },
          };
        } else {
          videoData[videoIndex] = {
            ...videoData[videoIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            videos: videoData,
          },
        };
      }

      return state;
    },

    UPDATE_VIDEO_FOR_EDITOR: (state, action) => {
      let videoData = [...state.data.videos];
      const singleData = videoData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        videoData[singleData] = { ...videoData[singleData], ...action.payload };
      }
      state.data = {
        ...state.data,
        videos: videoData,
      };
    },

    //3d model

    SET_3DMODEL: (state, action) => {
      if (!state.data.model) {
        state.data.model = [];
      }
      state.data = {
        ...state.data,
        model: [...state.data.model, action.payload],
      };
    },

    REMOVE_MODEL: (state, action) => {
      state.data = {
        ...state.data,
        model: state.data.model.filter(
          (model) => model.id !== action.payload.id
        ),
      };
    },

    SET_MODEL_PROPERTIES: (state, action) => {
      const { id, key, value, parentKey } = action.payload;
      const { model } = state.data;

      const modelIndex = model.findIndex((model) => model.id === id);

      if (modelIndex !== -1) {
        const modelData = [...model];
        if (parentKey === "Animation") {
          modelData[modelIndex] = {
            ...modelData[modelIndex],
            animation: {
              ...modelData[modelIndex].animation,
              [key]: value,
            },
          };
        } else {
          modelData[modelIndex] = {
            ...modelData[modelIndex],
            [key]: value,
          };
        }

        return {
          ...state,
          data: {
            ...state.data,
            model: modelData,
          },
        };
      }

      return state;
    },

    UPDATE_MODEL_FOR_EDITOR: (state, action) => {
      let modelData = [...state.data.model];
      const singleData = modelData.findIndex(
        (item) => item.id === action.payload.id
      );
      if (singleData !== -1) {
        modelData[singleData] = { ...modelData[singleData], ...action.payload };
      }
      state.data = {
        ...state.data,
        model: modelData,
      };
    },

    //Branding
    SET_BRANDING_DATA: (state, action) => {
      state.brandingData = action.payload;
    },

    //other

    SET_HISTORY_CURRENT_PAGE: (state, action) => {
      state.currentPageHistory = action.payload;
    },

    SET_HISTORY_TOTAL_PAGE: (state, action) => {
      state.totalPagesHistory = action.payload;
    },
    SET_TEMPLATE_CURRENT_PAGE: (state, action) => {
      state.currentPage = action.payload;
    },

    SET_TEMPLATE_TOTAL_PAGE: (state, action) => {
      state.totalPages = action.payload;
    },

    // __________Video End__________

    extraReducers: (builder) => {
      builder.addCase(LOGOUT, (state) => {
        state.data = INITIAL_STATE.data;
        state.historyData = INITIAL_STATE.historyData;
        state.multisceneData = INITIAL_STATE.multisceneData;
      });
    },
  },
});

export const {
  SET_INITIAL_STATE_AR,
  SET_UPDATE_STATE_AR,
  SET_TARGET_IMG,
  SET_TARGET_HIDDEN_VALUE,
  SET_MIND_FILE,
  SET_LOADED_AR,
  SET_HISTORY_DATA,
  SET_TEMPLATE_DATA,
  CLEAR_MULTISCENE_DATA,
  SET_HISTORY_DATA_FOR_SIDEBAR,
  SET_IS_VISIBLE_ON_LOAD,
  UPDATE_TARGET_FOR_EDITOR,
  SET_ALTERNATE_TARGET_IMG,
  SET_INITIAL_ALTERNATE_TARGET_IMG,
  DELETE_ALTERNATE_TARGET_IMG,
  SET_MIND_FORMULTIPLE_TARGET_IMG,

  // PHOTO
  SET_ADD_PHOTOS,
  REMOVE_PHOTO,
  CLEAR_ALL_PHOTO,
  SET_PHOTO_PROPERTIES,
  UPDATE_PHOTO_FOR_EDITOR,
  SET_PHOTO_LINK,

  //ICON
  CLEAR_ICONS,
  UPDATE_ICONS,
  REMOVE_ICONS,
  UPDATE_ICON_FOR_EDITOR,
  SET_ICONS_PROPERTIES,
  SET_ICONS_LINK,
  UPDATE_ICON_LINK,
  SET_ICONS_NAME,
  ADD_ITEM_TO_CAROUSEL,
  UPDATE_POSITION_FOR_FULLSCREEN,

  // TEXT
  UPDATE_TEXT_PROPERTIES,
  UPDATE_TEXT_FOR_EDITOR,
  REMOVE_TEXT,
  SET_ADD_TEXT,
  SET_MULTISCENE_DATA,

  // VIDEO
  SET_ADD_VIDEO,
  SET_VIDEO_PROPERTIES,
  UPDATE_VIDEO_FOR_EDITOR,
  REMOVE_VIDEO,

  // Model

  SET_3DMODEL,
  SET_MODEL_PROPERTIES,
  UPDATE_MODEL_FOR_EDITOR,
  REMOVE_MODEL,

  SET_CENTER_FOR_EDITOR,
  UPDATE_CENTER_FOR_EDITOR,

  //Resume

  SET_ADD_RESUME,
  REMOVE_RESUME,
  UPDATE_RESUME_FOR_EDITOR,
  ADD_NEW_RESUME_FIELD,
  REMOVE_RESUME_FIELD,
  SET_RESUME_FIELD,
  UPDATE_RESUME_FIELD,
  SET_RESUME_PROPERTIES,
  UPDATE_RESUME_FIELD_ITEM,

  //Other

  SET_CAROUSEL_CHILDREN_POSITION,
  SET_TEMPLATE_TOTAL_PAGE,
  UPDATE_CAROUSEL_FOR_EDITOR,
  SET_CAROUSEL_PROPERTIES,
  SET_ADD_CAROUSEL,
  REMOVE_CAROUSEL,
  SET_TEMPLATE_CURRENT_PAGE,
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
  SET_BRANDING_DATA,
  ADD_DOCUMENT,
  REMOVE_DOCUMENT,
  UPDATE_DOCUMENT_FOR_EDITOR,
  SET_DOCUMENT_PROPERTIES,
  SET_DOCUMENT_LINK,
  REMOVE_CAROUSEL_CHILDREN,
  SET_IS_ENQUIRY,
  UPDATE_TAP_TO_VIEW,
  SET_STICK_TO_CAMERA,
  SET_HIDE_CAMERA
  
} = arSlice.actions;

export default arSlice.reducer;
