import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { Box, TextField } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineClear } from "react-icons/md";
import { toast } from "react-hot-toast";
import { instance } from "common/Instance";
import { SET_PHOTO_PROPERTIES } from "../../../../../redux/reducerSlice/worldTrackSlice";
import CircularWithValueLabel from "components/circularProgress";
import { loadTexture } from "../../../arExperience/tabcomponent/EditorUtils";
import * as mind from "mind-ar/dist/mindar-image.prod";
import {
  SET_MIND_FILE,
  SET_TARGET_IMG,
  SET_CENTER_FOR_EDITOR,
} from "../../.././../../redux/reducerSlice/arSlice";
import CardOne from "../Cardtemplates/CardOne";
import { toSvg } from "html-to-image";
import imageCompression from 'browser-image-compression';

export default function UploadTarget({ open, handleClose }) {
  const [preview, setPreview] = useState([]);
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const [file, setFiles] = useState();
  const arData = useSelector((state) => state.WorldTrack.data);
  const targetPreview = useSelector((state) => state.WorldTrack.data.targetImage.src);
  const [compliningProgress, setCompilingProgress] = useState(0);
  const dispatch = useDispatch();
  const compiler = new mind.Compiler();
  const cardRef = useRef(null);
  const [name, setName] = useState("John Doe");
  const [jobTitle, setJobTitle] = useState("Managing Director");
  const [address, setAddress] = useState("123 Anywhere St, Any City");
  const [email, setEmail] = useState("hello@reallygreatsite.com");
  const [phone, setPhone] = useState("+123-456-7890");
  const [companyTagline, setCompanyTagline] = useState("Company tag line");
  const [companyName, setCompanyName] = useState("Company name");

  const handleFileUpload = async (e) => {
    const files = e?.target?.files;
    console.log(files[0], "files1");
    if (files) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(files[0].type)) {
        toast.error("Please select a .jpg, .jpeg, or .png file");
        return;
      }

      const fileSizeInMB = files[0].size / (1024 * 1024);
      if (fileSizeInMB > 2) {
        toast.error("File size should be less than 2 MB");
        return;
      }

      setFiles(files[0]);

      const imageFiles = Array.from(files).filter((file) =>
        file.type.startsWith("image/")
      );
      const imageElements = imageFiles.map((file) => {
        const image = new Image();

        image.src = URL.createObjectURL(file);
        return image;
      });

      setPreview(imageElements);
    }
  };

  const uploadFile = async (file, assetType) => {


    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }


    const compressedBlob = await imageCompression(file, options);
    console.log('Compressed file:', compressedBlob);
    console.log(`Compressed file size: ${compressedBlob.size / 1024 / 1024} MB`);

    // Convert Blob to File
    const compressedFile = new File([compressedBlob], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });


    const formData = new FormData();
    formData.append("file", compressedFile);
    formData.append("assetType", assetType);

    try {
      const response = await instance.post(
        `/api/ar/upload/${user.username}/${arData.name}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createTarget = async () => {
    setLoading(true);

    try {
      if (!preview.length) return;
      const imageUrl = await uploadFile(file, "image");
      const texture = await loadTexture(imageUrl);
      const textureWidth = texture.image.width;
      const textureHeight = texture.image.height;

      const aspectRatio = textureWidth / textureHeight;

      dispatch(
        SET_TARGET_IMG({
          src: imageUrl,
          scale: { x: aspectRatio * 0.6, y: 0.6, z: 1 },
        })
      );

      const dataList = await compiler.compileImageTargets(
        preview,
        (progress) => {
          setCompilingProgress(Math.round(progress));
        }
      );
      setCompilingProgress(0);

      const exportedBuffer = await compiler.exportData();
      const blob = new Blob([exportedBuffer]);
      const mindFile = new File([blob], "target.mind", { type: blob.type });

      const mindUrl = await uploadFile(mindFile, "mind");
      dispatch(SET_MIND_FILE(mindUrl));

      setLoading(false);
      toast.success("Target Image Uploaded Successfully");
      handleClose();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: "fit",
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
  };

  const handleDownload = () => {
    // const svg = cardRef.current;
    // const svgData = new XMLSerializer().serializeToString(svg);
    // const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    // saveAs(blob, "card.svg");
    if (cardRef.current === null) {
      return;
    }
    toSvg(cardRef.current, { quality: 1, width: 440, height: 260 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `card.svg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error("Error generating image:", err);
      });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      {/* <div>
        <Box sx={style}>
          <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
            <p className="text-xl font-bold ">Immarsify</p>
            <span onClick={handleClose} className="cursor-pointer">
              <MdOutlineClear size={24} />
            </span>
          </div>

          <div className="mt-2 flex h-full w-full flex-col items-center justify-center gap-2 p-2">
            <label
              htmlFor="fileInput"
              className="flex h-full w-[80%] flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4 py-2  dark:!border-navy-700 lg:pb-0"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/png, image/jpeg, image/jpg"
                className="hidden"
                onChange={(e) => handleFileUpload(e)}
              />

              {preview?.length > 0 ? (
                <img
                  src={preview.length > 0 ? preview[0]?.src : ""}
                  alt="Preview"
                  className="max-h-[250px] max-w-[250px] object-contain"
                />
              ) : (
                <div className="cursor-pointer  text-center font-semibold text-gray-900"></div>
              )}

              {preview?.length > 0 ? (
                <p className="mb-2 mt-4   text-center text-xl  font-medium text-gray-900">
                  Browse Other
                </p>
              ) : (
                <h4 className=" mb-2 mt-2 text-xl font-bold text-blue-500 dark:text-white">
                  Upload
                </h4>
              )}

              <p className="text-md mb-2 mt-2  text-center font-semibold text-gray-600">
                Supported File :- PNG, JPG and JEPG
              </p>
              <p className="my-2 text-sm text-gray-700">
                Single image limit size upto 2mb
              </p>
            </label>

            {preview?.length > 0 ? (
              <button
                className="text-md mx-auto mb-5  mt-5 w-[30%] cursor-pointer rounded-lg border border-gray-900 bg-brand-main p-2 font-normal text-white"
                onClick={() => createTarget(handleClose)}
              >
                {compliningProgress > 0 ? (
                  <CircularWithValueLabel value={compliningProgress} />
                ) : (
                  "Upload File"
                )}
              </button>
            ) : (
              ""
            )}
          </div>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="flex flex-col items-center justify-center gap-1.5">
              <h1 className="text-lg font-bold">Uploading Target</h1>
              <h1 className="text-md font-bold">
                Please don't referesh page, creating target image
              </h1>
              <h1 className="text-md font-bold">We appreciate your patience</h1>
            </div>
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </div> */}
      <div className="flex items-center gap-4 bg-white py-10">
        <div className="mb-10 flex w-[50%] flex-col gap-4 p-4 ">
          <TextField
            size="small"
            type="text"
            label="Company name"
            placeholder="Company name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="mb-2 rounded border p-2"
          />
          <TextField
            size="small"
            type="text"
            placeholder="Company tag line"
            multiline
            label="Company tag line"
            value={companyTagline}
            onChange={(e) => setCompanyTagline(e.target.value)}
            className="mb-2 rounded border p-2"
          />
          <TextField
            size="small"
            type="text"
            placeholder="Name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mb-2 rounded border p-2"
          />
          <TextField
            size="small"
            type="text"
            placeholder="Job Title"
            label="Job Title"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            className="mb-2 rounded border p-2"
          />

          <TextField
            size="small"
            type="email"
            placeholder="Email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mb-2 rounded border p-2"
          />
          <TextField
            size="small"
            type="tel"
            placeholder="Phone"
            label="Phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="mb-2 rounded border p-2"
          />
          <button
            onClick={handleDownload}
            className="mt-2 rounded bg-blue-500 p-2 text-white"
          >
            Download as SVG
          </button>
        </div>
        <div className="flex w-[50%] items-center justify-center">
          <CardOne
            ref={cardRef}
            name={name}
            companyName={companyName}
            companyTagline={companyTagline}
            jobTitle={jobTitle}
            address={address}
            email={email}
            phone={phone}
          />
        </div>
      </div>
    </Modal>
  );
}
