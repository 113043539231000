import React, { useState, useEffect } from "react";
import Card from "components/card";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import Chart from "react-apexcharts";
import Pagination from "@mui/material/Pagination";
import { useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import { barChartOptionsSocialMediaCounts } from "variables/charts";

const SocialMediaCountsList = ({ experienceName, userId }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userType = useSelector((state) => state?.auth?.auth?.user?.type);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setSelectedValue] = useState(10);
  const [socialMediaCounts, setSocialMediaCounts] = useState([{ name: "Social Media Counts", data: [] }]);
  const [chartOptions, setChartOptions] = useState({
    ...barChartOptionsSocialMediaCounts,
    xaxis: { ...barChartOptionsSocialMediaCounts.xaxis, categories: [] },
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (experienceName) {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            colorStops: [
              {
                offset: 0,
                color: "#50e7a6",
                opacity: 1,
              },
              {
                offset: 100,
                color: "rgba(80, 231, 166, 0.5)",
                opacity: 0.28,
              },
            ],
          },
        },
      }));
    }
  }, [experienceName]);


  const getSocialMediaCounts = async () => {
    if (tableData?.length === 0) {
      setLoading(true);
    }
    try {
      const url = userType === 'user' 
      ? 
      `api/visitors/get_visitors_most_used_count_user` 
      // `api/visitors/get_visitors_most_used_count_user${experienceName ? `?experienceName=${experienceName}` : ""}` 
      :
        `api/visitors/get_visitors_most_used_count_reseller?experienceName=${experienceName ? experienceName : ""}&userId=${userId ? userId : ""}`

      const response = await instance.get(url, {
        headers: {
          authorization: `Bearer ${authToken}`
        }
      })
      console.log("Social Media counts ", response);
      if (response.status === 200) {

        setTableData(response?.data?.data);
        const totalCnt = response?.data?.data?.map((obj) => obj.total);
        const platformName = response?.data?.data?.map((obj) => obj.platform);
        setSocialMediaCounts([{ name: "Daily Traffic", data: totalCnt }]);
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: { ...prevOptions.xaxis, categories: platformName },
        }));
        setTotalPages(response?.data?.totalPages);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error while getting social media counts");
      toast.error(error?.response?.message || "Error fetching user data");
      setLoading(false);
    }
  }

  useEffect(() => {
    getSocialMediaCounts()
  }, [currentPage, itemsPerPage, experienceName]);

  return (
    <div>
      <Card extra={"w-full h-full sm:overflow-auto px-6 py-4"}>
        <header className="flex w-full flex-col justify-center">
          <div className="relative flex items-center justify-between">
            <div className="text-md font-bold text-navy-700 dark:text-white mb-2">
              {experienceName ? `${experienceName}'s` : "Overall"} Social Media Counts
            </div>
          </div>
          <div className="relative flex w-full flex-col items-center gap-2 py-1 md:flex-row">

            <div className="flex w-full items-center justify-end gap-4 ">

              {/* <Tooltip title="Rows per page">
                <select
                  className="ml-2 block rounded-md border border-brand-main bg-gray-100 p-1 shadow-sm outline-none"
                  value={itemsPerPage}
                  onChange={handleChange}
                >
                  {[...Array(11).keys()].map((_, index) => (
                    index > 0 && (
                      <option key={index} value={index * 5}>{index * 5}</option>
                    )
                  ))}
                </select>
              </Tooltip> */}
            </div>
          </div>
        </header>

        <div className="mt-4 overflow-x-scroll xl:overflow-x-auto">
          {/* <table className="w-full min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Platform</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Total</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {loading ? (
                [...Array(itemsPerPage)].map((_, index) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div role="status" className="animate-pulse"><div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div></div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div role="status" className="animate-pulse"><div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div></div>
                    </td>
                   
                  </tr>
                ))
              ) : tableData.length > 0 ? (
                tableData.map((item) => (
                  <tr key={item._id}>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm font-medium text-gray-900">{item?.platform}</div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4">
                      <div className="text-sm text-gray-900">{item?.total}</div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="w-full">
                  <td colSpan="2" className="max-w-full py-4 text-center">
                    <p className="w-full max-w-full bg-blue-100 px-10 py-5 text-sm text-blue-600">Data Not Found!</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table> */}

          {/* <div className="my-5 flex w-full items-center justify-end text-sm">
            <Pagination
              count={totalPages}
              color="primary"
              page={currentPage}
              onChange={handleChanges}
            />
          </div> */}
        </div>
        <div className="h-[300px] w-full pb-0 pt-1 ">
          {loading ? (
            <div className="h-full w-full rounded-lg">
              <div className="flex h-full w-full animate-pulse">
                <div className="h-full w-full rounded-xl bg-gray-500">Loading...</div>
              </div>
            </div>
          ) : (
            <Chart
              options={chartOptions}
              series={socialMediaCounts}
              type="bar"
              width="100%"
              height="100%"
            />
          )}
        </div>
      </Card>
    </div>
  );
};

export default SocialMediaCountsList;
