import React from 'react'
import SocialMediaCountsList from './Components/SocialMediaCountsList'




const SocialMediaCountsDashboard = ({experienceName, userId}) => {
  return (
   
        <SocialMediaCountsList experienceName={experienceName} userId={userId} /> 
       
     
  )
}

export default SocialMediaCountsDashboard