import React, { useState, useEffect } from "react";
import { Slider, Select, MenuItem } from "@mui/material";
import { IoMdResize } from "react-icons/io";

const ZoomControl = ({ containerRef, containerRefForBack, tabValue }) => {
  const [zoom, setZoom] = useState(100);
  const [displayZoom, setDisplayZoom] = useState(100);
  const minZoom = 25;
  const maxZoom = 300;
  const zoomLevels = [25, 50, 75, 100, 150, 200, 300];

  useEffect(() => {
    if (tabValue === "front") {
      if (containerRef.current) {
        containerRef.current.style.transform = `scale(${zoom / 100})`;
        //   containerRefForBack.current.style.transform = `scale(${zoom / 100})`;
      }
    } else {
      if (containerRefForBack.current) {
        containerRefForBack.current.style.transform = `scale(${zoom / 100})`;
        //   containerRefForBack.current.style.transform = `scale(${zoom / 100})`;
      }
    }
  }, [zoom, containerRef, containerRefForBack]);

  useEffect(() => {
    setZoom(100);
    setDisplayZoom(100);
  }, [tabValue]);

  const handleSliderChange = (event, newValue) => {
    setZoom(newValue);
    setDisplayZoom(newValue);
  };

  const handleSelectChange = (event) => {
    const newZoom = Number(event.target.value);
    setZoom(newZoom);
    setDisplayZoom(newZoom);
  };

  return (
    <div className="hidden fixed bottom-0 left-0 right-0 md:flex items-center justify-start border-t border-gray-200 bg-white p-4">
      <div className="flex w-full max-w-md items-center space-x-4">
        <IoMdResize className="h-5 w-5" />
        <Slider
          value={zoom}
          min={minZoom}
          max={maxZoom}
          step={1}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => `${value}%`}
          className="flex-grow"
        />
        <Select
          value={displayZoom}
          onChange={handleSelectChange}
          size="small"
          className="w-24"
          renderValue={(value) => `${value}%`}
        >
          {zoomLevels.map((level) => (
            <MenuItem key={level} value={level}>
              {level}%
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default ZoomControl;
