import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import NfcImmarsify from "assets/svg/immarsifyM.svg";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaMobileAlt, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardNine = React.forwardRef(({ username, experiencename, name , cardData}, ref) => {
  return (
    <div
      ref={ref}
      items-center
      className="relative h-[250px] w-[400px]  overflow-hidden  bg-white"
    >
      <div className="absolute right-3 top-1  z-10 flex items-center justify-end ">
        <LuNfc size={25} color="#0067B1" className="top-3" />
        <Logo color={"#0067B1"} height={35} width={35} />
      </div>

      <div className="relative h-[250px] p-6">
        <svg
          className="absolute inset-0 h-full w-full opacity-20"
          viewBox="100 100 500 300"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: "#ff4a4a", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#ffb347", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <polygon points="0,0 800,0 600,400 0,400" fill="url(#grad1)" />
          <circle cx="150" cy="150" r="100" fill="rgba(0, 255, 255, 0.3)" />
          <rect
            x="200"
            y="50"
            width="200"
            height="200"
            fill="rgba(255, 255, 255, 0.2)"
          />
          <line
            x1="100"
            y1="0"
            x2="300"
            y2="400"
            stroke="#00ff99"
            strokeWidth="4"
          />
          <path
            d="M0,300 Q400,50 800,300"
            stroke="#ff00ff"
            strokeWidth="3"
            fill="none"
          />
        </svg>

        <div className="relative z-10 flex w-full flex-col items-center text-center text-gray-900">
          <h2
            className={` mb-2 flex items-center justify-center break-words capitalize break-all ${
              name.length > 20 ? "text-2xl" : "text-3xl"
            }  font-bold `}
          >
            {name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
          </h2>

          <div className="mt-2 rounded bg-white p-2 shadow-lg">
            <QRCodeSVG
              value={`https://sandboxar.immarsify.com/${username}/${experiencename}`}
              size={100}
              bgColor="#ffffff"
              fgColor="#000000"
              level="Q"
              includeMargin={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default CardNine;


export const CardNineBackSide = React.forwardRef(({ username, experiencename, name , cardData}, ref) => {
  return (
    <div
      ref={ref}
      items-center
      className="relative h-[250px] w-[400px]  overflow-hidden  bg-white"
    >
      <div className="absolute right-3 top-1  z-10 flex items-center justify-end ">
        <LuNfc size={25} color="#0067B1" className="top-3" />
        <Logo color={"#0067B1"} height={35} width={35} />
      </div>

      <div className="relative h-[250px] ">
        <svg
          className="absolute inset-0 h-full w-full opacity-20"
          viewBox="100 100 500 300"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop
                offset="0%"
                style={{ stopColor: "#ff4a4a", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "#ffb347", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
          <polygon points="0,0 800,0 600,400 0,400" fill="url(#grad1)" />
          <circle cx="150" cy="150" r="100" fill="rgba(0, 255, 255, 0.3)" />
          <rect
            x="200"
            y="50"
            width="200"
            height="200"
            fill="rgba(255, 255, 255, 0.2)"
          />
          <line
            x1="100"
            y1="0"
            x2="300"
            y2="400"
            stroke="#00ff99"
            strokeWidth="4"
          />
          <path
            d="M0,300 Q400,50 800,300"
            stroke="#ff00ff"
            strokeWidth="3"
            fill="none"
          />
        </svg>



        <div className="relative  p-4  text-sm break-all text-gray-900">
        <div className="mt-8">

    
<h2 className={` font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`} }`}>
    {cardData?.companyName?.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')}
  </h2>

<div className="flex justify-center ">

<div className="flex flex-col gap-2 mt-4">


{cardData?.address && <p className="text-sm  flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" />
{cardData?.address}</p>}

{cardData?.email && <p className="text-sm  flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" />
{cardData?.email}</p>}

{cardData?.Phone && <p className="text-sm  flex gap-2"><FaMobileAlt className="min-w-5 min-h-5"/>
{cardData?.Phone}</p>}

</div>


</div>







</div>
        </div>
      </div>
    </div>
  );
});