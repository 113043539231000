import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineClear } from "react-icons/md";
import { toast } from 'react-hot-toast';
import { instance } from "common/Instance";
import {SET_VIDEO_PROPERTIES} from "../../../../../redux/reducerSlice/worldTrackSlice"
export default function UploadVideo({ open, handleClose, id }) {
    const [loading, setLoading] = useState(false);
    const userToken = useSelector((state) => state.auth.auth.token);
    const user = useSelector((state) => state.auth.auth.user);
    const arData = useSelector((state) => state.WorldTrack.data);
    const dispatch = useDispatch();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        height: "fit",
        bgcolor: "background.paper",
        borderRadius: "10px",
        boxShadow: 24,
    };

    const handleFileUpload = async (e) => {
        try {
            const files = e.target.files;
            console.log(files, "fileSizeInMb");

            if (files) {
                const fileName = files[0].name.toLowerCase();
                if (!fileName.endsWith(".mp4")) {
                    toast.error("Please select a .mp4 file");
                    return;
                }

                const fileSizeInMB = files[0].size / (1024 * 1024);
                console.log(fileSizeInMB, "fileSizeInMb");

                if (fileSizeInMB > 25) {
                    toast.error("File size should be less than or equal to 25 MB");
                    return;
                }

                setLoading(true);
                const formData = new FormData();
                console.log(files, "files");
                formData.append("file", files[0]);
                formData.append("assetType", "video");
                const response = await instance.post(
                    `/api/ar/upload/${user.username}/${arData.name}`,
                    formData,
                    {
                        headers: {
                            authorization: `Bearer ${userToken}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.status === 200) {
                    setLoading(false);
                    const action = {
                        key:'src',
                        value :response?.data?.url,
                        id: id,
                    };
                    dispatch(SET_VIDEO_PROPERTIES(action));

                    toast.success("Video is Uploaded Successfully");

                    handleClose();
                }
            }
        } catch (error) {
            console.log(error, "error");
            setLoading(false);
            handleClose();
            toast.error(error?.response?.data?.message ?? error.message);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <div>
                <Box sx={style}>
                    <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
                        <p className="text-xl font-bold ">Immarsify</p>
                        <span onClick={handleClose} className="cursor-pointer">
                            <MdOutlineClear size={24} />
                        </span>
                    </div>

                    <div className="mt-4 flex h-full w-full cursor-pointer flex-col items-center justify-center  gap-2">
                        <label
                            htmlFor="fileInput"
                            className="flex  items-center justify-center rounded-md    p-2 text-white  dark:!border-navy-700 lg:pb-0"
                        >
                            <input
                                type="file"
                                id="fileInput"
                                accept="video/mp4"
                                className="hidden"
                                onChange={(e) => handleFileUpload(e)}
                            />

                            <div className="w-full cursor-pointer rounded-xs bg-brand-main px-2 py-2 text-center text-white rounded-md">
                                Replace Video
                            </div>
                        </label>

                        <p className="text-md mt-2 font-bold">Supported Format .MP4 </p>
                        <span>Please enter the video under 25mb</span>
                    </div>
                </Box>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <div className="flex flex-col items-center justify-center gap-4 ">
                        <h1 className="font-bold">Uploading Video</h1>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>
            </div>
        </Modal>
    );
};
