import React, { useState } from "react";
import * as THREE from "three";
import {} from "unsplash-js";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import Box from "@mui/material/Box";

import Drawer from "@mui/material/Drawer";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MdOutlineClear } from "react-icons/md";
import upload from "../../../../assets/img/upload.png";

import {
  SET_ADD_CAROUSEL,
  ADD_ITEM_TO_CAROUSEL,
} from "../../../../redux/reducerSlice/worldTrackSlice";

const ImageCarousel = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <Box
        sx={{ width: 500 }}
        role="presentation"
        className="relative h-screen overflow-hidden"
      >
        <div className="relative ">
          <div className="relative mb-9 mt-0   ">
            <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className=" text-lg font-semibold">Image Carousel</h4>
              <span
                className="cursor-pointer"
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                <MdOutlineClear size={20} />
              </span>
            </div>

            <div className="-mb-10 mt-10 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
              <img
                src={upload}
                alt="select "
                className="h-[20%] w-[40%] rounded-lg"
              />
            </div>

            <div className="flex h-[40vh] w-full items-center justify-center">

              
              <button
                className="text-md cursor-pointer rounded-lg bg-brand-main p-2 text-white "
                onClick={() => {
                  let initialImageData = {
                    type: "carousel",
                    type1: "dumyImage",
                    src: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRdbH8lXSyLmoUrFM3pbpOf9dMhvhxBGb28fw&s",
                    position: { x: 0, y: 0.0005, z: 0 },
                    rotation: { x: -Math.PI / 2, y: 0, z: 0 },
                    scale: { x: 1.4, y: 0.8, z: 1.0 },
                    geometry: "Plane",
                    urlLink: "",
                    isAnimation: false,
                    animation: {
                      duration: 0,
                      delay: 0,
                      direction: "topToBottom",
                    },
                  };

                  let group = new THREE.Group();

                  const data = {
                    userData: { type: "Group" },

                    id: uuidv4(),

                    isGroup: true,
                    isObject3D: true,
                    children: [initialImageData],
                    position: {
                      x: group.position.x,
                      y: group.position.y,
                      z: group.position.z,
                    },
                    rotation: { x: 6.284, y: 0, z: 0 },

                    scale: {
                      x: group.scale.x,
                      y: group.scale.y,
                      z: group.scale.z,
                    },
                  };

                  dispatch(SET_ADD_CAROUSEL(data));
                }}
              >
                Click here to create carousel
              </button>
            </div>
          </div>
        </div>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="font-bold">Uploading Image</h1>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
    </Drawer>
  );
};

export default ImageCarousel;
