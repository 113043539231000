import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";

import Pagination from "@mui/material/Pagination";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { instance } from "common/Instance";
import Card from "components/card";
// import { useRouteLoaderData } from "react-router-dom";

const RecycleBinDeletedExperienceTable = () => {
  const userToken = useSelector((state) => state.auth.auth.token);

  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [user, setUserId] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingRestore, setLoadingRestore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [itemsPerPage, setSelectedValue] = useState(10);
  const [timeoutId, setTimeoutId] = useState(null);
  const [cardList, setCardList] = useState([]);

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };

  const handleChange = (e) => {
    setCurrentPage(1);
    setSelectedValue(e.target.value);
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const cardDataList = async (perPage) => {
    setLoading(true);

    try {
      console.log(currentPage);
      const response = await instance.get(
        `/api/ar/deleted_experience_list_user?page=${
          perPage ? perPage : currentPage
        }&limit=${itemsPerPage}&searchText=${searchParam}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response?.status === 200) {
        setTotalPages(response?.data?.data?.totalPages);
        setLoading(false);
        setCardList(response?.data?.data?.data);
        console.log(response);
        // toast.success(response && response?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error && error?.response?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    cardDataList();
  }, [currentPage, itemsPerPage, searchParam]);

  const restoreCard = async (cardId) => {
    try {
      setLoadingRestore(true);
      const response = await instance.post(
        `/api/ar/restore_deleted_experience`,
        {
          id: cardId,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setOpenRestoreDialog(false);
        setLoadingRestore(false);
        cardDataList(searchParam ? 1 : currentPage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoadingRestore(false);
    }
  };

  const hardDeleteCard = async (cardId) => {
    try {
      setLoadingDelete(true);
      const response = await instance.delete(
        `/api/ar/delete_forever/${cardId}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        setOpenDeleteDialog(false);
        setLoadingDelete(false);
        cardDataList(searchParam ? 1 : currentPage);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoadingRestore(false);
    }
  }

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-3 py-4"}>
      <header className=" flex w-full flex-col justify-center gap-2">
        <div className="relative flex  items-center justify-between">
          <div className="text-md font-bold text-navy-700 dark:text-white">
            Recycle bin (CARD)
          </div>
        </div>
        <div className="relative flex  w-full items-center py-1">
          <div className="flex w-full items-center">
            <input
              className="w-full max-w-[500px] rounded-lg border-2 p-2 text-sm outline-none"
              type="text"
              value={search}
              onChange={handleSearch}
              placeholder="Search"
            />
          </div>
          <div className="flex w-full items-center justify-end ">
            {/* <p className="text-sm">Rows per page:</p> */}
            <Tooltip title="Rows per page">
            <select
              class="ml-2 block h-8 w-12 rounded-md  border bg-gray-200  shadow-sm outline-none"
              placeholder="List"
              value={itemsPerPage}
              onChange={handleChange}
            >
              {[...Array(11).keys()].map((_, index) => (
                <>
                  {index > 0 && (
                    <option key={index} value={index * 5}>
                      {index * 5}
                    </option>
                  )}
                </>
              ))}
            </select>
            </Tooltip>
          </div>
        </div>
      </header>

      <div className="mt-4 ">
        <div className="overflow-x-auto">
          
        
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Target Image
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Name
              </th>
              
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Username
              </th>
              {/* <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Status
              </th> */}
              <th
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              [...Array(itemsPerPage)].map((_, index) => (
                <tr key={index}>
                  <td
                    className="whitespace-nowrap px-3 py-4  "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className=" animate-pulse ">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4 "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4 "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4 "
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                  <td
                    className="whitespace-nowrap px-3 py-4"
                    style={{ width: "25%" }}
                  >
                    <div role="status" className="animate-pulse">
                      <div className="h-4  rounded-full bg-gray-200 dark:bg-gray-800 "></div>
                    </div>
                  </td>
                </tr>
              ))
            ) : cardList?.length > 0 ? (
              cardList?.map((item) => (
                <tr key={item._id}>
                  <td className="whitespace-nowrap px-3 py-4">
                    <div className="text-sm font-medium text-gray-900">
                      <img
                        src={item?.targetImage?.src}
                        width={80}
                        height={70}
                        alt=""
                      />
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4">
                    <div className="text-sm text-gray-900">{item?.name}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4">
                    <div className="text-sm text-gray-900">
                      {item?.userName}
                    </div>
                  </td>
                  {/* <td className="whitespace-nowrap px-3 py-4">
                    <div
                      className={`text-sm text-gray-900 ${
                        item?.status === "published"
                          ? "text-green-500"
                          : "text-red"
                      }`}
                    >
                      {item?.status}
                    </div>
                  </td> */}

                  <td className="whitespace-nowrap px-3 py-4 space-x-2">
                    <button
                      className=" rounded-md bg-green-600 p-1 px-4 text-sm font-semibold text-white"
                      onClick={() => {
                        setOpenRestoreDialog(true);
                        setUserId(item);
                      }}
                    >
                      Restore
                    </button>
                    <button
                      className=" rounded-md bg-red-600 p-1 px-4 text-sm font-semibold text-white"
                      onClick={() => {
                        setOpenDeleteDialog(true)
                        setUserId(item);
                      }}
                    >
                      Permanent Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="py-4 text-center">
                  <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                    Data Not Found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        <div className="my-4 flex w-full items-center justify-center text-sm ">
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChanges}
          />
        </div>
      </div>

      <Dialog
        open={openRestoreDialog}
        onClose={() => {
          setOpenRestoreDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Restore AR experience</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to restore this AR experiance?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="ml-5 rounded-md bg-gray-600 p-1 px-4 text-sm text-gray-900"
            onClick={() => {
              setOpenRestoreDialog(false);
            }}
          >
            Close
          </button>
          <button
            disabled={loadingRestore}
            className="ml-5 rounded-md bg-green-600 p-1 px-4 text-sm text-white"
            onClick={() => {
              restoreCard(user?._id);
            }}
            autoFocus
          >
            Confirm Restore
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Delete AR experience</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to restore this AR experiance?(This action cannot be undone!)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="ml-5 rounded-md bg-gray-600 p-1 px-4 text-sm text-gray-900"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Close
          </button>
          <button
            disabled={loadingDelete}
            className="ml-5 rounded-md bg-red-600 p-1 px-4 text-sm text-white"
            onClick={() => {
              hardDeleteCard(user?._id);
            }}
            autoFocus
          >
            Permanent Delete
          </button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default RecycleBinDeletedExperienceTable;
