import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineDelete } from "react-icons/ai";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { IoAddCircleOutline } from "react-icons/io5";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  DELETE_BG_IMAGE,
  DELETE_BG_IMAGE_FOR_BACK,
  UPDATE_BG_IMAGE,
  UPDATE_BG_IMAGE_FOR_BACK,
  UPDATE_ELEMENTS,
  UPDATE_ELEMENTS_FOR_BACK,
} from "../../../../../../redux/reducerSlice/customCardSlice";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ImageTab({
  bgImage,
  bgImageForBack,
  handleBgImageChange,
  tabValue,
  setElements,
  setElementsForBack,
  elements,
  elementsForBack,
  setBgImage,
  handleDeleteImageElement,
  setBgImageForBack,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const fileInputRef = React.useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddImage = () => {
    fileInputRef.current.click();
  };

  const imageElements = elements.filter((element) => element?.type === "image");
  const imageElementsBack = elementsForBack.filter(
    (element) => element?.type === "image"
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Background" {...a11yProps(0)} />
          <Tab label="Element" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {tabValue === "front" ? (
          <>
            {bgImage && <img src={bgImage} alt="bg_image" />}
            <div className="mt-2 flex justify-center">
              <label className="flex cursor-pointer items-center rounded-md bg-[#3d2fa9]  px-4 py-2    text-sm  font-bold text-white ">
                {tabValue === "front" && (
                  <>
                    {bgImage ? (
                      <span className="flex items-center">
                        <CgArrowsExchangeAlt className="mr-1 size-5 text-white" />
                        Change
                      </span>
                    ) : (
                      "Choose File"
                    )}
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleBgImageChange}
                  hidden
                />
              </label>

              {bgImage && (
                <label
                  onClick={() => {
                    setBgImage(null);
                    dispatch(DELETE_BG_IMAGE(null));
                  }}
                  className="mx-1 flex cursor-pointer items-center rounded-md bg-red-600 px-4 py-2  text-sm font-bold text-white"
                >
                  <AiOutlineDelete className="mr-1 size-5" /> Delete
                </label>
              )}
            </div>
          </>
        ) : (
          <>
            {bgImageForBack && <img src={bgImageForBack} alt="bg_image" />}
            <div className="mt-2 flex justify-center">
              <label className="flex cursor-pointer items-center rounded-md bg-[#3d2fa9]  px-4 py-2    text-sm  font-bold text-white ">
                <>
                  {bgImageForBack ? (
                    <span className="flex items-center">
                      <CgArrowsExchangeAlt className="mr-1 size-5 text-white" />
                      Change
                    </span>
                  ) : (
                    "Choose File"
                  )}
                </>

                <input
                  type="file"
                  accept="image/*"
                  onChange={handleBgImageChange}
                  hidden
                />
              </label>

              {bgImageForBack && (
                <label
                  onClick={() => {
                    setBgImageForBack(null);
                    dispatch(DELETE_BG_IMAGE_FOR_BACK(null));
                  }}
                  className="mx-1 flex cursor-pointer items-center rounded-md bg-red-600 px-4 py-2  text-sm font-bold text-white"
                >
                  <AiOutlineDelete className="mr-1 size-5" /> Delete
                </label>
              )}
            </div>
          </>
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {tabValue === "front" ? (
          <>
            <div className="flex flex-wrap gap-1">
              {imageElements &&
                imageElements.map((item) => {
                  return (
                    <div className="relative h-20 w-20">
                      <img
                        src={item?.src}
                        alt="element_image"
                        className="h-full w-full"
                      />
                      <button
                        onClick={() => handleDeleteImageElement(item?.id)}
                        className="absolute right-1 top-1 hover:bg-red-600 hover:p-1 hover:text-white"
                      >
                        <AiOutlineDelete />
                      </button>
                    </div>
                  );
                })}
            </div>
            <button
              className=" mt-2 flex w-full items-center justify-center rounded-lg  bg-brand-main  p-2   text-sm  font-bold text-white "
              onClick={handleAddImage}
            >
              <IoAddCircleOutline className="mr-1 size-5 text-white" />
              Add Image
            </button>
            <input
              type="file"
              ref={fileInputRef}
              hidden
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (!file) {
                  console.error("No file selected");
                  return;
                }

                // Validate file type
                if (!file.type.startsWith("image/")) {
                  toast.error("Please upload an image file");
                  console.error("Invalid file type");
                  return;
                }

                const imageUrl = URL.createObjectURL(file);

                const newElement = {
                  type: "image",
                  src: imageUrl,
                  x: 50,
                  y: 50,
                  width: 100,
                  height: 50,
                  rotation: 0,
                  fontSize: 16,
                  fontWeight: 200,
                  color: "#000000",
                  bgColor: null,
                  id: uuidv4(),
                  imageData: file,
                };
                console.log(newElement, file, "this is new element");
                // Update elements based on tabValue
                if (tabValue === "front") {
                  dispatch(UPDATE_ELEMENTS([...elements, newElement]));
                  setElements((prevElements) => [...prevElements, newElement]);
                } else {
                  dispatch(
                    UPDATE_ELEMENTS_FOR_BACK([...elementsForBack, newElement])
                  );
                  setElementsForBack((prevElementsForBack) => [
                    ...prevElementsForBack,
                    newElement,
                  ]);
                }
              }}
            />
          </>
        ) : (
          <>
            <div className="flex flex-wrap gap-1">
              {imageElementsBack &&
                imageElementsBack.map((item) => {
                  return (
                    <div className="relative h-20 w-20">
                      <img
                        src={item?.src}
                        alt="element_image"
                        className="h-full w-full"
                      />
                      <button
                        onClick={() => handleDeleteImageElement(item?.id)}
                        className="absolute right-1 top-1 hover:bg-red-600 hover:p-1 hover:text-white"
                      >
                        <AiOutlineDelete />
                      </button>
                    </div>
                  );
                })}
            </div>
            <button
              className=" mt-2 flex w-full items-center justify-center rounded-lg  bg-brand-main  p-2   text-sm  font-bold text-white "
              onClick={handleAddImage}
            >
              <IoAddCircleOutline className="mr-1 size-5 text-white" />
              Add Image
            </button>
            <input
              type="file"
              ref={fileInputRef}
              hidden
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                if (!file) {
                  console.error("No file selected");
                  return;
                }

                // Validate file type
                if (!file.type.startsWith("image/")) {
                  toast.error("Please upload an image file");
                  console.error("Invalid file type");
                  return;
                }

                const imageUrl = URL.createObjectURL(file);

                const newElement = {
                  type: "image",
                  src: imageUrl,
                  x: 50,
                  y: 50,
                  width: 100,
                  height: 50,
                  rotation: 0,
                  fontSize: 16,
                  fontWeight: 200,
                  color: "#000000",
                  bgColor: null,
                  id: uuidv4(),
                  imageData: file,
                };

                // Update elements based on tabValue
                if (tabValue === "front") {
                  dispatch(UPDATE_ELEMENTS([...elements, newElement]));
                  setElements((prevElements) => [...prevElements, newElement]);
                } else {
                  dispatch(
                    UPDATE_ELEMENTS_FOR_BACK([...elementsForBack, newElement])
                  );
                  setElementsForBack((prevElementsForBack) => [
                    ...prevElementsForBack,
                    newElement,
                  ]);
                }
              }}
            />
          </>
        )}
      </CustomTabPanel>
    </Box>
  );
}
