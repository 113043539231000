import { tab } from "@testing-library/user-event/dist/tab";
import React, { useEffect, useRef, useState } from "react";

const ContainerPreviewExport = ({
  frontRef,
  backRef,
  tabValue,
  setTabValue,
  elements,
  elementsForBack,
  setSelectedElementIndex,
  selectedElementIndex,
  isEditable,
  setIsEditable,
  previewFrontRef, 
  previewBackRef
}) => {
  const [frontPreview, setFrontPreview] = useState("");
  const [backPreview, setBackPreview] = useState("");


  useEffect(() => {
  
    const updatePreviews = () => {
      if (frontRef.current) {
        setFrontPreview(frontRef.current.innerHTML);
      }
      if (backRef.current) {
        setBackPreview(backRef.current.innerHTML);
      }
    };

    // Initial update
    updatePreviews();

    // Set up a MutationObserver to watch for changes
    const observer = new MutationObserver(updatePreviews);
    if (frontRef.current) {
      observer.observe(frontRef.current, {
        subtree: true,
        childList: true,
        attributes: true,
      });
    }
    if (backRef.current) {
      observer.observe(backRef.current, {
        subtree: true,
        childList: true,
        attributes: true,
      });
    }

    return () => observer.disconnect();
  }, [
    frontRef,
    backRef,
    elements,
    elementsForBack,
    selectedElementIndex,

  ]);

  useEffect(() => {
    if (previewFrontRef.current) {
      previewFrontRef.current.innerHTML = frontPreview;
    }
    if (previewBackRef.current) {
      previewBackRef.current.innerHTML = backPreview;
    }
  }, [frontPreview, backPreview]);
  // useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       const cardEditorSection = document.querySelector('.card-editor-section');

  //       if (cardEditorSection && !cardEditorSection.contains(event.target)) {

  //         setSelectedElementIndex(null); // Remove focus
  //       }
  //     };

  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, []);
  return (
    <div className="flex flex-col items-center space-y-4">
      <div 
      id="front-preview-card"
        onClick={() => setTabValue("front")}
        className={` cursor-pointer  rounded border   bg-gray-300 shadow-lg`}
      >
        <div ref={previewFrontRef} className=" h-full w-full  " />
      </div>
      <div
        onClick={() => setTabValue("back")}
        className={` cursor-pointer rounded border   bg-gray-300 shadow-lg`}
      >
        <div ref={previewBackRef} className=" h-full w-full " />
      </div>
    </div>
  );
};

export default ContainerPreviewExport;
