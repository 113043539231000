import { createSlice } from "@reduxjs/toolkit";
import { LOGOUT } from "./rootSlice";

const INITIAL_STATE = {
    cardsList: [], // List of cards from API
    currentCard: {
        elements: [],
        elementsForBack: [],
        bgColor: "",
        bgColorForBack: "",
        bgImage: null,        // For UI preview
        bgImageForBack: null, // For UI preview
        bgImageFile: null,    // Actual file for upload
        bgImageForBackFile: null, // Actual file for upload
        orientation: "landscape",
        name: "",
       
        _id: null,  // From API
    },
    isLoading: false,
    error: null
};

const cardSlice = createSlice({
    name: "card",
    initialState: INITIAL_STATE,
    reducers: {
        // Set all cards from API
        SET_CARDS_LIST: (state, action) => {
            state.cardsList = action.payload;
        },

        // Set single card data (when opening existing card)
        SET_CURRENT_CARD: (state, action) => {
            state.currentCard = {
                ...INITIAL_STATE.currentCard,
                ...action.payload
            };
        },

           // Set single card data (when opening existing card)
           SET_CURRENT_CARD_ID: (state, action) => {
            state.currentCard._id = action.payload;
        },


        // Reset card to initial state (for new card creation)
        RESET_CARD: (state) => {
            state.currentCard = INITIAL_STATE.currentCard;
        },

        // Update card elements
        UPDATE_ELEMENTS: (state, action) => {
            state.currentCard.elements = action.payload;
        },

        // Update back elements
        UPDATE_ELEMENTS_FOR_BACK: (state, action) => {
            state.currentCard.elementsForBack = action.payload;
        },

        // Update background color
        UPDATE_BG_COLOR: (state, action) => {
            state.currentCard.bgColor = action.payload;
        },

        // Update back background color
        UPDATE_BG_COLOR_FOR_BACK: (state, action) => {
            state.currentCard.bgColorForBack = action.payload;
        },

        // Update background image (both preview URL and file)
        UPDATE_BG_IMAGE: (state, action) => {
            const { previewUrl, file } = action.payload;
            state.currentCard.bgImage = previewUrl;
            state.currentCard.bgImageFile = file;
        },
        DELETE_BG_IMAGE: (state, action) => {
            state.currentCard.bgImage = null;
            state.currentCard.bgImageFile = null;
        },



        // Update back background image (both preview URL and file)
        UPDATE_BG_IMAGE_FOR_BACK: (state, action) => {
            const { previewUrl, file } = action.payload;
            state.currentCard.bgImageForBack = previewUrl;
            state.currentCard.bgImageForBackFile = file;
        },

        DELETE_BG_IMAGE_FOR_BACK: (state, action) => {
            state.currentCard.bgImageForBack = null;
            state.currentCard.bgImageForBackFile = null;
        },

        // Update card name
        UPDATE_CARD_NAME: (state, action) => {
            state.currentCard.name = action.payload.cardName;
            state.currentCard.orientation = action.payload.orientation;
        },

        // Set loading state
        SET_LOADING: (state, action) => {
            state.isLoading = action.payload;
        },

        // Set error state
        SET_ERROR: (state, action) => {
            state.error = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(LOGOUT, () => INITIAL_STATE);
    },
});

export const {
    SET_CARDS_LIST,
    SET_CURRENT_CARD,
    RESET_CARD,
    UPDATE_ELEMENTS,
    UPDATE_ELEMENTS_FOR_BACK,
    UPDATE_BG_COLOR,
    UPDATE_BG_COLOR_FOR_BACK,
    UPDATE_BG_IMAGE,
    UPDATE_BG_IMAGE_FOR_BACK,
    UPDATE_CARD_NAME,
    SET_LOADING,
    SET_ERROR,
    DELETE_BG_IMAGE, 
    DELETE_BG_IMAGE_FOR_BACK,
    SET_CURRENT_CARD_ID
} = cardSlice.actions;

export default cardSlice.reducer;