import { Tuple, configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createLogger } from "redux-logger";
import authSlice from "./reducerSlice/authSlice";
import rootSlice from "./reducerSlice/rootSlice";
import arSlice from "./reducerSlice/arSlice";
import valueSlice from "./reducerSlice/valueSlice";
import analyticsSlice from "./reducerSlice/analytics";
import productSlice from "./reducerSlice/productSlice";
import joyrideReducer from './reducerSlice/joyrideSlice';
import enquiryReducer from './reducerSlice/enquiry';
import tableDataSlice from "./reducerSlice/tableDataSlice";
import signupexpSlice from "./reducerSlice/signupexpSlice";
import worldTrackSlice from './reducerSlice/worldTrackSlice'

import customCardSlice from "./reducerSlice/customCardSlice";


const logger = createLogger();

const reducer = combineReducers({
  auth: authSlice,
  root: rootSlice,
  ar: arSlice,
  WorldTrack:worldTrackSlice,
  value: valueSlice,
  analytics: analyticsSlice,
  joyride: joyrideReducer,
  product: productSlice,
  enquiry: enquiryReducer,
  signupexp:signupexpSlice,
  customCard: customCardSlice,

  tableData:tableDataSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware: [logger],
  middleware: () => new Tuple(logger),
});

export const persistor = persistStore(store);
