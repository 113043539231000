import React, { useEffect, useRef, useState } from "react";
import { instance } from "common/Instance";
import toast from "react-hot-toast";

// import CenterPosition from './PositionObj'
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_MIND_FILE,
  SET_TARGET_IMG,
  SET_CENTER_FOR_EDITOR,
  SET_INITIAL_ALTERNATE_TARGET_IMG,
} from "../../../../redux/reducerSlice/arSlice";
import * as mind from "mind-ar/dist/mindar-image.prod";
import CircularWithValueLabel from "components/circularProgress";
import { MdCancelPresentation } from "react-icons/md";
import Box from "@mui/material/Box";
import { updateFile } from "../../../../redux/reducerSlice/valueSlice";
import { Backdrop, CircularProgress, TextField } from "@mui/material";
import upload from "../../../../assets/img/upload.png";
import { MdOutlineClear } from "react-icons/md";

import Drawer from "@mui/material/Drawer";
import { loadTexture } from "./EditorUtils";
import { toJpeg, toPng, toSvg } from "html-to-image";
import { saveAs } from "file-saver";
import CardOne from "./Cardtemplates/CardOne";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CardFive from "./Cardtemplates/CardFive";
import CardSix from "./Cardtemplates/CardSix";
import CardSeven from "./Cardtemplates/CardSeven";
import CardEight from "./Cardtemplates/CardEight";
import CardNine from "./Cardtemplates/CardNine";
import CardTen from "./Cardtemplates/CardTen";
import CardEleven from "./Cardtemplates/CardEleven";
import CardTwelve from "./Cardtemplates/CardTwelve";
import CardFifteen from "./Cardtemplates/CardFifteen";
import CardSixteen from "./Cardtemplates/CardSixteen";
import CardSeventeeen from "./Cardtemplates/CardSeventeen";
import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const TargetImage = ({ open, handleClose, fetchTargetMesh }) => {
  const [loading, setLoading] = useState(false);
  const arData = useSelector((state) => state?.ar?.data);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [currentTargetImageIndex, setCurrentTargetImageIndex] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    Phone: "",
    email: "",
    companyName: "",
    address: "",
    logo: null,
    visitingCardImage: null,
    websiteLink: "",
    ownerPhoto: null,
    facebook: "",
    linkedIn: "",
    youtube: "",
    googleMaps: "",
    googleReview: "",
    twitter: "",
    instagram: "",
  });
  const userName = useSelector((state) => state?.auth?.auth?.user?.username);
  const cardRefs = useRef([]);
  const testRef = useRef(null);
  const [preview, setPreview] = useState([]);

  const [compliningProgress, setCompilingProgress] = useState(0);
  const dispatch = useDispatch();
  const compiler = new mind.Compiler();

  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);
  const [selectedCard, setSelectedCard] = useState(null);

  const file = useSelector((state) => state.value.file);
  const cardRef = useRef(null);
  const [tab, setTab] = useState("upload");

  const handleSelect = (index) => {
    setSelectedIndex(index);
    setSelectedCard(cardRefs.current[index]);
    console.log("Selected card ref:", cardRefs.current[index]);
  };
  const handleFileUpload = async (e) => {
    const files = e?.target?.files;
    if (files) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(files[0]?.type)) {
        toast.error("Please select a .jpg, .jpeg, or .png file");
        return;
      }

      const fileSizeInMB = files[0].size / (1024 * 1024);
      if (fileSizeInMB > 2) {
        toast.error("File size should be less than 2 MB");
        return;
      }
      dispatch(updateFile(files));

      const imageFiles = Array.from(files).filter((file) =>
        file.type.startsWith("image/")
      );
      const imageElements = imageFiles.map((file) => {
        const image = new Image();

        image.src = URL.createObjectURL(file);
        return image;
      });

      setPreview(imageElements);
      console.log(preview);
    }
  };
  console.log("user", user);
  const uploadFile = async (file, assetType) => {
    console.log(`${file}------AssetType:${assetType}`);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);
    try {
      const response = await instance.post(
        `/api/ar/upload/${user?.username}/${arData?.name}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createTarget = async (handleClose, file, type, blobPreview) => {
    setLoading(true);

    console.log(file, "this is file ");

    const resizedFile = new File(
      [file],
      `targetimage_${Math.floor(1000 + Math.random() * 9000)}.jpeg`,
      {}
    );

    console.log(resizedFile, "this is resize file ");

    try {
      const imageUrl =
        type === "upload"
          ? await uploadFile(file[0], "image")
          : await uploadFile(resizedFile, "image");
      const texture = await loadTexture(imageUrl);
      const textureWidth = texture.image.width;
      const textureHeight = texture.image.height;

      let aspectRatio;

      if (arData) {
        dispatch(
          SET_CENTER_FOR_EDITOR({
            type: "centerPosition",
            position: { x: 0, y: 0, z: 0 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: { x: 0, y: 0, z: 0 },
          })
        );
      }

      if (textureWidth >= textureHeight) {
        aspectRatio = textureWidth / textureHeight;
        dispatch(
          SET_TARGET_IMG({
            id: uuidv4(),
            src: imageUrl,
            scale: { x: aspectRatio * 0.8, y: 0.75, z: 0.8 },
            isHidden: false,
            isTaptoView: true,
          })
        );

        dispatch(
          SET_INITIAL_ALTERNATE_TARGET_IMG({
            id: uuidv4(),
            src: imageUrl,
            scale: { x: aspectRatio * 0.8, y: 0.75, z: 0.8 },
            isHidden: false,
            isTaptoView: true,
          })
        );

        if (blobPreview) fetchTargetMesh();
      }
      if (textureHeight > textureWidth) {
        aspectRatio = textureHeight / textureWidth;
        dispatch(
          SET_TARGET_IMG({
            id: uuidv4(),
            src: imageUrl,
            scale: { x: 0.8, y: aspectRatio * 0.75, z: 0.8 },
            isHidden: false,
            isTaptoView: true,
          })
        );

        dispatch(
          SET_INITIAL_ALTERNATE_TARGET_IMG({
            id: uuidv4(),
            src: imageUrl,
            scale: { x: 0.8, y: aspectRatio * 0.75, z: 0.8 },
            isHidden: false,
            isTaptoView: true,
          })
        );
        if (blobPreview) fetchTargetMesh();
      }
      if (!blobPreview) {
        const dataList = await compiler.compileImageTargets(
          preview,
          (progress) => {
            setCompilingProgress(Math.round(progress));
          }
        );
        setCompilingProgress(0);

        const exportedBuffer = await compiler.exportData();
        const blob = new Blob([exportedBuffer]);
        const mindFile = new File([blob], "target.mind", { type: blob.type });

        console.log(mindFile, "AssetType");
        const mindUrl = await uploadFile(mindFile, "mind");

        dispatch(SET_MIND_FILE(mindUrl));
        toast.success("Target Image Uploaded Successfully");

        setLoading(false);
        handleClose();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  const createMind = async (preview) => {
    const dataList = await compiler.compileImageTargets(preview, (progress) => {
      setCompilingProgress(Math.round(progress));
    });
    setCompilingProgress(0);

    const exportedBuffer = await compiler.exportData();
    const blob = new Blob([exportedBuffer]);
    const mindFile = new File([blob], "target.mind", { type: blob.type });
    const mindUrl = await uploadFile(mindFile, "mind");

    dispatch(SET_MIND_FILE(mindUrl));
    toast.success("Target Image Uploaded Successfully");
    setLoading(false);
    handleClose();
  };

  const handleDownload = () => {
    if (selectedCard === null) {
      return;
    }
    setLoading(true);
    // toSvg(cardRef.current, { quality: 1, width: 440, height: 260 })
    //   .then((dataUrl) => {
    //     const utf8Prefix = "data:image/svg+xml;charset=utf-8,";
    //     if (!dataUrl.startsWith(utf8Prefix)) {
    //       throw new Error("Invalid data URL");
    //     }

    //     // Extract the UTF-8 encoded string
    //     const utf8String = decodeURIComponent(dataUrl.slice(utf8Prefix.length));

    //     // Create a Blob from the UTF-8 string
    //     const blob = new Blob([utf8String], { type: "image/svg+xml" });
    // const image = new Image();
    // image.src = URL.createObjectURL(blob);
    // setPreview([image]);
    // createTarget(handleClose, blob, "create", [image]);
    //     // const link = document.createElement("a");
    //     // link.download = `targetImage.svg`;
    //     // link.href = blobUrl;
    //     // link.click();
    //   })
    //   .catch((err) => {
    //     console.error("Error generating image:", err);
    //   });

    console.log(selectedCard, "this si card");
    toJpeg(selectedCard, { quality: 1, width: 400, height: 250 })
      .then((dataUrl) => {
        // saveAs(dataUrl, "image.png");

        console.log("saved url", dataUrl);
        return fetch(dataUrl).then((res) => res.blob());
      })
      .then((blob) => {
        setLoading(true);
        const image = new Image();

        console.log(URL.createObjectURL(blob), "this is image url");
        image.src = URL.createObjectURL(blob);
        setPreview([image]);
        setTimeout(() => {
          createTarget(handleClose, blob, "create", [image]);
          createMind([image]);
        }, 2000);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleDownloadForSilver = () => {
    if (cardRef.current === null) {
      return;
    }
    setLoading(true);
    toJpeg(cardRef.current)
      .then((dataUrl) => {
        // saveAs(dataUrl, "image.png");
        return fetch(dataUrl).then((res) => res.blob());
      })
      .then((blob) => {
        setLoading(true);
        const image = new Image();
        image.src = URL.createObjectURL(blob);
        setPreview([image]);
        setTimeout(() => {
          createTarget(handleClose, blob, "create", [image]);
          createMind([image]);
        }, 2000);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const cards = [
    CardOne,
    // CardFive,
    // CardSix,
    CardSeven,
    CardEight,
    CardNine,
    CardTen,
    CardEleven,
    CardTwelve,
    CardFifteen,
    CardSixteen,
    CardSeventeeen,
  ];

  const fetchCardData = async () => {
    try {
      const response = await instance.get(
        `api/card/get_card_info/${user?._id}`
      );
      const cardData = response.data?.data?.data;
      if (cardData) {
        setFormData({
          name: cardData?.name || "",
          Phone: cardData?.Phone || "",
          email: cardData?.email || "",
          companyName: cardData?.companyName || "",
          address: cardData?.address || "",
          logo: cardData?.logo || null,
          visitingCardImage: cardData?.visitingCardImage || null,
          websiteLink: cardData?.websiteLink || "",
          ownerPhoto: cardData?.ownerPhoto || null,
          facebook: cardData?.facebook || "",
          linkedIn: cardData?.linkedIn || "",
          youtube: cardData?.youtube || "",
          googleMaps: cardData?.googleMaps || "",
          googleReview: cardData?.googleReview || "",
          twitter: cardData?.twitter || "",
          instagram: cardData?.instagram || "",
        });
      }
    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  };
  useEffect(() => {
    fetchCardData();
  }, []);
  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
        <h4 className=" text-lg font-semibold">Target Image</h4>
        <span
          className="cursor-pointer"
          onClick={() => {
            handleClose();
          }}
        >
          {" "}
          <MdOutlineClear size={20} />
        </span>
      </div>
      <div className="mb-2 flex w-full items-center justify-between gap-1 px-2 ">
        <button
          className={`w-full rounded-lg  ${
            tab === "upload"
              ? "bg-[#3d2fa9] text-white"
              : "border-2  text-[#3d2fa9] "
          }  px-2 py-3  `}
          onClick={() => setTab("upload")}
        >
          Upload Image
        </button>
        <button
          className={`w-full rounded-lg px-2 py-3  ${
            tab === "createCard"
              ? "bg-[#3d2fa9] text-white"
              : "border-2  text-[#3d2fa9] "
          }`}
          onClick={() => setTab("createCard")}
        >
          Create card
        </button>
      </div>
      {tab === "upload" ? (
        <Box sx={{ width: 500 }} role="presentation" className="relative">
          <div className=" rounded-lg p-4">
            {!preview?.length > 0 && (
              <div className=" mb-4 mt-1 flex cursor-pointer flex-col items-center justify-center gap-1 px-2">
                <img
                  src={upload}
                  alt="select "
                  className="h-[40%] w-[40%] rounded-lg"
                />

                <p className="text-md py-1  text-center text-gray-700">
                  Choose and upload your target image, imaage with dark and
                  bright areas and well lit areas with unique features is
                  preferred for better recognition while scanning
                </p>
              </div>
            )}

            <label
              htmlFor="fileInput"
              className="mt-2 flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-600 p-4  dark:!border-navy-700 lg:pb-0"
            >
              <input
                type="file"
                id="fileInput"
                accept="image/png, image/jpeg, image/jpg"
                className="hidden"
                onChange={(e) => handleFileUpload(e)}
              />

              {preview.length > 0 ? (
                <img
                  src={preview.length > 0 ? preview[0]?.src : ""}
                  alt="Preview"
                  className="max-h-[300px] max-w-[300px] object-cover"
                />
              ) : (
                <div className="cursor-pointer  text-center font-semibold text-gray-900"></div>
              )}

              {preview.length > 0 ? (
                <p className="text-md mb-2 mt-2  cursor-pointer rounded-md bg-[#3d2fa9] px-4 py-2 text-white ">
                  Browse Other Image
                </p>
              ) : (
                <h4 className=" mb-2 mt-2 cursor-pointer rounded-md bg-[#3d2fa9] px-4 py-2 text-xl text-white">
                  Browse
                </h4>
              )}

              <p className="mt-4 text-lg font-semibold text-gray-900">
                Supported Format :- PNG, JPG and JEPG
              </p>

              <p className="text-md my-2 text-gray-900">
                Single Image limit size upto 2mb
              </p>
            </label>
            {preview.length > 0 ? (
              <button
                className="text-md mx-auto mt-5  w-full cursor-pointer rounded-lg border bg-[#3d2fa9] p-2 font-normal text-white"
                onClick={() => {
                  if (!preview.length) return;
                  createTarget(handleClose, file, "upload");
                }}
                disable={loading}
              >
                {compliningProgress > 0 ? (
                  <CircularWithValueLabel value={compliningProgress} />
                ) : (
                  "Upload Target Image"
                )}
              </button>
            ) : (
              ""
            )}
          </div>
        </Box>
      ) : (
        <div className="mt-2 flex items-center gap-4   md:flex-col-reverse ">
          <div className="mb-10 flex w-[40%] flex-col   md:w-full ">
            <button
              onClick={() => {
                if (user?.subscriptionType === "Silver") {
                  handleDownloadForSilver();
                } else {
                  handleDownload();
                }
              }}
              disable={
                (user?.subscriptionType === "Silver"
                  ? false
                  : selectedIndex !== null) || loading
              }
              className={`mt-2 rounded ${
                (user?.subscriptionType === "Silver"
                  ? false
                  : selectedIndex !== null) || loading
                  ? "bg-brand-main"
                  : "bg-brand-200"
              }  mx-auto w-[300px] p-2 text-white`}
            >
              {compliningProgress > 0 ? (
                <CircularWithValueLabel value={compliningProgress} />
              ) : (
                "Create and upload card"
              )}
            </button>
          </div>

          {user?.subscriptionType === "Silver" ? (
            <div>
              <CardOne
                ref={cardRef}
                name={user?.fullName}
                experiencename={arData?.name}
                username={userName}
                user={user}
              />
            </div>
          ) : (
            <>
              <div>
                Target Image card {currentTargetImageIndex + 1}/{cards.length}
              </div>
              <Carousel
                showArrows={true}
                showThumbs={false}
                showStatus={false}
                infiniteLoop={true}
                showIndicators={false}
                onChange={(e) => {
                  setCurrentTargetImageIndex(e);
                }}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`absolute bottom-0 left-2 top-[-70px] z-20 flex cursor-pointer items-center justify-center p-3 opacity-30 hover:opacity-100`}
                      onClick={clickHandler}
                    >
                      <FaAngleLeft className=" h-9 w-9 text-black" />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`absolute bottom-0 right-2 top-[-70px] z-20 flex cursor-pointer items-center justify-center p-3 opacity-30 hover:opacity-100`}
                      onClick={clickHandler}
                    >
                      <FaAngleRight className="h-9 w-9 text-black" />
                    </div>
                  );
                }}
                className="w-[500px]"
              >
                {cards.map((Component, index) => {
                  return (
                    <div className="flex flex-col items-center justify-center">
                      {" "}
                      <Component
                        ref={(el) => (cardRefs.current[index] = el)}
                        name={formData?.name || user?.fullName}
                        experiencename={arData?.name}
                        username={userName}
                        user={user}
                      />
                      <button
                        onClick={() => handleSelect(index)}
                        className={`z-40 mt-2 rounded-md px-4 py-2 text-white ${
                          selectedIndex === index
                            ? "bg-green-500"
                            : "bg-brand-main"
                        }`}
                      >
                        {selectedIndex === index ? "Selected" : "Select"}
                      </button>
                    </div>
                  );
                })}
              </Carousel>
            </>
          )}
        </div>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <div className="flex flex-col items-center justify-center gap-1.5">
            <h1 className="text-lg font-bold">Uploading Target</h1>
            <h1 className="text-md font-bold">
              Please don't referesh page, creating target image
            </h1>
            <h1 className="text-md font-bold">We appreciate your patience</h1>
          </div>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>
    </Drawer>
  );
};

export default TargetImage;
