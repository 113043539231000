import React from "react";
import "./NoProduct.css";

const NoProductFound = ({ content }) => {
  return (

    <div className="empty-state">
      <div className="empty-state__content">
        <div className="empty-state__icon">
          <img
            src="https://th.bing.com/th/id/OIP.AX3hbumSibrStpI353HxGwAAAA?rs=1&pid=ImgDetMain"
            alt
          />
        </div>
        <div className="empty-state__message">
          No Products has been added yet
        </div>
        <div className="empty-state__help">{content}</div>
      </div>
    </div>

  );
};
export default NoProductFound;
