import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import { toPng, toSvg } from "html-to-image";
import { useSelect } from "@react-three/drei";
import { useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { Tab, Tabs } from "@mui/material";
import TextEditorToolbar from "./CustomCardPage/components/header/text/TextHeader";

const Canvas = ({ width, height, bgColor, bgImage, children, onDrag, onResize, isSelected, onClick, }) => {
  const [bgPosition, setBgPosition] = useState({ x: 0, y: 0 });
  const [bgSize, setBgSize] = useState({ width, height });
  const nodeRef = useRef(null);

  const handleBgDrag = (e, data) => {


    if ((data.y > 240) || (data.y < -240) || (data.x < -390) || (data.x > 390)) {
      setBgPosition({ x: 0, y: 0 });
      return;
    }
    setBgPosition({ x: data.x, y: data.y });
    if (onDrag) onDrag(e, data);
  };

  const handleBgResize = (e, corner) => {
    e.stopPropagation();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = bgSize.width;
    const startHeight = bgSize.height;
    const startPosition = { ...bgPosition };

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      let newWidth = startWidth;
      let newHeight = startHeight;
      let newX = startPosition.x;
      let newY = startPosition.y;

      if (corner.includes('right')) newWidth += deltaX;
      if (corner.includes('bottom')) newHeight += deltaY;
      if (corner.includes('left')) {
        newWidth -= deltaX;
        newX += deltaX;
      }
      if (corner.includes('top')) {
        newHeight -= deltaY;
        newY += deltaY;
      }

      setBgSize({
        width: Math.max(100, newWidth),
        height: Math.max(100, newHeight)
      });
      setBgPosition({ x: newX, y: newY });

      // if (onResize) onResize({ width: newWidth, height: newHeight });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: bgColor,
      }}

    >
      <Draggable
        onDrag={handleBgDrag}
        position={bgPosition}
        nodeRef={nodeRef}
      >
        <div ref={nodeRef}>
          <ElementWrapper
            width={bgSize.width}
            height={bgSize.height}
            isSelected={isSelected}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',


              }}
              onClick={onClick}
            />
            {isSelected && (
              <>
                <ResizeHandle position="top-left" onMouseDown={(e) => handleBgResize(e, 'top-left')} />
                <ResizeHandle position="top-right" onMouseDown={(e) => handleBgResize(e, 'top-right')} />
                <ResizeHandle position="bottom-left" onMouseDown={(e) => handleBgResize(e, 'bottom-left')} />
                <ResizeHandle position="bottom-right" onMouseDown={(e) => handleBgResize(e, 'bottom-right')} />
              </>
            )}
          </ElementWrapper>
        </div>
      </Draggable>
      {children}
    </div>
  );
};

const ElementWrapper = ({
  width,
  height,
  rotation,
  fontSize,
  fontWeight,
  color,
  bgColor,
  isSelected,
  isEditable,
  setIsEditable,
  onClick,
  children,
  textAlign,
  italic,
  underline,

}) => (
  <div id="element-wrapper"
    className={` border-box absolute   flex cursor-move items-center ${textAlign === "right" ? "justify-end" : textAlign === "left" ? "justify-start" : "justify-center"}`}
    style={{
      width: `${width}px`,
      height: `${height}px`,
      transform: `rotate(${rotation}deg)`,
      fontSize: `${fontSize}px`,
      fontWeight: fontWeight || 200,
      color: color || "#000",
      backgroundColor: bgColor || "transparent",
      border: isSelected ? "2px solid blue" : "none",
      fontStyle: italic ? "italic" : "",
      textDecoration: underline ? "underline" : ""
    }}
    onClick={onClick}
  >
    {children}
  </div >
);

const ResizeHandle = ({ position, onMouseDown, cursor }) => (
  <div
    className={`border-transparent absolute border opacity-30`}
    style={{
      width: "5px",
      height: "5px",
      backgroundColor: "transparent",
      cursor: cursor || "nw-resize",
      ...(position === "top-left" && {
        top: "0",
        left: "0",
        zIndex: 5,
        cursor: "nw-resize",
      }),
      ...(position === "top-right" && {
        top: "0",
        right: "0",
        zIndex: 5,
        cursor: "ne-resize",
      }),
      ...(position === "bottom-left" && {
        bottom: "0",
        left: "0",
        zIndex: 5,
        cursor: "sw-resize",
      }),
      ...(position === "bottom-right" && {
        bottom: "0",
        right: "0",
        zIndex: 5,
        cursor: "se-resize",
      }),
      ...(position === "top" && {
        top: "0",
        left: "50%",
        width: "100%",
        transform: "translateX(-50%)",
        cursor: "n-resize",
      }),
      ...(position === "bottom" && {
        bottom: "0",
        left: "50%",
        width: "100%",
        transform: "translateX(-50%)",
        cursor: "s-resize",
      }),
      ...(position === "left" && {
        left: "0",
        top: "50%",
        height: "100%",
        transform: "translateY(-50%)",
        cursor: "w-resize",
      }),
      ...(position === "right" && {
        right: "0",
        top: "50%",
        height: "100%",
        transform: "translateY(-50%)",
        cursor: "e-resize",
      }),
    }}
    onMouseDown={onMouseDown}
  />
);

const CardEditor = ({
  elements,
  bgColor,
  bgImage,
  selectedElementIndex,
  setSelectedElementIndex,
  setElements,
  elementsForBack,
  bgColorForBack,
  bgImageForBack,
  setElementsForBack,
  tabValue,
  handleTabChange,
  containerRef,
  containerRefForBack,
  setEditorBox,
  mainContinerRef,
  mainContinerRefForBack, 
  setIsEditable, 
  isEditable,
  isBgImageSelected,
  setBgImageSelected,
  containerSize
}) => {
  const username = useSelector((state) => state.auth.auth.user.username);

  const [alignmentLines, setAlignmentLines] = useState({
    left: null,
    right: null,
    top: null,
    bottom: null,
    verticalCenter: null,
    horizontalCenter: null,
  });

  const [isDragging, setIsDragging] = useState(false);

  const [isRotating, setIsRotating] = useState(false);
  const [rotationStart, setRotationStart] = useState({ rotation: 0, angle: 0 });


  const [textValue, setTextvalue] = useState("")
  const [isEditing, setIsEditing] = useState(false);
  const [localContent, setLocalContent] = useState('');
  const divRef = useRef(null);



  const [canvasPosition, setCanvasPosition] = useState({ x: 0, y: 0 });
  const [canvasSize, setCanvasSize] = useState({ width: containerSize.width, height: containerSize.height });


  const handleCanvasDrag = (e, data) => {

    setCanvasPosition({ x: data.x, y: data.y });
  };

  const handleCanvasResize = (e, corner) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = canvasSize.width;
    const startHeight = canvasSize.height;

    const onMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      let newWidth = startWidth;
      let newHeight = startHeight;

      if (corner.includes('right')) newWidth += deltaX;
      if (corner.includes('bottom')) newHeight += deltaY;
      if (corner.includes('left')) {
        newWidth -= deltaX;
        setCanvasPosition(prev => ({ ...prev, x: prev.x + deltaX }));
      }
      if (corner.includes('top')) {
        newHeight -= deltaY;
        setCanvasPosition(prev => ({ ...prev, y: prev.y + deltaY }));
      }

      setCanvasSize({ width: Math.max(100, newWidth), height: Math.max(100, newHeight) });
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  

  // Handle changes to contenteditable div


  // Start editing when clicked


  //   const convertImageToDataURL = (url) => {
  //     return new Promise((resolve, reject) => {
  //       const img = new Image();
  //       img.crossOrigin = "Anonymous";
  //       img.src = url + "?not-from-cache-please";
  //       img.onload = () => {
  //         const canvas = document.createElement("canvas");
  //         canvas.width = img.width;
  //         canvas.height = img.height;
  //         const ctx = canvas.getContext("2d");
  //         ctx.drawImage(img, 0, 0);
  //         resolve(canvas.toDataURL("image/png"));
  //       };
  //       img.onerror = (err) => {
  //         reject(err);
  //       };
  //     });
  //   };

  const startResize = (e, position, index) => {
    e.preventDefault();
    e.stopPropagation();

    const startX = e.clientX;
    const startY = e.clientY;

    const selectedElement = elements[index];
    const startWidth = selectedElement.width;
    const startHeight = selectedElement.height;
    const startXPosition = selectedElement.x;
    const startYPosition = selectedElement.y;

    const onMouseMove = (moveEvent) => {
      let newWidth = startWidth;
      let newHeight = startHeight;
      let newX = startXPosition;
      let newY = startYPosition;

      switch (position) {
        case "top":
          newHeight = startHeight - (moveEvent.clientY - startY);
          newY = startYPosition + (moveEvent.clientY - startY);
          break;
        case "bottom":
          newHeight = startHeight + (moveEvent.clientY - startY);
          break;
        case "left":
          newWidth = startWidth - (moveEvent.clientX - startX);
          newX = startXPosition + (moveEvent.clientX - startX);
          break;
        case "right":
          newWidth = startWidth + (moveEvent.clientX - startX);
          break;
        case "top-left":
          newWidth = startWidth - (moveEvent.clientX - startX);
          newHeight = startHeight - (moveEvent.clientY - startY);
          newX = startXPosition + (moveEvent.clientX - startX);
          newY = startYPosition + (moveEvent.clientY - startY);
          break;
        case "top-right":
          newWidth = startWidth + (moveEvent.clientX - startX);
          newHeight = startHeight - (moveEvent.clientY - startY);
          newY = startYPosition + (moveEvent.clientY - startY);
          break;
        case "bottom-left":
          newWidth = startWidth - (moveEvent.clientX - startX);
          newHeight = startHeight + (moveEvent.clientY - startY);
          newX = startXPosition + (moveEvent.clientX - startX);
          break;
        case "bottom-right":
          newWidth = startWidth + (moveEvent.clientX - startX);
          newHeight = startHeight + (moveEvent.clientY - startY);
          break;
        default:
          break;
      }

      if (newWidth >= 10 && newHeight >= 10) {
        const updatedElements = elements.map((el, i) =>
          i === index
            ? {
              ...el,
              width: newWidth,
              height: newHeight,
              x: newX,
              y: newY,
            }
            : el
        );
        setElements(updatedElements);
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const startResizeForBack = (e, position, index) => {
    e.preventDefault();
    e.stopPropagation();

    const startX = e.clientX;
    const startY = e.clientY;

    const selectedElement = elementsForBack[index];
    const startWidth = selectedElement.width;
    const startHeight = selectedElement.height;
    const startXPosition = selectedElement.x;
    const startYPosition = selectedElement.y;

    const onMouseMove = (moveEvent) => {
      let newWidth = startWidth;
      let newHeight = startHeight;
      let newX = startXPosition;
      let newY = startYPosition;

      switch (position) {
        case "top":
          newHeight = startHeight - (moveEvent.clientY - startY);
          newY = startYPosition + (moveEvent.clientY - startY);
          break;
        case "bottom":
          newHeight = startHeight + (moveEvent.clientY - startY);
          break;
        case "left":
          newWidth = startWidth - (moveEvent.clientX - startX);
          newX = startXPosition + (moveEvent.clientX - startX);
          break;
        case "right":
          newWidth = startWidth + (moveEvent.clientX - startX);
          break;
        case "top-left":
          newWidth = startWidth - (moveEvent.clientX - startX);
          newHeight = startHeight - (moveEvent.clientY - startY);
          newX = startXPosition + (moveEvent.clientX - startX);
          newY = startYPosition + (moveEvent.clientY - startY);
          break;
        case "top-right":
          newWidth = startWidth + (moveEvent.clientX - startX);
          newHeight = startHeight - (moveEvent.clientY - startY);
          newY = startYPosition + (moveEvent.clientY - startY);
          break;
        case "bottom-left":
          newWidth = startWidth - (moveEvent.clientX - startX);
          newHeight = startHeight + (moveEvent.clientY - startY);
          newX = startXPosition + (moveEvent.clientX - startX);
          break;
        case "bottom-right":
          newWidth = startWidth + (moveEvent.clientX - startX);
          newHeight = startHeight + (moveEvent.clientY - startY);
          break;
        default:
          break;
      }

      if (newWidth >= 10 && newHeight >= 10) {
        const updatedElements = elementsForBack.map((el, i) =>
          i === index
            ? {
              ...el,
              width: newWidth,
              height: newHeight,
              x: newX,
              y: newY,
            }
            : el
        );
        setElementsForBack(updatedElements);
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const startRotate = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    const startX = e.clientX;
    const startY = e.clientY;

    const selectedElement = elements[index];
    const centerX = selectedElement.x + selectedElement.width / 2;
    const centerY = selectedElement.y + selectedElement.height / 2;

    const getAngle = (x1, y1, x2, y2) => Math.atan2(y2 - y1, x2 - x1);

    // Initial angle relative to the center
    const startAngle = getAngle(centerX, centerY, startX, startY);

    const onMouseMove = (event) => {
      const currentX = event.clientX;
      const currentY = event.clientY;

      // Angle from center to the current mouse position
      const currentAngle = getAngle(centerX, centerY, currentX, currentY);

      // Calculate the rotation difference
      const angleDifference = currentAngle - startAngle;

      // Update the rotation of the element
      const updatedElements = elements.map((el, i) =>
        i === index
          ? { ...el, rotation: el.rotation + angleDifference * (180 / Math.PI) }
          : el
      );
      setElements(updatedElements);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const startRotateForBack = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    const startX = e.clientX;
    const startY = e.clientY;

    const selectedElement = elementsForBack[index];
    const centerX = selectedElement.x + selectedElement.width / 2;
    const centerY = selectedElement.y + selectedElement.height / 2;

    const getAngle = (x1, y1, x2, y2) => Math.atan2(y2 - y1, x2 - x1);

    // Initial angle relative to the center
    const startAngle = getAngle(centerX, centerY, startX, startY);

    const onMouseMove = (event) => {
      const currentX = event.clientX;
      const currentY = event.clientY;

      // Angle from center to the current mouse position
      const currentAngle = getAngle(centerX, centerY, currentX, currentY);

      // Calculate the rotation difference
      const angleDifference = currentAngle - startAngle;

      // Update the rotation of the element
      const updatedElements = elementsForBack.map((el, i) =>
        i === index
          ? { ...el, rotation: el.rotation + angleDifference * (180 / Math.PI) }
          : el
      );
      setElementsForBack(updatedElements);
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const handleElementClick = (index, menu) => {
    setSelectedElementIndex(index);
    setBgImageSelected(false)
    if (menu) {


      if (menu === "Text") {
        setEditorBox({
          text: true,
          image: false,
          qr: false,
        });

      } else if (menu === "Images") {
        setEditorBox({
          text: false,
          image: true,
          qr: false,
        });


      } else if (menu === "Qr") {
        setEditorBox({
          text: false,
          image: false,
          qr: true,
        });

      }

    }

  };

  const handleResize = (e, elementIndex, position) => {
    if (tabValue === "front") {
      startResize(e, position, elementIndex);
    } else {
      startResizeForBack(e, position, elementIndex);
    }
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (tabValue === "front") {
  //       setContainerSize({
  //         width: containerRef.current.offsetWidth,
  //         height: containerRef.current.offsetHeight,
  //       });
  //     } else {
  //       setContainerSize({
  //         width: containerRefForBack.current.offsetWidth,
  //         height: containerRefForBack.current.offsetHeight,
  //       });
  //     }
  //   };
  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const handleDrag = (index, data) => {
    const updatedElements = elements.map((el, idx) =>
      idx === index ? { ...el, x: data.x, y: data.y } : el
    );
    setElements(updatedElements);

    const currentElement = updatedElements[index];
    let leftLine = null;
    let rightLine = null;
    let topLine = null;
    let bottomLine = null;
    let verticalCenterLine = null;
    let horizontalCenterLine = null;

    // Calculate centers
    const currentCenterX = currentElement.x + currentElement.width / 2;
    const currentCenterY = currentElement.y + currentElement.height / 2;
    const canvasCenterX = containerSize.width / 2;
    const canvasCenterY = containerSize.height / 2;

    // Check alignment with other elements
    updatedElements.forEach((el, idx) => {
      if (idx !== index) {
        const elementCenterX = el.x + el.width / 2;
        const elementCenterY = el.y + el.height / 2;

        if (Math.abs(el.x - currentElement.x) <= 5) {
          leftLine = { x: el.x };
        }
        if (
          Math.abs(
            el.x + el.width - (currentElement.x + currentElement.width)
          ) <= 5
        ) {
          rightLine = { x: el.x + el.width };
        }
        if (Math.abs(el.y - currentElement.y) <= 5) {
          topLine = { y: el.y };
        }
        if (
          Math.abs(
            el.y + el.height - (currentElement.y + currentElement.height)
          ) <= 5
        ) {
          bottomLine = { y: el.y + el.height };
        }
        if (Math.abs(elementCenterX - currentCenterX) <= 5) {
          verticalCenterLine = { x: elementCenterX };
        }
        if (Math.abs(elementCenterY - currentCenterY) <= 5) {
          horizontalCenterLine = { y: elementCenterY };
        }
      }
    });

    // Check alignment with canvas edges and center
    if (Math.abs(currentElement.x) <= 5) {
      leftLine = { x: 0 };
    }
    if (
      Math.abs(currentElement.x + currentElement.width - containerSize.width) <=
      5
    ) {
      rightLine = { x: containerSize.width };
    }
    if (Math.abs(currentElement.y) <= 5) {
      topLine = { y: 0 };
    }
    if (
      Math.abs(
        currentElement.y + currentElement.height - containerSize.height
      ) <= 5
    ) {
      bottomLine = { y: containerSize.height };
    }
    if (Math.abs(currentCenterX - canvasCenterX) <= 5) {
      verticalCenterLine = { x: canvasCenterX };
    }
    if (Math.abs(currentCenterY - canvasCenterY) <= 5) {
      horizontalCenterLine = { y: canvasCenterY };
    }

    setAlignmentLines({
      left: leftLine,
      right: rightLine,
      top: topLine,
      bottom: bottomLine,
      verticalCenter: verticalCenterLine,
      horizontalCenter: horizontalCenterLine,
    });
  };
  const handleDragForBack = (index, data) => {
    const updatedElements = elementsForBack.map((el, idx) =>
      idx === index ? { ...el, x: data.x, y: data.y } : el
    );
    setElementsForBack(updatedElements);

    const currentElement = updatedElements[index];
    let leftLine = null;
    let rightLine = null;
    let topLine = null;
    let bottomLine = null;
    let verticalCenterLine = null;
    let horizontalCenterLine = null;

    // Calculate centers
    const currentCenterX = currentElement.x + currentElement.width / 2;
    const currentCenterY = currentElement.y + currentElement.height / 2;
    const canvasCenterX = containerSize.width / 2;
    const canvasCenterY = containerSize.height / 2;

    // Check alignment with other elements
    updatedElements.forEach((el, idx) => {
      if (idx !== index) {
        const elementCenterX = el.x + el.width / 2;
        const elementCenterY = el.y + el.height / 2;

        if (Math.abs(el.x - currentElement.x) <= 5) {
          leftLine = { x: el.x };
        }
        if (
          Math.abs(
            el.x + el.width - (currentElement.x + currentElement.width)
          ) <= 5
        ) {
          rightLine = { x: el.x + el.width };
        }
        if (Math.abs(el.y - currentElement.y) <= 5) {
          topLine = { y: el.y };
        }
        if (
          Math.abs(
            el.y + el.height - (currentElement.y + currentElement.height)
          ) <= 5
        ) {
          bottomLine = { y: el.y + el.height };
        }
        if (Math.abs(elementCenterX - currentCenterX) <= 5) {
          verticalCenterLine = { x: elementCenterX };
        }
        if (Math.abs(elementCenterY - currentCenterY) <= 5) {
          horizontalCenterLine = { y: elementCenterY };
        }
      }
    });

    // Check alignment with canvas edges and center
    if (Math.abs(currentElement.x) <= 5) {
      leftLine = { x: 0 };
    }
    if (
      Math.abs(currentElement.x + currentElement.width - containerSize.width) <=
      5
    ) {
      rightLine = { x: containerSize.width };
    }
    if (Math.abs(currentElement.y) <= 5) {
      topLine = { y: 0 };
    }
    if (
      Math.abs(
        currentElement.y + currentElement.height - containerSize.height
      ) <= 5
    ) {
      bottomLine = { y: containerSize.height };
    }
    if (Math.abs(currentCenterX - canvasCenterX) <= 5) {
      verticalCenterLine = { x: canvasCenterX };
    }
    if (Math.abs(currentCenterY - canvasCenterY) <= 5) {
      horizontalCenterLine = { y: canvasCenterY };
    }

    setAlignmentLines({
      left: leftLine,
      right: rightLine,
      top: topLine,
      bottom: bottomLine,
      verticalCenter: verticalCenterLine,
      horizontalCenter: horizontalCenterLine,
    });
  };

  const handleMouseDown = (e, index) => {
    const element = elements[index];

    // Calculate the angle between the mouse and the center of the element
    const centerX = element.x + element.width / 2;
    const centerY = element.y + element.height / 2;
    const angle =
      Math.atan2(e.clientY - centerY, e.clientX - centerX) * (180 / Math.PI);

    setRotationStart({ rotation: element.rotation, angle });
    setIsRotating(true);
    // handleClick()
    setSelectedElementIndex(index);
  };
  const handleMouseDownForBack = (e, index) => {
    const element = elementsForBack[index];
    // Calculate the angle between the mouse and the center of the element
    const centerX = element.x + element.width / 2;
    const centerY = element.y + element.height / 2;
    const angle =
      Math.atan2(e.clientY - centerY, e.clientX - centerX) * (180 / Math.PI);

    setRotationStart({ rotation: element.rotation, angle });

    setSelectedElementIndex(index);
  };

  const handleMouseMove = (e) => {
    if (!isRotating || selectedElementIndex === null) return;

    const element = elements[selectedElementIndex];

    const centerX = element.x + element.width / 2;
    const centerY = element.y + element.height / 2;
    const angle =
      Math.atan2(e.clientY - centerY, e.clientX - centerX) * (180 / Math.PI);

    const newRotation = rotationStart.rotation + (angle - rotationStart.angle);

    const updatedElements = [...elements];
    updatedElements[selectedElementIndex].rotation = newRotation;
    setElements(updatedElements);
  };

  const handleMouseUp = () => {
    setIsRotating(false);
  };

  const handleStop = (index, data) => {
    setElements(
      elements.map((e, i) => (i === index ? { ...e, x: data.x, y: data.y } : e))
    );
    setAlignmentLines({
      left: null,
      right: null,
      top: null,
      bottom: null,
      horizontalCenter: null,
      verticalCenter: null,
    });
    setIsDragging(false);
    handleMouseUp();
  };
  const handleStopForBack = (index, data) => {
    setElementsForBack(
      elementsForBack.map((e, i) =>
        i === index ? { ...e, x: data.x, y: data.y } : e
      )
    );
    setAlignmentLines({
      left: null,
      right: null,
      top: null,
      bottom: null,
      horizontalCenter: null,
      verticalCenter: null,
    });
    setIsDragging(false);
    handleMouseUp();
  };

  //   const handleMouseDown = (e, index) => {
  //     const element = elements[index];

  //     // Calculate the angle between the mouse and the center of the element
  //     const centerX = element.x + element.width / 2;
  //     const centerY = element.y + element.height / 2;
  //     const angle =
  //       Math.atan2(e.clientY - centerY, e.clientX - centerX) * (180 / Math.PI);

  //     setRotationStart({ rotation: element.rotation, angle });
  //     setIsRotating(true);
  //     setSelectedElementIndex(index);
  //   };
  //   const handleMouseMove = (e) => {
  //     if (!isRotating || selectedElementIndex === null) return;

  //     const element = elements[selectedElementIndex];

  //     const centerX = element.x + element.width / 2;
  //     const centerY = element.y + element.height / 2;
  //     const angle =
  //       Math.atan2(e.clientY - centerY, e.clientX - centerX) * (180 / Math.PI);

  //     const newRotation = rotationStart.rotation + (angle - rotationStart.angle);

  //     const updatedElements = [...elements];
  //     updatedElements[selectedElementIndex].rotation = newRotation;
  //     setElements(updatedElements);
  //   };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     const cardEditorSection = document.querySelector('.card-editor-section');

  //     if (cardEditorSection && !cardEditorSection.contains(event.target)) {
  //       setIsEditable(false)
  //       setSelectedElementIndex(null); // Remove focus
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      {/* 
      <Tabs
        sx={{
          width: 400,
        }}
        value={tabValue}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        <Tab
          value={"front"}
          sx={{
            backgroundColor: tabValue === "front" ? "#3d2fa9" : "#efefef",
            color:
              tabValue === "front" ? "white !important" : "black !important",
            fontWeight: 900,
          }}
          label="Front"
        />

        <Tab
          sx={{
            backgroundColor: tabValue === "back" ? "#3d2fa9" : "#efefef",
            color:
              tabValue === "back" ? "white !important" : "black !important",
            fontWeight: 900,
          }}
          value={"back"}
          label="back"
        />
      </Tabs> */}
      {tabValue === "front" ? (




        <div className="p-2 md:p-12 bg-gray-200" ref={mainContinerRef}>


          <div
            ref={containerRef}
            className="relative border border-gray-400"
            style={{
              width: `${containerSize.width}px`,
              height: `${containerSize.height}px`,
            }}
          >



            <Canvas
              width={containerSize.width}
              height={containerSize.height}
              bgColor={bgColor}
              bgImage={bgImage}
              onDrag={handleCanvasDrag}
              onResize={handleCanvasResize}
              isSelected={isBgImageSelected}
              onClick={() => {
                setBgImageSelected(true);
                setSelectedElementIndex(null)
              }}
            >
              {elements.map((el, index) => (
                <Draggable
                  key={el?.id}
                  defaultPosition={{ x: el.x, y: el.y }}
                  onStop={(e, data) => {
                    setElements(
                      elements.map((e, i) =>
                        i === index ? { ...e, x: data.x, y: data.y } : e
                      )
                    );
                    handleStop(index, data);
                    handleMouseUp();
                  }}
                  onStart={(e) => {
                    handleMouseDown(e, index);
                    handleElementClick(index);
                  }}
                  onDrag={(e, data) => handleDrag(index, data)}
                >
                  <div>
                    {el.type === "text" && (
                      <ElementWrapper
                        width={el.width}
                        height={el.height}
                        rotation={el.rotation}
                        fontSize={el.fontSize}
                        fontWeight={el.fontWeight}
                        color={el.color}
                        bgColor={el.bgColor}
                        italic={el.italic}
                        underline={el.underline}
                        isEditable={isEditable}
                        setIsEditable={setIsEditable}
                        textAlign={el.textAlign}
                        isSelected={index === selectedElementIndex}
                        onClick={() => handleElementClick(index, "Text")}
                      >





                        {el.content}

                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top"
                            onMouseDown={(e) => handleResize(e, index, "top")}
                            cursor="n-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom"
                            onMouseDown={(e) => handleResize(e, index, "bottom")}
                            cursor="s-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="left"
                            onMouseDown={(e) => handleResize(e, index, "left")}
                            cursor="w-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="right"
                            onMouseDown={(e) => handleResize(e, index, "right")}
                            cursor="e-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <div
                            className="absolute -bottom-4 left-1/2 -translate-x-1/2 transform cursor-pointer bg-gray-700 p-1 text-white"
                            onMouseDown={(e) => startRotate(e, index)}
                          ></div>
                        )}
                      </ElementWrapper>
                    )}
                    {el.type === "image" && (
                      <ElementWrapper
                        width={el.width}
                        height={el.height}
                        rotation={el.rotation}
                        isSelected={index === selectedElementIndex}
                        onClick={() => handleElementClick(index, "Images")}
                      >
                        <img
                          src={el.src}
                          alt="Element"
                          style={{ width: "100%", height: "100%" }}
                        />
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top"
                            onMouseDown={(e) => handleResize(e, index, "top")}
                            cursor="n-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom"
                            onMouseDown={(e) => handleResize(e, index, "bottom")}
                            cursor="s-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="left"
                            onMouseDown={(e) => handleResize(e, index, "left")}
                            cursor="w-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="right"
                            onMouseDown={(e) => handleResize(e, index, "right")}
                            cursor="e-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <div
                            className="absolute -bottom-4 left-1/2 -translate-x-1/2 transform cursor-pointer bg-gray-700 p-1 text-white"
                            onMouseDown={(e) => startRotate(e, index)}
                          ></div>
                        )}
                      </ElementWrapper>
                    )}
                    {el.type === "QR" && (
                      <ElementWrapper
                        width={el.width}
                        height={el.height}
                        rotation={el.rotation}
                        isSelected={index === selectedElementIndex}
                        onClick={() => handleElementClick(index, "Qr")}
                      >
                        <QRCodeSVG
                          value={`https://sandboxar.immarsify.com/${username}/${el?.content}`}
                          fgColor={el?.color}
                          bgColor="transparent"
                        />
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top"
                            onMouseDown={(e) => handleResize(e, index, "top")}
                            cursor="n-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom"
                            onMouseDown={(e) => handleResize(e, index, "bottom")}
                            cursor="s-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="left"
                            onMouseDown={(e) => handleResize(e, index, "left")}
                            cursor="w-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="right"
                            onMouseDown={(e) => handleResize(e, index, "right")}
                            cursor="e-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <div
                            className="absolute -bottom-4 left-1/2 -translate-x-1/2 transform cursor-pointer bg-gray-700 p-1 text-white"
                            onMouseDown={(e) => startRotate(e, index)}
                          ></div>
                        )}
                      </ElementWrapper>
                    )}
                  </div>
                </Draggable>
              ))}
              {/* Alignment lines */}
              {alignmentLines.left && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: alignmentLines.left.x,
                    height: "100%",
                    width: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.right && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: alignmentLines.right.x,
                    height: "100%",
                    width: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.top && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: alignmentLines.top.y,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.bottom && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: alignmentLines.bottom.y,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.verticalCenter && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: alignmentLines.verticalCenter.x,
                    height: "100%",
                    width: "1px",
                    backgroundColor: "blue",
                  }}
                />
              )}
              {alignmentLines.horizontalCenter && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: alignmentLines.horizontalCenter.y,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "blue",
                  }}
                />
              )}
            </Canvas>

          </div>
        </div>

      ) : (
        <div className="p-2  md:p-12 bg-gray-200" ref={mainContinerRefForBack}>
          <div
            ref={containerRefForBack}
            className="relative border border-gray-400"
            style={{
              width: `${containerSize.width}px`,
              height: `${containerSize.height}px`,
            }}
          >
            <Canvas
              width={containerSize.width}
              height={containerSize.height}
              bgColor={bgColorForBack}
              bgImage={bgImageForBack}


              onDrag={handleCanvasDrag}
              onResize={handleCanvasResize}
              isSelected={isBgImageSelected}
              onClick={() => { setBgImageSelected(true); setSelectedElementIndex(null) }}

            >

              {elementsForBack.map((el, index) => (
                <Draggable
                  key={index}
                  defaultPosition={{ x: el.x, y: el.y }}
                  onStop={(e, data) => {
                  
                    // Update position
                    setElementsForBack(
                      elementsForBack.map((e, i) =>
                        i === index ? { ...e, x: data.x, y: data.y } : e
                      )
                    );
                    handleStopForBack(index, data);
                    handleMouseUp();
                  }}
                  onStart={(e) => {
                    handleMouseDownForBack(e, index);
                    handleElementClick(index);
                  }}
                  onDrag={(e, data) => handleDragForBack(index, data)}
                >
                  <div>
                    {el.type === "text" && (
                      <ElementWrapper
                        width={el.width}
                        height={el.height}
                        rotation={el.rotation}
                        fontSize={el.fontSize}
                        fontWeight={el.fontWeight}
                        color={el.color}
                        bgColor={el.bgColor}
                        italic={el.italic}
                        underline={el.underline}
                        isEditable={isEditable}
                        setIsEditable={setIsEditable}
                        textAlign={el.textAlign}
                        isSelected={index === selectedElementIndex}
                        onClick={() => handleElementClick(index, "Text")}
                      >

                        {el.content}

                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top"
                            onMouseDown={(e) => handleResize(e, index, "top")}
                            cursor="n-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom"
                            onMouseDown={(e) => handleResize(e, index, "bottom")}
                            cursor="s-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="left"
                            onMouseDown={(e) => handleResize(e, index, "left")}
                            cursor="w-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="right"
                            onMouseDown={(e) => handleResize(e, index, "right")}
                            cursor="e-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <div
                            className="absolute -bottom-4 left-1/2 -translate-x-1/2 transform cursor-pointer bg-gray-700 p-1 text-white"
                            onMouseDown={(e) => startRotateForBack(e, index)}
                          ></div>
                        )}
                      </ElementWrapper>
                    )}
                    {el.type === "image" && (
                      <ElementWrapper
                        width={el.width}
                        height={el.height}
                        rotation={el.rotation}
                        isSelected={index === selectedElementIndex}
                        onClick={() => handleElementClick(index, "Images")}
                      >
                        <img
                          src={el.src}
                          alt="Element"
                          style={{ width: "100%", height: "100%" }}
                        />
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top"
                            onMouseDown={(e) => handleResize(e, index, "top")}
                            cursor="n-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom"
                            onMouseDown={(e) => handleResize(e, index, "bottom")}
                            cursor="s-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="left"
                            onMouseDown={(e) => handleResize(e, index, "left")}
                            cursor="w-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="right"
                            onMouseDown={(e) => handleResize(e, index, "right")}
                            cursor="e-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <div
                            className="absolute -bottom-4 left-1/2 -translate-x-1/2 transform cursor-pointer bg-gray-700 p-1 text-white"
                            onMouseDown={(e) => startRotateForBack(e, index)}
                          ></div>
                        )}
                      </ElementWrapper>
                    )}
                    {el.type === "QR" && (
                      <ElementWrapper
                        width={el.width}
                        height={el.height}
                        rotation={el.rotation}
                        isSelected={index === selectedElementIndex}
                        onClick={() => handleElementClick(index, "Qr")}
                      >
                        <QRCodeSVG
                          value={`https://sandboxar.immarsify.com/${username}/${el?.content}`}
                          fgColor={el?.color}
                          bgColor="transparent"
                        />
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "top-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-left"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-left")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom-right"
                            onMouseDown={(e) =>
                              handleResize(e, index, "bottom-right")
                            }
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="top"
                            onMouseDown={(e) => handleResize(e, index, "top")}
                            cursor="n-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="bottom"
                            onMouseDown={(e) => handleResize(e, index, "bottom")}
                            cursor="s-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="left"
                            onMouseDown={(e) => handleResize(e, index, "left")}
                            cursor="w-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <ResizeHandle
                            position="right"
                            onMouseDown={(e) => handleResize(e, index, "right")}
                            cursor="e-resize"
                          />
                        )}
                        {index === selectedElementIndex && (
                          <div
                            className="absolute -bottom-4 left-1/2 -translate-x-1/2 transform cursor-pointer bg-gray-700 p-1 text-white"
                            onMouseDown={(e) => startRotateForBack(e, index)}
                          ></div>
                        )}
                      </ElementWrapper>
                    )}
                  </div>
                </Draggable>
              ))}
              {/* Alignment lines */}
              {alignmentLines.left && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: alignmentLines.left.x,
                    height: "100%",
                    width: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.right && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: alignmentLines.right.x,
                    height: "100%",
                    width: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.top && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: alignmentLines.top.y,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.bottom && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: alignmentLines.bottom.y,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "red",
                  }}
                />
              )}
              {alignmentLines.verticalCenter && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: alignmentLines.verticalCenter.x,
                    height: "100%",
                    width: "1px",
                    backgroundColor: "blue",
                  }}
                />
              )}
              {alignmentLines.horizontalCenter && (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: alignmentLines.horizontalCenter.y,
                    width: "100%",
                    height: "1px",
                    backgroundColor: "blue",
                  }}
                />
              )}
            </Canvas>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardEditor;
