import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "components/fields/InputField";
import Card from "components/card";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { names } from "common/Category";
import {
  SET_AUTH,
  SET_USERDATA,
} from "../../../../redux/reducerSlice/authSlice";
import Banner from "./Banner";
import "react-phone-input-2/lib/style.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { MdEdit, MdOutlineClear } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: "20px",
};

const AccountDetails = () => {
  const user = useSelector((state) => state.auth.auth.user);
  const subType = useSelector((state) => state?.auth?.auth?.user?.subType);

  const userToken = useSelector((state) => state.auth.auth.token);
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [editMobile, setEditMobile] = useState(false);
  const [verifyPhoneModal, setVerfiyPhoneModal] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (
      user?.phoneNumber &&
      user?.phoneNumber.startsWith("91") &&
      user?.phoneNumber?.length === 12
    ) {
      setPhoneNumber(user?.phoneNumber);
    } else {
      if (user?.phoneNumber) {
        const newPhone = "91" + user?.phoneNumber;

        setPhoneNumber(newPhone);
      }
    }
  }, [user, editMobile]);

  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    fullName: Yup.string(),
    username: Yup.string()
      .min(3, "Username must be at least 3 characters")
      .required("Username is required")
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Only letters, numbers, and underscores are allowed"
      )
      .test("uniqueUsername", "Username already exists", async (value) => {
        // Skip validation if the username is unchanged
        if (value === user.username) {
          return true;
        }

        try {
          const response = await instance.post("api/auth/check_username", {
            username: value.trim(),
          });
          return response.data.data;
        } catch (error) {
          return false;
        }
      }),
    email: Yup.string().email("Invalid email address"),
    type: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    phoneNumber: Yup.string().matches(
      /^[6-9]\d{9}$/,
      "Phone number is not valid"
    ),
  });

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.startsWith("91") && phoneNumber.length === 12) {
      return {
        countryCode: "91",
        number: phoneNumber.slice(2),
      };
    }
    return {
      countryCode: "",
      number: phoneNumber,
    };
  };

  const { countryCode, number } = formatPhoneNumber(user?.phoneNumber || "");

  const formik = useFormik({
    initialValues: {
      fullName: user?.fullName || "",
      username: user?.username || "",
      email: user?.email || "",
      type: user?.type || "",
      phoneNumber: number,
      businessTypeParent: user?.businessTypeParent || "",
      category: user?.category || [],
      city: user?.city || "",
      state: user?.state || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSubmitting(true); // Set submitting state to true before making the API call

      try {
        const response = await instance.post(
          `/api/user/update_user_details/${user._id}`,
          values,
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success("Profile updated successfully");
          dispatch(SET_USERDATA(response?.data?.data));
        }
      } catch (error) {
        toast.error("Failed to update profile");
      } finally {
        setSubmitting(false); // Set submitting state to false after API call completes
      }
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const { value } = event.target;
    formik.setFieldValue("businessTypeParent", value);

    const selectedOptions = names.find(
      (item) => Object.keys(item)[0] === value
    );

    if (selectedOptions) {
      formik.setFieldValue("category", selectedOptions[value]);
    } else {
      formik.setFieldValue("category", []);
    }
  };

  const getOtpHandler = () => {
    // const valid = validatePhoneNumber(phoneNumber);

    if (phoneNumber?.length === 12) {
      setPhoneError("");
      setVerfiyPhoneModal(true);
    } else {
      setPhoneError("Please add valid phone number");
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Check if the input is exactly 10 digits
    const isValid = /^\d{10}$/.test(phoneNumber);
    return isValid;
  };

  return (
    <div className="mt-3 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-12">
      <div className="col-span-4 lg:!mb-0">
        <Banner />
      </div>
      <div className="col-span-8 lg:!mb-0">
        <Card extra="px-4 py-4 w-full h-full bg-cover">
          <div className="mb-4">
            <h2 className="text-xl font-semibold">Account Settings</h2>

            <p className="mt-2 text-sm text-gray-600">
              Here you can change user account information
            </p>
          </div>

          <form
            onSubmit={formik.handleSubmit}
            className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2"
          >
            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Name"
                id="fullName"
                type="text"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={formik.touched.fullName && formik.errors.fullName}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <div className="px-4 text-xs text-red-500">
                  {formik.errors.fullName}
                </div>
              ) : null}
            </div>

            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Username"
                id="username"
                type="text"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={true}
                // error={formik.touched.username && formik.errors.username}
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="px-4 text-xs text-red-500">
                  {formik.errors.username}
                </div>
              ) : null}
            </div>

            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Email"
                id="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={true}
                // error={formik.touched.email && formik.errors.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="px-4 text-xs text-red-500">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Role"
                id="type"
                type="text"
                value={formik.values.type}
                onChange={formik.handleChange}
                disabled={true}
                onBlur={formik.handleBlur}
                // error={formik.touched.type && formik.errors.type}
              />
              {formik.touched.type && formik.errors.type ? (
                <div className="px-4 text-xs text-red-500">
                  {formik.errors.type}
                </div>
              ) : null}
            </div>

            <div className="w-full pt-0.5">
              <label htmlFor="phoneNumber" className="text-sm text-navy-700 font-medium">
                Phone Number
              </label>
              {editMobile || !user?.phoneNumber ? (
                <div className="mt-2">
                  <PhoneInput
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    name="phoneNumber"
                    id="phoneNumber"
                    onChange={(e) => {
                      setPhoneNumber(e);
                    }}
                    // onBlur={formik.handleBlur}
                    value={phoneNumber}
                  />
                  {/* <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Phone Number"
                    // id="phoneNumber"
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  /> */}
                  {phoneError && (
                    <div className="px-4 text-xs text-red-500">
                      {phoneError}
                    </div>
                  )}
                  <div className="mt-2 flex justify-end gap-2">
                    <span
                      onClick={() => {
                        setPhoneNumber(user?.phoneNumber);
                        setEditMobile(false);
                      }}
                      className="cursor-pointer rounded-md border px-4 py-1 shadow-md "
                    >
                      Cancel
                    </span>
                    <span
                      onClick={() => {
                        getOtpHandler();
                      }}
                      className="cursor-pointer rounded-md border bg-brand-main px-4 py-1 text-white shadow-md"
                    >
                      Get OTP
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="flex items-center gap-2">
                    {phoneNumber}{" "}
                    <MdEdit
                      onClick={() => setEditMobile(true)}
                      className="cursor-pointer"
                      size={20}
                    />
                  </p>
                </div>
              )}
            </div>

            <div className="mt-1 flex w-full flex-col gap-2">
              <label
                id="demo-select-small-label"
                className="text-sm font-medium text-navy-700  "
              >
                Business Category
              </label>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={formik.values.businessTypeParent}
                onChange={handleChange}
                input={<OutlinedInput label="Tag" />}
                // renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                style={{ width: "100%", height: "38px" }}
              >
                {names.map((item, index) => (
                  <MenuItem key={index} value={Object.keys(item)[0]}>
                    {Object.keys(item)[0]}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {subType && (
              <div className="w-full">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Sub Role"
                  id="type"
                  type="text"
                  value="subReseller"
                  disabled={true}
                />
              </div>
            )}

            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="City"
                id="city"
                type="text"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.city && formik.errors.city ? (
                <div className="px-4 text-xs text-red-500">
                  {formik.errors.city}
                </div>
              ) : null}
            </div>

            <div className="w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="State"
                id="state"
                type="text"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}

                // error={formik.touched.email && formik.errors.email}
              />
              {formik.touched.state && formik.errors.state ? (
                <div className="px-4 text-xs text-red-500">
                  {formik.errors.state}
                </div>
              ) : null}
            </div>

            <div className="col-span-2  w-full">
              <button
                type="submit"
                className={`rounded-md px-4 py-2 text-white ${
                  !formik.dirty || submitting
                    ? "cursor-not-allowed bg-gray-500"
                    : "bg-brand-main "
                } `}
                disabled={!formik.dirty || submitting}
              >
                {submitting ? "Saving..." : "Save Changes"}
              </button>

              <span
                className="ml-2 cursor-pointer rounded-md  bg-brand-main px-4 py-2 text-white"
                onClick={handleOpen}
              >
                Change Password
              </span>
            </div>
          </form>
          <Password
            open={open}
            handleClose={handleClose}
            valueemail={formik.values.email}
          />

          <VerifyPhone
            open={verifyPhoneModal}
            handleClose={() => setVerfiyPhoneModal(false)}
            phone={phoneNumber}
            setEditMobile={setEditMobile}
          />
        </Card>
      </div>
    </div>
  );
};

const Password = ({ open, handleClose, valueemail }) => {
  const [timer, setTimer] = useState(120);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      const id = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(id);
    }
  }, [timer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const validationSchema = Yup.object({
    resetPassword: Yup.string()
      .min(4, "Password must be at least 4 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("resetPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
    otp: Yup.string().required("OTP is required"),
  });

  const formik = useFormik({
    initialValues: {
      resetPassword: "",
      confirmPassword: "",
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      console.log(value, "valuehai");
      try {
        const response = await instance.post("api/auth/reset_password", {
          ...value,
          email: valueemail,
        });
        if (response.status === 200) {
          toast.success(response?.data?.message);
          handleClose();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message);
      }
    },
  });

  const Sendotp = async () => {
    try {
      const response = await instance.post("api/auth/forget_password", {
        email: valueemail,
      });

      if (response.status === 200) {
        toast.success("OTP is sent successfully to registered user");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  useEffect(() => {
    if (open) {
      Sendotp();
    }
  }, [open]);

  const handleResendOTP = async (valueemail) => {
    try {
      const response = await instance.post("api/auth/resend-otp", {
        email: valueemail,
      });
      if (response.status === 200) {
        toast.success("OTP sent successfully");
        setTimer(120);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div className="-mt-4 flex justify-end ">
            <span onClick={handleClose} className="cursor-pointer">
              <MdOutlineClear size={25} />
            </span>
          </div>

          <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <p className="mb-2.5 text-center text-2xl font-bold text-navy-700 ">
              Reset password
            </p>
            <p className="mb-2 ml-1 text-center  text-sm text-gray-600">
              Enter the otp that is sent to registered email and enter new
              password
            </p>

            <div className="mb-2 mt-6 w-full ">
              <OtpInput
                value={formik.values.otp}
                onChange={(otp) => formik.setFieldValue("otp", otp)}
                error={formik.touched.otp && formik.errors.otp}
                numInputs={5}
                isInputNum={true}
                separator={<span>-</span>}
                containerStyle={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  gap: "20px",
                  width: "100%",
                }}
                inputStyle={{
                  textAlign: "center",
                  borderRadius: "5px",
                  border: "1px solid darkgray",
                  padding: "10px",
                  width: "40px",
                  height: "40px",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            {timer > 0 && (
              <p className="my-2 px-6 text-right text-xs text-red-500">
                OTP timeout{" "}
                {`${minutes.toString().padStart(2, "0")}:${seconds
                  .toString()
                  .padStart(2, "0")}`}
              </p>
            )}

            {timer === 0 && (
              <p
                className="my-4 cursor-pointer px-6 text-right text-xs font-semibold text-gray-900"
                onClick={() => handleResendOTP(valueemail)}
              >
                Resend OTP
              </p>
            )}

            <div className="relative">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Enter New Password"
                id="resetPassword"
                type={showPassword1 ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.resetPassword}
                error={
                  formik.touched.resetPassword && formik.errors.resetPassword
                }
              />
              <button
                type="button"
                onClick={() => setShowPassword1(!showPassword1)}
                className="absolute right-3 top-[54px] -translate-y-1/2 transform"
              >
                {showPassword1 ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            <div className="relative">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Confirm New Password"
                id="confirmPassword"
                type={showPassword2 ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />
              <button
                type="button"
                onClick={() => setShowPassword2(!showPassword2)}
                className="absolute right-3 top-[54px] -translate-y-1/2 transform"
              >
                {showPassword2 ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>

            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Change Password
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const VerifyPhone = ({ open, handleClose, phone, setEditMobile }) => {
  const [timer, setTimer] = useState(120);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    if (timer > 0) {
      const id = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(id);
    }
  }, [timer]);

  const minutes = Math.floor(timer / 60);
  const seconds = timer % 60;

  const validationSchema = Yup.object({
    otp: Yup.string().required("OTP is required"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value, { resetForm }) => {
      console.log(value, "valuehai");
      try {
        const response = await instance.post(
          "api/user/verify_number",
          {
            ...value,
            phoneNumber: phone,
          },
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success(response?.data?.message);
          handleClose();
          const newUser = { ...user, phoneNumber: phone };
          dispatch(SET_USERDATA(newUser));

          setEditMobile(false);
          resetForm();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message);
      }
    },
  });

  const Sendotp = async () => {
    try {
      const response = await instance.post(
        "api/user/request_to_update_number",
        {
          phoneNumber: phone,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(response?.data?.message || "Otp sent successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  useEffect(() => {
    if (open) {
      Sendotp();
    }
  }, [open]);

  const handleResendOTP = async () => {
    try {
      const response = await instance.post(
        "api/user/request_to_update_number",
        {
          // email: valueemail,
          phoneNumber: phone,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("OTP sent successfully");
        setTimer(120);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div className="-mt-4 flex justify-end ">
            <span onClick={handleClose} className="cursor-pointer">
              <MdOutlineClear size={25} />
            </span>
          </div>

          <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <p className="mb-2.5 text-center text-2xl font-bold text-navy-700 ">
              Verify your phone
            </p>
            <p className="mb-2 ml-1 text-center  text-sm text-gray-600">
              Enter the otp that is sent to your new mobile number
            </p>

            <div className="mb-2 mt-6 w-full ">
              <OtpInput
                value={formik.values.otp}
                onChange={(otp) => formik.setFieldValue("otp", otp)}
                error={formik.touched.otp && formik.errors.otp}
                numInputs={5}
                isInputNum={true}
                separator={<span>-</span>}
                containerStyle={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  gap: "20px",
                  width: "100%",
                }}
                inputStyle={{
                  textAlign: "center",
                  borderRadius: "5px",
                  border: "1px solid darkgray",
                  padding: "10px",
                  width: "40px",
                  height: "40px",
                }}
                renderInput={(props) => <input {...props} />}
              />
            </div>

            {timer > 0 && (
              <p className="my-2 px-6 text-right text-xs text-red-500">
                OTP timeout{" "}
                {`${minutes.toString().padStart(2, "0")}:${seconds
                  .toString()
                  .padStart(2, "0")}`}
              </p>
            )}

            {timer === 0 && (
              <p
                className="my-4 cursor-pointer px-6 text-right text-xs font-semibold text-gray-900"
                onClick={() => handleResendOTP()}
              >
                Resend OTP
              </p>
            )}

            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Verify
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AccountDetails;
