import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { useRef } from "react";
import CardOne from "views/admin/arExperience/tabcomponent/Cardtemplates/CardOne";
import { toJpeg, toPng, toSvg } from "html-to-image";
import { loadTexture } from "views/admin/arExperience/tabcomponent/EditorUtils";
import "react-phone-input-2/lib/style.css";
import OutlinedInput from "@mui/material/OutlinedInput";
import { names } from "common/Category";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import PlanCard from "views/admin/pricing/components/PlanCard";
import _ from "lodash";
import * as mind from "mind-ar/dist/mindar-image.prod";
import { BsCamera, BsConeStriped } from "react-icons/bs";
import { cardStaticData } from "../static/staticData";
import CommonBackdrop from "common/CommonBackdrop";
import PaymentProcess from "common/PaymentProcess";
import PhoneInput from "react-phone-input-2";
import { RZOR_KEY } from "common/Instance";

// import { loadScript } from "common/scripts";

const FormTable = () => {
  const navigate=useNavigate()
  const cardRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("Creating user");
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [arData, setArData] = useState(cardStaticData);
  const [compliningProgress, setCompilingProgress] = useState(0);
  const [plans, setPlans] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const compiler = new mind.Compiler();
  const [activeTab, setActiveTab] = useState(1);
  const { search } = useLocation();

 
  const values = queryString.parse(search);

  const resellerName = values.reseller;



useEffect(() => {
  if (resellerName !== undefined) {
    const checkUsername = async () => {
      try {
        const response = await instance.post(
          "api/auth/check_reseller_username",
          {
            username: resellerName,
          }
        );
        if (response.data.data === false) {

        } else {
          navigate("/admin/form");
        }
      } catch (error) {
        console.log(error);
        navigate("/admin/form");
      }
    };

    checkUsername();
  }
}, [resellerName, navigate]);



  async function getSubscriptionPlans() {
    try {
      const response = await instance.get(
        "/api/subscription/get_subscriptions",
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setPlans(response?.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if(activeTab === 2){
      getSubscriptionPlans();
    }
    
  }, [token,activeTab]);

  const uploadFile = async (file, assetType, token) => {
    console.log(file, "filetype");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);

    try {
      const response = await instance.post(
        `/api/ar/upload/${formik.values.username}/${formik.values.username}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const fetchPaymentStatus = async (paymentId) => {
    try {
      const response = await instance.get(
        `/api/rezorpay/payment-status/${paymentId}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        if (response.data.status === "captured") {
          setPaymentCompleted(true);
          setTimeout(() => {
            setPaymentLoading(false);
            resetFullForm();
          }, 1500);
        } else {
          toast.error("payment failed please check");
          setPaymentCompleted(false);
          setPaymentLoading(false);
          setSelectedOption("");
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const createTarget = async (file, type, blobPreview, token, data) => {
    setLoading(true);
    setDynamicTitle("Creating Target image");
    try {
      const imageUrl =
        type === "upload"
          ? await uploadFile(file[0], "image", token)
          : await uploadFile(file, "image", token);

      const dataList = await compiler.compileImageTargets(
        blobPreview,
        (progress) => {
          setCompilingProgress(Math.round(progress));
        }
      );
      setCompilingProgress(0);
      const exportedBuffer = await compiler.exportData();
      const blob = new Blob([exportedBuffer]);
      const mindFile = new File([blob], "target.mind", { type: blob.type });
      const mindUrl = await uploadFile(mindFile, "mind", token);

      toast.success("Target Image Uploaded Successfully");

      console.log("data", data);
      await createARExperience(token, data, imageUrl, mindUrl);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  const handleTarget = (token, userData) => {
    if (cardRef.current === null) {
      return;
    }
    setDynamicTitle("Generating card for Experience");
    setLoading(true);

    toJpeg(cardRef.current)
      .then((dataUrl) => {
        return fetch(dataUrl).then((res) => res.blob());
      })
      .then((blob) => {
        setLoading(true);
        const image = new Image();
        image.src = URL.createObjectURL(blob);
        setTimeout(async () => {
          await createTarget(blob, "create", [image], token, userData);
        }, 500);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const createARExperience = async (token, userData, imageUrl, mindUrl) => {
    console.log(userData, "userData");
    setLoading(true);
    const texture = await loadTexture(imageUrl);
    const textureWidth = texture.image.width;
    const textureHeight = texture.image.height;
    let targetImage;
    let aspectRatio;

    if (textureWidth >= textureHeight) {
      aspectRatio = textureWidth / textureHeight;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeight > textureWidth) {
      aspectRatio = textureHeight / textureWidth;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }

    const texture1 = await loadTexture(userData?.imageAR);
    const textureWidthforPhoto = texture1.image.width;
    const textureHeightforPhoto = texture1.image.height;
    let profilePhoto;
    let aspectRatio1;
    let baseSize = 0.8;
    if (textureWidthforPhoto >= textureHeightforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: userData?.imageAR,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeightforPhoto > textureWidthforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: userData?.imageAR,
        scale: { x: baseSize, y: aspectRatio * baseSize, z: baseSize },
      };
    }

    try {
      const newIconLink = `https://wa.me/${userData.phoneNumber}`;
      const newIconsArray = (arData?.icons ?? []).map((icon, index) => {
        if (index === 3) {
          return {
            ...icon,
            iconLink: `tel:+${userData.phoneNumber}`,
            isLink: true,
          };
        }
        if (index === 4) {
          return {
            ...icon,
            vCardJson: {
              ...icon.vCardJson,
              firstName: userData?.fullName.split(" ")[0],
              lastName: userData?.fullName.split(" ")[1],
              photo: userData?.imageAR ?? "",
              cellPhone: userData?.phoneNumber ?? "",
              email: userData?.email ?? "",
              workUrl: `https://sandboxar.immarsify.com/${userData?.username}/${userData?.username}`,
              note: userData?.category?.join(", "),
            },
          };
        }
        if (index === 5 || index === 2) {
          return {
            ...icon,
            iconLink: `mailto:${userData?.email}`,
            isLink: true,
          };
        }
        if (index === 6) {
          return {
            ...icon,
            iconLink: newIconLink,
            isLink: true,
          };
        }
        return icon;
      });
      console.log("New icons array:", newIconsArray);

      let data = {
        ...arData,
        userId: userData._id,
        userName: userData?.username,
        arexperienceName: userData?.name,
        name: userData?.username,
        targetImage: targetImage,
        mind: {
          src: mindUrl,
        },
        text: [{ ...arData?.text[0], body: userData?.fullName }],
        photos: [{ ...arData?.photos[0], ...(profilePhoto ?? "") }],
        icons: newIconsArray,
      };
      const response = await instance.post(
        `/api/ar/create_experience`,
        { ...data },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setArData(cardStaticData);
        toast.success(response?.data?.message ?? "Card created successfully");
        setActiveTab(2);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setActiveTab(1);
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };
  const handleSubmit = async (values) => {
    console.log(values, "values11");
    let token;
    setLoading(true);
    setDynamicTitle("Creating User");
    const formData = new FormData();
    formData.append("fullName", values?.fullName);
    formData.append("phoneNumber", values?.phoneNumber);
    formData.append("email", values?.email);
    formData.append("company", values?.company);
    formData.append("username", values?.username);
    formData.append("route", values?.route);
    formData.append("category", JSON.stringify(values.category));
    formData.append("businessTypeParent", values?.businessTypeParent);
    if(resellerName){
      formData.append("referedBy", resellerName);
    }

    if (values.file) {
      formData.append("file", values.file);
    }

    try {
      const response = await instance.post(
        `/api/prototype/create_user`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response, "response");
      if (response.status === 200) {
        console.log("response", response);
        toast.success(response.data?.message);
        token = response?.data?.data?.token;
        let userData = response?.data?.data?.updatedUser;
        setUser(userData);
        setToken(token);
        handleTarget(token, userData);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading(false);
    }
  };

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      file: "",
      company: "",
      username: "",
      route: `${window.location.origin}/#/auth/user/setPassword`,
      category: [],
      businessTypeParent: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("Full name is required")
        .min(2, "Full name must be at least 2 characters")
        .max(50, "Full name cannot exceed 50 characters"),
      phoneNumber: Yup.string()
        .test(
          "is-10-digits",
          "Phone number must be exactly 10 digits",
          function (value) {
            const numericValue = value.replace(/\D/g, ""); // Strip non-numeric characters
            return numericValue.length === 12;
          }
        )
        .required("Phone number is required"),
      email: Yup.string()
        .trim()
        .email("Invalid email address")
        .required("Email is required"),
      file: Yup.mixed()
        .required("Profile image is required")
        .test(
          "fileFormat",
          `Supported formats are: ${SUPPORTED_FORMATS.join(", ")}`,
          (value) => value && SUPPORTED_FORMATS.includes(value.type)
        ),
    }),
    onSubmit: async (values) => {
      values.fullName = values.fullName.trim();
      values.email = values.email.trim();
      handleSubmit(values);
    },
  });

  console.log("formik", formik);

  const processName = (name) => {
    const replacementChar = "";
    const cleanedName = name
      .replace(/[^a-zA-Z0-9]/g, replacementChar)
      .toLowerCase();

    return `${cleanedName}${Math.floor(1000 + Math.random() * 9000)}`;
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleCheckOut = async (planId, planIndex) => {
    try {
      const loadingKey = `loading_${planIndex}`;
      setLoading({ ...loading, [loadingKey]: true });
      setDynamicTitle("Online Payment Processing");

      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        toast.error("Razorpay SDK failed to load. Are you online?");
        return;
      }

      razorPayPaymentSystem(planId);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    } catch (err) {
      console.error(err);
    }
  };

  const razorPayPaymentSystem = async (planId) => {
    try {
      const session = await PostApiRazorPay(planId);

      handleRazorPayResponse(session);
    } catch (err) {
      console.error(err);
    }
  };

  const handleRazorPayResponse = (data) => {
    try {
      const options = {
        key: RZOR_KEY,
        currency: "INR",
        name: "Immarsify",
        description: "Test Transaction",
        image: "https://example.com/your_logo",
        subscription_id: data.data.data.id,

        handler: async (response) => {
          if (response.razorpay_signature) {
            console.log("response", response);
            setPaymentLoading(true);
            fetchPaymentStatus(response?.razorpay_payment_id);
            // resetFullForm();
          }
        },
        prefill: {
          name: user?.fullName,
          email: user?.email,
          contact: user?.phoneNumber || "",
        },

        theme: {
          color: "#005aeb",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.on("payment.failure", (response) => {
        console.log(response);
      });

      rzp.open();
    } catch (err) {
      console.error(err);
    }
  };

  async function PostApiRazorPay(planId) {
    try {
      const response = await instance.post(
        "/api/rezorpay/subscription_create_checkout_session",
        { planId },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        const session = response;
        return session;
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error(err);
    }
  }

  function resetFullForm() {
    formik.resetForm();
    setUser("");
    setToken("");
    setSelectedOption("");
    setArData(cardStaticData);
    setLoading(false);
    setActiveTab(1);
    setPaymentCompleted(false);
    setPaymentLoading(false);
  }

  const [parentCategoryValue, setParentCatgoryValue] = useState("");

  const handleChange = (event) => {
    const { value } = event.target;
    setParentCatgoryValue(value);
    const selectedOptions = names.find(
      (item) => Object.keys(item)[0] === value
    );

    if (selectedOptions) {
      formik.setFieldValue("category", selectedOptions[value]);
      formik.setFieldValue("businessTypeParent", event?.target?.value);
    } else {
      formik.setFieldValue("category", []);
      formik.setFieldValue("businessTypeParent", "");
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <div className=" py-5">
        <nav className="flex ">
          <button
            className={`w-full rounded-s-lg	 px-4 py-2 font-semibold transition duration-300 focus:outline-none ${
              activeTab === 1
                ? "bg-brand-main text-white "
                : "bg-gray-200 text-black "
            }`}
          >
            Step 1 - Enter card details
          </button>
          <button
            className={`w-full  ${
              activeTab === 2 || activeTab === 3
                ? "cursor-pointer"
                : "cursor-not-allowed"
            } rounded-e-lg px-4 py-2  font-semibold  transition duration-300 focus:outline-none ${
              activeTab === 2 || activeTab === 3
                ? "bg-brand-main text-white "
                : "bg-gray-200 text-black "
            }`}
          >
            Step 2 - Payment
          </button>
        </nav>
      </div>
      {activeTab === 1 && (
        <div className="flex w-full flex-col-reverse items-center justify-between gap-2 md:flex-row">
          <div className=" w-full rounded-lg  md:w-[60%] md:py-10">
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-12 mb-4 md:col-span-6">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="fullName"
                  >
                    Full Name
                  </label>
                  <input
                    id="fullName"
                    name="fullName"
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "username",
                        processName(e.target.value)
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    className={`mt-1 block w-full border px-3 py-2 ${
                      formik.touched.fullName && formik.errors.fullName
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500`}
                  />
                  {formik.touched.fullName && formik.errors.fullName ? (
                    <div className="text-sm text-red-500">
                      {formik.errors.fullName}
                    </div>
                  ) : null}
                </div>

                <div className=" col-span-12 mb-4 flex flex-col gap-1.5 md:col-span-6">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="phoneNumber"
                  >
                    Phone Number
                  </label>
                  {/* <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    className={`mt-1 block w-full border px-3 py-2 ${
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500`}
                  /> */}
                  <PhoneInput
                    country={"in"}
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    name="phoneNumber"
                    id="phoneNumber"
                    onChange={(e) => {
                      formik.setFieldValue("phoneNumber", e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="text-sm text-red-500">
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-12 mb-4 md:col-span-6">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className={`mt-1 block w-full border px-3 py-2 ${
                      formik.touched.email && formik.errors.email
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500`}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-sm text-red-500">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="col-span-12 mb-4 md:col-span-6">
                  <label
                    className="block text-sm font-medium text-gray-700"
                    htmlFor="company"
                  >
                    Company Name (optional)
                  </label>
                  <input
                    id="company"
                    name="company"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company}
                    className={`mt-1 block w-full border px-3 py-2 ${
                      formik.touched.company && formik.errors.company
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500`}
                  />
                </div>
              </div>

              <div className="grid grid-cols-12 gap-2">
                <div className=" col-span-12  mb-4 flex items-end gap-1">
                  <div className="flex-1">
                    <label
                      className="block text-sm font-medium text-gray-700"
                      htmlFor="image"
                    >
                      Profile Image
                    </label>
                    <label
                      htmlFor="file"
                      className="flex h-full w-full flex-col  rounded-md border  border-gray-700 p-2"
                    >
                      <input
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg, image/jpg"
                        hidden
                        onChange={(event) => {
                          formik.setFieldValue(
                            "file",
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      {formik.values.file ? (
                        <p className=" text-md cursor-pointer  rounded-md font-semibold text-brand-main">
                          <p>{formik?.values?.file?.name}</p>
                        </p>
                      ) : (
                        <h4 className=" text-md cursor-pointer  rounded-md font-semibold text-brand-main">
                          Click and upload
                        </h4>
                      )}
                    </label>
                    {/* <input
                      id="file"
                      name="file"
                      type="file"
                      accept="image/*"
                      key={activeTab}
                      onChange={(event) => {
                        formik.setFieldValue(
                          "file",
                          event.currentTarget.files[0]
                        );
                      }}
                      className={`mt-1 block w-full border px-3 py-2 ${
                        formik.touched.file && formik.errors.file
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500`}
                    /> */}
                  </div>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      document.getElementById("fileClick").click();
                    }}
                    className="flex rounded bg-brand-main  p-3 font-medium text-white"
                  >
                    <BsCamera size={20} />
                  </a>
                  <input
                    hidden
                    id="fileClick"
                    name="file"
                    type="file"
                    accept="image/*"
                    capture="environment"
                    key={activeTab}
                    onChange={(event) => {
                      formik.setFieldValue(
                        "file",
                        event.currentTarget.files[0]
                      );
                    }}
                    className="hidden"
                  />
                  {formik.touched.file && formik.errors.file && (
                    <div className="mt-2 text-red-500">
                      {formik.errors.file}
                    </div>
                  )}
                </div>

                <div className="col-span-12 mb-4  w-full gap-2">
                  <label
                    id="demo-select-small-label"
                    className="text-sm font-medium text-navy-700"
                  >
                    Business Category
                  </label>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={parentCategoryValue}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    // renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    style={{ width: "100%", height: "38px" }}
                  >
                    {names.map((item, index) => (
                      <MenuItem key={index} value={Object.keys(item)[0]}>
                        {Object.keys(item)[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>

              <button
                type="submit"
                id="createCardSubmitButton"
                loading={loading}
                className="mt-4 w-full rounded-md bg-brand-main px-4 py-2 font-bold text-white focus:outline-none focus:ring-2  focus:ring-offset-2"
              >
                Submit
              </button>
            </form>
          </div>
          <div className="flex w-full min-w-[440px] items-center justify-center md:w-[40%] md:py-10">
            <CardOne
              ref={cardRef}
              username={formik.values.username}
              experiencename={formik.values.username}
              name={formik.values.fullName}
            />
          </div>
        </div>
      )}

      {activeTab === 2 && (
        <div>
          <div className="mx-auto max-w-full">
            <div className="flex flex-wrap items-center justify-center gap-2">
              {plans.length > 0 &&
                plans.map((plan, index) => (
                  <PlanCard
                    planId={plan?.PlanID}
                    planIndex={index}
                    planType={plan?.PlanName}
                    price={plan?.PlanAmount}
                    features={plan?.features}
                    handleCheckOut={handleCheckOut}
                  />
                ))}
            </div>
          </div>
        </div>
      )}

      <CommonBackdrop
        loading={loading}
        title={dynamicTitle}
        description={"Please wait..."}
        loadingColor={"white"}
      />
      <PaymentProcess open={paymentLoading} loadingSuccess={paymentCompleted} />
    </>
  );
};

export default FormTable;

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
