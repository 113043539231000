import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { IoIosAdd } from "react-icons/io";
import { MdDelete, MdEdit } from "react-icons/md";
import ProductCard from "./ProductCard";
import AddCategoryModal from "./Modal/AddCategoryModal";
import EditCategoryModal from "./Modal/EditCategoryModal";
import AddProductModal from "./Modal/AddProductModal";
import { instance } from "common/Instance";
import { useSelector } from "react-redux";
import { ConvolutionShader } from "three/examples/jsm/Addons";
import { MenuItem, Pagination, Select } from "@mui/material";
import Loader from "./Loader";
import { current } from "@reduxjs/toolkit";
import CategorySection from "./CategorySection";
import toast from "react-hot-toast";
import DeleteModal from "./Modal/DeleteModal";
import NoProductFound from "./NoProductFoun";

const allCategories = [
  { _id: "fdsjflsdjflds", name: "test" },
  { _id: "fdsjflsdjflds", name: "simple" },
];
const ImmersifyProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteCategoryModal, setOpenDeleteCateogryModal] = useState(false);

  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [opneEditCategoryModal, setOpenEditCategoryModal] = useState(false);
  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [editCategoryName, setEditCategoryName] = useState("");
  const [products, setProducts] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [key, setKey] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);

  const [categories, setCategories] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  console.log(categories, selectedCategory, "this is categories");
  const userToken = useSelector((state) => state.auth.auth.token);

  const searchChangeHandler = (e) => {
    const inputValue = e.target.value;
    setSearchString(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  const categoriesChangeHandler = (e) => {
    const category = categories.find(
      (category) => category?._id === e.target.value
    );
    console.log(category, "category found");
    if (category) {
      setSelectedCategory(category);
    }
  };

  const getCategorieshandler = async () => {
    try {
      setIsLoading(true);
      const response = await instance.get("api/categories/get_categories", {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200 && response?.data?.data) {
        console.log(response.data);
        setIsLoading(false);

        setCategories([{ _id: "All", category: "All" }, ...response?.data?.data]);
      }
    } catch (error) {
      console.log("Dashboard Error: ", error);
      setIsLoading(false);
    }
  };

  const createCategoryHandler = async (category) => {
    try {
      if (!category) {
        toast.error("Please add category name");
        return;
      }

      const response = await instance.post(
        "api/categories/create_categories",
        {
          category,
        },
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "this is repsonse");
      if (response.status === 201) {
        toast.success(response?.data?.message);
        getCategorieshandler();
        setOpenCategoryModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "Something went wrong");
      console.log("Dashboard Error: ", error);
    }
  };

  useEffect(() => {
    setSelectedCategory({ _id: "All", category: "All" });
    getCategorieshandler();
  }, []);
  useEffect(() => {
    if (categories.length > 0) {
      const updatedCategories = categories.find(
        (item) => selectedCategory._id === item._id
      );
      console.log("updatedCategories: ", updatedCategories);
      if (updatedCategories) {
        setSelectedCategory(updatedCategories);
      }
    }
  }, [key, categories]);
  const udpateCategoryHandler = async (categoryName) => {
    if (selectedCategory) {
      try {
        const response = await instance.put(
          `api/categories/update_categories/${selectedCategory?._id === 'All' ? '' : selectedCategory?._id === 'All'}`,
          { category: categoryName },
          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
        console.log(response, "this is repsonse");
        if (response.status === 200) {
          toast.success(response?.data?.message);
          setKey(Math.random());
          getCategorieshandler();
          setTimeout(() => {
            setOpenEditCategoryModal(false);
          }, 500);
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || "Something went wrong");
        console.log("Dashboard Error: ", error);
      }
    }
  };

  const deleteCategoryHandler = async () => {
    if (selectedCategory) {
      try {
        const response = await instance.delete(
          `api/categories/delete_categories/${selectedCategory?._id}`,

          {
            headers: {
              authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response.status === 200) {
          getCategorieshandler();
          toast.success(response?.data?.message);

          setOpenDeleteCateogryModal(false);
          setSelectedCategory({ id: "All", category: "All" });
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || "Something went wrong");
        console.log("Dashboard Error: ", error);
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="addCategory mb-4 -mt-4 sm:hidden">
            <buttton
              onClick={() => {
                setOpenCategoryModal(true);
              }}
              className="h-10  cursor-pointer rounded-lg  border border-none border-gray-300 bg-brand-main  px-4 py-2 text-sm text-white outline-none  transition duration-300 ease-in-out"
            >
              Add category
            </buttton>
          </div>
          {/* <div className="mb-8 mt-8">
            <hr />
          </div> */}
          <div className="flex w-full items-center justify-between gap-2">
            <input
              onChange={searchChangeHandler}
              value={searchString}
              className="block h-10 md:w-6/12 appearance-none rounded border border-gray-200 px-4 py-3 leading-tight text-gray-500 focus:border-gray-500 focus:bg-white focus:outline-none"
              id="grid-last-name"
              type="text"
              placeholder="Search the products"
            />
            <div className="flex items-center justify-between gap-2">


              <buttton
                onClick={() => {
                  setOpenCategoryModal(true);
                }}
                className=" hidden sm:block cursor-pointer rounded-lg  border border-none border-gray-300 bg-brand-main  px-4 py-2 text-sm text-white outline-none  transition duration-300 ease-in-out"
              >
                Add category
              </buttton>

              <Select
                size="small"
                labelId="category-select-label"
                value={selectedCategory?._id}
                onChange={categoriesChangeHandler}
                // label="Select Category"
                className="rounded-md border-gray-50 outline-none"
              >
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category?._id}>
                    {category?.category}
                  </MenuItem>
                ))}
              </Select>

              
              {/* <select
              value={selectedCategory?._id}
              onChange={categoriesChangeHandler}
              name=""
              id=""
              className="border-1 appearance-none rounded-md border-gray-200 bg-gray-200 px-2 outline-none "
            >
              {categories.map((category, index) => {
                return (
                  <>
                    <option value={category?._id}>{category?.category}</option>
                  </>
                );
              })}
            </select> */}
            </div>
          </div>

          {/* CATEGORY SECTION */}
          {selectedCategory && (
            <CategorySection
              category={selectedCategory}
              search={searchParam}
              setOpenEditCategoryModal={setOpenEditCategoryModal}
              setOpenDeleteCateogryModal={setOpenDeleteCateogryModal}
            />
          )}

          <AddCategoryModal
            isOpen={openCategoryModal}
            setIsOpen={setOpenCategoryModal}
            handler={createCategoryHandler}
          />
          <EditCategoryModal
            isOpen={opneEditCategoryModal}
            setIsOpen={setOpenEditCategoryModal}
            category={selectedCategory}
            handler={udpateCategoryHandler}
          />
          <DeleteModal
            content={"Are you sure to delete this category"}
            isOpen={openDeleteCategoryModal}
            setIsOpen={setOpenDeleteCateogryModal}
            handler={deleteCategoryHandler}
          />
        </div>
      )}
    </>
  );
};

export default ImmersifyProducts;
