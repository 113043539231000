import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GrThreeD } from "react-icons/gr";
import { IoDocument } from "react-icons/io5";
import { LiaImagesSolid } from "react-icons/lia";
import { MdViewCarousel } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa";
import { CiMobile3 } from "react-icons/ci";

import {
  createPhotoMesh,
  createTextMesh,
  createVideoMesh,
  createIconMesh,
  createTargetMesh,
  createDefaultPosition,
  create3DMesh,
  createDocumentMesh,
  createCarousel,
  createResumeMesh,
} from "./EditorUtils";
import TargetImage from "./TargetImage";
import AddingText from "./AddingText";
import AddingVideos from "./AddingVideos";
import UnsplashImages from "./AddImages";
// import AddResume from "./AddResume";
import AddIcon from "./AddIcons";
import AddDocument from "./AddDocument";
import FormView from "./EditorComponents/Formview";
import ImageCarousel from "./AddImageCarousel";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { instance } from "common/Instance";
import Joyride, { STATUS } from "react-joyride";
import { SET_USERDATA } from "../../../../redux/reducerSlice/authSlice";
import step2 from "../../.././../assets/videos/step2.mp4";
import step3 from "../../.././../assets/videos/step3.mp4";
import step4 from "../../.././../assets/videos/step4.mp4";
import step5 from "../../.././../assets/videos/step5.mp4";
import step6 from "../../.././../assets/videos/step6.mp4";
import step8 from "../../.././../assets/videos/step8.mp4";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UPDATE_PHOTO_FOR_EDITOR,
  UPDATE_CAROUSEL_FOR_EDITOR,
  UPDATE_TEXT_FOR_EDITOR,
  UPDATE_VIDEO_FOR_EDITOR,
  UPDATE_ICON_FOR_EDITOR,
  UPDATE_TARGET_FOR_EDITOR,
  REMOVE_PHOTO,
  REMOVE_VIDEO,
  REMOVE_TEXT,
  REMOVE_ICONS,
  SET_LOADED_AR,
  UPDATE_CENTER_FOR_EDITOR,
  REMOVE_MODEL,
  UPDATE_MODEL_FOR_EDITOR,
  SET_TARGET_HIDDEN_VALUE,
  SET_IS_VISIBLE_ON_LOAD,
  REMOVE_DOCUMENT,
  UPDATE_DOCUMENT_FOR_EDITOR,
  SET_IS_ENQUIRY,
  REMOVE_CAROUSEL,
  REMOVE_RESUME,
  UPDATE_RESUME_FOR_EDITOR,
  SET_HISTORY_CURRENT_PAGE,
  SET_HISTORY_TOTAL_PAGE,
  SET_STICK_TO_CAMERA,
  SET_HIDE_CAMERA
} from "../../../../redux/reducerSlice/arSlice";
import { SET_REDIRECT_TEMPLATE_URL } from "../../../../redux/reducerSlice/authSlice";

import {
  Stats,
  TextEditorSection,
  VideoEditorSection,
  PhotoEditorSection,
  IconEditorSection,
  ModalEditorSection,
  DocumentEditorSection,
  TargetEditorSection,
} from "./EditorComponents";

import TemplateModal from "./EditorComponents/templateModal";
import CarouselEditorSection from "./EditorComponents/CarouselEditorSection";
import { IoCloudDoneSharp } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { MdDeleteForever } from "react-icons/md";
import { FaVideo, FaImages, FaFileImage } from "react-icons/fa";
import { IoText } from "react-icons/io5";
import { IoLogoFlickr } from "react-icons/io";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { FaEye } from "react-icons/fa";
import { FaArrowsUpDownLeftRight } from "react-icons/fa6";
import { MdOutlineScreenRotationAlt } from "react-icons/md";
import { PiArrowsOutSimpleBold } from "react-icons/pi";
import toast from "react-hot-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { LOGOUT } from "../../../../redux/reducerSlice/rootSlice";
import { RiDeleteBin6Line } from "react-icons/ri";
import Avatar from "react-avatar";
import Dropdown from "components/dropdown";
import Add3dModel from "./Add3dModel";
import AddResume from "./AddResume";
import ResumeEditorSection from "./EditorComponents/ResumeEditorSection";
import { AWS_DATA_PREFIX } from "common/Instance";
import { useSelect } from "@react-three/drei";

const zoom = 35;
const aspect = window.innerWidth / window.innerHeight;

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(zoom, aspect, 0.1, 1000);
const ambientLight = new THREE.AmbientLight(0xffffff, 2);
// THREE.Cache.enabled = true;
const renderer = new THREE.WebGLRenderer({ antialias: true });

const orbitControls = new OrbitControls(camera, renderer.domElement);
const transformControls = new TransformControls(camera, renderer.domElement);
renderer.outputColorSpace = THREE.SRGBColorSpace;
renderer.setClearAlpha(0);
camera.position.set(0, 5, 5);
scene.add(ambientLight);
orbitControls.dampingFactor = 0.1;
orbitControls.enableDamping = true;
orbitControls.maxDistance = 15;
orbitControls.minDistance = 2;

export const loadingManager = new THREE.LoadingManager();

export default function Editor({
  form,
  handleOpen,
  taskId,
  setForm,
  handleSaveOpen,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const user = useSelector((state) => state.auth.auth.user);
  const [deleteComfirmationDialog, setDeleteComfirmationDialog] =
    useState(false);

  const [openResumeIconsModal, setOpenResumeIconsModal] = useState(false);
  const templateID = queryParams.get("templateID");
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);
  const [photoMeshes, setPhotoMeshes] = useState([]);
  const [resumeMeshes, setResumeMeshes] = useState([]);
  const [textMeshes, setTextMeshes] = useState([]);
  const [videoMeshes, setVideoMeshes] = useState([]);
  const [iconMeshes, setIconMeshes] = useState([]);
  const [documentMeshes, setDocumentMeshes] = useState([]);
  const [threeDMeshes, setThreeDMeshes] = useState([]);
  const [carouselMesh, setCarouselMesh] = useState([]);
  const [targetMesh, setTargetMesh] = useState(null);
  const [preview, setPrview] = useState(false);

  const handleClosePreview = () => {
    setPrview(false);
  };

  const isHiddenTargetValue = useSelector(
    (state) => state.ar?.data.targetImage?.isHidden
  );
  const [centerPosition, setCenterPosition] = useState(null);
  const [run, setRun] = useState(false);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);
  const userName = useSelector((state) => state?.auth?.auth?.user?.username);
  const checkActive = useSelector(
    (state) => state?.auth?.auth?.user?.isTourDone
  );
  const [templateData, setTemplateData] = useState(null);
  const [open, setOpenModal] = useState(false);
  const [restrictModel, setRestrictModel] = useState(false);
  const subscriptionType = useSelector(
    (state) => state?.auth?.auth?.user?.subscriptionType
  );

  const [templateLoading, setTemplateLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);
  const fetchTemplateData = async () => {
    try {
      setTemplateLoading(true);
      setRestrictModel(false);
      dispatch(SET_REDIRECT_TEMPLATE_URL(window.location.hash.split("#")[1]));
      const response = await instance.get(
        `api/template/template_by_id/${templateID}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_REDIRECT_TEMPLATE_URL(""));
        setRestrictModel(false);
        setTemplateData(response?.data?.data);
        setTemplateLoading(false);
        setOpenModal(true);
      }
    } catch (error) {
      setTemplateLoading(false);
      setRestrictModel(true);
    }
  };

  useEffect(() => {
    if (templateID) {
      fetchTemplateData();
    }
  }, [templateID]);

  // const [meshArray, setMeshArray] = useState([]);
  const [mode, setMode] = useState("translate");
  const [whatsClicked, setWhatsClicked] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const mountRef = useRef();
  const targetRef = useRef();
  let targetImage = targetRef.current;
  transformControls.setSize(1.5, 1.5);

  const gridHelper = new THREE.GridHelper(15, 15);
  gridHelper.material.opacity = 0.4;
  // gridHelper.position.y;
  gridHelper.material.transparent = true;

  function render() {
    renderer.render(scene, camera);
    orbitControls.update();
  }
  const handleResize = () => {
    const { clientWidth, clientHeight } = mountRef.current;
    renderer.setSize(clientWidth, clientHeight);
    camera.aspect = clientWidth / clientHeight;
    camera.updateProjectionMatrix();
  };

  loadingManager.onProgress = function () {
    dispatch(SET_LOADED_AR(true));
  };

  loadingManager.onLoad = function () {
    dispatch(SET_LOADED_AR(false));
  };

  // #3
  // function onMouseDown(event) {

  //   const rect = renderer.domElement.getBoundingClientRect();
  //   const mouse = new THREE.Vector2();
  //   mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
  //   mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  //   const raycaster = new THREE.Raycaster();
  //   raycaster.setFromCamera(mouse, camera);

  //   const clickableMeshes = scene.children.filter(
  //     (obj) =>
  //       obj !== targetImage && (obj.type === "Mesh" || obj.type1 === "Icon")
  //   );
  //   const clickableObjects = [...clickableMeshes];
  //   const intersects = raycaster.intersectObjects(clickableObjects);
  //   const clickedObjects = [];
  //   intersects.forEach((intersection) => {
  //     let parent = intersection.object.parent;
  //     while (parent) {
  //       if (parent.userData && parent.userData.class === "3Dmodal") {
  //         clickedObjects.push(parent);
  //         break;
  //       }
  //       parent = parent.parent;
  //     }
  //   });
  //   console.log("intersects", intersects);
  //   if (intersects.length > 0) {
  //     setIsClicked(true);
  //     if (
  //       intersects[0].object?.userData?.type !== "target_img" &&
  //       clickedObjects?.length === 0
  //     ) {
  //       if (intersects[0].object.geometry.type === "TextGeometry") {
  //         transformControls.attach(intersects[0].object.parent);
  //         intersects[0].object.parent.userData.clicked = true;
  //         setWhatsClicked(intersects[0].object.parent);
  //       } else {
  //         transformControls.attach(intersects[0]?.object);
  //         intersects[0].object.userData.clicked = true;
  //         setWhatsClicked(intersects[0].object);
  //       }
  //     } else if (clickedObjects?.length !== 0) {
  //       transformControls.attach(clickedObjects[0]);
  //       clickedObjects[0].userData.clicked = true;
  //       setWhatsClicked(clickedObjects[0]);
  //     }
  //   } else {
  //     // setWhatsClicked(null);
  //     // setIsClicked(false);
  //     // transformControls.detach(transformControls.object);
  //     console.log("intersects", intersects);
  //   }
  // }

  // let isDragging = false;
  // let mouseDownPosition = { x: 0, y: 0 };

  // function handleMouseDown(event) {
  //   mouseDownPosition.x = event.clientX;
  //   mouseDownPosition.y = event.clientY;
  //   isDragging = false;
  //   document.addEventListener("mousemove", handleMouseMove);
  //   document.addEventListener("mouseup", handleMouseUp);
  // }

  // function handleMouseMove(event) {
  //   const dx = event.clientX - mouseDownPosition.x;
  //   const dy = event.clientY - mouseDownPosition.y;
  //   if (Math.sqrt(dx * dx + dy * dy) > 5) {
  //     isDragging = true;
  //   }
  // }

  // function handleMouseUp(event) {
  //   document.removeEventListener("mousemove", handleMouseMove);
  //   document.removeEventListener("mouseup", handleMouseUp);

  //   if (!isDragging) {
  //     onMouseClick(event);
  //   }
  //   isDragging = false;
  // }

  // function onMouseClick(event) {
  //   const rect = renderer.domElement.getBoundingClientRect();
  //   const mouse = new THREE.Vector2();
  //   mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
  //   mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
  //   const raycaster = new THREE.Raycaster();
  //   raycaster.setFromCamera(mouse, camera);

  //   const clickableMeshes = scene.children.filter(
  //     (obj) =>
  //       obj !== targetImage && (obj.type === "Mesh" || obj.type1 === "Icon")
  //   );
  //   const clickableObjects = [...clickableMeshes];
  //   const intersects = raycaster.intersectObjects(clickableObjects);
  //   const clickedObjects = [];

  //   intersects.forEach((intersection) => {
  //     let parent = intersection.object.parent;

  //     while (parent) {
  //       if (parent.userData && parent.userData.class === "3Dmodal") {
  //         clickedObjects.push(parent);
  //         break;
  //       }
  //       parent = parent.parent;
  //     }
  //   });

  //   if (intersects.length > 0) {
  //     setIsClicked(true);
  //     if (
  //       intersects[0].object?.userData?.type !== "target_img" &&
  //       clickedObjects?.length === 0
  //     ) {
  //       transformControls.attach(intersects[0]?.object);
  //       intersects[0].object.userData.clicked = true;
  //       setWhatsClicked(intersects[0].object);
  //     } else if (clickedObjects?.length !== 0) {
  //       transformControls.attach(clickedObjects[0]);
  //       clickedObjects[0].userData.clicked = true;
  //       setWhatsClicked(clickedObjects[0]);
  //     }
  //   } else {
  //     setWhatsClicked(null);
  //     setIsClicked(false);
  //     transformControls.detach(transformControls.object);
  //   }
  // }

  let isDragging = false;
  let mouseDownPosition = { x: 0, y: 0 };

  function handleMouseDown(event) {
    mouseDownPosition.x = event.clientX;
    mouseDownPosition.y = event.clientY;
    isDragging = false;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }

  function handleMouseMove(event) {
    const dx = event.clientX - mouseDownPosition.x;
    const dy = event.clientY - mouseDownPosition.y;
    if (Math.sqrt(dx * dx + dy * dy) > 5) {
      isDragging = true;
    }
  }

  function handleMouseUp(event) {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);

    if (!isDragging) {
      onMouseClick(event);
    }

    isDragging = false;
  }

  function onMouseClick(event) {
    const rect = renderer.domElement.getBoundingClientRect();
    const mouse = new THREE.Vector2();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, camera);

    console.log(scene, "scenerohit");
    const clickableMeshes = scene.children.filter(
      (obj) =>
        obj.type === "Mesh" ||
        obj.type1 === "Icon" ||
        obj.type1 === "Model3d" ||
        obj.type === "Group"
    );

    const clickableObjects = [...clickableMeshes];

    console.log(clickableMeshes, "scenerohit1");

    const intersects = raycaster.intersectObjects(clickableObjects);
    console.log(intersects, "click");
    const clickedObjects = [];

    intersects.forEach((intersection) => {
      let parent = intersection.object.parent;

      while (parent) {
        if (parent.userData && parent.userData.class === "3Dmodal") {
          clickedObjects.push(parent);
          break;
        }
        parent = parent.parent;
      }
    });

    if (intersects.length > 0) {
      setIsClicked(true);

      console.log(intersects[0], "intersects");

      console.log(intersects[0]);

      if (clickedObjects?.length === 0) {
        if (intersects[0].object.geometry.type === "TextGeometry") {
          transformControls.attach(intersects[0].object.parent);
          intersects[0].object.parent.userData.clicked = true;
          setWhatsClicked(intersects[0].object.parent);
        } else if (intersects[0]?.object?.userData?.type === "target_img") {
          transformControls.attach(intersects[0]?.object);
          intersects[0].object.userData.clicked = true;
          setWhatsClicked(intersects[0].object);
          transformControls.setMode("scale");
          setMode("scale");
        } else if (intersects[0]?.object?.userData?.type === "carousel") {
          transformControls.attach(intersects[0]?.object.parent);
          intersects[0].object.parent.userData.clicked = true;
          setWhatsClicked(intersects[0].object.parent);
          transformControls.setMode("translate");
          setMode("translate");
        } else {
          transformControls.attach(intersects[0]?.object);
          intersects[0].object.userData.clicked = true;
          setWhatsClicked(intersects[0].object);
          transformControls.setMode("translate");
          setMode("translate");
        }
      } else if (clickedObjects?.length !== 0) {
        transformControls.attach(clickedObjects[0]);
        clickedObjects[0].userData.clicked = true;
        setWhatsClicked(clickedObjects[0]);
      }
    } else {
      setWhatsClicked(null);
      setIsClicked(false);
      transformControls.detach();
    }
  }

  function keyUp(event) {
    switch (event.keyCode) {
      case 16: // Shift
        transformControls.setTranslationSnap(null);
        transformControls.setRotationSnap(null);
        transformControls.setScaleSnap(null);
        break;
    }
  }

  function deleteConformation(event) {
    if (event.keyCode === 46) {
      setDeleteComfirmationDialog(true);
    }
  }

  const DeleteAssetdata = async (url) => {
    const newUrl = decodeURIComponent(url);
    const output = newUrl.replace(AWS_DATA_PREFIX, "");
    const result = output.startsWith("/") ? output.substring(1) : output;

    try {
      const response = await instance.post(
        `/api/ar/delete_file`,
        { fileKey: result },
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log(response, "abhaytum jao yaha se");
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  function handleDeleteKey(whatsClicked, dispatch, setWhatsClicked) {
    console.log(
      whatsClicked,
      dispatch,
      setWhatsClicked,
      "whatsClicked, dispatch, setWhatsClicked"
    );
    if (whatsClicked) {
      const { type, id, content, thumbnail } = whatsClicked?.userData || {};
      switch (type) {
        case "photo":
          dispatch(REMOVE_PHOTO({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);

          break;
        case "text":
          dispatch(REMOVE_TEXT({ body: content }));
          setWhatsClicked(null);

          break;
        case "video":
          dispatch(REMOVE_VIDEO({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "icons":
          dispatch(REMOVE_ICONS({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;

        case "Model3d":
          dispatch(REMOVE_MODEL({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "documents":
          dispatch(REMOVE_DOCUMENT({ id }));
          setWhatsClicked(null);
          DeleteAssetdata(thumbnail);
          break;
        case "Group":
          dispatch(REMOVE_CAROUSEL({ id }));
          setWhatsClicked(null);
          break;
        case "resume":
          dispatch(REMOVE_RESUME({ id }));
          setWhatsClicked(null);
          break;
        default:
          break;
      }
    }
  }

  const deleteKeyHandler = useCallback(
    (event) => {
      deleteConformation(event);
    },
    [whatsClicked, dispatch]
  );

  useEffect(() => {
    document.addEventListener("keydown", deleteKeyHandler);
    return () => {
      document.removeEventListener("keydown", deleteKeyHandler);
    };
  }, [deleteKeyHandler]);

  // this is for keyboard events
  function keyDown(event) {
    switch (event.keyCode) {
      // case 81: // Q
      //   transformControls.setSpace(
      //     transformControls.space === "local" ? "world" : "local"
      //   );
      //   break;

      case 16: // Shift
        transformControls.setTranslationSnap(0.25);
        transformControls.setRotationSnap(THREE.MathUtils.degToRad(15));
        transformControls.setScaleSnap(0.25);
        break;

      // case 87: // W
      //   transformControls.setMode("translate");
      //   setMode("translate");
      //   break;

      // case 69: // E
      //   transformControls.setMode("rotate");
      //   setMode("rotate");
      //   break;

      // case 82: // R
      //   transformControls.setMode("scale");
      //   setMode("scale");
      //   break;

      case 27: // Esc
        transformControls.reset();
        transformControls.detach();
        break;

      // case 46: // Delete
      //   if (whatsClicked) {
      //     const userType = whatsClicked?.userData?.type;
      //     const userId = whatsClicked?.userData?.id;
      //     const userContent = whatsClicked?.userData?.content;
      //     switch (userType) {
      //       case "photo":
      //         dispatch(REMOVE_PHOTO({ id: userId }));
      //         setWhatsClicked(null);
      //         break;
      //       case "text":
      //         dispatch(REMOVE_TEXT({ body: userContent }));
      //         setWhatsClicked(null);
      //         break;
      //       case "video":
      //         dispatch(REMOVE_VIDEO({ id: userId }));
      //         setWhatsClicked(null);
      //         break;
      //       case "icons":
      //         dispatch(REMOVE_ICONS({ id: userId }));
      //         setWhatsClicked(null);
      //         break;
      //       default:
      //         break;
      //     }
      //   }
      //   break;
    }
  }

  function transformControlsDrag(event) {
    orbitControls.enabled = !event.value;
  }

  // // taking data from global state and adding it to editor meshArray>>>
  // // #1
  // useEffect(() => {
  //   const populateMeshArray = async () => {
  //     const photoMeshes = await Promise.all(ardata.photos.map(createPhotoMesh));
  //     const textMeshes = await Promise.all(ardata.text.map(createTextMesh));
  //     const videoMeshes = await Promise.all(ardata.videos.map(createVideoMesh));
  //     const iconsMeshes = await Promise.all(ardata.icons.map(createIconMesh));
  //     const targetImage = await Promise.resolve(
  //       createTargetMesh(ardata?.targetImage)
  //     );
  //     const centerPosition = await Promise.resolve(
  //       createDefaultPosition(ardata)
  //     );
  //     const allMeshes = [
  //       ...photoMeshes,
  //       ...textMeshes,
  //       ...videoMeshes,
  //       ...iconsMeshes,
  //       ...[centerPosition],
  //       ...[targetImage],
  //     ];
  //     setMeshArray([...allMeshes]);
  //     render();
  //   };
  //   populateMeshArray();
  // }, [ardata]);

  // // #2
  // // takes everything from mesharray and add it to scene and also cleanup>>>
  // useEffect(() => {
  //   meshArray.forEach((mesh) => {
  //     scene.add(mesh);
  //   });
  //   return () => {
  //     meshArray.forEach((mesh) => {
  //       mesh?.material?.dispose();
  //       mesh?.geometry?.dispose();
  //       // scene.remove(mesh);
  //       scene.clear();
  //     });
  //   };
  // }, [meshArray]);

  const createPhotoMeshMemo = useCallback(
    async (photo, index) => await createPhotoMesh(photo, index),
    []
  );

  const createResumeMeshMemo = useCallback(
    async (resume, index) => await createResumeMesh(resume, index),
    []
  );

  const createTextMeshMemo = useCallback(
    async (text, index) => await createTextMesh(text, index),
    []
  );
  const createVideoMeshMemo = useCallback(
    async (video, index) => await createVideoMesh(video, index),
    []
  );

  const createIconMeshMemo = useCallback(
    async (icon, index) => await createIconMesh(icon, index),
    []
  );

  const createDocumentMeshMemo = useCallback(
    async (document, index) => await createDocumentMesh(document, index)
  );
  const create3DMeshMemo = useCallback(
    async (model, index) => await create3DMesh(model, index),
    []
  );

  const createCarouselMesh = useCallback(
    async (carousel, index) => await createCarousel(carousel, index),
    []
  );
  const createTargetMeshMemo = useCallback(
    async (targetImage) => await createTargetMesh(targetImage),
    []
  );

  const createDefaultPositionMemo = useCallback(
    async (data) => await createDefaultPosition(data),
    []
  );

  useEffect(() => {
    const fetchPhotoMeshes = async () => {
      const meshes = await Promise.all(ardata.photos.map(createPhotoMeshMemo));
      setPhotoMeshes(meshes);
    };
    fetchPhotoMeshes();
  }, [ardata.photos, createPhotoMeshMemo]);

  useEffect(() => {
    if (ardata?.resume) {
      const fetchResumeMeshes = async () => {
        const meshes = await Promise.all(
          ardata.resume.map(createResumeMeshMemo)
        );
        setResumeMeshes(meshes);
      };

      fetchResumeMeshes();
    }
  }, [ardata?.resume, createResumeMeshMemo]);

  useEffect(() => {
    const fetchTextMeshes = async () => {
      const meshes = await Promise.all(ardata.text.map(createTextMeshMemo));
      setTextMeshes(meshes);
    };
    fetchTextMeshes();
  }, [ardata.text, createTextMeshMemo]);

  useEffect(() => {
    const fetchVideoMeshes = async () => {
      const meshes = await Promise.all(ardata.videos.map(createVideoMeshMemo));
      setVideoMeshes(meshes);
    };
    fetchVideoMeshes();
  }, [ardata.videos, createVideoMeshMemo]);

  useEffect(() => {
    if (ardata?.carousel) {
      const fetchCarouselMeshes = async () => {
        const meshes = await Promise.all(
          ardata?.carousel?.map(createCarouselMesh)
        );
        setCarouselMesh(meshes);
      };
      fetchCarouselMeshes();
    }
  }, [ardata?.carousel, createCarouselMesh]);

  useEffect(() => {
    const fetchIconMeshes = async () => {
      const meshes = await Promise.all(ardata.icons.map(createIconMeshMemo));
      setIconMeshes(meshes);
    };
    fetchIconMeshes();
  }, [ardata.icons, createIconMeshMemo]);

  useEffect(() => {
    if (ardata?.documents) {
      const fetchDocumentMeshes = async () => {
        const meshes = await Promise.all(
          ardata.documents.map(createDocumentMesh)
        );
        setDocumentMeshes(meshes);
      };
      fetchDocumentMeshes();
    }
  }, [ardata.documents, createDocumentMesh]);

  useEffect(() => {
    if (ardata?.model) {
      const fetch3DMeshes = async () => {
        const meshes = await Promise.all(ardata?.model?.map(create3DMeshMemo));
        setThreeDMeshes(meshes);
      };
      fetch3DMeshes();
    }
  }, [ardata?.model, create3DMeshMemo]);

  const fetchTargetMesh = async () => {
    const mesh = await createTargetMeshMemo(ardata?.targetImage);
    setTargetMesh(mesh);
  };
  useEffect(() => {
    fetchTargetMesh();
  }, [ardata.targetImage, createTargetMeshMemo]);

  useEffect(() => {
    const fetchCenterPosition = async () => {
      const position = await createDefaultPositionMemo(ardata);
      setCenterPosition(position);
    };
    fetchCenterPosition();
  }, [ardata, createDefaultPositionMemo]);

  useEffect(() => {
    const meshes = [
      ...photoMeshes,
      ...textMeshes,
      ...videoMeshes,
      ...iconMeshes,
      ...threeDMeshes,
      ...documentMeshes,
      ...carouselMesh,
      ...resumeMeshes,
      centerPosition,
      targetMesh,
    ].filter(Boolean);

    meshes.forEach((mesh) => {
      if (mesh) {
        scene.add(mesh);
      }
    });

    return () => {
      meshes.forEach((mesh) => {
        if (mesh) {
          mesh?.material?.dispose();
          mesh?.geometry?.dispose();
          scene.remove(mesh);
        }
      });
    };
  }, [
    photoMeshes,
    textMeshes,
    videoMeshes,
    iconMeshes,
    centerPosition,
    targetMesh,
    documentMeshes,
    carouselMesh,
    threeDMeshes,
    resumeMeshes,
  ]);

  const meshArray = useMemo(() => {
    return [
      ...photoMeshes,
      ...textMeshes,
      ...videoMeshes,
      ...iconMeshes,
      ...threeDMeshes,
      ...documentMeshes,
      ...carouselMesh,
      ...resumeMeshes,
      centerPosition,
      targetMesh,
    ].filter(Boolean);
  }, [
    photoMeshes,
    textMeshes,
    videoMeshes,
    iconMeshes,
    centerPosition,
    targetMesh,
    documentMeshes,
    threeDMeshes,
    carouselMesh,
    resumeMeshes,
  ]);

  useEffect(() => {
    meshArray.forEach((mesh) => {
      if (mesh) {
        scene.add(mesh);
      }
    });

    return () => {
      meshArray.forEach((mesh) => {
        if (mesh) {
          mesh?.material?.dispose();
          mesh?.geometry?.dispose();
          scene.remove(mesh);
        }
      });
    };
  }, [meshArray]);

  function transformControlsMouseUp() {
    const object = transformControls.object;

    console.log(object, "object");

    console.log(object.userData.id);

    const data = {
      id: object.userData.id,
      type: object.userData.type,
      position: {
        x: object.position.x,
        y: object.position.y,
        z: object.position.z,
      },
      rotation: {
        x: object.rotation.x,
        y: object.rotation.y,
        z: object.rotation.z,
      },
      scale: { x: object.scale.x, y: object.scale.y, z: object.scale.z },
    };

    const data1 = {
      id: object.userData.id,
      type: object.userData.type,
      position: {
        x: object.position.x,
        y: object.position.y,
        z: object.position.z,
      },

      scale: { x: object.scale.x, y: object.scale.y, z: object.scale.z },
    };

    // dispatch Events based on type of mesh

    switch (object.userData.type) {
      case "target_img":
        dispatch(UPDATE_TARGET_FOR_EDITOR(data));
        break;
      case "photo":
        dispatch(UPDATE_PHOTO_FOR_EDITOR(data));
        break;
      case "text":
        dispatch(UPDATE_TEXT_FOR_EDITOR(data));
        break;
      case "video":
        dispatch(UPDATE_VIDEO_FOR_EDITOR(data));
        break;
      case "icons":
        dispatch(UPDATE_ICON_FOR_EDITOR(data));
        break;

      case "Model3d":
        dispatch(UPDATE_MODEL_FOR_EDITOR(data));
        break;

      case "documents":
        dispatch(UPDATE_DOCUMENT_FOR_EDITOR(data));
        break;
      case "Group":
        dispatch(UPDATE_CAROUSEL_FOR_EDITOR(data));
        break;

      case "centerPosition":
        dispatch(UPDATE_CENTER_FOR_EDITOR(data));

        break;

      case "resume":
        dispatch(UPDATE_RESUME_FOR_EDITOR(data));

        break;
      default:
        break;
    }
    setWhatsClicked(object);
  }
  // #4
  // useEffect(() => {
  //   // init >>>
  //   scene.add(gridHelper);
  //   scene.add(transformControls);
  //   scene.add(ambientLight);

  //   const mount = mountRef?.current;
  //   if (mount) {
  //     mount.appendChild(renderer.domElement);
  //   }
  //   const { clientWidth, clientHeight } = mount;
  //   renderer.setSize(clientWidth, clientHeight);
  //   camera.aspect = clientWidth / clientHeight;
  //   camera.updateProjectionMatrix();
  //   renderer.setAnimationLoop(() => {
  //     orbitControls.update();
  //     render();
  //   });
  //   // All Event Listeners >>>
  //   transformControls.addEventListener("change", render);
  //   transformControls.addEventListener("mouseUp", transformControlsMouseUp);
  //   transformControls.addEventListener(
  //     "dragging-changed",
  //     transformControlsDrag
  //   );
  //   window.addEventListener("keydown", keyDown);
  //   window.addEventListener("keyup", keyUp);
  //   window.addEventListener("resize", handleResize);
  //   mount.addEventListener("click", onMouseDown);

  //   // Cleanup >>>
  //   return () => {
  //     if (mount) {
  //       mount.removeEventListener("mousedown", onMouseDown);
  //     }
  //     transformControls.removeEventListener("change", render);
  //     transformControls.removeEventListener(
  //       "mouseUp",
  //       transformControlsMouseUp
  //     );
  //     transformControls.removeEventListener(
  //       "dragging-changed",
  //       transformControlsDrag
  //     );
  //     window.removeEventListener("keydown", keyDown);
  //     window.removeEventListener("keyup", keyUp);
  //     window.removeEventListener("resize", handleResize);
  //     renderer.dispose();
  //     if (mount) {
  //       mount.removeChild(renderer.domElement);
  //     }
  //     transformControls.detach();
  //     if (targetImage) {
  //       targetImage.material.dispose();
  //       targetImage.geometry.dispose();
  //       scene.remove(targetImage);
  //     }
  //     targetImage = null;
  //     scene.remove(gridHelper);
  //     scene.remove(transformControls);
  //     scene.remove(targetImage);
  //   };
  // }, [meshArray]);

  useEffect(() => {
    scene.add(gridHelper, transformControls);
    const mount = mountRef?.current;
    if (mount) {
      mount.appendChild(renderer.domElement);
    }
    const { clientWidth, clientHeight } = mount;
    renderer.setSize(clientWidth, clientHeight);
    camera.aspect = clientWidth / clientHeight;
    camera?.updateProjectionMatrix();
    let animationFrameId;
    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      orbitControls.update();
      render();
    };

    animate();
    const eventHandlers = [
      { target: transformControls, event: "change", handler: render },
      {
        target: transformControls,
        event: "mouseUp",
        handler: transformControlsMouseUp,
      },
      {
        target: transformControls,
        event: "dragging-changed",
        handler: transformControlsDrag,
      },
      { target: window, event: "keydown", handler: keyDown },
      { target: window, event: "keyup", handler: keyUp },
      { target: window, event: "resize", handler: handleResize },
      { target: mount, event: "mousedown", handler: handleMouseDown },
    ];

    eventHandlers.forEach(({ target, event, handler }) =>
      target.addEventListener(event, handler)
    );
    if (whatsClicked) {
      console.log(whatsClicked.userData, "userData");
      console.log(meshArray, "meshArray");
      let objectToAttach = meshArray.find(
        (mesh) => mesh.userData.id === whatsClicked.userData.id
      );
      if (whatsClicked.userData.type === "target_img") {
        objectToAttach = meshArray.find(
          (mesh) => mesh.userData.type === whatsClicked.userData.type
        );
      }

      if (
        objectToAttach?.type === "Mesh" ||
        objectToAttach?.type1 === "Icon" ||
        objectToAttach?.type1 === "Model3d"
      ) {
        transformControls.attach(objectToAttach);
      } else {
        transformControls.detach(objectToAttach);
      }
    }
    return () => {
      cancelAnimationFrame(animationFrameId);
      eventHandlers.forEach(({ target, event, handler }) =>
        target.removeEventListener(event, handler)
      );

      renderer.dispose();
      if (mount) {
        mount.removeChild(renderer.domElement);
      }
      transformControls.detach();
      // disposeObject(targetImage);
      scene.remove(gridHelper, transformControls);
    };
  }, [meshArray]);

  useEffect(() => {
    const eventHandlers = [
      { target: window, event: "keydown", handler: keyDown },
    ];

    eventHandlers.forEach(({ target, event, handler }) =>
      target.addEventListener(event, handler)
    );
  }, [whatsClicked]);

  function disposeObject(object) {
    if (!object) return;

    if (object.geometry) {
      object.geometry.dispose();
    }

    if (object.material) {
      if (Array.isArray(object.material)) {
        object.material.forEach((material) => material.dispose());
      } else {
        object.material.dispose();
      }
    }

    scene.remove(object);
  }

  function render() {
    renderer.render(scene, camera);
  }

  // Clean up targetImage if it is being reassigned somewhere in the code.
  useEffect(() => {
    return () => {
      disposeObject(targetImage);
    };
  }, [targetImage]);

  const isLoaded = useSelector((state) => state.ar.isLoaded);

  useEffect(() => {
    if (checkActive !== true) {
      setRun(true);
    }
  }, []);

  const EditorSteps = [
    {
      content: (
        <h3>
          Hello <span className="font-semibold">{userName} </span> 👋 Welcome to
          the Editor
        </h3>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "center",
      target: ".editor-main",
    },

    {
      content: (
        <div className="flex flex-col  gap-2">
          <h3 className="text-center font-semibold ">Target Image</h3>
          <p className="text-center text-sm">
            From here we can upload the target image and create the new target
            image
          </p>
          <ol
            type="1"
            className="flex flex-col items-start justify-start gap-1 py-2 text-left text-xs"
          >
            <li>1. The first tab is for the upload target image</li>
            <li>2. The second tab is for creating the new target image</li>
          </ol>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step2} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".target1",
    },
    {
      content: (
        <div className="flex flex-col gap-2">
          <h3 className="text-center font-semibold ">Editor Text</h3>
          <p className="text-center text-sm">
            Clicking this button allows you to add text to the editor as well as
            delete existing text.
          </p>
          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-md "
          >
            <source src={step3} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".addingText",
    },
    {
      content: (
        <div className="flex flex-col gap-2">
          <h3 className="text-center font-semibold ">Videos</h3>
          <p className="text-center text-sm">
            By clicking here, you can add a video to the editor as well as
            delete it.
          </p>
          <ol
            type="1"
            className="flex flex-col items-start justify-start gap-1 py-2 text-left text-xs"
          >
            <li>1. The first tab is for the upload Video.</li>
            <li>
              2. The second tab will display all the videos you have used to
              create experiences in this account.
            </li>
          </ol>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step4} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".addingVideo",
    },
    {
      content: (
        <div className="flex max-h-[50vh] flex-col gap-2 overflow-y-auto">
          <h3 className="text-center font-semibold ">Images</h3>
          <p className="text-center text-sm">
            By clicking here, you can add images to the editor and also delete
            them.
          </p>

          <ol
            type="1"
            className="flex flex-col items-start justify-start gap-1 py-2 text-left text-xs"
          >
            <li>1. The first tab is for uploading images from Local.</li>
            <li>
              2. The second tab will display all the images you have used to
              create experiences in this account.
            </li>
            <li>
              3. The third tab will allow you to choose images from the
              internet, including search functionalities.
            </li>
          </ol>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step5} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".addingImages",
    },
    {
      content: (
        <div className="flex max-h-[40vh] flex-col gap-2 overflow-y-auto ">
          <h3 className="text-center font-semibold ">Icons</h3>

          <p className="text-center text-sm">
            By clicking here, you can enrich your editor with vibrant 3D icons,
            and effortlessly remove any icons as needed.
          </p>

          <ol
            type="1"
            className="flex flex-col items-start justify-start gap-1 py-2 text-left text-xs"
          >
            <li>1. The first tab is for uploading icons from Local.</li>
            <li>
              2. The second tab will display all the icons you have used to
              create experiences in this account.
            </li>
            <li>
              3. The third tab will allow you to choose icons from the platform.
            </li>
          </ol>

          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-lg"
          >
            <source src={step6} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "right",
      target: ".addingIcons",
    },
    {
      content: (
        <div className="flex flex-col gap-2">
          <h3 className="text-center font-semibold ">Models</h3>

          <p className="text-sm">
            Here, you have the power to enhance your editor with stunning 3D
            models, and with equal ease, remove any model at your command
          </p>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: ".addingModel",
    },
    {
      content: (
        <div className="flex flex-col gap-2">
          <h3 className="text-center font-semibold">Publish</h3>
          <p>We can publish to the experience by click here.</p>
          <video
            width="700"
            height="500"
            autoPlay
            controls
            className="rounded-md border-2 border-gray-900"
          >
            <source src={step8} type="video/mp4" />
          </video>
        </div>
      ),
      locale: { skip: <strong>SKIP</strong> },
      placement: "bottom",
      target: ".publish",
    },
  ];
  const joyrideOptions = {
    styles: {
      options: {
        borderRadius: "0.5rem",
        border: "none",
        outline: "none",
      },
      buttonBack: {
        color: "#222",
      },
      buttonNext: {
        backgroundColor: "#40A2D8",
        color: "#fff",
        borderRadius: "0.5rem",
        padding: "10px",
        border: "none",
        outline: "none",
        "&:hover": {
          border: "none",
        },
      },
      buttonSkip: {
        backgroundColor: "#6c757d",
        color: "#fff",
        borderRadius: "0.5rem",
        padding: "10px",
        border: "none",
        outline: "none",
      },
    },
  };

  const updateJoyRide = async () => {
    try {
      const response = await instance.post(
        `/api/user/update_user_details/${userData._id}`,
        { isTourDone: true },
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch(SET_USERDATA(response?.data?.data));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      updateJoyRide();
    } else {
    }
  };

  const stickToCam = useSelector((state) => state.ar?.data?.stickTOCam);

  const isVisibleOnLoad = useSelector(
    (state) => state.ar?.data?.isVisibleOnLoad
  );
  const setEnquiry = useSelector((state) => state.ar?.data?.setEnquiry);
  const hideCamera=useSelector((state)=>state?.ar?.data?.hideCamera)
  console.log(whatsClicked, "whatsClicked");

  return (
    <>
      <div className="rounded-xs sticky top-0  flex w-full flex-wrap justify-between border-gray-700 bg-white px-2 py-1 shadow-md md:rounded-lg">
        <div className="flex items-center justify-between gap-2 ">
          <button
            onClick={() => {
              handleSaveOpen();
            }}
            className="rounded-lg  bg-[#3d2fa9]  p-2   text-sm  font-bold text-white "
          >
            Back To Dashboard
          </button>
          <button
            onClick={() => setForm(!form)}
            className="rounded-lg border border-[#3d2fa9] p-2 text-sm  font-bold text-[#3d2fa9]  hover:bg-[#3d2fa9] hover:text-white"
          >
            {form ? "Editor View" : "Form View"}
          </button>
        </div>

        <div className="rounded-xs relative flex h-auto flex-grow items-center justify-between gap-6 bg-white  py-2  text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-800  dark:shadow-none md:flex-grow-0  md:gap-1 xl:gap-2">
          {/* <div
            onClick={() => {
              setPrview(true);
            }}
            className=" flex items-center justify-center gap-1   p-2  font-semibold text-[#3d2fa9] "
          >
            Preview <CiMobile3 />
          </div> */}

          <div className="px-auto mx-2">
            <div
              className="flex cursor-pointer items-center justify-center gap-1 rounded-md border border-[#3d2fa9] p-2 text-sm  font-semibold text-brand-main"
              onClick={() => setRun(true)}
            >
              Help
              <IoIosHelpCircleOutline size={20} color="#3d2fa9" />
            </div>
          </div>

          <div className="mr-5 hidden rounded-md border border-[#3d2fa9] p-2  font-semibold text-[#3d2fa9]  md:block">
            Experience Name: {taskId}
          </div>

          <div className="mr-2 flex  ">
            <button
              onClick={handleOpen}
              className="publish flex justify-around gap-1 rounded-lg  bg-[#3d2fa9] bg-gradient-to-r p-2 text-sm font-bold text-white"
            >
              <IoCloudDoneSharp size={20} /> Publish
            </button>
          </div>
          <Dropdown
            button={
              user?.image ? (
                <img
                  src={user.image}
                  className="h-10 w-10 cursor-pointer rounded-full object-cover "
                  alt="user avatar"
                />
              ) : (
                <Avatar
                  size={40}
                  round={true}
                  name={user?.username}
                  className="h-10 w-10 rounded-full object-cover "
                />
              )
            }
            children={
              <div className="z-999 z-50 flex  w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      👋 Hey,{user?.username ?? ""}
                    </p>
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  <a
                    onClick={() => {
                      navigate("/admin/profile");
                    }}
                    className="cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    Profile
                  </a>

                  <a
                    href=" "
                    className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                    onClick={() => {
                      dispatch(LOGOUT());
                      dispatch(SET_HISTORY_CURRENT_PAGE(1));
                      dispatch(SET_HISTORY_TOTAL_PAGE(0));
                      navigate("/auth/sign-in");
                    }}
                  >
                    Log Out
                  </a>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </div>
      <div className="flex w-full  items-center justify-center rounded-t-lg bg-gradient-to-r from-indigo-500 from-10% via-blue-500 via-50% to-green-500 to-90% p-1.5">
        <h1 className="text-sm font-semibold capitalize text-white ">
          Immarsify Editor
        </h1>
      </div>

      {userData?.isSubscriptionActive &&
        (checkActive !== true || run) &&
        authToken && (
          <Joyride
            steps={EditorSteps}
            run={run}
            continuous={true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            disableOverlayClose={true}
            callback={handleJoyrideCallback}
            {...joyrideOptions}
            locale={{
              next: "Next",
              back: "Back",
              skip: "Skip",
            }}
          />
        )}

      <div className="editor-main  flex flex-col justify-center  overflow-hidden overflow-y-auto bg-white dark:text-white md:flex-row md:gap-0 md:overflow-hidden ">
        {!form && (
          <AssetsBar
            className={"mr-1.5 w-full rounded-lg bg-gray-100 lg:w-[17%] "}
            meshArray={meshArray}
            whatsClicked={whatsClicked}
            setWhatsClicked={setWhatsClicked}
            fetchTargetMesh={fetchTargetMesh}
            setMode={setMode}
          />
        )}

        <div
          className={`relative   h-[80vh] sm:w-full  ${
            form ? " lg:w-8/12 " : "lg:w-[60%] "
          }   `}
        >
          <Toolbar
            mode={mode}
            whatsClicked={whatsClicked}
            setMode={setMode}
            isClicked={isClicked}
          />
          <div
            ref={mountRef}
            className="h-full  bg-gray-100 dark:bg-gray-800 sm:w-full"
          ></div>
        </div>

        {!form ? (
          <div className="right bg-zinc-900 dark:bg-zinc-800 mx-1  h-[80vh] overflow-y-auto  rounded-md bg-gray-100 py-2 text-sm lg:w-[22%]">
            {/* <div className="info-container m-4 mb-2">{whatsClicked && <>{ }</>}</div>  */}
            <div className="views px-auto mx-2 mt-2  flex gap-1.5 ">
              <button
                className="eye  flex w-full items-center justify-between rounded-lg bg-white p-2 hover:bg-gray-50 dark:bg-gray-900 dark:text-white "
                onClick={() => {
                  orbitControls.reset();
                }}
              >
                <span className="select-none text-sm ">Center View</span>
                <FaEye />
              </button>
              {whatsClicked && (
                <button
                  className="text-md    rounded-lg bg-red-500  p-2 font-bold text-white "
                  onClick={() => {
                    setDeleteComfirmationDialog(true);
                  }}
                  autoFocus
                >
                  <RiDeleteBin6Line size={15} />
                </button>
              )}
            </div>

            <div className=" flex w-full items-center gap-2 px-4 pt-4">
              <div className=" text-sm ">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isVisibleOnLoad}
                      onChange={() => {
                        dispatch(SET_IS_VISIBLE_ON_LOAD(!isVisibleOnLoad));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>Auto load in AR</span>}
                />
              </div>

              {subscriptionType !== "Silver" && (
                <div className="text-sm">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={setEnquiry}
                        onChange={() => {
                          dispatch(SET_IS_ENQUIRY(!setEnquiry));
                        }}
                      />
                    }
                    label={<span style={{ fontSize: 12 }}>Set Enquiry</span>}
                  />
                </div>
              )}
            </div>

            {isVisibleOnLoad && (
              <div className="px-4 pb-2 pt-1 text-sm">
                <span className="text-md font-semibold text-red-500">
                  Warning:{" "}
                </span>
                This feature is only available for non-iOS users in AR, due to
                iOS user policies.
              </div>
            )}

            <div className=" flex w-full flex-wrap items-center gap-2 px-4 pt-4">
              <div className=" text-sm ">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={stickToCam}
                      onChange={() => {
                        dispatch(SET_STICK_TO_CAMERA(!stickToCam));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>Stick to Camera</span>}
                />
              </div>

              {whatsClicked?.userData?.type === "target_img" && (
                <div className="text-sm ">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isHiddenTargetValue}
                        onChange={() => {
                          dispatch(
                            SET_TARGET_HIDDEN_VALUE(!isHiddenTargetValue)
                          );
                        }}
                      />
                    }
                    label={<span style={{ fontSize: 12 }}>Hide in AR</span>}
                  />
                </div>
              )}


<div className="text-sm">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={hideCamera}
                        onChange={() => {
                          dispatch(SET_HIDE_CAMERA(!hideCamera));
                        }}
                      />
                    }
                    label={<span style={{ fontSize: 12 }}>Hide Camera</span>}
                  />
                </div>

            </div>

            {/* -------------------Text Editor Section--------------------- */}
            <TextEditorSection whatsClicked={whatsClicked} />
            {/* -------------------Photo Editor Section--------------------- */}
            <PhotoEditorSection whatsClicked={whatsClicked} />
            {/* -------------------Video Editor Section--------------------- */}
            <VideoEditorSection whatsClicked={whatsClicked} />
            {/* -------------------Icon Editor Section--------------------- */}
            <IconEditorSection whatsClicked={whatsClicked} />

            <TargetEditorSection whatsClicked={whatsClicked} />
            <ResumeEditorSection
              whatsClicked={whatsClicked}
              open={openResumeIconsModal}
              setOpenModal={setOpenResumeIconsModal}
            />
            <ModalEditorSection whatsClicked={whatsClicked} />

            <DocumentEditorSection whatsClicked={whatsClicked} />
            <CarouselEditorSection
              whatsClicked={whatsClicked}
              meshArray={meshArray}
            />

            <Stats
              mode={mode}
              whatsClicked={whatsClicked}
              setWhatsClicked={setWhatsClicked}
            />
          </div>
        ) : (
          <FormView />
        )}

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoaded || templateLoading}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <h1 className="font-bold">Loading...</h1>
            <CircularProgress color="inherit" />
          </div>
        </Backdrop>
      </div>
      {TemplateModal(open, templateData)}
      <Dialog fullWidth="sm" open={restrictModel}>
        <DialogTitle>Important</DialogTitle>
        <DialogContent>
          <DialogContentText className="text-xs">
            Please log in to access the AR Experience editor or register if
            you're new. Our platform offers a seamless editor to create
            professional AR experiences. Thank you!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              dispatch(LOGOUT());
              dispatch(SET_HISTORY_CURRENT_PAGE(1));
              dispatch(SET_HISTORY_TOTAL_PAGE(0));
              navigate("/auth/sign-in");
              setOpenModal(false);
            }}
            className="rounded-lg border border-gray-200  bg-blue-500 p-2 text-sm font-normal text-white"
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteComfirmationDialog}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="font-semibold">
          Are you sure you want to delete this item?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className=" text-sm "
            id="alert-dialog-description"
          >
            This action cannot be undone. Once you delete this item, it will be
            permanently removed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="text-md  rounded-lg  bg-gray-200   p-2.5  font-bold text-black "
            onClick={() => {
              setDeleteComfirmationDialog(false);
            }}
          >
            Cancel
          </button>
          <button
            className="text-md  rounded-lg  bg-red-500   p-2.5  font-bold text-white "
            onClick={() => {
              handleDeleteKey(whatsClicked, dispatch, setWhatsClicked);
              setDeleteComfirmationDialog(false);
            }}
            autoFocus
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>

      <Preview open={preview} handleClose={handleClosePreview} />
    </>
  );
}

function Toolbar({ whatsClicked, isClicked, mode, setMode }) {
  return (
    <div
      className={clsx(
        "bg-zinc-200 absolute left-2 top-4 h-36 w-12 overflow-hidden rounded-xl border-gray-500 dark:border",
        !whatsClicked
          ? "pointer-events-none opacity-20"
          : "pointer-events-auto opacity-100"
      )}
    >
      <input
        type="radio"
        className="peer/translate hidden"
        name="mode"
        id="translate"
        onClick={() => {
          transformControls.setMode("translate");

          setMode("translate");
        }}
        value={mode === "translate" ? true : false}
      />
      <input
        type="radio"
        className="peer/rotate hidden"
        name="mode"
        id="rotate"
        onClick={() => {
          transformControls.setMode("rotate");
          setMode("rotate");
        }}
        value={mode === "rotate" ? true : false}
      />

      <input
        type="radio"
        className="peer/scale hidden"
        name="mode"
        id="scale"
        onClick={() => {
          transformControls.setMode("scale");
          setMode("scale");
        }}
        value={mode === "scale" ? true : false}
      />

      {whatsClicked?.userData?.type !== "target_img" && (
        <>
          <Tooltip
            className="cursor-pointer"
            title="Position"
            arrow
            placement="right"
          >
            <label
              htmlFor="translate"
              className={clsx(
                "position flex h-12 w-12 items-center justify-center ",
                mode === "translate" ? "bg-blue-300" : ""
              )}
            >
              <FaArrowsUpDownLeftRight className="text-xl" />
            </label>
          </Tooltip>
          <Tooltip
            className="cursor-pointer"
            title="Rotate"
            arrow
            placement="right"
          >
            <label
              htmlFor="rotate"
              className={clsx(
                "position flex h-12 w-12 items-center justify-center ",
                mode === "rotate" ? "bg-blue-300" : ""
              )}
            >
              <MdOutlineScreenRotationAlt className="text-xl" />
            </label>
          </Tooltip>
        </>
      )}

      <Tooltip className="cursor-pointer" title="Scale" arrow placement="right">
        <label
          htmlFor="scale"
          className={clsx(
            "position flex h-12 w-12 items-center justify-center ",
            mode === "scale" ? "bg-blue-300" : ""
          )}
        >
          <PiArrowsOutSimpleBold className="text-xl" />
        </label>
      </Tooltip>
    </div>
  );
}

function AssetsBar({
  className,
  meshArray,
  whatsClicked,
  setWhatsClicked,
  fetchTargetMesh,
  setMode,
}) {
  const [openIconModal, setIconModal] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const [showAssetBar, setShowAssetBar] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenModal = (modal) => {
    setOpenModal(modal);
  };

  useEffect(() => {
    console.log(openModal, `openModal`);
  }, [openModal]);

  useEffect(() => {
    if (!targetPreview) {
      handleOpenModal("targetImage");
    }
  }, []);

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const targetPreview = useSelector(
    (state) => state?.ar?.data?.targetImage?.src
  );
  const targetMind = useSelector((state) => state?.ar?.data?.mind?.src);
  const arData = useSelector((state) => state?.ar?.data);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userName = useSelector((state) => state?.auth?.auth?.user?.username);

  const dispatch = useDispatch();

  const DeleteAssetdata = async (url) => {
    const newUrl = decodeURIComponent(url);
    const output = newUrl.replace(AWS_DATA_PREFIX, "");
    const result = output.startsWith("/") ? output.substring(1) : output;

    try {
      const response = await instance.post(
        `/api/ar/delete_file`,
        { fileKey: result },
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log(response, "abhaytum jao yaha se");
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  console.log(meshArray, "meshArray");

  return (
    <div
      className={`${className}  overflow-y-auto text-sm sm:h-full md:h-[80vh] lg:h-[80vh]`}
    >
      <div
        className={`mx-2 my-2 flex items-center justify-center rounded-md bg-white py-2 text-center text-sm font-semibold  text-[#3d2fa9] shadow-sm`}
      >
        {window.innerWidth < 900 ? (
          <IoIosAddCircleOutline
            size={20}
            onClick={() => setShowAssetBar(!showAssetBar)}
          />
        ) : (
          "Assets"
        )}
      </div>

      {(window.innerWidth > 900 || (isMobile && showAssetBar)) && (
        <div className="flex flex-col gap-2 px-2 ">
          {/* TargetImage */}
          <div className="target">
            <Accordion
              expanded={expanded === "targetImage" && targetPreview}
              onChange={handleChange("targetImage")}
              className="target1"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("targetImage");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <FaFileImage size={22} color="#3d2fa9" />{" "}
                  <p> Target Images</p>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {arData?.targetImage?.src && (
                  <div
                    className="mx-auto flex h-full min-w-[150px] items-center justify-center"
                    onClick={() => {
                      transformControls.detach();
                      setWhatsClicked(
                        meshArray.find(
                          (mesh) =>
                            mesh.uuid ===
                            meshArray.find(
                              (element) =>
                                element.userData.type === "target_img"
                            )?.uuid
                        )
                      );
                      transformControls.attach(
                        meshArray.find(
                          (mesh) =>
                            mesh.uuid ===
                            meshArray.find(
                              (element) =>
                                element.userData.type === "target_img"
                            )?.uuid
                        )
                      );

                      transformControls.setMode("scale");
                      setMode("scale");
                    }}
                  >
                    <img
                      src={arData?.targetImage?.src}
                      alt="Hello"
                      className="h-full min-w-[150px] rounded-sm "
                    />
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
            <TargetImage
              open={openModal === "targetImage"}
              fetchTargetMesh={() => fetchTargetMesh()}
              handleClose={handleCloseModal}
            />
          </div>

          {/* addingText */}
          <div>
            <Accordion
              expanded={
                expanded === "addingText" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "text")
              }
              onChange={handleChange("addingText")}
              disabled={!targetMind}
              className="addingText"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("addingText");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <IoText size={20} color="#3d2fa9" /> <p>Text</p>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "text")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg  border px-2 py-4 text-xs font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        <p
                          className={`w-[90%] truncate text-sm ${
                            data.name ? "" : "text-gray-400"
                          } `}
                        >
                          {data.name ? data.name : "Add text"}
                        </p>
                        <span
                          className="absolute right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_TEXT({
                                body: data.userData.content,
                              })
                            );
                          }}
                        >
                          {" "}
                          <MdDeleteForever size={20} color="#FF6868" />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>

            <AddingText
              open={openModal === "addingText"}
              handleClose={handleCloseModal}
            />
          </div>

          {/* addingVideo */}
          <div>
            <Accordion
              expanded={
                expanded === "addingVideo" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "video")
              }
              disabled={!targetMind}
              onChange={handleChange("addingVideo")}
              className="addingVideo"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      // if (meshArray?.length === 0) {
                      event.stopPropagation();
                      if (targetPreview) {
                        handleOpenModal("addingVideo");
                      }
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={(event) => {
                  if (!targetPreview) {
                    event.stopPropagation();
                  }
                }}
              >
                <div className="flex items-center justify-center gap-4 ">
                  <FaVideo size={20} color="#3d2fa9" /> <p>Videos</p>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "video")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg  border px-2 py-4  text-xs font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        {data.name ? data.name : data.uuid}
                        <span
                          className="absolute  right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_VIDEO({
                                id: data.userData.id,
                              })
                            );

                            DeleteAssetdata(data.userData?.thumbnail);
                          }}
                        >
                          <MdDeleteForever color="#FF6868" size={20} />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>

            <AddingVideos
              open={openModal === "addingVideo"}
              handleClose={handleCloseModal}
            />
          </div>

          {/* Images */}
          <div>
            <Accordion
              expanded={
                expanded === "addingImages" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "photo")
              }
              disabled={!targetMind}
              onChange={handleChange("addingImages")}
              className="addingImages"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("addingImages");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <FaImages size={20} color="#3d2fa9" /> <p>Images</p>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "photo")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg border px-2   py-4  font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        <p className="truncate pr-4 text-sm">
                          {data.name ? data.name : data.uuid}
                        </p>

                        <span
                          className="absolute  right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_PHOTO({
                                id: data.userData.id,
                              })
                            );
                            DeleteAssetdata(data.userData?.thumbnail);
                          }}
                        >
                          <MdDeleteForever color="#FF6868" size={20} />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>
            <UnsplashImages
              open={openModal === "addingImages"}
              handleClose={handleCloseModal}
            />
          </div>

          <div>
            <Accordion
              expanded={
                expanded === "addingIcons" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "icons")
              }
              disabled={!targetMind}
              onChange={handleChange("addingIcons")}
              className="addingIcons"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("addingIcons");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <IoLogoFlickr size={20} color="#3d2fa9" /> <p>Icons</p>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "icons")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg  border px-2 py-4  text-xs font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        <p className="truncate pr-4 text-sm">
                          {data.name ? data.name : data.uuid}
                        </p>
                        <span
                          className="absolute right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_ICONS({
                                id: data.userData.id,
                              })
                            );
                            DeleteAssetdata(data.userData?.thumbnail);
                          }}
                        >
                          <MdDeleteForever size={20} color="#FF6868" />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>
            <AddIcon
              open={openModal === "addingIcons"}
              handleClose={handleCloseModal}
            />
          </div>

          <div>
            <Accordion
              expanded={
                expanded === "addingModel" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "Model3d")
              }
              disabled={!targetMind}
              onChange={handleChange("addingModel")}
              className="addingModel"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("addingModel");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <GrThreeD size={20} color="#3d2fa9" /> <p>Models</p>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "Model3d")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg  border px-2 py-4  text-xs font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        <p
                          style={{ fontSize: "13px" }}
                          className="truncate pr-4 "
                        >
                          {data.name ? data.name : data.uuid}
                        </p>
                        <span
                          className="absolute right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_MODEL({
                                id: data.userData.id,
                              })
                            );
                            DeleteAssetdata(data.userData?.thumbnail);
                          }}
                        >
                          <MdDeleteForever size={20} color="#FF6868" />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>
            <Add3dModel
              open={openModal === "addingModel"}
              handleClose={handleCloseModal}
            />
          </div>

          <div>
            <Accordion
              expanded={
                expanded === "addingCarousel" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "Group")
              }
              disabled={!targetMind}
              onChange={handleChange("addingCarousel")}
              className="addingCarousel"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("addingCarousel");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <MdViewCarousel size={20} color="#3d2fa9" />{" "}
                  <p>Image Carousel</p>
                </div>
              </AccordionSummary>

              {console.log(meshArray, "meshArray")}

              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "Group")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg border px-2   py-4  font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        <p className="truncate pr-4 text-sm">{data.name}</p>

                        <span
                          className="absolute  right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_CAROUSEL({
                                id: data.userData.id,
                              })
                            );
                          }}
                        >
                          <MdDeleteForever color="#FF6868" size={20} />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>
            <ImageCarousel
              open={openModal === "addingCarousel"}
              handleClose={handleCloseModal}
            />
          </div>

          <div>
            <Accordion
              expanded={
                expanded === "addingResume" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "resume")
              }
              disabled={!targetMind}
              onChange={handleChange("addingResume")}
              className="addingResume"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("addingResume");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <LiaImagesSolid size={20} color="#3d2fa9" /> <p>Add Resume</p>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "resume")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg border px-2   py-4  font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        <p className="truncate pr-4 text-sm">{data.name}</p>

                        <span
                          className="absolute  right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_RESUME({
                                id: data.userData.id,
                              })
                            );
                          }}
                        >
                          <MdDeleteForever color="#FF6868" size={20} />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>

            <AddResume
              open={openModal === "addingResume"}
              handleClose={handleCloseModal}
            />
          </div>

          <div>
            <Accordion
              expanded={
                expanded === "addingDocument" &&
                targetMind &&
                meshArray.find((mesh) => mesh?.userData?.type === "documents")
              }
              disabled={!targetMind}
              onChange={handleChange("addingDocument")}
              className="addingDocument"
            >
              <AccordionSummary
                expandIcon={
                  <IoIosAddCircleOutline
                    size={20}
                    onClick={(event) => {
                      event.stopPropagation();

                      handleOpenModal("addingDocument");
                    }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div className="flex items-center justify-center gap-4">
                  <FaRegFilePdf size={20} color="#3d2fa9" /> <p>Document</p>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                {meshArray &&
                  meshArray.length > 0 &&
                  meshArray
                    .filter((data) => data?.userData?.type === "documents")
                    .map((data, index) => (
                      <div
                        className={clsx(
                          "relative mx-1 mb-1 flex h-5 w-full items-center justify-start truncate rounded-lg  border px-2 py-4  text-xs font-semibold",
                          whatsClicked &&
                            whatsClicked.uuid === data.uuid &&
                            "bg-gray-300"
                        )}
                        key={index}
                        onClick={() => {
                          transformControls.detach();
                          setWhatsClicked(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                          transformControls.attach(
                            meshArray.find((mesh) => mesh.uuid === data.uuid)
                          );
                        }}
                      >
                        <p
                          style={{ fontSize: "13px" }}
                          className="truncate pr-4 "
                        >
                          {data.name ? data.name : data.uuid}
                        </p>
                        <span
                          className="absolute right-1 cursor-pointer"
                          onClick={() => {
                            dispatch(
                              REMOVE_DOCUMENT({
                                id: data.userData.id,
                              })
                            );
                            DeleteAssetdata(data.userData?.thumbnail);
                          }}
                        >
                          <MdDeleteForever size={20} color="#FF6868" />
                        </span>
                      </div>
                    ))}
              </AccordionDetails>
            </Accordion>
            <AddDocument
              open={openModal === "addingDocument"}
              handleClose={handleCloseModal}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function Preview({ open, handleClose }) {
  return <div></div>;
}
