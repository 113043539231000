import React, { useState, useEffect } from "react";
import {
  MdArrowDropUp,
  MdArrowDropDown,
  MdOutlineCalendarToday,
  MdBarChart,
} from "react-icons/md";
import Card from "components/card";
import LineChart from "components/charts/LineChart";
import axios from "axios";
import { instance } from "common/Instance";
import { useSelector, useDispatch } from "react-redux";
import { SET_INITIAL_STATE_ANALYTICS_YEARLY } from "../../../../../redux/reducerSlice/analytics";
import { lineChartOptionsTotalSpent } from "variables/charts";

const MonthlyTraffic = ({ activeSwitchId, isMultiscene, experience }) => {
  console.log(activeSwitchId, "userID1");
  console.log(experience, "userID1 name");
  const [dailyVisitor, setDailyVisitor] = useState(0);
  const [visitor_monthly_list, setVisitor_monthly_list] = useState([]);
  const [percentageChange, setPercentageChange] = useState("");
  const [loading, setLoading] = useState(true);
  const [yearlyData, setYearlyData] = useState({

  })

  const userToken = useSelector((state) => state?.auth?.auth?.token);
  const getYearlyAnalytics = useSelector(
    (state) => state?.analytics?.data?.getYearlyVisitor
  );


  useEffect(() => {
    if (activeSwitchId && experience) {
      getDashboardData();
    }

  }, [userToken, experience, activeSwitchId, isMultiscene]);


  useEffect(() => {
    if (yearlyData) {
      setDailyVisitor(yearlyData?.visitor_count);
      setVisitor_monthly_list(yearlyData?.visitor_monthly_list);
      setLoading(false);
    }
  }, [yearlyData]);


  console.log(yearlyData, 'rohit112e')
  console.log(dailyVisitor, 'rohit112e visit')


  const getDashboardData = async () => {
    try {
      const response = await instance.get(
        `api/visitors/get_yearly_dashboard_data_user_reseller?userID=${activeSwitchId}&experienceName=${experience}${isMultiscene ? `&multiScene=true` : ""
        }`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response) {
        setYearlyData(response?.data?.data)
        console.log(response?.data?.data, 'rohit1e')
      }


    } catch (error) {
      console.log("Dashboard Error: ", error);
    }
  };

  useEffect(() => {
    if (visitor_monthly_list?.length >= 2) {
      const valData = visitor_monthly_list?.map((item) => item?.totalCount);
      const lastTwo = valData?.slice(-2);

      if (lastTwo?.[0] !== 0) {
        const percentageChanges =
          ((lastTwo?.[1] - lastTwo?.[0]) / lastTwo?.[0]) * 100;
        setPercentageChange(percentageChanges);
      }
    }
  }, [visitor_monthly_list]);

  const chartOptions = {
    ...lineChartOptionsTotalSpent,
    xaxis: {
      ...lineChartOptionsTotalSpent?.xaxis,
      categories: visitor_monthly_list?.map((item) => item?.month?.slice(0, 3)),
    },
  };

  const lineChartDataTotalSpent = [
    {
      name: "Total Traffic",
      data: visitor_monthly_list?.map((item) => item?.totalCount),
    },
    {
      name: "Desktop",
      data: visitor_monthly_list?.map((item) => item?.desktopCount),
    },
    {
      name: "Mobile",
      data: visitor_monthly_list?.map((item) => item?.mobileCount),
    },
  ];

  useEffect(() => {
    if (getYearlyAnalytics) {
      setDailyVisitor(getYearlyAnalytics?.visitor_count);
      setVisitor_monthly_list(getYearlyAnalytics?.visitor_monthly_list);
      setLoading(false);
    }
  }, [getYearlyAnalytics]);

  return (
    <Card extra="!p-[20px] text-center">
      <div className="flex h-full w-full flex-col justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="flex items-center justify-between px-2">
          {loading ? (
            <LoadingPlaceholder />
          ) : (
            <>
              <div className=" flex flex-col items-start justify-center">
                <p className="mt-2 text-sm font-medium text-gray-600">Total Visitors</p>
                <p className=" text-3xl font-bold text-navy-700 dark:text-white">
                  {dailyVisitor}
                </p>
              </div>
              <div className="flex flex-col items-start">

                <PercentageChangeIndicator
                  percentageChange={percentageChange}
                />
              </div>
            </>

          )}
        </div>
        <div className="h-full w-full">
          {loading ? (
            <ChartLoadingPlaceholder />
          ) : (
            <LineChart
              options={chartOptions}
              series={lineChartDataTotalSpent}
            />
          )}
        </div>
      </div>
    </Card>
  );
};

const LoadingPlaceholder = () => (
  <div className="mt-[20px] rounded-lg">
    <div className="flex animate-pulse items-center justify-center py-2">
      <div className="h-6 w-12 rounded-xl bg-gray-500"></div>
    </div>
  </div>
);

const ChartLoadingPlaceholder = () => (
  <div className="h-full w-full rounded-lg">
    <div className="flex h-full w-full animate-pulse">
      <div className="h-full w-full rounded-xl bg-gray-500"></div>
    </div>
  </div>
);

const PercentageChangeIndicator = ({ percentageChange }) => (
  <div
    className={`flex items-center text-sm ${percentageChange >= 0
      ? " font-bold text-green-500"
      : "font-bold text-red-500"
      }`}
  >
    {percentageChange >= 0 ? (
      <MdArrowDropUp className="h-5 w-5" />
    ) : (
      <MdArrowDropDown className="h-5 w-5" />
    )}
    <p>
      {percentageChange >= 0 ? "+" : "-"}
      {Math.abs(percentageChange)?.toFixed(2)}%
    </p>
  </div>
);

export default MonthlyTraffic;
