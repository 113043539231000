import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaMobileAlt, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardTen = React.forwardRef(
  ({ username, experiencename, name, user }, ref) => {
    console.log(user, "this is user");
    return (
      <div
        ref={ref}
        style={{
          backgroundImage:
            "repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0, 0, 0, 0.05) 10px, rgba(0, 0, 0, 0.05) 20px)",
        }}
        className="relative h-[250px] w-[400px]  overflow-hidden  bg-gray-50 p-6 shadow-lg"
      >
        <div className="absolute right-3   top-3 z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#0067B1" />
          <Logo color={"#0067B1"} height={35} width={35} />
        </div>

        <svg
          className="absolute left-0 top-0 h-32 w-32 opacity-30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <circle cx="50" cy="50" r="40" fill="rgba(255, 0, 0, 0.2)" />
        </svg>
        <svg
          className="absolute bottom-0 right-0 h-32 w-32 opacity-30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <polygon points="0,100 100,100 100,0" fill="rgba(0, 255, 255, 0.2)" />
        </svg>

        <div className="relative z-10 flex w-full flex-col items-center pt-6">
          <h2
            className={` mb-2 flex items-center justify-center break-words capitalize break-all ${
              name.length > 20 ? "text-2xl" : "text-3xl"
            }  font-bold `}
          >
                {name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
          </h2>

          <QRCodeSVG
            value={`https://sandboxar.immarsify.com/${username}/${experiencename}`}
            size={120}
            bgColor="transparent"
            fgColor="#0067B1"
            includeMargin={false}
          />
        </div>
      </div>
    );
  }
);

export default CardTen;




export const CardTenBackSide = React.forwardRef(
  ({ username, experiencename, name, user, cardData }, ref) => {
    console.log(user, "this is user");
    return (
      <div
        ref={ref}
        style={{
          backgroundImage:
            "repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0, 0, 0, 0.05) 10px, rgba(0, 0, 0, 0.05) 20px)",
        }}
        className="relative h-[250px] w-[400px]  overflow-hidden  bg-gray-50  shadow-lg"
      >
        <div className="absolute right-3   top-3 z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#0067B1" />
          <Logo color={"#0067B1"} height={35} width={35} />
        </div>

        <svg
          className="absolute left-0 top-0 h-32 w-32 opacity-30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <circle cx="50" cy="50" r="40" fill="rgba(255, 0, 0, 0.2)" />
        </svg>
        <svg
          className="absolute bottom-0 right-0 h-32 w-32 opacity-30"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
        >
          <polygon points="0,100 100,100 100,0" fill="rgba(0, 255, 255, 0.2)" />
        </svg>

        <div className="relative  p-4  text-sm break-all text-gray-900">
        <div className="mt-8">

    
<h2 className={` font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`} }`}>
    {cardData?.companyName?.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')}
  </h2>

<div className="flex justify-center ">

<div className="flex flex-col gap-2 mt-4">


{cardData?.address && <p className="text-sm  flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" />
{cardData?.address}</p>}

{cardData?.email && <p className="text-sm  flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" />
{cardData?.email}</p>}

{cardData?.Phone && <p className="text-sm  flex gap-2"><FaMobileAlt className="min-w-5 min-h-5"/>
{cardData?.Phone}</p>}

</div>


</div>







</div>
        </div>
      </div>
    );
  }
);