import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useReducer,
  useRef,
} from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";
import { CSVLink } from "react-csv";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputField from "components/fields/InputField";
import { MdOutlineClear } from "react-icons/md";
import { useSelector } from "react-redux";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_AUTH } from "../../../../../redux/reducerSlice/authSlice";
import { SET_HISTORY_DATA } from "../../../../../redux/reducerSlice/arSlice";
import Pagination from "@mui/material/Pagination";
import VCard from "vcard-creator";

import * as Yup from "yup";
import { GrPowerReset } from "react-icons/gr";
import ActionMenu, { ActionBar, FilterDateMenu } from "./ActionMenu";
import { Chip, MenuItem, Tooltip } from "@mui/material";
import LabelManager from "./modal/AddLabel";
import InfoDrawer from "./drawer/Drawer";
import { IoIosOpen, IoMdClose } from "react-icons/io";
import { IconButton } from "@mui/material";
import {
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaShareAlt,
  FaSave,
} from "react-icons/fa";
import { SET_LABLES } from "../../../../../redux/reducerSlice/enquiry";
import Userlist from "../../userlist";
import { debounce } from "lodash";
import SendToWhatsapp from "./checkBox";
import { WhatsappModals } from "./modal/WhatsappModal";
import { FiCalendar } from "react-icons/fi";
import moment from "moment";
import { CiLock } from "react-icons/ci";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};
const defaultLables = {
  importance: [
    {
      _id: 1,
      name: "Lead",
      type: "importance",
      color: "#FF0000",
      default: true,
    },
  ],
  status: [
    { _id: 2, name: "Check", type: "status", color: "#00FF00", default: true },
  ],
  source: [
    {
      _id: 3,
      name: "Facebook",
      type: "source",
      color: "#0000FF",
      default: true,
    },
  ],
};
const LeadTable = () => {
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userData = useSelector((state) => state?.auth?.auth?.user);
  const [search, setSearch] = useState("");
  const [user, setUserId] = React.useState("");

  const [openLabel, setOpenLabel] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);
  const [selectedEnquriy, setSelectedEnquiry] = React.useState(null);

  const [openWhatsappModal, setOpenWhatsappModal] = useState(false);
  const handleOpenList = () => setOpenList(true);
  const handleCloseList = () => setOpenList(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [tableData, setTablesData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setSelectedValue] = useState(5);
  const staticOptions = useSelector((state) => state?.enquiry?.lables);
  const [timeoutId, setTimeoutId] = useState(null);
  const userToken = useSelector((state) => state.auth.auth.token);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [isAppliedFilter, setIsAppliedFilter] = useState(false);

  const dispatch = useDispatch();
  const userList = async () => {
    fetchInquiryLabels();
    setLoading(true);
    try {
      // console.log(startDate, endDate, "this is date")
      // const startDateParam = startDate ? startDate?.toISOString()?.split('T')[0] : '';
      // const endDateParam = endDate ? endDate?.toISOString()?.split('T')[0] : '';
      const response = await instance.get(
        `/api/inquiries/get_all_inquiry?page=${currentPage}&limit=${itemsPerPage}&search=${searchParam}&startDate=${startDateFilter}&endDate=${endDateFilter}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        setTablesData(response?.data?.data?.data);
        setLoading(false);
        setTotalPages(response?.data?.data?.totalPages);
      } else {
        setLoading(false);
        setTotalPages(1);
        setTablesData([]);
      }
    } catch (error) {
      setTotalPages(1);
      setTablesData([]);
      console.log("error", error);
      // toast.error(error && error?.response?.message);
      setLoading(false);
    }
  };

  const allEnquiryData = async () => {
    try {
      // console.log(startDate, endDate, "this is date")
      // const startDateParam = startDate ? startDate?.toISOString()?.split('T')[0] : '';
      // const endDateParam = endDate ? endDate?.toISOString()?.split('T')[0] : '';
      const response = await instance.get(
        `/api/inquiries/get_all_inquiry_csv?search=${searchParam}&startDate=${startDateFilter}&endDate=${endDateFilter}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        setAllData(response?.data?.data?.data);
      } else {
        setTablesData([]);
      }
    } catch (error) {
      setTablesData([]);
      console.log("error", error);
      // toast.error(error && error?.response?.message);
    }
  };

  useEffect(() => {
    allEnquiryData();
  }, [startDateFilter, endDateFilter]);

  const fetchInquiryLabels = async () => {
    try {
      const response = await instance.get("api/inquiries/get_inquiry_labels", {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response?.data?.data) {
        const data = response?.data?.data;

        const importanceLables = data.filter(
          (data) => data.type === "importance"
        );
        const statusLables = data.filter((data) => data.type === "status");
        const sourceLables = data.filter((data) => data.type === "source");

        const updatedLables = {
          status: [...defaultLables.status, ...statusLables],
          importance: [...defaultLables.importance, ...importanceLables],
          source: [...defaultLables.source, ...sourceLables],
        };
        console.log(updatedLables, "got data from api");

        dispatch(SET_LABLES(updatedLables));
      }
      console.log(response);
    } catch (error) {
      console.error("Failed to fetch inquiry labels:", error);
    }
  };

  useEffect(() => {
    userList();
  }, [itemsPerPage, currentPage, searchParam, startDateFilter, endDateFilter]);

  const handleChanges = (event, value) => {
    setCurrentPage(value);
  };
  // useEffect(() => {
  // userList();

  // console.log("calling api fro search ")
  // }, [searchParam]);

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const fallbackShare = (enquiry) => {
    // Create VCF content using VCard library for consistency
    const myVCard = new VCard();
    myVCard
      .addName(enquiry.viewerName)
      .addPhoneNumber(enquiry.phone, "MOBILE")
      .addEmail(enquiry.email);

    const vcfContent = myVCard.toString();
    const blob = new Blob([vcfContent], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${enquiry.viewerName}.vcf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    toast.error("VCF file downloaded. You can now share this file.");

    // Optionally, also copy to clipboard
    navigator.clipboard
      .writeText(
        `Name: ${enquiry.viewerName}\nPhone: ${enquiry.phone}\nEmail: ${enquiry.email}`
      )
      .then(() => console.log("Text copied to clipboard"))
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  const handlePhone = (phone) => {
    window.location.href = `tel:${phone}`;
  };
  const handleEmail = (enquiry) => {
    if (!enquiry?.email) {
      return toast.error("Email not available!");
    }
    const subject = `Immarsify Enquiry`;
    const body = `Dear ${enquiry?.viewerName},
\nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
\nWe look forward to staying in touch!
\nBest regards,
IMMARSIFY`;
    window.location.href = `mailto:${enquiry?.email}?subject=${subject}&body=${body}`;
  };

  const handleWhatsApp = (enquiry) => {
    // setSelectedEnquiry(enquiry)
    // setOpenWhatsappModal(true)
    // const message = encodeURIComponent(
    //   `Hello ${name}, I'm contacting you regarding your enquiry.`
    // );
    // window.open(`https://wa.me/${phone}?text=${message}`, "_blank");
    const whatsappMSG = `Dear ${enquiry?.viewerName},
\nThank you for attending our event! We truly appreciate your time and support. If you have any questions or need further information, feel free to reach out.
\nWe look forward to staying in touch!
\nBest regards,
IMMARSIFY`;
    const whatsappMessage = userData?.defaultMessage?.Whatsapp
      ? encodeURIComponent(userData?.defaultMessage?.Whatsapp)
      : encodeURIComponent(whatsappMSG);
    const url = `https://wa.me/${enquiry?.phone}?text=${whatsappMessage}`;
    window.open(url, "_self");
  };

  const handleSaveContact = (enquiry) => {
    // Create a new vCard
    const myVCard = new VCard();

    // Add contact information
    myVCard
      .addName(enquiry.viewerName)
      .addPhoneNumber(enquiry.phone, "MOBILE")
      .addEmail(enquiry.email)
      .addCompany(enquiry.company || "")
      .addAddress(
        "",
        "",
        enquiry.address,
        enquiry.city,
        enquiry.state,
        enquiry.pincode,
        "WORK"
      )
      .addURL(enquiry.website || "");

    // Generate vCard as string
    const vCardString = myVCard.toString();

    // Create a Blob with the vCard data
    const blob = new Blob([vCardString], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element and trigger download
    const tempLink = document.createElement("a");
    tempLink.href = url;
    tempLink.setAttribute("download", `${enquiry.viewerName}.vcf`);
    tempLink.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  };

  const handleShare = async (enquiry) => {
    const shareData = {
      title: `Contact: ${enquiry.viewerName}`,
      text: `Name: ${enquiry.viewerName}\nPhone: ${enquiry.phone}\nEmail: ${enquiry.email}`,
      // url: window.location.hre[][;-0lop[]]
    };

    // First, check if the browser supports sharing
    if (navigator.share) {
      try {
        // Ask for user confirmation before attempting to share
        const userConfirmed = await showConfirmDialog(
          "Would you like to share this contact?"
        );

        if (userConfirmed) {
          await navigator.share(shareData);
          console.log("Successfully shared");
        } else {
          console.log("User cancelled sharing");
        }
      } catch (error) {
        console.error("Error sharing:", error);
        if (error.name === "AbortError") {
          console.log("Sharing was aborted");
        } else if (error.name === "NotAllowedError") {
          console.log("Permission to share was denied");
        }
        // Fallback to alternative sharing method
        fallbackShare(enquiry);
      }
    } else {
      console.log("Web Share API not supported");
      fallbackShare(enquiry);
    }
  };

  // Helper function to show a confirmation dialog
  const showConfirmDialog = (message) => {
    return new Promise((resolve) => {
      // You can replace this with a custom modal if you prefer
      const result = window.confirm(message);
      resolve(result);
    });
  };

  const handleFilter = () => {
    setIsAppliedFilter(true);
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };
  const handleClear = () => {
    setIsAppliedFilter(false);
    setStartDateFilter("");
    setEndDateFilter("");
    setEndDate("");
    setStartDate("");
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className=" flex w-full flex-col justify-center gap-2 py-4">
        <div className="relative  flex  items-center justify-between">
          <div className="text-md font-bold text-navy-700 ">Enquiry list</div>
          <div className="sm:hidden">
            <ActionBar
              handleOpen={handleOpen}
              setOpenLabel={setOpenLabel}
            ></ActionBar>
          </div>
          <div className="hidden items-center justify-between gap-4 sm:flex">
            <div>
              {(userData?.allowedFeature &&
                userData?.allowedFeature?.ocr?.allowed) ||
              userData?.type !== "user" ? (
                <a
                  className="cursor-pointer rounded-md bg-brand-main px-4 py-2 text-sm font-semibold text-white shadow-lg hover:bg-blue-600"
                  href="https://scan.immarsify.com/"
                  target="_blank"
                >
                  Create OCR
                </a>
              ) : (
                <Tooltip
                  title="To unlock this feature, please upgrade and purchase the OCR add-on."
                  placement="top"
                >
                  <button className="flex cursor-pointer items-center gap-1 rounded-md bg-gray-600 px-4 py-2 text-sm font-semibold text-white shadow-lg">
                    <CiLock size={15} />
                    <span>Create OCR</span>
                  </button>
                </Tooltip>
              )}
            </div>
            <button
              className="rounded-md bg-brand-main px-4 py-2 text-center text-sm font-semibold text-white shadow-lg  hover:bg-blue-600"
              onClick={() => setOpenLabel(true)}
            >
              Manage Labels
            </button>
            <button
              className="rounded-md bg-brand-main px-4 py-2 text-center text-sm font-semibold text-white shadow-lg  hover:bg-blue-600"
              onClick={handleOpen}
            >
              Add enquiry
            </button>
          </div>
        </div>
        <div className="relative flex w-full flex-col items-center gap-4  py-1 lg:flex-row lg:gap-0">
          <input
            className="w-full rounded-lg border-2 p-2 px-4 text-sm outline-none lg:w-[500px]"
            type="text"
            value={search}
            onChange={handleSearch}
            placeholder="Search"
          />
          {/* <div className="flex flex-col lg:gap-0 gap-4 lg:flex-row">

<div className="ml-2 flex items-center">
      <label htmlFor="start-date" className="mr-2 text-sm">Start Date:</label>
      <input
        id="start-date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="rounded-lg border-2 p-2 text-sm outline-none sm:w-full"
      />
    </div>
    <div className="ml-2 flex items-center">
      <label htmlFor="end-date" className="mr-2 text-sm">End Date:</label>
      <input
        id="end-date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="rounded-lg border-2 p-2 text-sm outline-none sm:w-full"
      />
    </div>
    <div className="flex flex-col  items-center justify-center sm:flex-row sm:gap-0 gap-2">
    <button
      className="ml-2 w-full sm:w-24 rounded-md bg-brand-main px-2 py-2 text-sm text-white"
      onClick={()=>{setStartDateFilter(""); setEndDateFilter(""); setEndDate(""); setStartDate("")}}
    > Clear
    </button>
   
    <button
      className="ml-2 w-full sm:w-24 rounded-md bg-brand-main px-2 py-2 text-sm text-white"
      onClick={handleFilter}
    >  Apply Filter
    </button>

    <CSVLink
      data={allData}
      filename={"enquiries.csv"}
      className="ml-2 flex w-full text-center justify-center sm:w-28 rounded-md bg-green-600 px-2 py-2 text-sm text-white"
    >
      Export to CSV
    </CSVLink>
      
</div>
    </div> */}

          <div className="flex w-full flex-col items-center justify-end gap-2 xsm:flex-row sm:gap-0 ">
            <div className="">
              {isAppliedFilter ? (
                <div className="flex items-center space-x-2 rounded-md border p-2 py-3 text-sm">
                  <FiCalendar size={20} className="text-blue-600" />
                  <span className="font-medium text-blue-800">
                    {moment(startDate).format("DD/MM/YYYY")} -{" "}
                    {moment(endDate).format("DD/MM/YYYY")}
                  </span>

                  <span onClick={handleClear} className="cursor-pointer">
                    <IoMdClose size={20} className="text-red-500" />
                  </span>
                </div>
              ) : (
                <FilterDateMenu
                  setStartDateFilter={setStartDateFilter}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  setEndDateFilter={setEndDateFilter}
                  handleFilter={handleFilter}
                  startDate={startDate}
                  endDate={endDate}
                  setIsAppliedFilter={setIsAppliedFilter}
                />
              )}
            </div>
            <div>
              <CSVLink
                data={allData}
                filename={"enquiries.csv"}
                className="flex w-full justify-center rounded-md bg-green-600 px-2 py-2 text-center text-sm font-semibold text-white xsm:mx-2 sm:w-28"
              >
                Export to CSV
              </CSVLink>
            </div>
            <div className="flex items-center">
              <p className="hidden text-sm md:block">List per page:</p>
              <select
                class="ml-2 block h-8 w-12 rounded-md  border bg-gray-200  shadow-sm outline-none "
                placeholder="List"
                value={itemsPerPage}
                onChange={handleChange}
              >
                {[...Array(11).keys()].map((_, index) => (
                  <>
                    {index > 0 && (
                      <option key={index} value={index * 5}>
                        {index * 5}
                      </option>
                    )}
                  </>
                ))}
              </select>
            </div>
          </div>
        </div>
      </header>

      <div className="  ">
        <div className="overflow-x-auto">

        
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Contact
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Phone
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Email
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Label
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                From
              </th>

              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200 bg-white">
            {loading ? (
              [...Array(itemsPerPage)].map((_, index) => (
                <tr key={index}>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>

                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4">
                    <div role="status" className="animate-pulse">
                      <div className="h-4 rounded-full bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </td>
                </tr>
              ))
            ) : tableData.length > 0 ? (
              tableData.map((item) => {
                if (item?.archive) {
                  return null;
                }
                let statusData;
                let importanceData;

                if (item?.status) {
                  statusData = staticOptions?.status?.find(
                    (lab) =>
                      lab?.name.toLowerCase() === item?.status?.toLowerCase()
                  );
                }

                if (item?.importance) {
                  importanceData = staticOptions?.importance?.find(
                    (lab) =>
                      lab?.name.toLowerCase() ===
                      item?.importance?.toLowerCase()
                  );
                }

                // console.log("Importance data: ", importanceData, statusData );

                return (
                  <tr key={item._id}>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div className="flex items-center space-x-4">
                        <div className="text-sm font-medium text-gray-900">
                          {item?.viewerName}
                        </div>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div className="flex space-x-2">
                        <IconButton
                          onClick={() => handlePhone(item?.phone)}
                          aria-label="Call"
                          sx={{
                            color: "#4CAF50", // Green for Call
                            "&:hover": {
                              color: "#388E3C", // Darker green on hover
                            },
                          }}
                        >
                          <FaPhone />
                        </IconButton>
                        <IconButton
                          onClick={() => handleEmail(item)}
                          aria-label="Email"
                          sx={{
                            color: "#0077B6", // Blue for Email
                            "&:hover": {
                              color: "#005A9E", // Darker blue on hover
                            },
                          }}
                        >
                          <FaEnvelope />
                        </IconButton>
                        <IconButton
                          onClick={() => handleWhatsApp(item)}
                          aria-label="WhatsApp"
                          sx={{
                            color: "#25D366", // Green for WhatsApp
                            "&:hover": {
                              color: "#1AA051", // Darker green on hover
                            },
                          }}
                        >
                          <FaWhatsapp />
                        </IconButton>
                        <IconButton
                          onClick={() => handleShare(item)}
                          aria-label="Share"
                          sx={{
                            color: "#6c757d", // Gray for Share
                            "&:hover": {
                              color: "#495057", // Darker gray on hover
                            },
                          }}
                        >
                          <FaShareAlt />
                        </IconButton>
                        <IconButton
                          onClick={() => handleSaveContact(item)}
                          aria-label="Save Contact"
                          sx={{
                            color: "#FF9800", // Orange for Save Contact
                            "&:hover": {
                              color: "#F57C00", // Darker orange on hover
                            },
                          }}
                        >
                          <FaSave />
                        </IconButton>
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div className="text-sm text-gray-900">
                        <Chip
                          size="small"
                          label={item?.status || "No Status"}
                          sx={{
                            backgroundColor: statusData?.color || "#cccccc",
                            color: "white",
                          }}
                        />
                      </div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div className="text-sm text-gray-900">{item?.phone}</div>
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div className="text-sm text-gray-900">{item?.email}</div>
                    </td>
                    <td
                      className="mx-auto whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <Chip
                        size="small"
                        label={item?.importance || "No Label"}
                        sx={{
                          backgroundColor: importanceData?.color || "#cccccc",
                          color: "white",
                        }}
                      />
                      {/* <Dropdown labels={labels} /> */}
                    </td>

                    <td
                      className="whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <div className="text-sm text-gray-900">
                        {item?.generatedFrom}
                      </div>
                    </td>

                    <td
                      className="mx-auto whitespace-nowrap px-6 py-4"
                      style={{ width: "25%" }}
                    >
                      <ActionMenu
                        fetchEnquiry={userList}
                        leadInfo={item}
                        setCurrentPage={setCurrentPage}
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="8" className="py-4 text-center">
                  <p className="bg-blue-100 px-10 py-5 text-sm text-blue-600">
                    Data Not Found!
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        <LabelManager openLabel={openLabel} setOpenLabel={setOpenLabel} />
        <WhatsappModals
          number={"023480238432"}
          onClose={() => setOpenWhatsappModal(false)}
          open={openWhatsappModal}
          selectedEnquriy={selectedEnquriy}
        />
        <InfoDrawer
          drawerType={"create"}
          openDrawer={open}
          setOpenDrawer={setOpen}
          fetchEnquiry={userList}
        />
        <div className="my-5 flex w-full items-center justify-end  text-sm">
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChanges}
          />
        </div>
      </div>

      {/* <CreateAccount open={open} handleClose={handleClose} /> */}
    </Card>
  );
};

export default LeadTable;
