import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  Modal,
  Pagination,
  Skeleton,
  TextareaAutosize,
} from "@mui/material";
import InputField from "components/fields/InputField";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { useNavigate } from "react-router-dom";
import Dropdown from "components/dropdown";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineClear } from "react-icons/md";
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 180,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};
const style = {
  display: "flex",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: 24,
  transform: "translate(-50%, -50%)",
  borderRadius: "0.5rem",
  outline: "none",
};
const CardList = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const authToken = useSelector((state) => state?.auth?.auth?.token);
  const userid = useSelector((state) => state.auth.auth.user._id);

  const [cardName, setCardName] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [timeoutId, setTimeoutId] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [cardCreating, setCardCreating] = useState(false);
  const [cardNameError, setCardNameError] = useState(null);
  const [cardData, setCardData] = useState([]);
  const [search, setSearch] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const createCardHandler = async () => {
    navigate("/admin/edit-card");
    // try {
    //   if (!cardName) {
    //     setCardNameError("Card name is required");
    //     return;
    //   }
    //   setCardNameError(null);
    //   setCardCreating(true);
    //   const response = await instance.post(
    //     `/api/customCard/create_custom_card`,
    //     { name: cardName ? cardName : "custom card" },
    //     {
    //       headers: {
    //         authorization: `Bearer ${authToken}`,
    //       },
    //     }
    //   );
    //   console.log(response.status, "this is status");
    //   if (response.status === 201) {
    //     toast.success("Card saved ");
    //     navigate("/admin/edit-card");
    //   }
    // } catch (error) {
    //   toast.error(error?.response?.data?.message ?? error.message);
    // } finally {
    //   setCardCreating(false);
    // }
  };

  const getCardData = async () => {
    setLoading(true);

    try {
      const response = await instance.get(
        `api/customCard/custom_card_list?page=${currentPage}&limit=${itemsPerPage}&searchText=${searchParam}`,

        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status === 200) {
        // dispatch(SET_HISTORY_DATA(response?.data?.data));
        // dispatch(SET_HISTORY_TOTAL_PAGE(response?.data?.totalPages));
        const totalPages = Math.ceil(response?.data?.total / itemsPerPage);
        setTotalPages(totalPages);
        setCardData(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message ?? error.message);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    setSearch(inputValue);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!inputValue || inputValue.trim() === "") {
        setSearchParam("");
      } else {
        setSearchParam(inputValue.trim());
      }
    }, 1000);

    setTimeoutId(newTimeoutId);
  };
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    getCardData();
  }, [currentPage, searchParam, itemsPerPage]);
  return (
    <div className=" relative mt-4 flex h-full w-full flex-col  gap-2 px-4 ">
      <div className="top-24 z-10 -mt-2  flex w-full flex-wrap items-center justify-between    p-2     ">
        <input
          className="h-10 w-full rounded-lg border  border-gray-700  bg-opacity-0 p-4 text-sm md:w-[30%] "
          placeholder="Search the Card Name"
          value={search}
          onChange={handleSearch}
        />

        <div>
          <div className="  flex w-full items-center justify-end gap-1 rounded-sm px-4 py-2 ">
            <button
              onClick={() => navigate("/admin/edit-card")}
              className="mr-4 rounded-md bg-brand-main px-4 py-2 text-center text-sm font-semibold text-white  shadow-lg hover:bg-blue-600"
            >
              Create Card
            </button>
            <p className="text-sm"> Card per page</p>
            <select
              class="ml-2 block h-6 w-12  rounded-sm  border bg-gray-200  text-sm outline-none"
              placeholder="List"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(e.target.value);
              }}
            >
              {[...Array(12).keys()].map((_, index) => (
                <>
                  {index > 0 && (
                    <option key={index} value={index * 5}>
                      {index * 5}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="grid grid-cols-1 justify-center gap-4 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 ">
          {[...Array(6)].map((element) => (
            <Skeleton variant="rectangular" width={300} height={150} />
          ))}
        </div>
      ) : cardData?.length > 0 ? (
        <div className="  flex flex-wrap gap-4 px-2  ">
          {cardData?.length > 0 &&
            cardData?.map((item) => {
              return (
                <div
                  className="group relative mx-auto flex w-[100%]  max-w-[300px] cursor-pointer flex-col rounded-lg bg-white pb-1 shadow-lg  md:mx-0  "
                  key={item.id}
                >
                  <div className="flex items-center justify-center overflow-hidden rounded-md px-6  ">
                    <img
                      src={
                        item?.cardPreviewUrl
                          ? item.cardPreviewUrl
                          : "https://liftlearning.com/wp-content/uploads/2020/09/default-image.png"
                      }
                      alt="hello"
                      loading="lazy"
                      className="mt-2 h-[150px]  rounded-md object-cover"
                    />
                  </div>

                  <div className="mt-2 flex items-center justify-between px-7 ">
                    <p className="text-left text-gray-900 ">{item?.name}</p>
                    <p
                      className={`text-xs font-semibold ${
                        item.status === "published"
                          ? "text-green-600"
                          : item.status === "in-progress"
                          ? "text-yellow-400"
                          : "text-green-600"
                      }`}
                    >
                      {item?.status?.toUpperCase()}
                    </p>
                  </div>
                  <div className="absolute inset-0 top-2 flex h-full w-full items-center justify-center  opacity-100 duration-200 ">
                    <button
                      className="open-preview rounded-lg border   border-gray-200 bg-gray-100 px-2 py-2  text-center text-sm text-[#222] hover:border-none hover:bg-[#3d2fa9]  hover:text-white"
                      onClick={() => {
                        // dispatch(SET_UPDATE_STATE_AR(item));
                        // dispatch(setViewCreated(""));
                        navigate(`/admin/edit-card?cardid=${item?._id}`);
                      }}
                    >
                      Edit
                    </button>
                  </div>

                  <div className="absolute right-0 top-2 ">
                    <div className="hs-dropdown relative inline-flex">
                      <Dropdown
                        button={
                          <button
                            id="hs-dropdown-custom-icon-trigger"
                            type="button"
                            className="hs-dropdown-toggle dark:bg-slate-900 flex h-9 w-9 items-center justify-center rounded-lg border border-gray-200 bg-white text-sm font-semibold text-gray-800  shadow-xl hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                          >
                            <BsThreeDotsVertical size={18} />
                          </button>
                        }
                        children={
                          <div className="flex w-[60px] items-center justify-center rounded-lg bg-[#FBF9F1] shadow-xl">
                            <div className="flex flex-col gap-3 px-2 py-3">
                              <p
                                className="cursor-pointer text-sm text-gray-800 hover:text-[#76453B] "
                                onClick={() => setOpenDelete(item._id)}
                              >
                                Delete
                              </p>
                            </div>
                          </div>
                        }
                        classNames={"top-8 right-5  w-full"}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <h2 className="text-center text-2xl">No card found </h2>
      )}
      {cardData && cardData?.length > 0 && (
        <div className="mt-4 flex justify-center">
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={handleChange}
          />
        </div>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Create Card</DialogTitle>
        <DialogContent>
          <div className="  flex w-[500px]   flex-col justify-center  outline-none  ">
            <div className="flex w-full items-center justify-center gap-2 ">
              <div className="w-full">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Card Name*"
                  placeholder="Enter Card Name"
                  id="experienceName"
                  type="text"
                  onChange={(event) => {
                    setCardName(event.target.value);
                  }}
                  value={cardName}
                />
                {cardNameError && (
                  <span className="text-xs text-red-600">{cardNameError}</span>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleCloseDialog}
            className=" rounded-md bg-red-600 px-4 py-2 text-center text-sm font-semibold text-white  shadow-lg hover:bg-red-700"
          >
            Cancel
          </button>
          <button
            onClick={createCardHandler}
            className=" rounded-md bg-brand-main px-4 py-2 text-center text-sm font-semibold text-white  shadow-lg hover:bg-brand-700"
            disabled={cardCreating}
          >
            {cardCreating ? "Creating" : "Create"}
          </button>
        </DialogActions>
      </Dialog>

      <Delete
        _id={openDelete}
        handleClose={() => setOpenDelete(null)}
        getCardData={getCardData}
      />
    </div>
  );
};

export default CardList;

const Delete = ({ _id, handleClose, getCardData }) => {
  console.log(_id);
  const [loading, setLoading] = useState(false);
  const userToken = useSelector((state) => state.auth.auth.token);

  const handleDelete = async (_id) => {
    setLoading(true);
    console.log(_id);
    try {
      const response = await instance.delete(
        `api/customCard/custom_card_delete/${_id}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.status === 200) {
        handleClose();
        setLoading(false);
        getCardData();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={_id}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleDelete} className="rounded-lg">
        <div className="flex items-center justify-between rounded-t-lg  bg-gray-200 p-2">
          <p className="text-lg font-bold ">Immarsify</p>
          <span onClick={handleClose} className="cursor-pointer">
            <MdOutlineClear size={20} />
          </span>
        </div>
        <p className="text-md p-4">Are you sure, you want to delete ?</p>

        <div className="absolute bottom-2 right-2 mt-2 flex items-center justify-center gap-2 p-2 ">
          <button
            className="rounded-lg border bg-[#3d2fa9] px-4 py-2 text-white "
            onClick={() => handleDelete(_id)}
          >
            yes
          </button>
          <button
            className="rounded-lg border border-gray-500 px-4 py-2 text-gray-900 "
            onClick={handleClose}
          >
            No
          </button>
        </div>
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <div className="flex flex-col items-center justify-center gap-2">
              <h1 className="font-bold">Delete Card</h1>
              <CircularProgress color="inherit" />
            </div>
          </Backdrop>
        </div>
      </Box>
    </Modal>
  );
};
