import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CardEditor from "../CardEditor";
import { useState } from "react";
import { useRef } from "react";
import { toPng } from "html-to-image";
import {
  AiFillBackward,
  AiFillDelete,
  AiFillStepBackward,
  AiOutlineArrowLeft,
  AiOutlineDelete,
} from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { instance } from "common/Instance";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import TextEditorToolbar from "./components/header/text/TextHeader";
import TextEditorUI from "./components/drawer/CardDrawer";
import ImageTab from "./components/drawer/Tab";
import ZoomControl from "./components/Zoom";
import { element } from "three/examples/jsm/nodes/Nodes";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Input, TextareaAutosize } from "@mui/material";
import ContainerPreview from "./components/preview/Preview";
import PrintWrapper from "./components/Export";
import { IoMdClose } from "react-icons/io";
import OrientationDialog from "./components/SizeDialog";
import { IoSaveOutline } from "react-icons/io5";
import { SET_CURRENT_CARD_ID, UPDATE_BG_IMAGE, UPDATE_BG_IMAGE_FOR_BACK, UPDATE_ELEMENTS, UPDATE_ELEMENTS_FOR_BACK } from "../../../../redux/reducerSlice/customCardSlice";
import html2canvas from "html2canvas";
import { TbClipboardData } from "react-icons/tb";

const drawerWidth = 100;

const CustomCard = () => {
  const navigate = useNavigate();
  const userToken = useSelector((state) => state?.auth?.auth?.token);
  const elementsFront = useSelector((state) => state?.customCard?.currentCard?.elements);
  // const cardId = useSelector((state) => state?.customCard?.currentCard?._id);
  const search = window.location.search;


  const params = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('cardid');
  const [cardId, setCardId] = useState(null)
  const [elements, setElements] = useState([]);
  const [bgColor, setBgColor] = useState("#ffffff");
  const dispatch = useDispatch()
  const [containerSize, setContainerSize] = useState({
    width: 400,
    height: 250,
  });
  const [isBlocking, setIsBlocking] = useState(false);

  const [isEditable, setIsEditable] = useState(false)
  const [bgImage, setBgImage] = useState(null);
  const [bgImageData, setBgImageData] = useState(null);
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const containerRefForBack = useRef(null);
  const [elementsForBack, setElementsForBack] = useState([]);
  const [bgColorForBack, setBgColorForBack] = useState("#ffffff");
  const [bgImageForBack, setBgImageForBack] = useState(null);
  const [isSavingCard, setIsSavingCard] = useState(false)
  const [bgImageForBackData, setBgImageForBackData] = useState(null);
  const mainContinerRef = useRef(null);
  const [openOrientationDiload, setOpenOrientaionDilaog] = useState(false)
  const mainContinerRefForBack = useRef(null);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [selectedArExperience, setSelectedArExperience] = useState(null);
  const [isBgImageSelected, setBgImageSelected] = useState(false);
  // const [cardId, setCardId] = useState(null)
  const [openDialog, setOpenDialog] = useState(false);
  const [isNavigatingAway, setIsNavigatingAway] = useState(false);
  const previewFrontRef = useRef(null);
  const previewBackRef = useRef(null);
  const [cardName, setCardName] = useState("")

  const [editorBox, setEditorBox] = useState({
    text: false,
    image: false,
    qr: false,

  })

  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState("front");
  const [allCardNames, setAllCardNames] = useState([]);

  const authToken = useSelector((state) => state?.auth?.auth?.token);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedElementIndex(null);
    if (newValue === "front") {
      setElements(elements);
    } else {
      setElementsForBack(elementsForBack);
    }
  };
  const handleTextColorChange = (e) => {
    if (tabValue === "front") {
      const newColor = e.target.value;
      const updatedElements = elements.map((el, index) =>
        index === selectedElementIndex ? { ...el, color: newColor } : el
      );

      dispatch(UPDATE_ELEMENTS(updatedElements))
      setElements(updatedElements);
    } else {
      const newColor = e.target.value;
      const updatedElements = elementsForBack.map((el, index) =>
        index === selectedElementIndex ? { ...el, color: newColor } : el
      );
      dispatch(UPDATE_ELEMENTS_FOR_BACK(updatedElements))
      setElementsForBack(updatedElements);
    }
  };


  const handleTextColorChangeById = (e, id) => {

    if (tabValue === "front") {
      const selectedElementIndex = elements.findIndex((item) => item.id === id);
      const newColor = e.target.value;
      const updatedElements = elements.map((el, index) =>
        index === selectedElementIndex ? { ...el, color: newColor } : el
      );
      dispatch(UPDATE_ELEMENTS(updatedElements))
      setElements(updatedElements);
    } else {
      const selectedElementIndex = elementsForBack.findIndex((item) => item.id === id);
      const newColor = e.target.value;
      const updatedElements = elementsForBack.map((el, index) =>
        index === selectedElementIndex ? { ...el, color: newColor } : el
      );
      dispatch(UPDATE_ELEMENTS_FOR_BACK(updatedElements))
      setElementsForBack(updatedElements);
    }
  };

  const handleFontSizeChange = (e) => {
    if (tabValue === "front") {
      const newFontSize = parseInt(e.target.value, 10);
      const updatedElements = elements.map((el, index) =>
        index === selectedElementIndex ? { ...el, fontSize: newFontSize } : el
      );
      dispatch(UPDATE_ELEMENTS(updatedElements))
      setElements(updatedElements);
    } else {
      const newFontSize = parseInt(e.target.value, 10);
      const updatedElements = elementsForBack.map((el, index) =>
        index === selectedElementIndex ? { ...el, fontSize: newFontSize } : el
      );
      dispatch(UPDATE_ELEMENTS_FOR_BACK(updatedElements))
      setElementsForBack(updatedElements);
    }
  };

  const handleTextChange = (e) => {
    if (tabValue === "front") {
      const text = e.target.value;
      const updatedElements = elements.map((el, index) =>
        index === selectedElementIndex ? { ...el, content: text } : el
      );
      dispatch(UPDATE_ELEMENTS(updatedElements))
      setElements(updatedElements);
    } else {
      const text = e.target.value;
      const updatedElements = elementsForBack.map((el, index) =>
        index === selectedElementIndex ? { ...el, content: text } : el
      );
      dispatch(UPDATE_ELEMENTS_FOR_BACK(updatedElements))
      setElementsForBack(updatedElements);
    }
  };
  const handleFontWeightChange = (e) => {
    if (tabValue === "front") {
      const fontWeight = e.target.value;
      const updatedElements = elements.map((el, index) =>
        index === selectedElementIndex ? { ...el, fontWeight: fontWeight } : el
      );
      dispatch(UPDATE_ELEMENTS(updatedElements))
      setElements(updatedElements);
    } else {
      const fontWeight = e.target.value;
      const updatedElements = elementsForBack.map((el, index) =>
        index === selectedElementIndex ? { ...el, fontWeight: fontWeight } : el
      );
      dispatch(UPDATE_ELEMENTS_FOR_BACK(updatedElements))
      setElementsForBack(updatedElements);
    }
  };
  const handleBgColorChange = (e) => {
    // if (tabValue === "front") {
    setBgColor(e.target.value);
    // } else {
    //   setBgColorForBack(e.target.value);
    // }
  };
  const handleBgImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file
    if (!file.type.startsWith('image/')) {
      toast.error('Please upload an image file');
      return;
    }

    // Create URL for preview in UI
    const imageUrl = URL.createObjectURL(file);

    // Store the actual file for FormData
    if (tabValue === "front") {
      setBgImage(imageUrl);
      dispatch(UPDATE_BG_IMAGE({ previewUrl: imageUrl, file }))
      // For UI preview
      setBgImageData(file);         // Store the actual file
    } else {
      dispatch(UPDATE_BG_IMAGE_FOR_BACK({ previewUrl: imageUrl, file }))
      setBgImageForBack(imageUrl);  // For UI preview
      setBgImageForBackData(file);  // Store the actual file
    }
  };
  const handleDeleteElement = () => {
    if (selectedElementIndex !== null) {
      if (tabValue === "front") {
        const newElements = elements.filter(
          (_, index) => index !== selectedElementIndex
        );
        setElements(newElements);
        dispatch(UPDATE_ELEMENTS(newElements))
        setSelectedElementIndex(null);
      } else {
        const newElements = elementsForBack.filter(
          (_, index) => index !== selectedElementIndex
        );
        dispatch(UPDATE_ELEMENTS_FOR_BACK(newElements))
        setElementsForBack(newElements);
        setSelectedElementIndex(null);
      }
    }
  };
  const handleDeleteTextElement = (id) => {


    if (tabValue === "front") {
      const element = elements.findIndex((el) => el.id === id);
      const newElements = elements.filter((item, index) => index !== element);
      setElements(newElements);
      dispatch(UPDATE_ELEMENTS(newElements))
      setSelectedElementIndex(null);
    } else {
      const element = elementsForBack.findIndex((el) => el.id === id);
      const newElements = elementsForBack.filter(
        (item, index) => index !== element
      );
      dispatch(UPDATE_ELEMENTS_FOR_BACK(newElements))
      setElementsForBack(newElements);
      setSelectedElementIndex(null);
    }

  };

  const handleDeleteImageElement = (id) => {


    if (tabValue === "front") {
      const element = elements.findIndex((el) => el.id === id);
      const newElements = elements.filter((item, index) => index !== element);
      setElements(newElements);
      dispatch(UPDATE_ELEMENTS(newElements))
      setSelectedElementIndex(null);
    } else {
      const element = elementsForBack.findIndex((el) => el.id === id);
      const newElements = elementsForBack.filter(
        (item, index) => index !== element
      );
      dispatch(UPDATE_ELEMENTS_FOR_BACK(newElements))
      setElementsForBack(newElements);
      setSelectedElementIndex(null);
    }
  };

  const downloadCardImage = async () => {
    const node = containerRef.current;
    if (node) {
      try {
        const dataUrl = await toPng(node, { backgroundColor: bgColor });
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "card.png";
        link.click();
      } catch (err) {
        console.error("Failed to generate card image", err);
      }
    }
  };

  const handleAddImage = () => {
    fileInputRef.current.click();
  };

  const addTextElement = () => {
    if (tabValue === "front") {
      setElements([
        ...elements,
        {
          type: "text",
          content: "Sample Text",
          x: 50,
          y: 50,
          width: 100,
          height: 50,
          rotation: 0,
          fontSize: 16,
          fontWeight: 200,
          textAlign: "center",

          italic: false,
          underline: false,
          color: "#000000",
          bgColor: null,
          id: uuidv4(),
        },
      ]);


      dispatch(UPDATE_ELEMENTS([
        ...elements,
        {
          type: "text",
          content: "Sample Text",
          x: 50,
          y: 50,
          width: 100,
          height: 50,
          rotation: 0,
          fontSize: 16,
          fontWeight: 200,
          textAlign: "center",

          italic: false,
          underline: false,
          color: "#000000",
          bgColor: null,
          id: uuidv4(),
        },
      ]))
    } else {
      setElementsForBack([
        ...elementsForBack,
        {
          type: "text",
          content: "Sample Text",
          x: 50,
          y: 50,
          width: 100,
          height: 50,
          rotation: 0,
          fontSize: 16,
          fontWeight: 200,
          textAlign: "center",
          bold: false,
          italic: false,
          underline: false,
          color: "#000000",
          bgColor: null,
          id: uuidv4(),
        },
      ]);

      dispatch(UPDATE_ELEMENTS_FOR_BACK([
        ...elementsForBack,
        {
          type: "text",
          content: "Sample Text",
          x: 50,
          y: 50,
          width: 100,
          height: 50,
          rotation: 0,
          fontSize: 16,
          fontWeight: 200,
          textAlign: "center",
          bold: false,
          italic: false,
          underline: false,
          color: "#000000",
          bgColor: null,
          id: uuidv4(),
        },
      ]))
    }
  };

  const addQRCodeElement = (value) => {
    const newElement = {
      type: "QR",
      content: value,
      x: 150,
      y: 150,
      width: 100,
      height: 100,
      rotation: 0,
      fontSize: 16,
      fontWeight: 200,
      color: "#000000",
      bgColor: null,
      id: uuidv4(),
    };

    if (tabValue === "front") {
      const existingIndex = elements.findIndex((el) => el.type === "QR");
      if (existingIndex !== -1) {
        const updatedElements = [...elements];
        updatedElements[existingIndex] = {
          ...updatedElements[existingIndex],
          content: value,
        };
        setElements(updatedElements);
        dispatch(UPDATE_ELEMENTS(updatedElements))
      } else {
        setElements([...elements, newElement]);
        dispatch(UPDATE_ELEMENTS([...elements, newElement]))
      }
    } else {
      const existingIndex = elementsForBack.findIndex((el) => el.type === "QR");
      if (existingIndex !== -1) {
        const updatedElementsForBack = [...elementsForBack];
        updatedElementsForBack[existingIndex] = {
          ...updatedElementsForBack[existingIndex],
          content: value,
        };
        dispatch(UPDATE_ELEMENTS_FOR_BACK(updatedElementsForBack))
        setElementsForBack(updatedElementsForBack);
      } else {
        dispatch(UPDATE_ELEMENTS_FOR_BACK([...elementsForBack, newElement]))
        setElementsForBack([...elementsForBack, newElement]);
      }
    }
  };

  const fetchAllCardsName = async () => {
    try {
      const response = await instance.get(`api/ar/experience_list`, {
        headers: {
          authorization: `Bearer ${userToken}`,
        },
      });
      if (response.status === 200) {
        const arData = response.data?.data;
        setAllCardNames(arData);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchAllCardsName();

  }, []);



  const getCardDetails = async (id) => {
    try {


      const response = await instance.get(
        `api/customCard/custom_card/${id}`,

        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );


      if (response.status === 200) {
        setCardId(id);
        console.log(response?.data?.data, "this is response")

        const cardData = response?.data?.data;

        if (cardData?.elements) {
          setElements(cardData?.elements)
        }
        if (cardData?.elementsForBack) {
          setElementsForBack(cardData?.elementsForBack)
        }

        if (cardData?.name) {
          setCardName(cardData?.name);
        }
        if (cardData?.bgColor) {
          setBgColor(cardData?.bgColor);
        }
        if (cardData?.bgColorForBack) {
          setBgColorForBack(cardData?.bgColorForBack)
        }
        if (cardData?.bgImageUrl) {
          setBgImage(cardData?.bgImageUrl)
        }
        if (cardData?.bgImageForBackUrl) {
          setBgImageForBack(cardData?.bgImageForBackUrl)
        }

        if(cardData?.containerSize){
          const containerSize = JSON.parse(cardData?.containerSize);
          console.log(containerSize, "this is container size")
          if(containerSize){
            setContainerSize(containerSize)
          }
        }


      } else {
        setOpenOrientaionDilaog(true)
      }
    } catch (error) {
      setOpenOrientaionDilaog(true)
    }
  }

  useEffect(() => {
    console.log(id, "this is id")
    if (id) {

      getCardDetails(id)
    } else {
      setOpenOrientaionDilaog(true)
    }
  }, [id])

  useEffect(() => {
    if (tabValue === "front") {
      if (elements[selectedElementIndex]?.type === "text") {
        setEditorBox({
          text: true,
          image: false,
        })
      }
      else if (
        elements[selectedElementIndex]?.type === "image"
      ) {
        setEditorBox({
          text: false,
          image: true,
        })
      }
    } else {
      if (elementsForBack[selectedElementIndex]?.type === "text") {
        setEditorBox({
          text: true,
          image: false,
        })
      }
      else if (
        elementsForBack[selectedElementIndex]?.type === "image"
      ) {
        setEditorBox({
          text: false,
          image: true,
        })
      }
    }
  }, [selectedElementIndex]);

  const handleSelectText = (id) => {
    if (tabValue === "front") {
      const element = elements.findIndex((el) => el.id === id);
      console.log(element, "this is element");
      setSelectedElementIndex(element);
    } else {
      const element = elementsForBack.findIndex((el) => el.id === id);

      setSelectedElementIndex(element);
    }
  };



  const saveCardHandler = async () => {
    try {
      setIsSavingCard(true)
      const formData = new FormData();
      // Capture the container as a canvas using html2canvas
      try {
        // Check if containerRef is ready


        console.log("Starting html2canvas...");
        // Capture the container as a canvas using html2canvas
        const canvas = await html2canvas(tabValue === "front" ? containerRef.current : containerRefForBack.current, {
          scale: 2,
          useCORS: true,
          backgroundColor: null,
          logging: false,
        });

        console.log("Canvas created:", canvas);





        // Convert the canvas to a Blob
        const canvasToFile = new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], 'card-preview.png', { type: 'image/png' });
              resolve(file);
            } else {
              reject(new Error("Canvas to Blob conversion failed"));
            }
          }, 'image/png', 1.0);
        });

        // Await the file
        const previewFile = await canvasToFile;
        console.log(previewFile, "this is the file"); // Confirm file is created

        // Append the file to FormData
        formData.append('cardPreview', previewFile);

        console.log("File appended to FormData:", formData.get('cardPreview'));
      } catch (error) {
        console.error("Error occurred:", error);
      }

      // Append all text data
      formData.append('elements', JSON.stringify(elements));
      formData.append('elementsForBack', JSON.stringify(elementsForBack));
      formData.append('name', cardName ? cardName : "custom card");
      formData.append('bgColor', bgColor);
      formData.append('bgColorForBack', bgColorForBack);
      formData.append("containerSize", JSON.stringify(containerSize));

      const frontImages = elements.filter((element) => element.type === "image")
      const backImages = elementsForBack.filter((element) => element.type === "image")

      frontImages.forEach(image => {
        const file = image?.imageData;
        if (file instanceof File) {  // Just check if file exists
          const fileWithId = new File([file], image?.id, { type: file.type });
          formData.append('elementsImages', fileWithId);
        }
      });
      backImages.forEach(image => {
        const file = image?.imageData;
        if (file instanceof File) {  // Just check if file exists
          const fileWithId = new File([file], image?.id, { type: file.type });
          formData.append('elementsForBackImages', fileWithId);
        }



      });

      // Append files if they exist
      if (bgImageData) {
        formData.append('bgImage', bgImageData, bgImageData.name);
      }
      if (bgImageForBackData) {
        formData.append('bgImageForBack', bgImageForBackData, bgImageForBackData.name);
      }

      if (cardId) {


        const response = await instance.put(
          `/api/customCard/custom_card_update/${cardId}`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'multipart/form-data', // Important for FormData
            },
          }
        );


        if (response.status === 200) {
          toast.success("Card saved");

          console.log(response.data, "this is data")
          if (response?.data?.id) {
            dispatch(SET_CURRENT_CARD_ID(response?.data?.id))
          }



        }
      } else {


        const response = await instance.post(
          `/api/customCard/create_custom_card`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'multipart/form-data', // Important for FormData
            },
          }
        );


        if (response.status === 201) {
          toast.success("Card saved");

          console.log(response.data, "this is data")
          if (response?.data?.id) {
            // dispatch(SET_CURRENT_CARD_ID(response?.data?.id))
            setCardId(response?.data?.id);
          }



        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
    finally {
      setIsSavingCard(false)
    }
  };
  const handleLeave = (event) => {
    // Prevents the default action
    event.preventDefault();
    // Open the dialog
    setOpenDialog(true);
    return ''; // This is necessary for some browsers to show a confirmation dialog
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {

      const confirmationMessage = "Changes that you made may not be saved.";
      e.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // Chrome requires a return value

    };

    // Attach the beforeunload event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup function to remove the listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const handleSaveAndLeave = async () => {
    await saveCardHandler();
    setOpenDialog(false);
    setIsBlocking(false); // Allow navigation
    navigate(-1)
  }

  const handleConfirmLeave = () => {
    setOpenDialog(false);
    setIsBlocking(false); // Allow navigation
    navigate(-1)
  };
  const frontText = elements.filter((el) => el.type === "text");
  const backText = elementsForBack.filter((el) => el.type === "text");
  const frontQr = elements.filter((el) => el.type === "QR");
  const backQr = elementsForBack.filter((el) => el.type === "QR");
  console.log(tabValue, "this is selcted eelement")
  return (
    <div className="flex flex-col md:flex-row relative md:static" >
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "white",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >



          <div className="flex items-center gap-2">
            <button
              className=" flex items-center gap-2 rounded-md shadow-lg font-semibold bg-brand-main px-4 py-2 text-white"
              onClick={() => {
                setOpenDialog(true)
              }}
            >
              <AiOutlineArrowLeft />
              back
            </button>
            <p className="text-lg font-bold text-black">Custom card</p>
          </div>
          {/* <ReactToPrint
            trigger={() => (
              <button className="text-md ml-5 rounded-md bg-brand-main p-1 px-4 font-semibold text-white">
                Print {tabValue}
              </button>
            )}
            content={() =>
              tabValue === "front"
                ? containerRef.current
                : containerRefForBack.current
            }
            onBeforeGetContent={() => {
              setSelectedElementIndex(null);
            }}
            onBeforePrint={() => setSelectedElementIndex(null)}
          /> */}


          <div className="flex gap-2">
            <button
              className=" flex items-center gap-2 rounded-md shadow-lg font-semibold bg-brand-main px-4 py-2 text-white"
              onClick={saveCardHandler}
              disabled={isSavingCard}
            >
              <IoSaveOutline className="size-5" />
              {isSavingCard ? "Saving" : "Save"}
            </button>

            <PrintWrapper frontRef={containerRef}
              backRef={containerRefForBack}
              tabValue={tabValue}
              setTabValue={setTabValue}
              elements={elements}
              elementsForBack={elementsForBack}
              selectedElementIndex={selectedElementIndex}
              setIsEditable={setIsEditable}
              isEditable={isEditable}
              setSelectedElementIndex={setSelectedElementIndex}
              setBgImageSelected={setBgImageSelected}

              previewFrontRef={previewFrontRef}
              previewBackRef={previewBackRef}
            />
          </div>
        </Toolbar>

        <Divider />
        {
          tabValue === "front" && elements[selectedElementIndex]?.type === "text" && <TextEditorToolbar selectedElementIndex={selectedElementIndex}
            setSelectedElementIndex={setSelectedElementIndex}
            elements={elements}
            bgColor={bgColor}
            bgImage={bgImage}
            setElements={setElements}
            elementsForBack={elementsForBack}
            bgColorForBack={bgColorForBack}
            bgImageForBack={bgImageForBack}
            setElementsForBack={setElementsForBack}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            containerRef={containerRef}
            containerRefForBack={containerRefForBack}

            handleFontSizeChange={handleFontSizeChange}
            handleFontWeightChange={handleFontWeightChange}
            handleTextColorChange={handleTextColorChange}
            handleDeleteElement={handleDeleteElement}
          />

        }

        {
          tabValue === "back" && elementsForBack[selectedElementIndex]?.type === "text" && <TextEditorToolbar selectedElementIndex={selectedElementIndex}
            setSelectedElementIndex={setSelectedElementIndex}
            elements={elements}
            bgColor={bgColor}
            bgImage={bgImage}
            setElements={setElements}
            elementsForBack={elementsForBack}
            bgColorForBack={bgColorForBack}
            bgImageForBack={bgImageForBack}
            setElementsForBack={setElementsForBack}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            containerRef={containerRef}
            containerRefForBack={containerRefForBack}

            handleFontSizeChange={handleFontSizeChange}
            handleFontWeightChange={handleFontWeightChange}
            handleTextColorChange={handleTextColorChange}
            handleDeleteElement={handleDeleteElement}
          />

        }



      </AppBar>







      {/* <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          marginTop: "4rem"
        }}
      >
        <Toolbar />
     
      </Drawer> */}
      <Box sx={{ marginTop: "9rem" }} display={"flex"} flexDirection={"column"} gap={1} p={1}>

        <div className="flex flex-col gap-2">
          <TextEditorUI anchorEl={anchorEl}
            setAnchorEl={setAnchorEl} setSelectedElementIndex={setSelectedElementIndex} bgImage={bgImage} bgImageForBack={bgImageForBack} handleBgImageChange={handleBgImageChange} elements={elements} elementsForBack={elementsForBack} selectedElementIndex={selectedElementIndex} setElements={setElements} setElementsForBack={setElementsForBack} tabValue={tabValue} editorBox={editorBox} setEditorBox={setEditorBox} setBgColor={setBgColor} bgColor={bgColor}

            setBgColorForBack={setBgColorForBack}
            bgColorForBack={bgColorForBack}
          />
          {/* <div className="flex flex-col gap-1">
           <p className="text-sm">Color</p>
           <label>
             <input
               type="color"
               value={tabValue === "front" ? bgColor : bgColorForBack}
               onChange={handleBgColorChange}
               className="ml-2"
             />
           </label>
         </div>
         <div className="flex flex-col gap-1">
           <p className="text-sm">Image</p>
           <label className="rounded-md bg-[#3d2fa9]  p-2   text-sm  font-bold text-white ">
             {tabValue === "front" ? (
               <>{bgImage ? "Change image" : "Choose File"}</>
             ) : (
               <>{bgImageForBack ? "Change image" : "Choose File"}</>
             )}
             <input
               type="file"
               accept="image/*"
               onChange={handleBgImageChange}
               hidden
             />
           </label>
         </div> */}
        </div>

        {/* <div className="flex flex-col gap-2 rounded-md border p-2">
       <p className="font-bold">Add items</p>
       <div className="flex flex-col justify-center gap-1 ">
         <button
           className="rounded-lg  bg-yellow-500  p-2   text-sm  font-bold text-white "
           onClick={addTextElement}
         >
           Add text
         </button>
         <div>
           <button
             className=" w-full rounded-lg  bg-blue-500  p-2   text-sm  font-bold text-white "
             onClick={handleAddImage}
           >
             Add Image
           </button>
           <input
             type="file"
             ref={fileInputRef}
             hidden
             accept="image/*"
             onChange={(e) => {
               if (tabValue === "front") {
                 setElements([
                   ...elements,
                   {
                     type: "image",
                     src: URL.createObjectURL(e.target.files[0]),
                     x: 50,
                     y: 50,
                     width: 100,
                     height: 50,
                     rotation: 0,
                     fontSize: 16,
                     fontWeight: 200,
                     color: "#000000",
                     bgColor: null,
                     id: uuidv4(),
                   },
                 ]);
               } else {
                 setElementsForBack([
                   ...elementsForBack,
                   {
                     type: "image",
                     src: URL.createObjectURL(e.target.files[0]),
                     x: 50,
                     y: 50,
                     width: 100,
                     height: 50,
                     rotation: 0,

                     fontSize: 16,
                     fontWeight: 200,
                     color: "#000000",
                     bgColor: null,
                     id: uuidv4(),
                   },
                 ]);
               }
             }}
           />
         </div>
       </div>
     </div> */}
      </Box>
      <div className="hidden md:block md:mt-[9rem] mb-[5rem] md:mb-0">



        <div className="">
          {/* Existing content */}


          {editorBox.text && (
            <div className="w-full px-4 py-2 bg-white shadow-lg md:w-72 md:ml-2 md:p-4 md:rounded-lg ">
              <h2 className="text-2xl font-bold">Text</h2>

              <p className="text-sm text-gray-600">
                Edit your text below, or click on field you will able to edit the
                text
              </p>

              {tabValue === "front"
                ? frontText &&
                frontText.map((item) => {
                  return (
                    <div className="flex  space-x-1">
                      <Input aria-label="Demo input" multiline placeholder="Type something…" value={item?.content}
                        onFocus={() => handleSelectText(item?.id)}
                        onChange={handleTextChange}

                        style={{ width: "100%" }} />                    {/* <textarea
                      type="text"
                      value={item?.content}
                      onFocus={() => handleSelectText(item?.id)}
                      onChange={handleTextChange}
                      className="my-2 h-20 w-full resize-none rounded border border-gray-300 p-2"
                    /> */}
                      <button
                        onClick={() => handleDeleteTextElement(item?.id)}
                        className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                      >
                        <AiOutlineDelete size={20} />
                      </button>
                    </div>
                  );
                })
                : backText &&
                backText.map((item) => {
                  return (
                    <div className="flex  space-x-1">
                      <Input aria-label="Demo input" multiline placeholder="Type something…" value={item?.content}
                        onChange={handleTextChange}
                        onFocus={() => handleSelectText(item?.id)}


                        style={{ width: "100%" }} />
                      {/* <textarea
                      type="text"
                      value={item?.content}
                      onChange={handleTextChange}
                      className=" my-2  h-20 w-full resize-none rounded border border-gray-300 p-2"
                    /> */}

                      <button
                        onClick={() => handleDeleteTextElement(item?.id)}
                        className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                      >
                        <AiOutlineDelete size={20} />
                      </button>
                    </div>
                  );
                })}

              <button
                onClick={addTextElement}
                className="mt-2 text-sm font-bold w-full rounded-md border bg-brand-main px-4 py-2 text-white"
              >
                New Text Field
              </button>
            </div>
          )}
          {editorBox.image &&
            <div className="w-full px-4 py-2 bg-white shadow-lg md:w-72 md:ml-2 md:p-4 md:rounded-lg">
              <h2 className="text-2xl font-bold">Images</h2>
              <ImageTab
                tabValue={tabValue}
                bgImage={bgImage}
                bgImageForBack={bgImageForBack}
                handleBgImageChange={handleBgImageChange}
                elements={elements}
                elementsForBack={elementsForBack}
                setElements={setElements}
                setElementsForBack={setElementsForBack}
                setBgImage={setBgImage}
                handleDeleteImageElement={handleDeleteImageElement}
                setBgImageForBack={setBgImageForBack}

              />
            </div>
          }


          {
            editorBox.qr && (
              <div className="w-full px-4 py-2 bg-white shadow-lg md:w-72 md:ml-2 md:p-4 md:rounded-lg">
                <h2 className="text-2xl font-bold">Qr code</h2>
                <div className="mb-4 mt-2">
                  {/* <p className="block text-sm font-bold text-gray-700">
                  Select AR Card
                </p>
                <select
                  value={selectedArExperience}
                  onChange={(e) => {
                    addQRCodeElement(e.target.value);
                    setSelectedArExperience(e.target.value);
                  }}
                  name="arcard"
                  id="arcard"
                  className="w-full border p-2"
                >
                  {allCardNames.length > 0 ? (
                    allCardNames.map((card) => {
                      return <option value={card?.name}>{card?.name}</option>;
                    })
                  ) : (
                    <option value="No card found">No card found</option>
                  )}
                </select> */}
                </div>
                {tabValue === "front" ? (
                  <div>
                    <p className="block text-sm font-bold text-gray-700">
                      Select AR Card
                    </p>
                    <select
                      value={frontQr[0]?.content}
                      onChange={(e) => {
                        if (e.target.value) {

                          addQRCodeElement(e.target.value);
                          setSelectedArExperience(e.target.value);
                        }
                      }}
                      name="arcard"
                      id="arcard"
                      className="w-full border p-2"
                    >
                      <option value="">Select Card</option>
                      {allCardNames.length > 0 && (
                        allCardNames.map((card) => {
                          return <option value={card?.name}>{card?.name}</option>;
                        })
                      )}
                    </select>
                    {(frontQr && elements[selectedElementIndex]?.type === "QR") && (
                      <div className="mb-4">

                        <p className="block text-sm font-bold text-gray-700">
                          QR Code color:
                        </p>
                        <div className="flex justify-start items-center">


                          <IconButton
                            sx={{ color: "#fffffff" }}
                            className="relative h-12 w-12  rounded-full p-0"
                          >
                            <input
                              type="color"
                              value={frontQr[0]?.color}
                              onChange={(e) => { handleTextColorChangeById(e, frontQr[0]?.id) }}
                              className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-full p-0 opacity-0"
                            />
                            <div
                              className="h-full w-full rounded-full"
                              style={{
                                background: frontQr[0]?.color,
                              }}
                            ></div>
                          </IconButton>

                          <button
                            onClick={() => handleDeleteImageElement(frontQr[0]?.id)}
                            className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                          >
                            <AiOutlineDelete size={20} />
                          </button>

                        </div>

                      </div>
                    )
                    }

                  </div>
                ) : (
                  <div>
                    <p className="block text-sm font-bold text-gray-700">
                      Select AR Card
                    </p>
                    <select
                      value={backQr[0]?.content}
                      onChange={(e) => {
                        if (e.target.value) {


                          addQRCodeElement(e.target.value);
                          setSelectedArExperience(e.target.value);
                        }
                      }}
                      name="arcard"
                      id="arcard"
                      className="w-full border p-2"
                    >
                      <option value="">Select Card</option>
                      {allCardNames.length > 0 && (
                        allCardNames.map((card) => {
                          return <option value={card?.name}>{card?.name}</option>;
                        })
                      )}
                    </select>
                    {(backQr && elementsForBack[selectedElementIndex]?.type === "QR") && (
                      <div className="mb-4">
                        <p className="block text-sm font-bold text-gray-700">
                          QR Code color:
                        </p>
                        <div className="flex justify-start items-center">
                          <IconButton
                            sx={{ color: "#fffffff" }}
                            className="relative h-12 w-12  rounded-full p-0"
                          >
                            <input
                              type="color"
                              value={backQr[0]?.color}
                              onChange={(e) => handleTextColorChangeById(e, backQr[0]?.id)}
                              className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-full p-0 opacity-0"
                            />
                            <div
                              className="h-full w-full rounded-full"
                              style={{
                                background: backQr[0]?.color,
                              }}
                            ></div>
                          </IconButton>



                          <button
                            onClick={() => handleDeleteImageElement(backQr[0]?.id)}
                            className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                          >
                            <AiOutlineDelete size={20} />
                          </button>
                        </div>

                      </div>
                    )}

                  </div>
                )}
              </div>
            )
          }
        </div>
      </div>
      <div
        className="card-editor-section mt-[-5rem] md:mt-[5rem] flex-grow"
        display="flex"

        component="main"

      >

        <Box sx={{ flexGrow: 1, p: 3 }}>



          <Toolbar />
          <CardEditor
            selectedElementIndex={selectedElementIndex}
            setSelectedElementIndex={setSelectedElementIndex}
            elements={elements}
            bgColor={bgColor}
            bgImage={bgImage}
            setElements={setElements}
            elementsForBack={elementsForBack}
            bgColorForBack={bgColorForBack}
            bgImageForBack={bgImageForBack}
            setElementsForBack={setElementsForBack}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            containerRef={containerRef}
            containerRefForBack={containerRefForBack}
            setEditorBox={setEditorBox}
            mainContinerRef={mainContinerRef}
            mainContinerRefForBack={mainContinerRefForBack}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            isBgImageSelected={isBgImageSelected}
            setBgImageSelected={setBgImageSelected}
            containerSize={containerSize}
          />





        </Box>



      </div>
      <Box height={200} display={"flex"} flexDirection={"column"} sx={{ marginTop: { xs: 0, md: "9rem" }, }} p={1} >
        {/* <Toolbar /> */}

        <div className="md:hidden flex space-x-4 justify-center">
          <h3 onClick={() => {

            setSelectedElementIndex(null);
            setIsEditable(false);
            setBgImageSelected(false)
            setTimeout(() => {
              setTabValue("front");
            }, 0);

          }} className={`${tabValue === "front" ? "shadow-lg bg-white px-4 py-1 rounded-3xl" : "px-4 py-1"} `}>Front</h3>
          <h3 onClick={() => {

            setSelectedElementIndex(null);
            setIsEditable(false);
            setBgImageSelected(false)

            setTimeout(() => {
              setTabValue("back");
            }, 0);

          }} className={`${tabValue === "back" ? "shadow-lg bg-white px-4 py-1 rounded-3xl" : "px-4 py-1"} `} >Back</h3>
        </div>

        {(editorBox.qr || editorBox.image || editorBox.text) && <div className="md:hidden mt-4   bg-white ">

          <div className="">
            {/* Existing content */}

            <div className="flex justify-end p-2" >
              <IoMdClose onClick={() => { setEditorBox({ image: false, qr: false, text: false }) }} />

            </div>
            {editorBox.text && (
              <div className="w-full px-4 py-2 bg-white shadow-lg md:w-72 md:ml-2 md:p-4 md:rounded-lg ">
                <h2 className="text-2xl font-bold">Text</h2>

                <p className="text-sm text-gray-600">
                  Edit your text below, or click on field you will able to edit the
                  text
                </p>

                {tabValue === "front"
                  ? frontText &&
                  frontText.map((item) => {
                    return (
                      <div className="flex  space-x-1">
                        <Input aria-label="Demo input" multiline placeholder="Type something…" value={item?.content}
                          onFocus={() => handleSelectText(item?.id)}
                          onChange={handleTextChange}

                          style={{ width: "100%" }} />                    {/* <textarea
              type="text"
              value={item?.content}
              onFocus={() => handleSelectText(item?.id)}
              onChange={handleTextChange}
              className="my-2 h-20 w-full resize-none rounded border border-gray-300 p-2"
            /> */}
                        <button
                          onClick={() => handleDeleteTextElement(item?.id)}
                          className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                        >
                          <AiOutlineDelete size={20} />
                        </button>
                      </div>
                    );
                  })
                  : backText &&
                  backText.map((item) => {
                    return (
                      <div className="flex  space-x-1">
                        <Input aria-label="Demo input" multiline placeholder="Type something…" value={item?.content}
                          onChange={handleTextChange}
                          onFocus={() => handleSelectText(item?.id)}


                          style={{ width: "100%" }} />
                        {/* <textarea
              type="text"
              value={item?.content}
              onChange={handleTextChange}
              className=" my-2  h-20 w-full resize-none rounded border border-gray-300 p-2"
            /> */}

                        <button
                          onClick={() => handleDeleteTextElement(item?.id)}
                          className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                        >
                          <AiOutlineDelete size={20} />
                        </button>
                      </div>
                    );
                  })}

                <button
                  onClick={addTextElement}
                  className="mt-2 text-sm font-bold w-full rounded-md border bg-brand-main px-4 py-2 text-white"
                >
                  New Text Field
                </button>
              </div>
            )}
            {editorBox.image &&
              <div className="w-full px-4 py-2 bg-white shadow-lg md:w-72 md:ml-2 md:p-4 md:rounded-lg">
                <h2 className="text-2xl font-bold">Images</h2>
                <ImageTab
                  tabValue={tabValue}
                  bgImage={bgImage}
                  bgImageForBack={bgImageForBack}
                  handleBgImageChange={handleBgImageChange}
                  elements={elements}
                  elementsForBack={elementsForBack}
                  setElements={setElements}
                  setElementsForBack={setElementsForBack}
                  setBgImage={setBgImage}
                  handleDeleteImageElement={handleDeleteImageElement}
                  setBgImageForBack={setBgImageForBack}

                />
              </div>
            }


            {
              editorBox.qr && (
                <div className="w-full px-4 py-2 bg-white shadow-lg md:w-72 md:ml-2 md:p-4 md:rounded-lg">
                  <h2 className="text-2xl font-bold">Qr code</h2>
                  <div className="mb-4 mt-2">
                    {/* <p className="block text-sm font-bold text-gray-700">
          Select AR Card
        </p>
        <select
          value={selectedArExperience}
          onChange={(e) => {
            addQRCodeElement(e.target.value);
            setSelectedArExperience(e.target.value);
          }}
          name="arcard"
          id="arcard"
          className="w-full border p-2"
        >
          {allCardNames.length > 0 ? (
            allCardNames.map((card) => {
              return <option value={card?.name}>{card?.name}</option>;
            })
          ) : (
            <option value="No card found">No card found</option>
          )}
        </select> */}
                  </div>
                  {tabValue === "front" ? (
                    <div>
                      <p className="block text-sm font-bold text-gray-700">
                        Select AR Card
                      </p>
                      <select
                        value={frontQr[0]?.content}
                        onChange={(e) => {
                          if (e.target.value) {

                            addQRCodeElement(e.target.value);
                            setSelectedArExperience(e.target.value);
                          }
                        }}
                        name="arcard"
                        id="arcard"
                        className="w-full border p-2"
                      >
                        <option value="">Select Card</option>
                        {allCardNames.length > 0 && (
                          allCardNames.map((card) => {
                            return <option value={card?.name}>{card?.name}</option>;
                          })
                        )}
                      </select>
                      {(frontQr && elements[selectedElementIndex]?.type === "QR") && (
                        <div className="mb-4">

                          <p className="block text-sm font-bold text-gray-700">
                            QR Code color:
                          </p>
                          <div className="flex justify-start items-center">


                            <IconButton
                              sx={{ color: "#fffffff" }}
                              className="relative h-12 w-12  rounded-full p-0"
                            >
                              <input
                                type="color"
                                value={frontQr[0]?.color}
                                onChange={(e) => { handleTextColorChangeById(e, frontQr[0]?.id) }}
                                className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-full p-0 opacity-0"
                              />
                              <div
                                className="h-full w-full rounded-full"
                                style={{
                                  background: frontQr[0]?.color,
                                }}
                              ></div>
                            </IconButton>

                            <button
                              onClick={() => handleDeleteImageElement(frontQr[0]?.id)}
                              className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                            >
                              <AiOutlineDelete size={20} />
                            </button>

                          </div>

                        </div>
                      )
                      }

                    </div>
                  ) : (
                    <div>
                      <p className="block text-sm font-bold text-gray-700">
                        Select AR Card
                      </p>
                      <select
                        value={backQr[0]?.content}
                        onChange={(e) => {
                          if (e.target.value) {


                            addQRCodeElement(e.target.value);
                            setSelectedArExperience(e.target.value);
                          }
                        }}
                        name="arcard"
                        id="arcard"
                        className="w-full border p-2"
                      >
                        <option value="">Select Card</option>
                        {allCardNames.length > 0 && (
                          allCardNames.map((card) => {
                            return <option value={card?.name}>{card?.name}</option>;
                          })
                        )}
                      </select>
                      {(backQr && elementsForBack[selectedElementIndex]?.type === "QR") && (
                        <div className="mb-4">
                          <p className="block text-sm font-bold text-gray-700">
                            QR Code color:
                          </p>
                          <div className="flex justify-start items-center">
                            <IconButton
                              sx={{ color: "#fffffff" }}
                              className="relative h-12 w-12  rounded-full p-0"
                            >
                              <input
                                type="color"
                                value={backQr[0]?.color}
                                onChange={(e) => handleTextColorChangeById(e, backQr[0]?.id)}
                                className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-full p-0 opacity-0"
                              />
                              <div
                                className="h-full w-full rounded-full"
                                style={{
                                  background: backQr[0]?.color,
                                }}
                              ></div>
                            </IconButton>



                            <button
                              onClick={() => handleDeleteImageElement(backQr[0]?.id)}
                              className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                            >
                              <AiOutlineDelete size={20} />
                            </button>
                          </div>

                        </div>
                      )}

                    </div>
                  )}
                </div>
              )
            }
          </div>
        </div>}

        <ContainerPreview backRef={containerRefForBack} frontRef={containerRef} tabValue={tabValue}
          elements={elements} elementsForBack={elementsForBack}

          setSelectedElementIndex={setSelectedElementIndex}
          setIsEditable={setIsEditable}
          setBgImageSelected={setBgImageSelected}
          setTabValue={setTabValue} />

        <div>

        </div>
      </Box>

      <ZoomControl containerRef={mainContinerRef} containerRefForBack={mainContinerRefForBack} tabValue={tabValue} />
      <OrientationDialog open={openOrientationDiload} onClose={() => setOpenOrientaionDilaog(false)} containerSize={containerSize} setContainerSize={setContainerSize} setCardName={setCardName} cardName={cardName} />



      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Navigation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to leave this page? Unsaved changes may be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>


          <button onClick={handleCloseDialog} className="mr-4 rounded-md bg-gray-800 px-4 py-2 text-center text-sm font-semibold text-white  shadow-lg hover:bg-gray-900">
            Cancel
          </button>

          
          <button onClick={handleConfirmLeave} className="mr-4 rounded-md bg-red-600 px-4 py-2 text-center text-sm font-semibold text-white  shadow-lg hover:bg-red-500">
            Leave without save
          </button>
          <button onClick={handleSaveAndLeave} className="mr-4 rounded-md bg-green-600 px-4 py-2 text-center text-sm font-semibold text-white  shadow-lg hover:bg-green-500" >
            {isSavingCard ? "Please wait" : "Save and leave"}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomCard;
