import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import {
  MdTextFields,
  MdImage,
  MdBrush,
  MdQrCode2,
  MdGridOn,
} from "react-icons/md";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import { element } from "three/examples/jsm/nodes/Nodes";
import ImageTab from "./Tab";
import { v4 as uuidv4 } from "uuid";

import { AiOutlineDelete } from "react-icons/ai";
import { IoQrCodeOutline } from "react-icons/io5";
import useDebounce from "./debouncehook";
import { UPDATE_BG_COLOR, UPDATE_BG_COLOR_FOR_BACK } from "../../../../../../redux/reducerSlice/customCardSlice";
import { useDispatch } from "react-redux";

// Sidebar component
const Sidebar = ({
  elements,
  selectedElementIndex,
  elementsForBack,
  tabValue,
  setElements,
  setElementsForBack,
  handleBgImageChange,
  bgImageForBack,
  bgImage,
  setSelectedElementIndex,
  anchorEl,
  setAnchorEl,
  editorBox,
  setEditorBox,
  setBgColor,
  bgColor,
  setBgColorForBack,
  bgColorForBack,
}) => {
  const dispatch = useDispatch()
  const open = Boolean(anchorEl);
  const handleClick = (event, menu) => {
    setSelectedMneu(menu);

    if (menu === "Text") {
      setEditorBox({
        text: true,
        image: false,
        qr: false,
      });
      setSelectedElementIndex(null);
    } else if (menu === "Images") {
      setEditorBox({
        text: false,
        image: true,
        qr: false,
      });

      setSelectedElementIndex(null);
    } else if (menu === "Qr") {
      setEditorBox({
        text: false,
        image: false,
        qr: true,
      });

      setSelectedElementIndex(null);
    }
    // setAnchorEl(event.currentTarget);
  };
  const [frontColor, setFrontColor] = useState(bgColor);
  const [backColor, setBackColor] = useState(bgColorForBack);

  const debouncedBgColorFront = useDebounce(frontColor, 300); // 300ms delay
  const debouncedBgColorBack = useDebounce(backColor, 300); // 300ms delay
  const [selectedMenu, setSelectedMneu] = useState(null);
  React.useEffect(() => {
    // Here you can perform any action that should happen after the debounce
    setBgColor(debouncedBgColorFront);
    // For example, you might want to update some styles or make an API call here
  }, [debouncedBgColorFront]);

  React.useEffect(() => {
    // Here you can perform any action that should happen after the debounce
    setBgColorForBack(debouncedBgColorBack);
    // For example, you might want to update some styles or make an API call here
  }, [debouncedBgColorBack]);

  console.log(editorBox.text || editorBox.image || editorBox.qr);
  return (
    <div
      style={{
        display:
          window.innerWidth < 768 &&
          (editorBox.text || editorBox.image || editorBox.qr)
            ? "none"
            : undefined,
      }}
      className="  fixed bottom-0 left-0 right-0 z-50 flex h-auto w-full flex-row items-center justify-between bg-black px-4 py-2 text-white md:static md:h-screen md:w-16 md:flex-col md:justify-normal md:bg-[#f4f7fe] md:px-0 md:py-4 md:text-black"
    >
      <div
        onClick={(e) => handleClick(e, "Text")}
        className={`flex flex-col items-center justify-center md:mb-6`}
      >
        <MdTextFields
          className={`mb-1 text-4xl md:text-2xl ${
            editorBox.text ? "rounded-full bg-black p-1 text-white" : ""
          }`}
        />
        <Typography variant="caption" className="text-xs">
          Text
        </Typography>
      </div>
      <div
        onClick={(e) => handleClick(e, "Images")}
        className={`flex flex-col items-center justify-center md:mb-6`}
      >
        <MdImage
          className={` mb-1 text-4xl md:text-2xl ${
            editorBox.image ? "rounded-full bg-black p-1 text-white" : ""
          }`}
        />
        <Typography variant="caption" className=" text-xs">
          Images
        </Typography>
      </div>

      <div className={`flex flex-col items-center justify-center md:mb-6`}>
        {tabValue === "front" ? (
          <IconButton
            sx={{ color: "#000" }}
            className="relative h-10 w-10 rounded-full p-0  md:h-12 md:w-12"
          >
            <input
              type="color"
              value={bgColor}
              onChange={(e) =>{ setFrontColor(e.target.value);
                dispatch(UPDATE_BG_COLOR(e.target.value))
              }}
              className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-full p-0 opacity-0"
            />
            <div
              className="h-full w-full rounded-full shadow-lg"
              style={{
                background: bgColor,
              }}
            ></div>
          </IconButton>
        ) : (
          <IconButton
            sx={{ color: "#000" }}
            className="relative h-10 w-10 rounded-full p-0  md:h-12 md:w-12"
          >
            <input
              type="color"
              value={bgColorForBack}
              onChange={(e) => {
                setBackColor(e.target.value);

                dispatch(UPDATE_BG_COLOR_FOR_BACK(e.target.value))
                
              }}
              className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-full p-0 opacity-0"
            />
            <div
              className="h-full w-full rounded-full shadow-lg"
              style={{
                background: bgColorForBack,
              }}
            ></div>
          </IconButton>
        )}
        <Typography variant="caption" className="text-xs">
          Color
        </Typography>
      </div>
      <div
        onClick={(e) => handleClick(e, "Qr")}
        className={`flex flex-col items-center justify-center md:mb-6`}
      >
        <IoQrCodeOutline
          className={` mb-1 text-4xl md:text-2xl ${
            editorBox.qr ? "rounded-full bg-black p-1 text-white" : ""
          }`}
        />
        <Typography variant="caption" className=" text-xs">
          QR Code
        </Typography>
      </div>

      {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        // onClose={handleClose}
        // onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: -10,
              ml: 10,
              p: 2,
              maxWidth: 300,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      > */}

      {/* {elements[selectedElementIndex] &&
        elements[selectedElementIndex]?.type === "text" && (
          <div>
            <h2 className="text-2xl font-bold">Text</h2>

            <p>
              Edit your text below, or click on field you will able to edit the
              text
            </p>
            {tabValue === "front"
              ? frontText &&
                frontText.map((item) => {
                  return (
                    <div className="flex  space-x-1">
                      <textarea
                        type="text"
                        value={item?.content}
                        onFocus={() => handleSelectText(item?.id)}
                        onChange={handleTextChange}
                        className="my-2 h-20 w-full resize-none rounded border border-gray-300 p-2"
                      />
                      <button
                        onClick={() => handleDeleteElement(item?.id)}
                        className="my-2 h-8 rounded-md bg-red-600 p-1.5   text-sm  font-bold text-white "
                      >
                        <AiOutlineDelete size={20} />
                      </button>
                    </div>
                  );
                })
              : frontText &&
                frontText.map((item) => {
                  return (
                    <div>
                      <textarea
                        type="text"
                        value={item?.content}
                        onChange={handleTextChange}
                        className="h-20 w-full resize-none rounded border border-gray-300 p-2"
                      />

                      <button
                        onClick={handleDeleteElement}
                        className="rounded-md bg-red-600  p-1.5   text-sm  font-bold text-white "
                      >
                        <AiOutlineDelete size={20} />
                      </button>
                    </div>
                  );
                })}

            <button
              onClick={addTextElement}
              className="mt-2 w-full rounded-md border bg-brand-main px-4 py-1 text-white"
            >
              New Text Field
            </button>
          </div>
        )}

      {selectedMenu === "Images" && (
        <ImageTab
          tabValue={tabValue}
          bgImage={bgImage}
          bgImageForBack={bgImageForBack}
          handleBgImageChange={handleBgImageChange}
          elements={elements}
          elementsForBack={elementsForBack}
          setElements={setElements}
          setElementsForBack={setElementsForBack}
        />
      )} */}
      {/* </Menu> */}
    </div>
  );
};

// TextMenu component
const TextMenu = () => {
  return (
    <Card className="w-64 shadow-lg">
      <CardContent>
        <Typography variant="h6" className="mb-4">
          Text
        </Typography>
        <Typography variant="body2" className="mb-4">
          Edit your text below, or click on the field you'd like to edit
          directly on your design.
        </Typography>
        <div className="mb-4 rounded bg-gray-100 p-2">
          <Typography variant="body2" className="text-gray-500">
            Type text here
          </Typography>
        </div>
        <Button
          variant="contained"
          fullWidth
          className="bg-blue-400 hover:bg-blue-500"
        >
          New Text Field
        </Button>
      </CardContent>
    </Card>
  );
};

// Main component
const TextEditorUI = ({
  elements,
  selectedElementIndex,
  elementsForBack,
  tabValue,
  setElements,
  setElementsForBack,
  bgImage,
  bgImageForBack,
  handleBgImageChange,
  setSelectedElementIndex,
  anchorEl,
  setAnchorEl,
  editorBox,
  setEditorBox,
  setBgColor,
  bgColor,
  setBgColorForBack,
  bgColorForBack,
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 md:sticky md:bottom-auto md:left-auto md:right-auto md:flex md:h-screen">
      <Sidebar
        elements={elements}
        elementsForBack={elementsForBack}
        selectedElementIndex={selectedElementIndex}
        setElements={setElements}
        setElementsForBack={setElementsForBack}
        tabValue={tabValue}
        bgImage={bgImage}
        bgImageForBack={bgImageForBack}
        handleBgImageChange={handleBgImageChange}
        setSelectedElementIndex={setSelectedElementIndex}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        editorBox={editorBox}
        setEditorBox={setEditorBox}
        setBgColor={setBgColor}
        bgColor={bgColor}
        setBgColorForBack={setBgColorForBack}
        bgColorForBack={bgColorForBack}
      />
      {/* <div className="flex-grow p-8">
        <TextMenu />
      </div> */}
    </div>
  );
};

export default TextEditorUI;
