import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";

import { CgAlignRight, CgAlignLeft } from "react-icons/cg";
import { IoIosPhoneLandscape, IoIosPhonePortrait } from "react-icons/io";
import { useDispatch } from "react-redux";
import { UPDATE_CARD_NAME } from "../../../../../redux/reducerSlice/customCardSlice";
const OrientationDialog = ({
  containerSize,
  setContainerSize,
  open,
  onClose,
  setCardName,
  cardName,
}) => {
  const [orientation, setOrientation] = useState("landscape");
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const handleOrientationChange = (event) => {
    setOrientation(event.target.value);
  };

  const handleApply = () => {
    if (orientation === "portrait") {
      setContainerSize({ width: 250, height: 400 });
    } else {
      setContainerSize({ width: 400, height: 250 });
    }

    if (!cardName) {
      setError("Please enter card name");
      return;
    }

    dispatch(UPDATE_CARD_NAME({orientation, cardName}))
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <DialogContent className="w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
          <DialogTitle className="border-b pb-4 text-xl font-semibold text-gray-900">
            Select Card Orientation
          </DialogTitle>

          <div className="mt-6 space-y-6">
            {/* Card Name Input */}
            <div className="space-y-2">
              <label
                htmlFor="cardName"
                className="block text-sm font-medium text-gray-700"
              >
                Card Name
              </label>
              <input
                type="text"
                id="cardName"
                onChange={(e) => setCardName(e.target.value)}
                className="w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                placeholder="Enter card name"
              />
              {error && <span className="xs text-red-600">{error}</span>}
            </div>

            {/* Orientation Radio Group */}
            <RadioGroup
              value={orientation}
              onChange={handleOrientationChange}
              className="space-y-4"
            >
              <FormControlLabel
                value="landscape"
                control={
                  <Radio className="text-blue-600 focus:ring-blue-500" />
                }
                label={
                  <div className="flex items-center space-x-3 rounded-md p-2 transition-colors hover:bg-gray-50">
                    <IoIosPhoneLandscape className="h-6 w-6 text-gray-600" />
                    <span className="font-medium text-gray-700">Landscape</span>
                  </div>
                }
                className="!m-0 !-mx-2 rounded-md"
              />
              <FormControlLabel
                value="portrait"
                control={
                  <Radio className="text-blue-600 focus:ring-blue-500" />
                }
                label={
                  <div className="flex items-center space-x-3 rounded-md p-2 transition-colors hover:bg-gray-50">
                    <IoIosPhonePortrait className="h-6 w-6 text-gray-600" />
                    <span className="font-medium text-gray-700">Portrait</span>
                  </div>
                }
                className="!m-0 !-mx-2 rounded-md"
              />
            </RadioGroup>
          </div>

          <div className="mt-8 flex justify-end space-x-3 border-t pt-4">
          
            <button
              onClick={handleApply}
              className="rounded-md bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Create
            </button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default OrientationDialog;
