// src/Card.js
import React from "react";
import pattern from "../../../../../assets/svg/boxPattern.svg";
import { QRCodeSVG } from "qrcode.react";
import { LuNfc } from "react-icons/lu";
import NfcImmarsify from "assets/svg/immarsifyM.svg";
import Logo from "assets/svg/logogolden.svg";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaMobileAlt,
  FaYoutube,
} from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardOne = React.forwardRef(
  ({ username, experiencename, name, cardData }, ref) => {
    return (
      <div
        ref={ref}
        items-center
        className="relative  flex h-[250px] w-[400px] flex-col  overflow-hidden border bg-black p-3 
        font-cardFont shadow-lg"
      >
        <div className="absolute right-2 top-2 flex items-center justify-end ">
          <LuNfc size={25} color="#fdd446" className="    top-3 bg-black" />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          <img src={NfcImmarsify} className=" right-1 top-4  h-4 w-7" />
        </div>
        <div className="mt-2.5 flex h-full w-full flex-col items-center justify-center p-2.5">
          <p
            className={` mb-2 flex items-center justify-center break-words text-center ${
              name.length > 20 ? "text-2xl" : "text-3xl"
            }  font-bold text-[#fdd446] `}
          >
            {name
              .split(" ")
              .map(
                (word) =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
              )
              .join(" ")}
          </p>

          <QRCodeSVG
            value={`https://sandboxar.immarsify.com/${username}/${experiencename}`}
            fgColor="#fdd446"
            bgColor="transparent"
            className="h-[115px] w-[115px] text-[#fdd446]"
          />

          <div className="text-md mt-4 font-semibold italic  tracking-wide text-[#fdd446]">
            Business की कहानी, Card की जुबानी
          </div>
          {/* <div className="z-[1] flex w-1/2 flex-col justify-center p-4 text-[#fafad2]">
            <div className="w-full text-2xl font-semibold">{name}</div>
            <div className="mb-2 w-full text-sm">{jobTitle}</div>
            <div className="text-sm">{email}</div>
            <div className="text-sm">{phone}</div>
          </div> */}
        </div>
      </div>
      // <svg
      //   ref={ref}
      //   width="440"
      //   height="260"
      //   viewBox="0 0 440 260"
      //   xmlns="http://www.w3.org/2000/svg"
      //   className="border shadow-lg"
      // >
      //   {/* <rect width="440" height="260" fill="#000" /> */}
      //   <rect x="220" width="220" height="260" fill="#000" />

      //   {/* Company Section */}
      //   {companyName && (
      //     <text
      //       x="110"
      //       y="130"
      //       fontSize="24"
      //       fontWeight="bold"
      //       textAnchor="middle"
      //       fill="#f4dd4b"
      //     >
      //       {companyName}
      //     </text>
      //   )}
      //   {companyTagline && (
      //     <text
      //       x="110"
      //       y="160"
      //       fontSize="14"
      //       textAnchor="middle"
      //       fill="#ffffff"
      //     >
      //       {companyTagline}
      //     </text>
      //   )}

      //   {/* Employee Section */}
      //   <text
      //     x="330"
      //     y="110"
      //     fontSize="24"
      //     fontWeight="bold"
      //     textAnchor="middle"
      //     fill="#f4dd4b"
      //   >
      //     {name}
      //   </text>
      //   <text x="330" y="140" fontSize="14" textAnchor="middle" fill="#ffffff">
      //     {jobTitle}
      //   </text>
      //   <text x="330" y="170" fontSize="14" textAnchor="middle" fill="#ffffff">
      //     {email}
      //   </text>
      //   <text x="330" y="200" fontSize="14" textAnchor="middle" fill="#ffffff">
      //     {phone}
      //   </text>
      // </svg>
    );
  }
);

export default CardOne;

export const CardOneBackSide = React.forwardRef(
  ({ username, experiencename, name, cardData }, ref) => {
    return (
      <div
        ref={ref}
        items-center
        className="relative  flex h-[250px] w-[400px] flex-col  overflow-hidden border bg-black p-3 
        font-cardFont shadow-lg"
      >
        <div className="absolute right-2 top-2 flex items-center justify-end ">
          <LuNfc size={25} color="#fdd446" className="    top-3 bg-black" />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          <img src={NfcImmarsify} className=" right-1 top-4  h-4 w-7" />
        </div>

        <div className="relative  break-all  p-4 text-sm text-yellow-300">
          <div className="mt-8">
            <h2
              className={` mb-2 text-center font-bold ${
                cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`
              } }`}
            >
              {cardData?.companyName
                ?.split(" ")
                .map(
                  (word) =>
                    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                )
                .join(" ")}
            </h2>

            <div className="mt-2 flex justify-center  ">
              <div className="flex flex-col gap-2 ">
                {cardData?.address && (
                  <p className="flex  gap-2 text-sm">
                    <IoLocationSharp size={20} />
                    {cardData?.address}
                  </p>
                )}

                {cardData?.email && (
                  <p className="flex  gap-2 text-sm">
                    <MdOutlineMail size={20} />
                    {cardData?.email}
                  </p>
                )}

                {cardData?.Phone && (
                  <p className="flex  gap-2 text-sm">
                    <FaMobileAlt size={20} />
                    {cardData?.Phone}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      // <svg
      //   ref={ref}
      //   width="440"
      //   height="260"
      //   viewBox="0 0 440 260"
      //   xmlns="http://www.w3.org/2000/svg"
      //   className="border shadow-lg"
      // >
      //   {/* <rect width="440" height="260" fill="#000" /> */}
      //   <rect x="220" width="220" height="260" fill="#000" />

      //   {/* Company Section */}
      //   {companyName && (
      //     <text
      //       x="110"
      //       y="130"
      //       fontSize="24"
      //       fontWeight="bold"
      //       textAnchor="middle"
      //       fill="#f4dd4b"
      //     >
      //       {companyName}
      //     </text>
      //   )}
      //   {companyTagline && (
      //     <text
      //       x="110"
      //       y="160"
      //       fontSize="14"
      //       textAnchor="middle"
      //       fill="#ffffff"
      //     >
      //       {companyTagline}
      //     </text>
      //   )}

      //   {/* Employee Section */}
      //   <text
      //     x="330"
      //     y="110"
      //     fontSize="24"
      //     fontWeight="bold"
      //     textAnchor="middle"
      //     fill="#f4dd4b"
      //   >
      //     {name}
      //   </text>
      //   <text x="330" y="140" fontSize="14" textAnchor="middle" fill="#ffffff">
      //     {jobTitle}
      //   </text>
      //   <text x="330" y="170" fontSize="14" textAnchor="middle" fill="#ffffff">
      //     {email}
      //   </text>
      //   <text x="330" y="200" fontSize="14" textAnchor="middle" fill="#ffffff">
      //     {phone}
      //   </text>
      // </svg>
    );
  }
);
