import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { CiExport } from "react-icons/ci";

export default function ExportMenuButton({
  setSelectedElementIndex,
  setIsEditable,
  setBgImageSelected,
  printComponent,
  exportToImage,
  exportToPDF,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        onMouseEnter={(event) => {
          setSelectedElementIndex(null);
          setIsEditable(false);
          setBgImageSelected(false);
        }}
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="rounded-md bg-brand-main px-4 py-2 text-white flex items-center gap-2 font-semibold"
      >
        <CiExport className="size-5 " />
        Export
      </button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            exportToPDF();
            handleClose();
          }}
        >
          PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            exportToImage();
            handleClose();
          }}
        >
          Image
        </MenuItem>
      </Menu>
    </div>
  );
}
