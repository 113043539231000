import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { instance } from "common/Instance";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { CircularProgress } from "@mui/material";
import { cardStaticData } from "../admin/Reseller/static/staticData";
import { toJpeg } from "html-to-image";
import CardOne from "views/admin/arExperience/tabcomponent/Cardtemplates/CardOne";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { AWS_STATIC_PREFIX } from "common/Instance";
import { loadTexture } from "views/admin/arExperience/tabcomponent/EditorUtils";
import CommonBackdrop from "common/CommonBackdrop";
import * as mind from "mind-ar/dist/mindar-image.prod";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  p: 4,
  borderRadius: 2,
};

export default function Otp() {
  const email = useSelector((state) => state?.auth?.signupEmail);
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("Creating user");
  const [open, setOpen] = useState(false);
  const [arData, setArData] = useState(cardStaticData);
  const [compliningProgress, setCompilingProgress] = useState(0);
  const compiler = new mind.Compiler();
  const handleClose = () => setOpen(false);

  const user = useSelector((state) => state?.signupexp?.userdata);
  console.log(user, "user1");

  const createARExperience = async (token, userData, imageUrl, mindUrl) => {
    console.log(userData, "userData");
    setLoading1(true);
    const texture = await loadTexture(imageUrl);
    const textureWidth = texture.image.width;
    const textureHeight = texture.image.height;
    let targetImage;
    let aspectRatio;

    if (textureWidth >= textureHeight) {
      aspectRatio = textureWidth / textureHeight;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeight > textureWidth) {
      aspectRatio = textureHeight / textureWidth;
      targetImage = {
        src: imageUrl,
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }

    const texture1 = await loadTexture(
      AWS_STATIC_PREFIX + "images/dummyProfile.jpg"
    );
    const textureWidthforPhoto = texture1.image.width;
    const textureHeightforPhoto = texture1.image.height;
    let profilePhoto;
    let aspectRatio1;
    let baseSize = 0.8;
    if (textureWidthforPhoto >= textureHeightforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: AWS_STATIC_PREFIX + "images/dummyProfile.jpg",
        scale: { x: 1.959, y: 1.098, z: 0.8 },
      };
    }
    if (textureHeightforPhoto > textureWidthforPhoto) {
      aspectRatio1 = textureWidth / textureHeight;
      profilePhoto = {
        src: AWS_STATIC_PREFIX + "images/dummyProfile.jpg",
        scale: { x: baseSize, y: aspectRatio * baseSize, z: baseSize },
      };
    }

    try {
      const newIconLink = `https://wa.me/${userData?.phoneNumber}`;
      const newIconsArray = (arData?.icons ?? []).map((icon, index) => {
        if (index === 3) {
          return {
            ...icon,
            iconLink: `tel:+${userData?.phoneNumber}`,
            isLink: true,
          };
        }
        if (index === 4) {
          return {
            ...icon,
            vCardJson: {
              ...icon.vCardJson,
              firstName: userData?.fullName.split(" ")[0],
              lastName: userData?.fullName.split(" ")[1],
              photo: AWS_STATIC_PREFIX + "images/dummyProfile.jpg" ?? "",
              cellPhone: userData?.phoneNumber ?? "",
              email: userData?.email ?? "",
              workUrl: `https://sandboxar.immarsify.com/${userData?.username}/${userData?.username}`,
              note: userData?.category?.join(", "),
            },
          };
        }
        if (index === 5 || index === 2) {
          return {
            ...icon,
            iconLink: `mailto:${userData?.email}`,
            isLink: true,
          };
        }
        if (index === 6) {
          return {
            ...icon,
            iconLink: newIconLink,
            isLink: true,
          };
        }
        return icon;
      });
      console.log("New icons array:", newIconsArray);

      let data = {
        ...arData,
        userId: userData._id,
        userName: userData?.username,
        arexperienceName: userData?.fullName,
        name: userData?.username,
        targetImage: targetImage,
        mind: {
          src: mindUrl,
        },
        text: [{ ...arData?.text[0], body: userData?.fullName }],
        photos: [{ ...arData?.photos[0], ...(profilePhoto ?? "") }],
        icons: newIconsArray,
      };
      const response = await instance.post(
        `/api/ar/create_experience`,
        { ...data },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setArData(cardStaticData);
        toast.success(response?.data?.message ?? "Card created successfully");
        setLoading1(false);
        handleClose();
        navigate("/auth/sign-in");
      }
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  const uploadFile = async (file, assetType, token, data) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetType", assetType);

    try {
      const response = await instance.post(
        `api/ar/upload/${data?.username}/${data?.username}`,
        formData,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const createTarget = async (file, type, blobPreview, token, data) => {
    setLoading1(true);
    setDynamicTitle("Creating Target image");
    try {
      const imageUrl =
        type === "upload"
          ? await uploadFile(file[0], "image", token, data)
          : await uploadFile(file, "image", token, data);

      const dataList = await compiler.compileImageTargets(
        blobPreview,
        (progress) => {
          setCompilingProgress(Math.round(progress));
        }
      );
      setCompilingProgress(0);
      const exportedBuffer = await compiler.exportData();
      const blob = new Blob([exportedBuffer]);
      const mindFile = new File([blob], "target.mind", { type: blob.type });
      const mindUrl = await uploadFile(mindFile, "mind", token, data);

      toast.success("Target Image Uploaded Successfully");
      setLoading1(false);

      await createARExperience(token, data, imageUrl, mindUrl);
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
      setLoading1(false);
    }
  };

  const handleTarget = (token, userData) => {
    setDynamicTitle("Generating card for Experience");
    setLoading1(true);

    console.log(cardRef, "cardRef");
    if (cardRef.current === null) {
      return;
    }

    toJpeg(cardRef.current)
      .then((dataUrl) => {
        return fetch(dataUrl).then((res) => res.blob());
      })
      .then((blob) => {
        setLoading1(true);
        const image = new Image();
        image.src = URL.createObjectURL(blob);
        setTimeout(async () => {
          await createTarget(blob, "create", [image], token, userData);
        }, 500);
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const validationSchema = Yup.object({
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{5}$/, "OTP must be exactly 5 digits"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (value) => {
      setLoading(true);
      try {
        const response = await instance.post("api/auth/verify_otp", {
          otp: value.otp,
          email: email,
        });
        

        if (response.status === 200) {
          toast.success(response?.data?.message);
          setOpen(true);
          setTimeout(()=>{
            handleTarget(user?.token, user?.userData);
          },1000)
        

          setLoading(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message);
        setLoading(false);
        setOpen(false);
      }
    },
  });

  const handleResendOTP = async () => {
    try {
      const response = await instance.post("api/auth/resend-otp", {
        email,
      });
      if (response.status === 200) {
        toast.success("OTP sent successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error.message);
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:mt-32 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[2vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="mb-4 flex flex-col items-center ">
          <h4 className="mb-2.5 text-4xl font-bold text-navy-700 ">
            OTP Verification
          </h4>
          <p className="mb-2 ml-1  text-base text-gray-600">
            Enter the OTP sent to your registered email address
          </p>
          <p className="text-md  mb-4 ml-1 font-semibold text-gray-900">
            {email}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center gap-4">
          <OtpInput
            value={formik.values.otp}
            onChange={(otp) => formik.setFieldValue("otp", otp)}
            numInputs={5}
            isInputNum={true}
            separator={<span>-</span>}
            containerStyle={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "20px",
              width: "100%",
            }}
            inputStyle={{
              textAlign: "center",
              borderRadius: "5px",
              border: "1px solid darkgray",
              padding: "10px",
              width: "40px",
              height: "40px",
            }}
            renderInput={(props) => <input {...props} />}
          />
          <p className="text-sm text-red-500">
            {formik.touched.otp && formik.errors.otp}
          </p>
          <div className="mt-2 flex w-full flex-col items-end gap-2 ">
            <button
              className="linear mt-2 w-full rounded-xl bg-brand-500 px-10 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              type="submit"
              onClick={formik.handleSubmit}
            >
              {loading ? (
                <>
                  <CircularProgress sx={{ color: "white" }} size={30} />
                </>
              ) : (
                "Verify OTP"
              )}
            </button>
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not received OTP yet?{" "}
              <button
                className="text-brand-500 hover:text-brand-600 dark:text-white"
                onClick={handleResendOTP}
                disabled={loading}
              >
                Resend OTP
              </button>
            </span>
          </div>
        </div>
      </div>

      <ExperienceCard
        open={open}
        handleClose={handleClose}
        cardRef={cardRef}
        username={user?.userData?.username}
        fullName={user?.userData?.fullName}
      />

      <CommonBackdrop
        loading={loading1}
        title={dynamicTitle}
        loadingColor={"white"}
      />
    </div>
  );
}

const ExperienceCard = ({ open, handleClose, cardRef, username, fullName }) => {
  return (
    <div>
      <Modal
        open={open}
  
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-center">
            <CardOne
              ref={cardRef}
              username={username}
              experiencename={username}
              name={fullName}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};
