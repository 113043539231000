import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaMobileAlt, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";

const CardEleven = React.forwardRef(
  ({ username, experiencename, name, user , cardData}, ref) => {
    return (
      <div
        ref={ref}
        className="relative   h-[250px] w-[400px] overflow-hidden  bg-white shadow-lg"
      >
        {/* Abstract SVG Background */}

        <div className="absolute right-3   z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#0067B1" className="top-3" />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          {/* <img src={Logo("#0067B1")} className=" right-1 top-4   h-4 w-7" /> */}

          <Logo color={"#0067B1"} height={35} width={35} />
        </div>
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0,100 Q100,0 200,100 T400,100"
            fill="none"
            stroke="#f0f0f0"
            strokeWidth="2"
          />
          <path
            d="M0,150 Q100,50 200,150 T400,150"
            fill="none"
            stroke="#e0e0e0"
            strokeWidth="2"
          />
        </svg>

        {/* Card Content */}
        <div className="flex flex-col items-center pt-12 ">
          <div className="relative z-10 flex h-full flex-col break-all">
            <div>
              <h2
                className={` mb-2 flex items-center justify-center break-words capitalize break-all ${
                  name.length > 20 ? "text-2xl" : "text-3xl"
                }  font-bold `}
              >
                  {name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
              </h2>
            </div>
          </div>
          <QRCodeSVG
            value={`https://sandboxar.immarsify.com/${username}/${experiencename}`}
            fgColor="#ffff"
            bgColor="black"
            className="mb-4 h-[115px] w-[115px] z-20 text-[#fdd446]"
          />
        </div>
      </div>
    );
  }
);

export default CardEleven;



export const CardElevenBackSide = React.forwardRef(
  ({ username, experiencename, name, user , cardData}, ref) => {
    return (
      <div
        ref={ref}
        className="relative   h-[250px] w-[400px] overflow-hidden  bg-white shadow-lg"
      >
        {/* Abstract SVG Background */}

        <div className="absolute right-3   z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#0067B1" className="top-3" />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          {/* <img src={Logo("#0067B1")} className=" right-1 top-4   h-4 w-7" /> */}

          <Logo color={"#0067B1"} height={35} width={35} />
        </div>
        <svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 400 225"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0,100 Q100,0 200,100 T400,100"
            fill="none"
            stroke="#f0f0f0"
            strokeWidth="2"
          />
          <path
            d="M0,150 Q100,50 200,150 T400,150"
            fill="none"
            stroke="#e0e0e0"
            strokeWidth="2"
          />
        </svg>

        {/* Card Content */}
     
        <div className="relative  p-4  text-sm break-all text-gray-900">
        <div className="mt-8">

    
<h2 className={` font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`} }`}>
    {cardData?.companyName?.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')}
  </h2>

<div className="flex justify-center ">

<div className="flex flex-col gap-2 mt-4">


{cardData?.address && <p className="text-sm  flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" />
{cardData?.address}</p>}

{cardData?.email && <p className="text-sm  flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" />
{cardData?.email}</p>}

{cardData?.Phone && <p className="text-sm  flex gap-2"><FaMobileAlt className="min-w-5 min-h-5"/>
{cardData?.Phone}</p>}

</div>


</div>







</div>
        </div>
     
      </div>
    );
  }
);