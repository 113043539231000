import React from 'react'
import UserList from './Components/UserList'



const UserDashboard = () => {
  return (
   
        <UserList/> 
       
     
  )
}

export default UserDashboard