import { QRCodeSVG } from "qrcode.react";
import React from "react";
import { LuNfc } from "react-icons/lu";
import NfcImmarsify from "assets/svg/immarsifyM.svg";
import Logo from "./logo/Logo";
import { IoLocationSharp } from "react-icons/io5";
import { SiGooglemaps } from "react-icons/si";
import { TbWorldWww } from "react-icons/tb";
import { MdEmail, MdOutlineMail } from "react-icons/md";
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaMobileAlt, FaYoutube } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneFlip } from "react-icons/fa6";
const CardEight = React.forwardRef(
  ({ username, experiencename, name , cardData}, ref) => {
    return (
      <div
        ref={ref}
        className=" relative  h-[250px] w-[400px]  overflow-hidden  bg-black shadow-lg"
      >
        <div className="absolute right-4 top-1  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#0067B1" className="    top-3 " />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          <Logo color={"#0067B1"} height={35} width={35} />
        </div>
        <div className="relative p-6">
          <svg
            className="absolute inset-0 h-full w-full opacity-20"
            viewBox="0 0 800 400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="200" cy="100" r="80" fill="rgba(255, 0, 0, 0.3)" />
            <polygon
              points="400,100 700,300 400,500"
              fill="rgba(255, 255, 255, 0.2)"
            />
            <rect
              x="50"
              y="150"
              width="200"
              height="100"
              fill="rgba(255, 0, 0, 0.2)"
            />
          </svg>

          <div className="relative z-10 flex flex-col items-center text-center text-white">
            <h2
              className={` mb-2 flex items-center justify-center break-words capitalize break-all ${
                name.length > 20 ? "text-2xl" : "text-3xl"
              }  font-bold `}
            >
               {name.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')}
            </h2>
            {/* <p className="mt-2 text-lg">Powered by Immarsify</p> */}
            <div className="mt-2 rounded bg-white p-2">
              <QRCodeSVG
                value={`https://sandboxar.immarsify.com/${username}/${experiencename}`}
                size={100}
                bgColor="#ffffff"
                fgColor="#000000"
                level="Q"
                includeMargin={false}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default CardEight;



export const CardEightBackSide = React.forwardRef(
  ({ username, experiencename, name, cardData }, ref) => {
    return (
      <div
        ref={ref}
        className=" relative  h-[250px] w-[400px]  overflow-hidden  bg-black shadow-lg"
      >
        <div className="absolute right-4 top-1  z-10 flex items-center justify-end ">
          <LuNfc size={25} color="#0067B1" className="    top-3 " />
          {/* <img src={Logo} className="absolute left-4 top-2 w-[110px]" /> */}
          <Logo color={"#0067B1"} height={35} width={35} />
        </div>
        <div className="relative">
          <svg
            className="absolute inset-0 h-full w-full opacity-20"
            viewBox="0 0 800 400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="200" cy="100" r="80" fill="rgba(255, 0, 0, 0.3)" />
            <polygon
              points="400,100 700,300 400,500"
              fill="rgba(255, 255, 255, 0.2)"
            />
            <rect
              x="50"
              y="150"
              width="200"
              height="100"
              fill="rgba(255, 0, 0, 0.2)"
            />
          </svg>

          <div className="relative  p-4  text-sm break-all text-white">
          <div className="mt-8">

    
<h2 className={` font-bold mb-2 text-center ${cardData?.companyName?.length > 20 ? `text-2xl` : `text-3xl`} }`}>
    {cardData?.companyName?.split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')}
  </h2>

<div className="flex justify-center ">

<div className="flex flex-col gap-2 mt-4">


{cardData?.address && <p className="text-sm  flex gap-2"><IoLocationSharp className="min-w-5 min-h-5" />
{cardData?.address}</p>}

{cardData?.email && <p className="text-sm  flex gap-2"><MdOutlineMail className="min-w-5 min-h-5" />
{cardData?.email}</p>}

{cardData?.Phone && <p className="text-sm  flex gap-2"><FaMobileAlt className="min-w-5 min-h-5"/>
{cardData?.Phone}</p>}

</div>


</div>







</div>
        </div>
        </div>
      </div>
    );
  }
);