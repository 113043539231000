import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_ADD_TEXT} from "../../../../redux/reducerSlice/worldTrackSlice";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { MdOutlineClear } from "react-icons/md";

const AddingText = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [textContent, setTextContent] = useState("");

  return (
    <Drawer anchor="left" open={open} onClose={handleClose}>
      <Box sx={{ width: 500 }} role="presentation" className="relative">

      <div className="mb-2 flex  items-center justify-between bg-white p-3  text-gray-900 shadow-lg ">
              <h4 className=" text-lg font-semibold">Text</h4>
              <span className='cursor-pointer' onClick={()=>{
                handleClose()
              }}> <MdOutlineClear size={20} /></span>
            </div>

        <div className="mb-9 mt-0 w-full p-2 ">
          {/* Input for text content */}
          <div className="flex w-full flex-col items-start justify-start gap-2 ">
            <TextField
              placeholder="Enter text content"
              value={textContent}
              onChange={(e) => setTextContent(e.target.value)}
              multiline
              rows={4}
              className="mb-4 w-full rounded-lg border"
            />
            {/* Button to add and preview text */}
            <span
              onClick={() => {
               
                dispatch(
                  SET_ADD_TEXT({
                    id: uuidv4(),
                    color: '#000000',
                    backgroundColor: '#000000',
                    fontSize: 8,
                    font: '',
                    align: 'left',
                    weight: 'normal',
                    type: "text",
                    style: 'normal',
                    body: textContent,
                    position: { x: 0, y: 0.05, z: 0 },
                    rotation: { x: -1.66, y: 0.006, z: 0 },
                    scale: { x: 1, y: 1, z: 1 },
                    isAnimation: false,
                    animation: {
                      duration: 0,
                      delay: 0,
                      direction: "topToBottom"
                    }
                  })
                );
                setTextContent("");
                handleClose()
              }}
              className="rounded bg-[#3d2fa9] p-2 text-white cursor-pointer"
            >
              Add Text
            </span>
          </div>
        </div>
      </Box>
    </Drawer>
  );
};

export default AddingText;
